import { yupResolver } from '@hookform/resolvers/yup';
import React, { PropsWithChildren } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';

import { TransferCreateNewContact } from '@components/TransferNewContactForm';
import { TransferTab } from '@components/TransferTabs';
import {
  PreparedFile,
  TransferFormDetails,
  transferFormDetailsValidation,
  TransferFormDetailsWithType
} from '@screens/Dashboard/Transfer/TransferDetails';
import { captureException } from '@sentry/react';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import { Transaction } from '@services/bapi/transactions';
import {
  Attachment,
  createTransfer,
  CreateTransferData,
  TransferType
} from '@services/bapi/transfer';
import { removeAllWhiteSpaces } from '@utils';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

const TRANSFER_TYPE_CODE = 90200;

type TransferStatus = 'INITIAL' | 'FAILED' | 'SUCCESS';

export type TransferState = {
  debitAccount?: BankAccountWithCurrencies;
  accountToDebitId: string;
  accountToDebitName: string;
  accountToDebitAccountHolderName: string;
  accountToCreditName: string;
  accountToCreditAccountHolderName: string;
  accountToCreditIban: string;
  accountToCreditAccountNumber: string;
  accountToCreditBankNumber: string;
  isInternalTransfer?: boolean;
  // Details
  amount: number;
  currency: string;
  referenceMessage: string;
  date: Date;
  typeCode: number;
  transferType?: TransferType;
  amountFx?: number | undefined;
  currencyFx?: string | undefined;
  // Beneficiary
  name: string;
  swiftBic?: string;
  bankName?: string;
  email?: string;
  saveBeneficiary?: boolean;
  street?: string;
  telephone?: string;
  country?: string;
  countryCode: string;
  houseNumber?: string;
  city?: string;
  zipCode?: string;
  label?: string;
  isGlobal?: boolean;
  files?: PreparedFile[];
};

type TransferContextData = {
  currentStep: number;
  transferStatus: TransferStatus;
  transferStatusMessage?: string;
  transferState: TransferState;
  handleDebitAccountSelect: (account: BankAccountWithCurrencies) => void;
  handleContactInfo: (
    values: TransferCreateNewContact,
    isInternalTransfer?: boolean,
    goNextStep?: boolean
  ) => void;
  handleTransferDetails: (
    values: Partial<TransferFormDetailsWithType>,
    files?: PreparedFile[]
  ) => void;
  onPrevious: () => void;
  useTransferDetails: UseFormReturn<Partial<TransferFormDetails>>;
  isTransferring: boolean;
  jumpToStep: (step: number) => void;
  selectedTab: TransferTab;
  setSelectedTab: (tab: TransferTab) => void;
  handleClearContactInfo: () => void;
};

type TransferProviderProps = {
  totalSteps: number;
  repeatTransaction?: {
    transaction: Transaction;
    debitAccount: BankAccountWithCurrencies;
  };
};

export const TransferProvider: React.FC<
  PropsWithChildren<TransferProviderProps>
> = ({ children, totalSteps, repeatTransaction }) => {
  const [transferState, setTransferState] = React.useState<TransferState>(
    repeatTransaction
      ? getDetailsFromTransfer(
          repeatTransaction.transaction,
          repeatTransaction.debitAccount
        )
      : INITIAL_TRANSFER_STATE
  );

  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedTab, setSelectedTab] =
    React.useState<TransferTab>('make-transfer');

  const [transferStatus, setTransferStatus] =
    React.useState<TransferStatus>('INITIAL');
  const [transferStatusMessage, setTransferStatusMessage] = React.useState();

  const useTransferDetails = useForm({
    resolver: yupResolver(transferFormDetailsValidation),
    defaultValues: {
      amount: transferState.amount,
      currency: transferState.currency,
      date: transferState.date,
      reference: transferState.referenceMessage,
      isInternalTransfer: false,
      currencyFx: transferState.currencyFx,
      amountFx: transferState.amountFx
    }
  });

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        // Encoding file and removing information about type in Base64 string
        resolve(
          (reader.result as string).substring(
            (reader.result as string).indexOf(',') + 1
          )
        );
      reader.onerror = error => reject(error);
    });
  };

  const executeTransfer = useMutation(
    'createTransfer',
    (data: CreateTransferData) => createTransfer(data)
  );

  const onNext = React.useCallback(() => {
    if (totalSteps === currentStep) {
      return;
    }

    setCurrentStep(currentStep + 1);
  }, [currentStep, totalSteps]);

  const onPrevious = React.useCallback(() => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleDebitAccountSelect = React.useCallback(
    (account: BankAccountWithCurrencies) => {
      useTransferDetails.reset({
        amount: transferState.amount,
        currency: transferState.currency,
        date: transferState.date,
        reference: transferState.referenceMessage
      });

      setTransferState({
        ...INITIAL_TRANSFER_STATE,
        debitAccount: account,
        accountToDebitId: account.iban,
        accountToDebitName: account?.alias || account.productDescription,
        accountToDebitAccountHolderName: account.accountHolderName
      });

      onNext();
    },
    [onNext, transferState, useTransferDetails]
  );

  const handleContactInfo = React.useCallback(
    (
      values: TransferCreateNewContact,
      isInternalTransfer = false,
      goNextStep = true
    ) => {
      const trimedBic = removeAllWhiteSpaces(values.swiftBic);
      const countryCode = trimedBic?.slice(4, 6);

      const isSouthAfricaClient = countryCode?.toLowerCase() === 'za';

      setTransferState(prevState => ({
        ...prevState,
        name: values.name,
        accountToCreditIban: removeAllWhiteSpaces(values.iban),
        accountToCreditAccountNumber: removeAllWhiteSpaces(
          values.accountNumber
        ),
        accountToCreditBankNumber: isSouthAfricaClient
          ? removeAllWhiteSpaces(values.bankKey, true)
          : removeAllWhiteSpaces(values.bankKey, false),
        accountToCreditName: values.name,
        accountToCreditAccountHolderName: values.accountHolderName,
        swiftBic: removeAllWhiteSpaces(values.swiftBic),
        bankName: values.bankName,
        saveBeneficiary: values.saveBeneficiary,
        street: values.street,
        city: values.city,
        houseNumber: values.houseNumber,
        countryCode: values.countryCode,
        zipCode: values.zipCode,
        email: values.email,
        telephone: values.telephone,
        country: values.country,
        label: values.label,
        transferType: values.transferType as TransferType,
        isInternalTransfer,
        isGlobal: values.isGlobal
      }));
      if (goNextStep) {
        onNext();
      }
    },
    [onNext]
  );

  const handleClearContactInfo = React.useCallback(() => {
    setTransferState(prevState => ({
      ...prevState,
      accountToCreditIban: '',
      accountToCreditAccountNumber: '',
      accountToCreditBankNumber: '',
      accountToCreditName: '',
      accountToCreditAccountHolderName: '',
      swiftBic: '',
      name: '',
      label: '',
      bankName: '',
      saveBeneficiary: false,
      street: '',
      city: '',
      houseNumber: '',
      countryCode: '',
      zipCode: '',
      email: '',
      telephone: '',
      isInternalTransfer: false
    }));
  }, []);

  const handleCreateTransfer = React.useCallback(
    async (state: typeof transferState, files: PreparedFile[]) => {
      try {
        const encodedFiles = files.map(async file => {
          const encodedContent = await getBase64(file.content).then(
            data => data as string
          );
          return {
            name: file.name,
            content: encodedContent
          };
        });

        const transferAttachments: Attachment[] =
          await Promise.all(encodedFiles);
        const data: CreateTransferData = {
          transferType: state.transferType,
          data: {
            accountId: `${state.accountToDebitId}-${state.currency}`,
            amount: String(state.amount),
            currency: state.currency,
            paymentNote: state.referenceMessage,
            postDate: state.date,
            typeCode: state.typeCode,
            recipientIban: state.accountToCreditIban || undefined,
            recipientAccountNumber:
              state.accountToCreditAccountNumber || undefined,
            recipientBankNumber: state.accountToCreditBankNumber || '',
            // NOTE: internal transfers should have a account holder name as the recipient account name
            recipientAccountName: state.isInternalTransfer
              ? state.accountToCreditAccountHolderName
              : state.accountToCreditName,
            recipientBic: state.swiftBic,
            beneficiaryAddress: {
              streetName: state.street,
              cityName: state.city,
              houseId: state.houseNumber,
              countryCode: state.countryCode,
              streetPostalCode: state.zipCode
            },
            transferAttachments
          }
        };

        const response = await executeTransfer.mutateAsync({
          ...data
        });

        if (
          response.data?.warningMessages &&
          response.data?.warningMessages.length > 0
        ) {
          setTransferStatusMessage(response.data?.warningMessages);
        }
        setTransferStatus('SUCCESS');
      } catch (error) {
        captureException(error);
        console.error(error);
        setTransferStatus('FAILED');
        setTransferStatusMessage(
          (error as AxiosError<any>).response?.data?.message ??
            'Something went wrong'
        );
      }
    },
    [executeTransfer]
  );

  const handleTransferDetails = React.useCallback(
    (values: TransferFormDetailsWithType, files: PreparedFile[]) => {
      const nextState = {
        ...transferState,
        amount: values.amount,
        date: values.date,
        referenceMessage: values.reference,
        currency: values.currency,
        transferType: values.transferType,
        files
      };

      setTransferState(nextState);
      handleCreateTransfer(nextState, files);
    },
    [handleCreateTransfer, transferState]
  );

  const jumpToStep = (step: number) => {
    if (step === currentStep) return;

    setCurrentStep(step);
  };

  React.useEffect(() => {
    // NOTE(diogo-menezes) the repeat transaction is disabled
    if (repeatTransaction) {
      setCurrentStep(1);
    }
  }, [repeatTransaction]);

  return (
    <TransferContext.Provider
      value={{
        currentStep,
        transferStatus,
        transferState,
        handleDebitAccountSelect,
        handleContactInfo,
        handleTransferDetails,
        onPrevious,
        useTransferDetails,
        isTransferring: executeTransfer.isLoading,
        jumpToStep,
        transferStatusMessage,
        selectedTab,
        setSelectedTab,
        handleClearContactInfo
      }}
    >
      {children}
    </TransferContext.Provider>
  );
};

export const INITIAL_TRANSFER_STATE: TransferState = {
  accountToDebitId: '',
  accountToDebitName: '',
  accountToDebitAccountHolderName: '',
  accountToCreditName: '',
  accountToCreditAccountHolderName: '',
  accountToCreditIban: '',
  accountToCreditAccountNumber: '',
  accountToCreditBankNumber: '',
  amount: undefined,
  currency: '',
  referenceMessage: '',
  date: dayjs().subtract(1, 'd').toDate(),
  typeCode: TRANSFER_TYPE_CODE,
  swiftBic: '',
  bankName: '',
  saveBeneficiary: false,
  debitAccount: null,
  countryCode: '',
  name: '',
  isInternalTransfer: false,
  transferType: 'SEPA',
  amountFx: 0,
  currencyFx: undefined
};

const getDetailsFromTransfer = (
  transaction: Transaction,
  debitAccount: BankAccountWithCurrencies
): TransferState => {
  // Used for repeat transfers. If repeat transfers get available again, counterPartyBankAccountId must be handled to fill accountToCreditIban, accountToCreditAccountNumber and/or accountToCreditBankNumber
  return {
    accountToDebitId: transaction.accountId,
    accountToDebitName: transaction.accountProductId,
    accountToDebitAccountHolderName: '',
    accountToCreditIban: transaction.counterPartyBankAccountId,
    accountToCreditAccountNumber: transaction.counterPartyBankAccountId,
    accountToCreditBankNumber: transaction.counterPartyBankAccountId,
    accountToCreditName: transaction.counterPartyAccountHolder,
    accountToCreditAccountHolderName: '',
    amount: transaction.amount,
    currency: transaction.currency,
    referenceMessage: transaction.paymentNote,
    date: new Date(),
    typeCode: TRANSFER_TYPE_CODE,
    name: '',
    swiftBic: '',
    bankName: '',
    debitAccount,
    countryCode: transaction.counterPartyBankCountry,
    amountFx: 0,
    currencyFx: ''
  };
};

const TransferContext = React.createContext<TransferContextData>(
  {} as TransferContextData
);

export const useTransfer = () => React.useContext(TransferContext);
