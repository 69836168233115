import Bank from '@assets/icons/icon_bank.svg?react';
import { addSpacesEvery4Letters } from '@utils';
import { FC } from 'react';

interface AccountOverviewDetailsHeaderProps {
  accountName: string;
  iban: string;
  onClick?: () => void;
}

export const AccountOverviewDetailsHeader: FC<
  AccountOverviewDetailsHeaderProps
> = ({ accountName, iban, onClick }) => {
  return (
    <div className="page-header-button">
      <div className="page-header-button__text">
        <h3 className="small">{accountName}</h3>
        <h4 className="secondary">{addSpacesEvery4Letters(iban)}</h4>
      </div>
      <button
        className="page-header-button__button"
        type="button"
        onClick={onClick}
        aria-label="details button"
      >
        <Bank />
      </button>
    </div>
  );
};
