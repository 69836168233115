import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface PageSecondaryHeaderProps {
  title?: string;
  titleRedirect?: any;
  secondaryTitle?: string;
}

export const PageSecondaryHeader: FC<
  PropsWithChildren<PageSecondaryHeaderProps>
> = ({ title, secondaryTitle, titleRedirect, children }) => {
  return (
    <section>
      <div className="page-secondaryHeader">
        {titleRedirect ? (
          <Link to={titleRedirect} replace>
            <h3 className="page-secondaryHeader__title">{title}</h3>
          </Link>
        ) : (
          <h3 className="page-secondaryHeader__title">{title}</h3>
        )}
        {secondaryTitle && (
          <>
            <span>&gt;</span>
            <h4 className="page-secondaryHeader__secondary-title">
              {secondaryTitle}
            </h4>
          </>
        )}
      </div>
      {children}
    </section>
  );
};
