import { Button } from '@components/Button';
import routes from '@res/routes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="page404">
      <div className="page404__background">
        <div>
          <h1>Ops.</h1>
          <p className="paragraph--large">
            You may have entered the wrong address.
          </p>
          <p className="paragraph--large">The link is not available</p>
          <Button
            variant="underline"
            type="button"
            onClick={() =>
              navigate(routes.dashboard.accountOverview, { replace: true })
            }
          >
            BACK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
