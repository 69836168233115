import React from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@components/Button';
import { CustomDateSelector } from '@components/CustomDateSelector';
import { Input } from '@components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { GetTransactionsFilter } from '@services/bapi/transactions';
import { statusOptions } from '@utils/transactionHelpers';
import * as Yup from 'yup';

type Period = '' | 'WEEK' | 'MONTH' | 'CUSTOM';

export type Options = { value: string | number; label: string }[];

export type FilterForm = GetTransactionsFilter & { period: Period };

type FiltersProps = {
  onApply: (values: FilterForm) => void;
};

export const Filters: React.FC<FiltersProps> = ({ onApply }) => {
  const {
    register,
    watch,
    reset,
    handleSubmit,
    resetField,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  function handleReset() {
    reset({
      date: undefined,
      senderRecipient: '',
      status: '',
      type: '',
      period: undefined
    });
  }

  return (
    <div className="filters">
      <h1 className="semiBold filters__title">Filter</h1>
      <Input
        variant="background"
        className="filters__input"
        id="sender-recipient"
        placeholder="Sender/Recipient"
        name="senderRecipient"
        register={register}
        type="text"
        error={errors?.senderRecipient?.message}
      />
      <div className="filters__select">
        <select id="status" {...register('status')}>
          <option value="">Status</option>
          {statusOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <h4 className="filters__arrow-pointer">▼</h4>
      </div>
      <div className="filters__select">
        <select id="type" {...register('type')}>
          <option value="">Type</option>
          {typeOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <h4 className="filters__arrow-pointer">▼</h4>
      </div>
      {/* <div className="filters__select">
        <select id="currency" {...register('currency')}>
          <option value="">Currency</option>
          {availableCurrencies.map(currency => (
            <option key={currency} value={currency}>
              {currency}
            </option>
          ))}
        </select>
        <h4 className="filters__arrow-pointer">▼</h4>
      </div> */}

      <CustomDateSelector
        resetField={resetField}
        register={register}
        errors={errors}
        watch={watch}
      />

      <Button
        className="filters__apply-filters"
        onClick={handleSubmit(onApply)}
        disabled={!isDirty}
        type="button"
      >
        Apply
      </Button>

      <Button onClick={handleReset} variant="underline" type="button">
        Clear Filters
      </Button>
    </div>
  );
};

const typeOptions: Options = [
  { label: 'Credit', value: 'CREDIT' },
  { label: 'Debit', value: 'DEBIT' }
];

const validationSchema = Yup.object().shape({
  currency: Yup.string().notRequired(),
  senderRecipient: Yup.string().notRequired(),
  status: Yup.string().notRequired(),
  type: Yup.string().notRequired(),
  period: Yup.string().notRequired().nullable(),
  date: Yup.object()
    .shape({
      startDate: Yup.date(),
      endDate: Yup.date()
    })
    .notRequired()
    .when('period', ([period], schema) => {
      return period === 'CUSTOM'
        ? Yup.object().shape({
            startDate: Yup.date()
              .required()
              .typeError('From date is required')
              .max(new Date(), "From date shouldn't  be greater than today"),
            endDate: Yup.date()
              .required()
              .max(new Date(), "To date shouldn't  be greater than today")
              .typeError('To date is required')
              .test(
                'greater',
                'Must be greater than From date',
                (value, context) => {
                  if (
                    value?.getTime() >= context.parent?.startDate?.getTime()
                  ) {
                    return true;
                  }

                  return false;
                }
              )
          })
        : schema.notRequired();
    })
});
