import { me, MeResponse } from '@services/bapi/auth';
import { useQuery, UseQueryOptions } from 'react-query';

export default function useMe<T extends any = MeResponse>(
  options?: UseQueryOptions<MeResponse, unknown, T>
) {
  return useQuery('me', () => me().then(r => r.data), {
    refetchOnWindowFocus: false,
    ...options
  });
}
