import { captureException } from '@sentry/react';
import { Beneficiary, createBeneficiary } from '@services/bapi/beneficiaries';
import { useMutation, useQueryClient } from 'react-query';

export default function useCreateBeneficiary() {
  const queryClient = useQueryClient();

  return useMutation(
    (newBeneficiary: Beneficiary) => createBeneficiary(newBeneficiary),
    {
      onSuccess: resp => {
        queryClient.setQueryData('beneficiaries', [
          queryClient.getQueryData('beneficiaries'),
          { ...resp.data.beneficiary }
        ]);
      },
      onError: error => {
        captureException(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries('beneficiaries');
      }
    }
  );
}
