import {
  GetAccountAliasResponse,
  getAccountsAlias
} from '@services/bapi/accounts';
import { UseQueryOptions, useQuery } from 'react-query';

export default function useAccountAlias<
  T extends any = GetAccountAliasResponse
>(options?: UseQueryOptions<GetAccountAliasResponse, unknown, T>) {
  return useQuery('alias', () => getAccountsAlias().then(res => res.data), {
    retry: 2,
    refetchOnWindowFocus: false,
    ...options
  });
}
