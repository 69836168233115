import { Currencies } from '@res/availableCurrencies';
import { formatDateToODATAFormat } from '@utils/date';
import { AxiosResponse } from 'axios';
import { InferType, array, boolean, date, number, object, string } from 'yup';
import { bapi } from './client';

export type GetTransactionsFilter = {
  currency?: string;
  date?: { startDate: Date; endDate: Date };
  senderRecipient?: string;
  status?: string;
  type?: 'DEBIT' | 'CREDIT' | '';
  account?: string;
  page?: number;
  limit?: number;
  orderBy?: string;
  withPaymentNotes: string;
};

const paymentNotesDTO = object({
  typeCode: string().required(),
  note: string().required()
});

export const transactionDTO = object({
  accountId: string().required(),
  transactionId: string().required(),
  typeCode: string().required(),
  typeName: string().required(),
  paymentTypeCode: string().required(),
  paymentTypeName: string().required(),
  transactionTypeCode: string().required(),
  transactionTypeName: string(),
  communicationChannelCode: string().required(),
  communicationChannelName: string().required(),
  accountHolderId: string().required(),
  accountProductType: string().required(),
  accountProductId: string().required(),
  postingDate: date().required(),
  valueDate: date().required(),
  statusName: string().required(),
  debitIndicator: boolean().required(),
  amount: number().required(),
  currency: string().required(),
  amountAc: number().required(),
  currencyAc: string().required(),
  exchangeRateTypeCode: string(),
  exchangeRateTypeName: string(),
  exchangeRate: number().required(),
  prenoteId: string(),
  reversalReasonCode: string(),
  reversalReasonName: string(),
  reversalAllowed: boolean().required(),
  refundAllowed: boolean().required(),
  returnReasonCode: string(),
  returnReasonName: string(),
  counterPartyAccountHolder: string(),
  counterPartyBankCountry: string(),
  counterPartyBankId: string(),
  counterPartyBankAccountId: string(),
  counterPartyBankAccount: string(),
  counterPartyBic: string(),
  paymentTransactionOrderId: string(),
  paymentTransactionOrderItemId: string(),
  paymentNote: string().required(),
  referenceAdjustmentTransactionId: string(),
  pendingSettlementEntryReference: string(),
  isCancellationTransaction: boolean().required(),
  cancelledTransactionReference: string(),
  cancellationTransactionReference: string(),
  creationTs: date().required(),
  creationUser: string().required(),
  changeUser: string(),
  releaseTs: date().required(),
  releaseUser: string().required(),
  paymentNotes: array().of(paymentNotesDTO).required()
});

export type Transaction = InferType<typeof transactionDTO>;

export type GetTransactionsResponse = {
  transactions: Transaction[];
  total: number;
  nextPageSkip: number;
};

export async function getTransactions(filter: GetTransactionsFilter) {
  let query = '';

  if (filter.date?.startDate && filter.date?.endDate) {
    const startDate = formatDateToODATAFormat(filter.date?.startDate);
    const endDate = formatDateToODATAFormat(filter.date?.endDate);

    query += `startDate=${startDate}&endDate=${endDate}`;
  }

  if (filter.currency) {
    query += `&currency=${filter.currency}`;
  }

  if (filter.senderRecipient) {
    query += `&senderRecipient=${filter.senderRecipient}`;
  }

  if (filter.status) {
    query += `&statusCode=${filter.status}`;
  }

  if (filter.type) {
    query += `&transactionType=${filter.type}`;
  }

  if (filter.page && filter.limit) {
    const { page, limit } = filter;

    query += `&skip=${page * limit - limit}`;
  }

  if (filter.limit) {
    query += `&limit=${filter.limit}`;
  }

  if (filter.orderBy) {
    query += `&orderBy=${filter.orderBy}`;
  }

  if (filter.withPaymentNotes) {
    query += `&withPaymentNotes=${filter.withPaymentNotes}`;
  }

  return bapi.get<GetTransactionsResponse>(
    `/v1/accounts/${filter.account}/transactions?${query}`
  );
}

export type GetTransactionDetailsParams = {
  accountId: string;
  transactionId: string;
};

export const getTransactionDetails = ({
  accountId,
  transactionId
}: GetTransactionDetailsParams) => {
  return bapi.get<Transaction>(
    `/v1/accounts/${accountId}/transactions/${transactionId}`
  );
};

export type GetQuoteRequestParams = {
  sourceCurrency: Currencies;
  targetCurrency: Currencies | undefined;
  amountCurrency: Currencies;
  amount: number;
};

export type PostQuotePayload = {
  clientOrderUUID: string;
  sourceCurrency: Currencies;
  targetCurrency: Currencies;
  amountCurrency: Currencies;
  amount: number;
  midRate: number;
  customerAccount: string;
  referenceNote: string;
};

export const getQuoteResponseDTO = object({
  exchangeRate: number(),
  expireDate: date(),
  clientOrderUUID: string()
});

export type GetQuoteResponse = InferType<typeof getQuoteResponseDTO>;

export async function getQuote(
  params: GetQuoteRequestParams
): Promise<AxiosResponse<GetQuoteResponse>> {
  // HACK: reset params type cause URLSearchParams doesn't support number as a type
  const searchParams = new URLSearchParams(params as any).toString();
  const response = await bapi.get<GetQuoteResponse>(
    `/v1/fx/quote?${searchParams}`
  );
  return response;
}

export async function postQuote(
  payload: PostQuotePayload
): Promise<AxiosResponse<boolean>> {
  const response = await bapi.post<boolean>(`/v1/fx/quote`, payload);
  return response;
}
