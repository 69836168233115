import IconCheck from '@assets/icons/icon_check.svg?react';
import { Step } from '@screens/Dashboard/AccountSettings/Reset/ResetLoginMfa';
import classNames from 'classnames';
import React from 'react';

type StepsInfoProps = {
  stepsList: Step[];
  currentStep: number;
};

const StepsInfo: React.FC<StepsInfoProps> = ({ stepsList, currentStep }) => {
  return (
    <div className="steps-info">
      {stepsList.map((step, index) => {
        return (
          <div className="steps-info__step" key={`step-${index + 1}`}>
            <div
              className={classNames(
                'steps-info__number',
                currentStep === index + 1 && 'steps-info__number-active'
              )}
            >
              {index > 0 && (
                <hr
                  className={classNames(
                    'steps-info__number-left',
                    currentStep > index && 'completed'
                  )}
                />
              )}
              <span
                className={classNames(currentStep > index + 1 && 'completed')}
              >
                {currentStep > index + 1 ? <IconCheck /> : index + 1}
              </span>
              {index + 1 < stepsList.length && (
                <hr
                  className={classNames(
                    'steps-info__number-right',
                    currentStep > index + 1 && 'completed'
                  )}
                />
              )}
            </div>
            <p>{step.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default StepsInfo;
