import { Flag } from '@components/Flag';
import InputNumber from 'rc-input-number';
import React, { FC, useCallback, useMemo } from 'react';
// import { formatCurrencyWithoutCurrencyCode } from '@utils/index';
import { FieldError } from '@components/Input';
import {
  Currencies,
  MAPPING_CURRENCIES_FULL_NAMES,
  allCurrencies
} from '@res/availableCurrencies';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

type CurrencyInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  nameCurrency: string;
  nameAmount: string;
  error?: string;
  className?: string;
  availableCurrencyCodes?: ReadonlyArray<Currencies>;
  exceptedCurrencyCodes?: ReadonlyArray<Currencies>;
  register?: any;
  control?: any;
  helperText?: string;
  handleOnBlur?: any;
};

type CurrencyOption = {
  image: FC;
  label: Currencies;
  value: Currencies;
  extraLabel?: string;
};

export const CurrencyInput: FC<CurrencyInputProps> = ({
  availableCurrencyCodes,
  exceptedCurrencyCodes,
  register,
  nameAmount,
  nameCurrency,
  control,
  error,
  helperText,
  ...rest
}) => {
  const selectOptions = useMemo(() => {
    const currencies = availableCurrencyCodes ?? allCurrencies;
    return currencies
      .filter(currency => !exceptedCurrencyCodes?.includes(currency))
      .map(currency => {
        const currencyFullName = MAPPING_CURRENCIES_FULL_NAMES[currency];
        return {
          value: currency,
          // HACK: made a complex label for searching not only by code
          // but also by country name
          label: `${currency}_${currencyFullName}`,
          image: <Flag currencyCode={currency} />,
          extraLabel: currencyFullName
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [availableCurrencyCodes, exceptedCurrencyCodes]);

  const selectedCountry = useCallback(
    country => <SelectOption country={country} />,
    []
  );

  return (
    <>
      <div
        className={classNames(
          'currency-input',
          error ? 'currency-input__errorMessage' : undefined
        )}
      >
        <Controller
          name={nameAmount}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <InputNumber
              prefixCls="amount-input"
              placeholder="0"
              name={name}
              id="currency"
              type="number"
              value={value}
              onChange={val => onChange(val)}
              step="any"
              min={0}
            />
          )}
        />
        <Controller
          render={({ field }) => (
            <Select
              className="react-select"
              classNamePrefix="react-select"
              isSearchable
              defaultValue={selectOptions[0]}
              options={selectOptions}
              value={{
                value: field.value,
                label: field.value,
                image: <Flag currencyCode={field.value} />
              }}
              formatOptionLabel={selectedCountry}
              isDisabled={rest.disabled}
              onChange={(option: CurrencyOption) =>
                field.onChange(option.value)
              }
            />
          )}
          name={nameCurrency}
          control={control}
        />
      </div>
      {error ? (
        <FieldError error={error} />
      ) : (
        <h4 className="regular currency-input-helper-text">{helperText}</h4>
      )}
    </>
  );
};

const SelectOption: FC<{
  country: CurrencyOption | undefined;
}> = ({ country }) => {
  const { label, extraLabel } = country;
  const currencyCode = label?.split('_')[0];

  return (
    <>
      {country.image}
      <h3 className="small">{currencyCode}</h3>
      <h4 className="extra-label">
        {extraLabel && <span className="secondary"> - {extraLabel}</span>}
      </h4>
    </>
  );
};
