export function getCookie(cookieName: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);

  // NOTE: in case the user has more than one
  // cookie with the same name, we will only work with the first one
  if (parts.length > 1) {
    const cookieValue = parts.pop().split(';').shift();
    console.debug(
      `[getCookie] resolve the cookie ${cookieName}:${cookieValue}`
    );
    return cookieValue;
  }
  console.warn(`[getCookie] couldn't resolve the cookie ${cookieName}`);
  return undefined;
}

export function getBooleanCookie(cookieName: string): boolean | undefined {
  const cookie = getCookie(cookieName);
  if (cookie === undefined) {
    return undefined;
  }

  return cookie.toLowerCase() === 'true';
}
