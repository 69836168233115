import React, { FC } from 'react';

/**
 * @Timer component created by @arungopi
 * it handles count down time
 */
type TimerProps = {
  isActive?: boolean;
  Time?: number;
  minutes?: boolean;
  seconds?: boolean;
  timerRunningMessage?: string;
  timerCompletedMessage?: string;
  EndTimerDirectCallFunction?: () => void;
};
export const Timer: FC<TimerProps> = ({
  isActive = false,
  Time,
  minutes = false,
  seconds = false,
  timerRunningMessage = '',
  timerCompletedMessage = '',
  EndTimerDirectCallFunction
}) => {
  const [remainTime, setRemainTime] = React.useState(Time);
  const [display, setDisplay] = React.useState();
  React.useEffect(() => {
    let timerId: any;
    if (isActive) {
      if (remainTime >= 0) {
        timerId = setInterval(() => {
          setRemainTime(countDown => countDown - 1);
        }, 1000);
      }
    }
    return () => clearInterval(timerId);
  }, [isActive, remainTime]);
  React.useEffect(() => {
    if (Time > 0) {
      setRemainTime(Time);
    }
  }, [Time]);
  React.useEffect(() => {
    let jsxElement;
    if (isActive) {
      if (remainTime === -1) {
        jsxElement = <>{timerCompletedMessage}</>;
        EndTimerDirectCallFunction();
      } else {
        const secs = String(remainTime % 60).padStart(2, '0');
        const mins = String(Math.floor(remainTime / 60)).padStart(2);

        if (minutes && seconds) {
          jsxElement = <>{`${timerRunningMessage} ${mins}:${secs}`}</>;
        } else if (minutes) {
          jsxElement = <>{`${timerRunningMessage} ${mins} minutes`}</>;
        } else if (seconds) {
          jsxElement = <>{`${timerRunningMessage} ${secs} seconds`}</>;
        }
      }
    }

    setDisplay(jsxElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainTime]);

  return (
    <div className="timer">
      {' '}
      <p>
        <b>{display}</b>
      </p>
    </div>
  );
};
