import DashboardLayout from '@components/DashboardLayout';
import { TransferSteps } from '@components/DetailsSidebars/TransferSteps/transferSteps';
import { InnerHeader } from '@components/InnerHeader/innerHeader';
import { PageHeader } from '@components/PageHeader';
import React from 'react';
import { TransferAccountToCredit } from './TransferAccountToCredit';
import { TransferAccountToDebit } from './TransferAccountToDebit';

import { TransferConfirmation } from './TransferConfirmation/transferConfirmation';

import { TransferProvider, useTransfer } from '@hooks/useTransfer';
import { getTransactionDetails } from '@services/bapi/transactions';
import { useQuery } from 'react-query';
import TransferDetails from './TransferDetails';
// import { useRouter } from 'next/router';
import BackButton from '@components/BackButton';
import useAccounts from '@hooks/useAccounts';

const steps = [
  {
    title: 'Account to Debit',
    Component: <TransferAccountToDebit />
  },
  {
    title: 'Account to Credit',
    Component: <TransferAccountToCredit />
  },
  {
    title: 'Details',
    Component: <TransferDetails />
  }
];

export type TransferProps = {
  repeat?: { transactionId: string; accountId: string };
};

const Transfer: React.FC<TransferProps> = ({ repeat }) => {
  const { data: accounts } = useAccounts();
  // const router = useRouter();

  const isRepeat = React.useMemo(() => {
    if (repeat?.transactionId && repeat?.accountId) return true;
    return false;
  }, [repeat?.accountId, repeat?.transactionId]);

  const { data: repeatTransaction } = useQuery(
    ['transaction', { ...repeat }],
    () =>
      getTransactionDetails({
        transactionId: repeat.transactionId,
        accountId: repeat.accountId
      }).then(res => res.data),

    {
      enabled: isRepeat,
      onError: () => {
        // router.back();
      }
    }
  );

  const debitAccount = React.useMemo(() => {
    if (isRepeat && repeatTransaction) {
      return accounts.find(account =>
        account.id
          .split('-')[0]
          .includes(repeatTransaction.accountId.split('-')[0])
      );
    }
    return undefined;
  }, [accounts, isRepeat, repeatTransaction]);

  if (isRepeat && !repeatTransaction && !debitAccount) {
    return <DashboardLayout mainContent={<p>Loading...</p>} />;
  }

  return (
    <TransferProvider
      totalSteps={steps.length - 1}
      repeatTransaction={
        isRepeat
          ? {
              transaction: repeatTransaction,
              debitAccount
            }
          : undefined
      }
    >
      <DashboardLayout
        mainContent={<MainContent />}
        sidebar={<TransferSidebar />}
      />
    </TransferProvider>
  );
};

export default Transfer;

const MainContent: React.FC = () => {
  const {
    transferStatus,
    currentStep,
    onPrevious,
    transferStatusMessage,
    transferState
  } = useTransfer();

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const transferDate = transferState.date;
  const files = transferState?.files;

  return (
    <div className="transfer">
      {transferStatus === 'INITIAL' && (
        <>
          <PageHeader title="Transfer" subTitle="New transfer" />
          {currentStep > 0 && <BackButton onClick={onPrevious} />}
          <InnerHeader
            currentStep={currentStep + 1}
            title={steps[currentStep]?.title}
          />
          {steps[currentStep]?.Component}
        </>
      )}
      {transferStatus === 'SUCCESS' && (
        <TransferConfirmation
          success
          message={
            currentDate.getTime() === transferDate.getTime()
              ? 'Your money will be sent'
              : 'Your request has been posted, but will only be executed, if the account is having enough funds at that date. Otherwise it will be cancelled.'
          }
          files={files}
          warningMessages={transferStatusMessage}
        />
      )}
      {transferStatus === 'FAILED' && (
        <TransferConfirmation success={false} message={transferStatusMessage} />
      )}
    </div>
  );
};

const TransferSidebar: React.FC = () => {
  const { transferState } = useTransfer();

  return (
    <TransferSteps
      details={{
        accountToDebitName: transferState.accountToDebitName,
        accountToDebitBankName: transferState.debitAccount?.accountHolderName,
        accountToDebitIban: transferState.debitAccount?.iban,
        accountToDebitBic: transferState.debitAccount?.bic,
        accountToCredit: transferState.accountToCreditName,
        amount: transferState.amount,
        currency: transferState.currency,
        referenceMessage: transferState.referenceMessage,
        bic: transferState.swiftBic,
        beneficiaryBankName: transferState.bankName,
        beneficiaryName: transferState.accountToCreditName,
        accountToCreditIban: transferState.accountToCreditIban,
        accountToCreditAccountNumber:
          transferState.accountToCreditAccountNumber,
        accountToCreditBankNumber: transferState.accountToCreditBankNumber,
        date: transferState.date.toLocaleDateString('en-EN')
      }}
    />
  );
};
