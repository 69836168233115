/**
 * Cookie keys
 */
export const USER_ACCESS_TOKEN_COOKIE_KEY = 'iban_x_user_access_token';
export const USER_REFRESH_TOKEN_COOKIE_KEY = 'iban_x_user_refresh_token';
export const KEYCLOAK_TOKEN_KEY = 'kcToken';

/**
 * Broadcast channel messages
 */
export const authChannelMessages = {
  logout: 'logout',
  login: 'login',
  sessionEnd: 'sessionEnd',
  sessionExtended: 'sessionExtended'
};

export const AUTH_CHANNEL = 'auth';
