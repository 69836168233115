export const downloadFile = (url, blob, fileName) => {
  const link = document.createElement('a');
  link.href = blob
    ? window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
    : url;
  link.target = '_blank';
  if (blob) link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
