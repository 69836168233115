import { Feature, GetFeaturesListResponse } from '@services/bapi/features';

export const breakpoints = {
  desktopS: 1440,
  desktopM: 1791
};

export const formatCurrency = (
  amount: number,
  currencyCode: string
): string => {
  return new Intl.NumberFormat('de-LU', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code'
  }).format(amount);
};

export const formatCurrencyWithoutCurrencyCode = (
  amount: number,
  currencyCode: string
): string => {
  return new Intl.NumberFormat('de-LU', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code'
  })
    .format(amount)
    .replace(currencyCode, '');
};

export const formatAmountWithCurrency = (
  amount: number,
  currencyCode: string
) => {
  return `${currencyCode} ${formatCurrencyWithoutCurrencyCode(
    amount,
    currencyCode
  )}`;
};

export const addSpacesEvery4Letters = string => {
  string = removeAllWhiteSpaces(string);
  const regex = /.{1,4}/g;
  const isAccount = string?.split('-').length > 2;
  return !isAccount ? string?.match(regex).join(' ') : string;
};

export const checkIbanOrAccount = (
  iban: string,
  accountNumber?: string,
  bankNumber?: string
) => {
  if (!iban && accountNumber && bankNumber)
    return {
      accountType: 'Account number',
      universalType: 'Swift'
    };

  const isAccountNumber = iban?.split('-').length > 2;

  return {
    accountType: isAccountNumber ? 'Account number' : 'Iban',
    universalType: isAccountNumber ? 'Swift' : 'Bic'
  };
};

export const removeAllWhiteSpaces = (
  textWithSpaces: string,
  deafultString: boolean = false
) => {
  if (!textWithSpaces) {
    if (deafultString) {
      return '';
    }
    return undefined;
  }
  return textWithSpaces.replaceAll(' ', '');
};

// Check if a feature is active
export const isFeatureActive = (features: Feature[], featureName: string) =>
  features
    ? features?.some(f => f?.feature === featureName && f?.status === 'Active')
    : true;
