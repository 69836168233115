import { AxiosResponse } from 'axios';
import { bapi } from './client';

export type GetValidateBicCodeResponse = boolean;

export const getValidateBicCode = (
  bicCode: string
): Promise<AxiosResponse<GetValidateBicCodeResponse>> => {
  return bapi.get<GetValidateBicCodeResponse>(
    `/v1/validate/biccode/${bicCode}`
  );
};
