import { addSpacesEvery4Letters, checkIbanOrAccount } from '@utils';
import dayjs from 'dayjs';
import React from 'react';

interface TransactionPDFProps {
  transactionNumber: string;
  senderName: string;
  senderBankName: string;
  senderIban: string;
  senderBic: string;
  recipient?: string;
  recipientBankName?: string;
  recipientIban?: string;
  recipientBic?: string;
  amount: string;
  transferDate: Date;
  executionDate: Date;
  currentStatus: string;
  type: string;
  referenceText: string;
}

export const TransactionPDF = React.forwardRef(
  (props: TransactionPDFProps, ref: any) => {
    const today = dayjs().format('YYYY-MM-DD');
    const accountType = checkIbanOrAccount(props.recipientIban);
    return (
      <div className="transaction-pdf" ref={ref}>
        <div className="transaction-pdf__header">
          <div>
            <img
              src="/assets/logos/iban-s_vertical.svg?react"
              alt="iban icon"
              width={60}
              height={60}
            />
          </div>
          <div className="transaction-pdf__header-text">
            <h3 className="small">Transfer Confirmation</h3>
            <h5 className="secondary">Generated on</h5>
            <h4 className="small">{today}</h4>
          </div>
        </div>
        <div className="transaction-pdf__main-content">
          <div className="transaction-pdf__details">
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">
                Transaction Number
              </h3>
              <p className="large transaction-pdf__detail--column">
                {props.transactionNumber}
              </p>
            </div>
            <div className="large transaction-pdf__divider" />
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">Sender</h3>
              <p className="large transaction-pdf__detail--column">
                {props.senderName}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <div className="transaction-pdf__detail--column">
                <h3 className="small transaction-pdf__detail--right">
                  Bank Name
                </h3>
              </div>
              <p className="large transaction-pdf__detail--column">
                {props.senderBankName}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <div className="transaction-pdf__detail--column">
                <h3 className="small transaction-pdf__detail--right">IBAN</h3>
              </div>
              <p className="large transaction-pdf__detail--column">
                {addSpacesEvery4Letters(props.senderIban)}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <div className="transaction-pdf__detail--column">
                <h3 className="small transaction-pdf__detail--right">BIC</h3>
              </div>
              <p className="large transaction-pdf__detail--column">
                {props.senderBic}
              </p>
            </div>
            {props.recipient && (
              <>
                <div className="large transaction-pdf__divider" />
                <div className="transaction-pdf__detail">
                  <h3 className="large transaction-pdf__detail--column">
                    Recipient
                  </h3>
                  <p className="large transaction-pdf__detail--column">
                    {props.recipient}
                  </p>
                </div>
                {props.recipientBankName && (
                  <div className="transaction-pdf__detail">
                    <div className="transaction-pdf__detail--column">
                      <h3 className="small transaction-pdf__detail--right">
                        Bank Name
                      </h3>
                    </div>
                    <p className="large transaction-pdf__detail--column">
                      {props.recipientBankName}
                    </p>
                  </div>
                )}
                {props.recipientIban && (
                  <div className="transaction-pdf__detail">
                    <div className="transaction-pdf__detail--column">
                      <h3 className="small transaction-pdf__detail--right">
                        {accountType.accountType}
                      </h3>
                    </div>
                    <p className="large transaction-pdf__detail--column">
                      {addSpacesEvery4Letters(props.recipientIban)}
                    </p>
                  </div>
                )}
                {props.recipientBic && (
                  <div className="transaction-pdf__detail">
                    <div className="transaction-pdf__detail--column">
                      <h3 className="small transaction-pdf__detail--right">
                        {accountType.universalType}
                      </h3>
                    </div>
                    <p className="large transaction-pdf__detail--column">
                      {props.recipientBic}
                    </p>
                  </div>
                )}
              </>
            )}
            <div className="large transaction-pdf__divider" />
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">Amount</h3>
              <p className="large transaction-pdf__detail--column">
                {props.amount}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">
                Transfer Date
              </h3>
              <p className="large transaction-pdf__detail--column">
                {props.transferDate.toLocaleDateString('fr-CA')}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">
                Execution Date
              </h3>
              <p className="large transaction-pdf__detail--column">
                {props.executionDate.toLocaleDateString('fr-CA')}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">
                Current Status
              </h3>
              <p className="large transaction-pdf__detail--column">
                {props.currentStatus}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">Type</h3>
              <p className="large transaction-pdf__detail--column">
                {props.type}
              </p>
            </div>
            <div className="transaction-pdf__detail">
              <h3 className="large transaction-pdf__detail--column">
                Reference
              </h3>
              <p className="large transaction-pdf__detail--column">
                {props.referenceText}
              </p>
            </div>
          </div>
          <div>
            <h5 className="regular">
              This document is computer generated and does not require the
              Registrar’s signature or the Company’s stamp in order to be
              considered valid
            </h5>
          </div>
        </div>
      </div>
    );
  }
);
