import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

interface PageHeaderProps {
  title?: string;
  subTitle?: string;
  smallMarginBottom?: boolean;
}

export const PageHeader: FC<PropsWithChildren<PageHeaderProps>> = ({
  title,
  children,
  subTitle,
  smallMarginBottom
}) => {
  return (
    <section
      className={classNames(
        'page-header',
        smallMarginBottom && 'page-header--small-margin'
      )}
    >
      <div className="page-header__title-container">
        {title && <h1>{title}</h1>}
        {subTitle && (
          <h2 className="page-header__subtitle text-uppercase">{`\xa0|\xa0${subTitle}`}</h2>
        )}
      </div>
      {children}
    </section>
  );
};
