/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import DateIcon from '@assets/icons/icon_date.svg?react';
import Message from '@assets/icons/icon_message.svg?react';
import Money from '@assets/icons/icon_money.svg?react';
import UploadIcon from '@assets/icons/icon_transfer_upload.svg?react';

import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { OneTimeCodeModal } from '@components/OneTimeCodeModal';
import { UploadFiles } from '@components/UploadFiles';

import { CurrencyInput } from '@components/CurrencyInput';
import { Switch } from '@components/Switch';
import useAccounts from '@hooks/useAccounts';
import { useTransfer } from '@hooks/useTransfer';
import { Currencies, allCurrencies } from '@res/availableCurrencies';
import { formatAmountWithCurrency } from '@utils/index';

export type PreparedFile = {
  name: string;
  content: File;
};

export type TransferFormDetails = {
  accountBalance: number;
  amount: number;
  amountFx: number;
  currencyFx: string;
  currency: string;
  reference: string;
  date: Date;
  isInternalTransfer?: boolean;
  internalAccountCurrencies?: string[];
  files: PreparedFile[];
};

export type TransferFormDetailsWithType = TransferFormDetails & {
  transferType: 'SEPA' | 'SWIFT';
};

const MAX_REFERENCE_FIELD_LENGTH = 80;

const VALID_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];

const minDate = new Date();
// NOTE - This will be the date from yesterday.
minDate.setDate(minDate.getDate() - 1);

export const transferFormDetailsValidation = Yup.object({
  accountBalance: Yup.number().required(),
  amount: Yup.number()
    .required()
    .label('Amount')
    .typeError('Amount is required')
    .min(0.01)
    .test(
      'balance',
      'Insufficient funds to complete your transfer',
      (value, context) => {
        if (!value) return true;

        if (value <= context.parent.accountBalance) {
          return true;
        }

        return false;
      }
    ),
  currency: Yup.string()
    .required()
    .label('Currency')
    .test(
      'invalid',
      'Currency not available in the credit account',
      (value, context) => {
        if (!context.parent.isInternalTransfer) {
          return true;
        }

        return context.parent.internalAccountCurrencies?.includes(value);
      }
    ),
  currencyFx: Yup.string(),
  amountFx: Yup.number(),
  reference: Yup.string()
    .label('Reference')
    .max(MAX_REFERENCE_FIELD_LENGTH)
    .matches(/^[0-9a-zA-Z/\-?:().,'+ ]*$/, 'Invalid reference'),
  date: Yup.date()
    .typeError('Date is required')
    .min(
      moment().set('h', 23).set('m', 59).subtract(1, 'd'),
      'Date of execution is not valid'
    )
    .required()
    .label('Date'),
  isInternalTransfer: Yup.boolean(),
  internalAccountCurrencies: Yup.array(Yup.string()).when(
    'isInternalTransfer',
    ([isInternalTransfer], schema) => {
      return isInternalTransfer ? Yup.array().required() : schema;
    }
  ),
  files: Yup.array().test(
    'fileError',
    'Remove files with error',
    (files: any[]) => {
      if (files.length === 0) return true;

      const validateFile = file => {
        let isError = false;
        const isValidType = VALID_TYPES.includes(file.type);

        if (!isValidType) {
          isError = true;
        }

        if (file.size > 2000000) {
          isError = true;
        }

        return isError;
      };

      const validatedFiles = files.map(file => {
        return validateFile(file.content);
      });

      return !validatedFiles.includes(true);
    }
  )
});

const TransferDetails: React.FC = () => {
  const {
    handleTransferDetails,
    useTransferDetails,
    isTransferring,
    transferState
  } = useTransfer();

  const [transferType, setTransferType] = useState(undefined);

  const [selectedAccount] = React.useState(transferState.debitAccount);
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const [otpModalOpen, setOtpModalOpen] = React.useState(false);
  const [isFxTransferAvailable, setIsFxTransferAvailable] =
    React.useState(false);
  const [isSchedulerDisabled, setIsSchedulerDisabled] = React.useState(false);

  const { data: debitAccount } = useAccounts({
    enabled: transferState?.isInternalTransfer || false,
    select: accounts => {
      return accounts.find(
        account => account.iban === transferState.accountToCreditIban
      );
    }
  });
  const handleSelect = React.useCallback(
    (values: Partial<TransferFormDetails>, files: PreparedFile[]) => {
      const currDate = moment(watch('date'));
      currDate.set({
        hour: 1,
        minute: 0,
        second: 0
      });
      const nativeDate = currDate.toDate();
      handleTransferDetails(
        {
          ...values,
          transferType: transferType || 'SEPA',
          date: nativeDate
        },
        files
      );
    },
    [transferType]
  );

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useTransferDetails;

  const files: PreparedFile[] = watch('files');

  function handleDateChange(event) {
    const localDate = event.target.value;
    setValue('date', localDate);
  }

  const currencyOptions = React.useMemo(() => {
    if (!selectedAccount) return [];

    const sortedAccountCurrencies = selectedAccount.currencies
      .map(currency => {
        if (transferState.isInternalTransfer) {
          return debitAccount?.currencies?.find(c => c.code === currency.code)
            ? currency.code
            : null;
        }
        return currency.code;
      })
      .filter(code => code !== null)
      .sort(() => -1);
    // NOTE: We want the first 3 currencies to be this, if they are available
    const firstCurrencies = ['EUR', 'GBP', 'USD'].filter(currency =>
      sortedAccountCurrencies.includes(currency)
    );

    const otherCurrencies = sortedAccountCurrencies.reduce((prev, curr) => {
      if (!firstCurrencies.includes(curr)) {
        prev.push(curr);
      }
      return prev;
    }, []);

    let currencies = [...firstCurrencies];

    if (otherCurrencies.length > 0) {
      currencies = [...currencies, ...otherCurrencies];
    }

    return currencies;
  }, [selectedAccount]);

  React.useEffect(() => {
    if (currencyOptions.length) {
      setValue('currency', currencyOptions[0]);
    }
  }, [currencyOptions]);

  function handleOtpConfirmation() {
    setOtpModalOpen(false);
    submitButtonRef.current.click();
  }

  function onValidate() {
    setOtpModalOpen(true);
  }

  React.useEffect(() => {
    if (transferState.isInternalTransfer) {
      setValue('isInternalTransfer', true);
      setValue(
        'internalAccountCurrencies',
        debitAccount?.currencies?.map(currency => currency.code)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currencyWatch = watch('currency');
  const currencyReceiveWatch = watch('currencyFx');
  const amountWatch = watch('amount');
  // const amountReceiveWatch = watch('amountFx');
  const accountBalance = watch('accountBalance');

  React.useEffect(() => {
    if (!isSchedulerDisabled) {
      setValue('date', new Date());
    }
  }, [isSchedulerDisabled]);

  React.useEffect(() => {
    if (isFxTransferAvailable) {
      // FIXME: remove a mock
      if (currencyReceiveWatch === currencyWatch) {
        setValue('amountFx', amountWatch);
        return;
      }
      setValue('amountFx', amountWatch * 0.5);
    } else {
      setValue('amountFx', undefined);
    }
  }, [isFxTransferAvailable, amountWatch, currencyReceiveWatch, currencyWatch]);

  React.useEffect(() => {
    const balance = transferState.debitAccount.currencies.find(
      ({ code }) => code === currencyWatch
    )?.availableBalance;

    setValue(
      'currencyFx',
      allCurrencies
        .slice(0, 2)
        .filter(currency => currency !== currencyWatch)[0]
    );
    setValue('accountBalance', balance);
    setTransferType(checkTransferType);
  }, [currencyWatch]);

  // NOTE: follow code is a mock for the FX topic
  // const { isLoading: isLoadingQuote, data: dataQuote, error } = useQuote({
  //   sourceCurrency: currencyWatch as Currencies,
  //   targetCurrency: currencyReceiveWatch as Currencies,
  //   amount: 1,
  //   amountCurrency: currencyWatch as Currencies
  // });

  const handleFileUpload = React.useCallback(
    (filesItems: any) => {
      const preparedFiles: PreparedFile[] = filesItems.map((file: File) => {
        return {
          name: file.name,
          content: file
        };
      });
      setValue('files', preparedFiles, { shouldValidate: true });
    },
    [setValue]
  );

  const checkTransferType = () => {
    if (transferState.isInternalTransfer) return undefined;

    if (!transferType) {
      if (transferState.transferType === 'SWIFT') return 'SWIFT';

      if (currencyWatch === 'EUR') return 'SEPA';

      return 'SWIFT';
    }

    // if currency is EUR return what user selected in prev step
    if (currencyWatch === 'EUR') return transferState.transferType;

    return 'SWIFT';
  };

  const isInternalNotOwnAccount = useMemo(() => {
    const { accountToCreditIban } = transferState;
    if (accountToCreditIban === undefined) {
      return false;
    }

    return (
      accountToCreditIban.slice(0, 2) === 'LU' &&
      accountToCreditIban.slice(4, 7) === '713'
    );
  }, [transferState.accountToCreditIban]);

  const currencyInputHelperText = useMemo((): string | undefined => {
    if (!currencyWatch || accountBalance <= 0) {
      return undefined;
    }

    return `Available Balance: ${formatAmountWithCurrency(
      accountBalance,
      currencyWatch
    )}`;
  }, [currencyWatch, accountBalance]);

  const fxCurrencyInputHelperText = useMemo((): string | undefined => {
    if (!currencyReceiveWatch || !currencyWatch) {
      return undefined;
    }
    return `Rate: 1 ${currencyWatch} = 0.84 ${currencyReceiveWatch}`;
  }, [currencyWatch, currencyReceiveWatch]);

  return (
    <>
      <form
        className="transfer-details"
        onSubmit={handleSubmit(data => handleSelect(data, files))}
      >
        <div className="transfer-details__item-container">
          <div className="transfer-details__item-label">
            <Money />
            <div>
              <h2>Amount</h2>
              <h4 className="regular">How much you´d like</h4>
              <h4 className="regular">to transfer</h4>
            </div>
            <div className="transfer-details__method">
              {transferType === 'SEPA' && !isInternalNotOwnAccount && (
                <img
                  src="/assets/logos/sepa.png"
                  alt="SEPA logo"
                  className="svg_element"
                />
              )}
              {transferType === 'SWIFT' && !isInternalNotOwnAccount && (
                <img
                  src="/assets/logos/swift.png"
                  alt="SEPA logo"
                  className="svg_element"
                />
              )}
              {transferState.isInternalTransfer ||
                (isInternalNotOwnAccount && (
                  <div className="transfer-details__method-internal">
                    <p>internal</p>
                    <p>transfer</p>
                  </div>
                ))}
            </div>
          </div>
          <div className="transfer-details__inputs-container">
            <div className="transfer-details__amount-input">
              <CurrencyInput
                register={register}
                availableCurrencyCodes={currencyOptions as any}
                nameAmount="amount"
                nameCurrency="currency"
                control={control}
                error={errors.amount?.message || errors.currency?.message}
                autoComplete="off"
                step={0.01}
                min={0}
                helperText={currencyInputHelperText}
              />
            </div>
          </div>
        </div>

        {/* NOTE: Next line is for FX topic */}
        {false && (
          <div className="transfer-details__item-container">
            <div className="transfer-details__item-label">
              <Money />
              <div>
                <h2>Foreign exchange</h2>
                <h4 className="regular">
                  Recipient receives in different currency
                </h4>
              </div>
              <Switch
                name="isFxTransferAvailable"
                checked={isFxTransferAvailable}
                onChange={() =>
                  setIsFxTransferAvailable(!isFxTransferAvailable)
                }
              />
            </div>
            <div className="transfer-details__inputs-container">
              <div className="transfer-details__amount-input">
                <CurrencyInput
                  register={register}
                  nameAmount="amountFx"
                  nameCurrency="currencyFx"
                  control={control}
                  error={errors.amountFx?.message || errors.currencyFx?.message}
                  autoComplete="off"
                  step={0.01}
                  min={0}
                  disabled={!isFxTransferAvailable}
                  helperText={fxCurrencyInputHelperText}
                  exceptedCurrencyCodes={[currencyWatch as Currencies]}
                />
              </div>
            </div>
          </div>
        )}

        <div className="transfer-details__item-container">
          <div className="transfer-details__item-label">
            <Message />
            <div>
              <h2>Reference</h2>
              <h4 className="regular">Add a personal message or payment</h4>
              <h4 className="regular">reference for the recipient(optional)</h4>
            </div>
          </div>
          <div className="transfer-details__inputs-container">
            <Input
              className="transfer-details__fullwidth"
              register={register}
              name="reference"
              error={errors.reference?.message}
              type="text"
              autoComplete="off"
              maxLength={MAX_REFERENCE_FIELD_LENGTH}
            />
          </div>
        </div>
        <div className="transfer-details__item-container">
          <div className="transfer-details__item-label">
            <DateIcon />
            <div>
              <h2>Date</h2>
              <h4 className="regular">Select the execution date</h4>
            </div>
            <Switch
              name="isSchedulerDisabled"
              checked={isSchedulerDisabled}
              onChange={() => setIsSchedulerDisabled(!isSchedulerDisabled)}
            />
          </div>
          <div className="transfer-details__inputs-container">
            <Input
              className="transfer-details__fullwidth"
              register={register}
              name="date"
              error={errors.date?.message}
              type="date"
              autoComplete="off"
              onChange={handleDateChange}
              onSelect={handleDateChange}
              value={moment(watch('date')).format('YYYY-MM-DD')}
              min={moment().format('YYYY-MM-DD')}
              disabled={!isSchedulerDisabled}
            />
          </div>
        </div>
        <div className="transfer-details__item-container">
          <div className="transfer-details__item-label">
            <UploadIcon />
            <div>
              <h2>Upload Flies</h2>
              <h4 className="regular">Files supported: PDF, JPEG and PNG</h4>
              <h4 className="regular">Maximum size: 2MB each</h4>
            </div>
          </div>
          <div className="contact-form__container--buttons">
            <div className="transfer-details__inputs-container">
              <UploadFiles
                onFilesSelected={handleFileUpload}
                validationFieldError={errors?.files?.message}
              />
            </div>
          </div>
        </div>
        <div className="transfer-details__button-container">
          <Button
            type="button"
            isLoading={isTransferring}
            onClick={
              process.env.NEXT_PUBLIC_OTP_ENABLED === 'true'
                ? handleSubmit(onValidate)
                : handleSubmit(data => handleSelect(data, files))
            }
          >
            Transfer
          </Button>
          {/* NOTE: We use this button to submit the form after valid OTP */}
          <button ref={submitButtonRef} hidden type="submit">
            submit
          </button>
        </div>
      </form>
      {otpModalOpen && (
        <OneTimeCodeModal
          onConfirmed={handleOtpConfirmation}
          viewModal={setOtpModalOpen}
        />
      )}
    </>
  );
};

export default TransferDetails;
