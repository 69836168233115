import {
  BeneficiariesTab,
  BeneficiariesTabs
} from '@components/BeneficiariesTabs';
import CreateEditBeneficiaryForm from '@components/ChangeBeneficiaryForm';
import DashboardLayout from '@components/DashboardLayout';
import { LoadingScreen } from '@components/LoadingScreen';
import { PageHeader } from '@components/PageHeader';
import { SwitchWithLabel } from '@components/Switch';
import { TransferLine } from '@components/TransferLine';
import useBeneficiaries from '@hooks/useBeneficiaries';
import { Beneficiary } from '@services/bapi/beneficiaries';
import React, { useState } from 'react';
import { useFilteredBeneficiaries } from './useFilteredBeneficiaries';

const Beneficiaries: React.FC = () => {
  const [selectedTab, setSelectedTab] =
    useState<BeneficiariesTab>('beneficiaries');

  const [beneficiaryToEdit, setBeneficiaryToEdit] =
    useState<Beneficiary | null>(null);

  const { data: beneficiaries, isLoading } = useBeneficiaries();

  const {
    filteredBeneficiaries,
    isGlobalSelected,
    isPersonalSelected,
    toggleGlobalSwitcher,
    togglePersonalSwitcher
  } = useFilteredBeneficiaries(beneficiaries);

  const handleEdit = React.useCallback((beneficiary: Beneficiary) => {
    setSelectedTab('add-beneficiary');
    setBeneficiaryToEdit(beneficiary);
  }, []);

  const handleTabSelect = React.useCallback(
    (tab: BeneficiariesTab) => {
      if (tab !== selectedTab) {
        setSelectedTab(tab);
        setBeneficiaryToEdit(null);
      }
    },

    [selectedTab]
  );

  if (isLoading) {
    <DashboardLayout
      mainContent={
        <div className="beneficiaries">
          <PageHeader title="Beneficiaries" />
          <LoadingScreen />
        </div>
      }
    />;
  }

  return (
    <DashboardLayout
      mainContent={
        <div className="beneficiaries">
          <PageHeader title="Beneficiaries" />
          <BeneficiariesTabs
            currentTab={selectedTab}
            handleTabSelect={handleTabSelect}
            isEdit={Object?.entries(beneficiaryToEdit || {}).length > 0}
          />
          <div className="beneficiaries__list-container">
            {selectedTab === 'beneficiaries' && (
              <>
                <div className="beneficiaries__list-filters">
                  <SwitchWithLabel
                    onChange={togglePersonalSwitcher}
                    checked={isPersonalSelected}
                    label="Show personal beneficiaries"
                    name="personal"
                  />
                  <SwitchWithLabel
                    onChange={toggleGlobalSwitcher}
                    checked={isGlobalSelected}
                    label="Show global beneficiaries"
                    name="global"
                  />
                </div>
                {filteredBeneficiaries.length !== 0 ? (
                  <ul className="beneficiaries__list">
                    {filteredBeneficiaries?.map((beneficiary, index) => (
                      <TransferLine
                        // HACK: as backend doesn't validate bens, it might contains duplicates
                        // eslint-disable-next-line react/no-array-index-key
                        key={beneficiary.id + beneficiary.label + index}
                        onAccounClick={() => handleEdit(beneficiary)}
                        id={beneficiary.id}
                        bankName={beneficiary.bankName}
                        iban={beneficiary.iban}
                        bic={beneficiary.bic}
                        label={beneficiary.label}
                        accountName={!beneficiary.label && beneficiary.name}
                        accountNumber={beneficiary.accountNumber}
                        bankNumber={beneficiary.bankNumber}
                        className={
                          beneficiary.isGlobal && 'beneficiaries__list-list'
                        }
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="beneficiaries__empty">
                    <p className="paragraph--large semiBold">
                      No beneficiaries available.
                    </p>
                  </div>
                )}
              </>
            )}
            {selectedTab === 'add-beneficiary' && (
              <CreateEditBeneficiaryForm
                beneficiary={beneficiaryToEdit}
                onGoBack={() => {
                  setSelectedTab('beneficiaries');
                  setBeneficiaryToEdit(null);
                }}
              />
            )}
          </div>
        </div>
      }
    />
  );
};
export default Beneficiaries;
