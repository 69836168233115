import {
  BankAccountData,
  BankAccountWithCurrencies
} from '@services/bapi/accounts';

export type GetAllBankAccountsResponse = BankAccountData[];

export function convertBankAccountsData(
  bankAccounts: GetAllBankAccountsResponse
): BankAccountWithCurrencies[] {
  return bankAccounts?.reduce((prevValue, curr) => {
    const index = prevValue.findIndex(
      account => account.bankAccountId === curr.bankAccountId
    );

    if (index > -1) {
      const { currencies } = prevValue[index];

      currencies.push({
        code: curr.currencyCode,
        availableBalance: curr.availableAmount,
        currentBalance: curr.currentBalance,
        creationDate: curr.startDate
      });

      prevValue[index] = {
        ...prevValue[index],
        currencies
      };
    } else {
      const currentWithCurrency: BankAccountWithCurrencies = {
        ...curr,
        currencies: [
          {
            code: curr.currencyCode,
            availableBalance: curr.availableAmount,
            currentBalance: curr.currentBalance,
            creationDate: curr.startDate
          }
        ]
      };

      prevValue.push(currentWithCurrency);
    }

    return prevValue;
  }, [] as BankAccountWithCurrencies[]);
}
