import { Feedback, Ticket, ticketFeedback } from '@services/bapi/jira';
import { UseMutationOptions, useMutation } from 'react-query';

export const useSubmitFeedback = (
  ticketId: Ticket['ticketId'],
  options: UseMutationOptions<unknown, unknown, Feedback>
) => {
  return useMutation(feedback => ticketFeedback(ticketId, feedback), {
    ...options
  });
};
