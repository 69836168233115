import * as ibantools from 'ibantools';

const startsWithTwoLetters = /^[A-Za-z]{2}/;

export const isIBAN = (value: string) => startsWithTwoLetters.test(value);

export const isValidIBAN = (iban: string) => {
  return ibantools.validateIBAN(iban).valid;
};

export const isValidBIC = (bic: string) => {
  return ibantools.isValidBIC(bic);
};
