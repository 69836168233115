import { RequestPreGeneratedStatements } from '@components/PreGeneratedStatements';
import { getPreGeneratedReport } from '@services/bapi/accounts';
import { useMutation } from 'react-query';

export default function usePreGeneratedReports(options: any) {
  return useMutation(
    (reportRequestData: RequestPreGeneratedStatements) =>
      getPreGeneratedReport(reportRequestData).then(res => res.data),
    {
      ...options
    }
  );
}
