import MobileNumberRegistration from '@components/MobileNumberRegistration';
import routes from '@res/routes';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

/**
 * @author Arungopi
 * @returns the @MobileNumberRegistration component
 */
const MobileNumber: React.FC = () => {
  /**
   * If the mobileVerified is true it navigate to dashboard page
   */
  const navigate = useNavigate();
  const [cookies] = useCookies();
  // useEffect(() => {
  //   if (cookies.mobileVerified === 'true' || cookies.mobileVerified === true) {
  //     navigate(routes.dashboard.root, { replace: true });
  //   }
  // }, [cookies, navigate]);
  return (
    <div className="mobileNumber">
      <div className="mobileNumber__introContainer">
        <h2>Please verify your mobile number</h2>
        <MobileNumberRegistration />
      </div>
    </div>
  );
};

export default MobileNumber;
