import Padlock from '@assets/icons/icon_padlock.svg?react';
import { Button } from '@components/Button';
import DashboardLayout from '@components/DashboardLayout';
import { PageHeader } from '@components/PageHeader';
import React from 'react';
import { Link } from 'react-router-dom';
// import Phone from '@assets/icons/icon_phone.svg?react';
import { LogoutAlert } from '@components/LogoutAlert';
import { PageSecondaryHeader } from '@components/PageSecondaryHeader';
import { useAuth } from '@hooks/useAuth';
import routes from '@res/routes';
import { resetTransferMFA } from '@services/bapi/auth';
import { useMutation } from 'react-query';

const ResetMfa: React.FC = () => {
  const { handleLogout } = useAuth();
  const [openModal, setOpenModal] = React.useState(false);
  /**
   * @mutate handles the resetTanseferMFA api request
   * it handles the handle logout function
   */
  const { mutate } = useMutation(resetTransferMFA, {
    onSuccess: () => {
      handleLogout();
    }
  });
  /**
   * @resetMFA function handles the modal component
   * @handleCancel function handles the modal component states
   * @handleok function handles the @mutate and modal component states
   */
  const resetMFA = () => {
    setOpenModal(true);
  };
  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleOk = () => {
    mutate();
    setOpenModal(false);
  };
  return (
    <DashboardLayout
      mainContent={
        <div className="resetMfa">
          <PageHeader title="Account Settings" />
          <PageSecondaryHeader title="Reset Authentication Information" />
          <div className="dashboard-layout__parent-container">
            <div className="resetMfa__options">
              <Link
                style={{ width: '100%' }}
                to={routes.dashboard.loginMfa}
                replace
              >
                <Button
                  variant="outline"
                  uppercase={false}
                  className="resetMfa__option"
                >
                  <Padlock />
                  Reset Login MFA
                </Button>
              </Link>
              <Link style={{ width: '100%' }} to="" replace>
                <Button
                  variant="outline"
                  uppercase={false}
                  className="resetMfa__option"
                  onClick={() => resetMFA()}
                >
                  <Padlock />
                  Reset Transfer MFA
                </Button>
              </Link>
              {/* NOTE(diamantino) Feature is not implemented yet */}
              {/* <Link passHref href={routes.dashboard.resetMfa} replace>
                <Button
                  variant="outline"
                  uppercase={false}
                  className="resetMfa__option"
                >
                  <Phone />
                  Reset Mobile Number
                </Button>
              </Link> */}
            </div>
          </div>
          {openModal && (
            <LogoutAlert
              continueOperation={() => handleOk()}
              cancelOperation={() => handleCancel()}
            >
              <>
                You need to Re-Login to continue with this action.
                <br />
                Do you want to continue?
              </>
            </LogoutAlert>
          )}
        </div>
      }
    />
  );
};

export default ResetMfa;
