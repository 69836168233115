import { DashboardMenu } from '@components/DashboardMenu';
import { SessionExpiration } from '@components/SessionExpiration';
import { AuthContext } from '@hooks/useAuth';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    document.addEventListener('contextmenu', e => e.preventDefault());
  }, []);

  return (
    <>
      {location.pathname.includes('dashboard') ? (
        <AuthContext.Consumer>
          {({ user }) =>
            user ? (
              <>
                {location.pathname.includes('/login-mfa') ||
                location.pathname.includes('/transfer-mfa') ? (
                  <></>
                ) : (
                  <DashboardMenu />
                )}
                <Outlet />
                <SessionExpiration />
              </>
            ) : (
              <></>
            )
          }
        </AuthContext.Consumer>
      ) : (
        <Outlet />
      )}
    </>
  );
};
