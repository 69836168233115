import { PageSecondaryHeader } from '@components/PageSecondaryHeader';
import PatternBanner from '@components/PatternBanner';
import StepsInfo from '@components/StepsInfo';
import ValidateSmsOtp from '@components/ValidateSmsOtp';
import VerifyPassword from '@components/VerifyPassword';
import React, { useState } from 'react';

export type Step = {
  title: string;
};

const ResetLoginMfa: React.FC = () => {
  const [step, setStep] = useState(1);
  const stepsList: Step[] = [
    {
      title: 'Confirm Password'
    },
    {
      title: 'Verify Mobile OTP'
    },
    {
      title: 'Reset Login MFA'
    }
  ];

  return (
    <div className="reset-login-mfa">
      <PatternBanner />
      <PageSecondaryHeader>
        <StepsInfo stepsList={stepsList} currentStep={step} />
      </PageSecondaryHeader>
      <div className="reset-login-mfa__steps">
        <p className="reset-login-mfa__steps-step">Step {step}</p>
        <h2 className="reset-login-mfa__steps-title">
          {stepsList[step - 1].title}
        </h2>
        <div className="reset-login-mfa__dynamic-steps">
          {step === 1 && <VerifyPassword step={step} setStep={setStep} />}
          {step === 2 && <ValidateSmsOtp />}
        </div>
      </div>
    </div>
  );
};

export default ResetLoginMfa;
