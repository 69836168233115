import { GetValidateIbanResponse, getValidateIban } from '@services/bapi/iban';
import { useQueryClient } from 'react-query';

export function useValidateIban() {
  const queryClient = useQueryClient();

  const fetchIban = async (
    countryCode: string,
    iban: string
  ): Promise<GetValidateIbanResponse | undefined> => {
    const response = await getValidateIban(countryCode, iban || '');
    return response.data;
  };

  const executeQuery = (countryCode: string, iban: string) => {
    return queryClient.fetchQuery(['bicCode'], () =>
      fetchIban(countryCode, iban)
    );
  };

  return executeQuery;
}
