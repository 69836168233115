import BackButton from '@components/BackButton';
import { Button } from '@components/Button';
import { CommunicationLine } from '@components/CommunicationLine';
import DashboardLayout from '@components/DashboardLayout';
import { Loading } from '@components/Loading';
import { PageHeader } from '@components/PageHeader';
import { SearchBar } from '@components/SearchBar';
import useAllTickets from '@hooks/useAllTickets';
import useAttachThumbnail from '@hooks/useAttachThumbnail';
import useTicketDetails from '@hooks/useTicketDetails';
import { bapi } from '@services/bapi/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useNavigate } from 'react-router-dom';
import FaqContact from '../FaqContact';
import { Modal } from '@components/Modal';

const AttachmentResponse: FC<any> = ({ isOpenId, file }) => {
  const downloadFile = (attachmentId, filename) => {
    bapi
      .get(`/v1/helpdesk/tickets/${isOpenId}/attachments/${attachmentId}`, {
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  return (
    <div className="communication-history__sidebar-files-item">
      <h4>{file.createdDate}</h4>
      <h4 onClick={() => downloadFile(file.attachmentId, file.filename)}>
        {file.filename}
      </h4>
    </div>
  );
};

const CommunicationHistory: React.FC = () => {
  const isLoaded = useRef(false);
  const [isOpenId, setIsOpenId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const {
    data: tickets,
    isLoading: isLoadingTickets,
    isFetching
  } = useAllTickets();
  const [search, setSearch] = React.useState('');
  const [attachments, setAttachments] = useState<any[]>([]);

  const { data: ticketDetails, isLoading: isLoadingTicketDetails } =
    useTicketDetails(isOpenId, isOpenId !== null);
  const { data: attachBytes, refetch } = useAttachThumbnail(
    isOpenId,
    isOpenId,
    attachments,
    ticketDetails?.createdDate
  );

  useEffect(() => {
    if (isLoaded.current) return;
    setAttachments([]);

    if (ticketDetails?.comments.commentsList.length) {
      isLoaded.current = true;
      ticketDetails?.comments.commentsList.forEach(item => {
        if (
          item.attachments.length > 0 &&
          ticketDetails.ticketId === isOpenId
        ) {
          const file = item.attachments[0];
          file.createdDate = item.createdDate;
          setAttachments(prevState => [...prevState, item.attachments[0]]);

          if (ticketDetails?.attachments && ticketDetails?.attachments[0]) {
            setAttachments(prevState => [
              ticketDetails.attachments[0],
              ...prevState
            ]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDetails]);

  useEffect(() => {
    isLoaded.current = false;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  function handleSelectCommunication(e, id: number) {
    const pressedCommunication = e.target.closest('.communication-line');
    setAttachments([]);
    isLoaded.current = false;
    if (isOpenId === id) {
      setShowForm(false);
      pressedCommunication
        .querySelector('.communication-line__conversation')
        .addEventListener(
          'transitionend',
          function () {
            setIsOpenId(null);
          },
          { once: true }
        );
    } else if (isOpenId === null) {
      setIsOpenId(id);
      setTimeout(() => {
        setShowForm(true);
      }, 100);
    } else {
      setShowForm(false);
      e.target
        .closest('ul')
        .querySelector('.communication-line__conversation')
        .addEventListener(
          'transitionend',
          function () {
            setIsOpenId(id);
            setTimeout(() => {
              setShowForm(true);
            }, 100);
          },
          { once: true }
        );
    }
  }

  const onCloseModal = () => {
    setShowModal(false);
  };

  const filteredTickets = React.useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();

    return tickets?.tickets.length
      ? tickets.tickets.filter(
          ticket =>
            ticket.currentStatus.toLowerCase()?.includes(lowerCaseSearch) ||
            ticket.summary.toLocaleLowerCase().includes(lowerCaseSearch) ||
            ticket.createdDate.toLocaleLowerCase().includes(lowerCaseSearch) ||
            String(ticket.ticketId).toLowerCase().includes(lowerCaseSearch)
        )
      : [];
  }, [tickets?.tickets, search]);

  const renderTickets = () => {
    if (isLoadingTickets) {
      return <Loading />;
    }

    if (!isLoadingTickets && !tickets?.tickets?.length) {
      return (
        <p className="communication-history__no-tickets">No tickets created</p>
      );
    }

    return (
      <ScrollContainer
        component="ul"
        className="transfer-to-debit__account-list"
        vertical
        activationDistance={1}
      >
        {filteredTickets.map(communication => (
          <CommunicationLine
            key={communication.ticketId}
            isOpen={communication.ticketId === isOpenId}
            setIsOpen={e =>
              handleSelectCommunication(e, communication.ticketId)
            }
            ticketId={communication.ticketId}
            link={communication.link}
            title={communication.summary}
            status={communication.currentStatus}
            date={communication.createdDate}
            referenceNo={communication.ticketId}
            isLoadingTicketDetails={isLoadingTicketDetails}
            content={
              communication.ticketId === isOpenId &&
              !isLoadingTicketDetails &&
              ticketDetails?.comments.commentsList?.length
                ? ticketDetails.comments.commentsList
                : []
            }
            description={
              communication.ticketId === isOpenId && !isLoadingTicketDetails
                ? ticketDetails.renderedDescription
                : ''
            }
            feedbackDetails={
              communication.ticketId === isOpenId && !isLoadingTicketDetails
                ? ticketDetails.feedback
                : {}
            }
            showForm={showForm}
            isLoaded={isLoaded}
            setAttachments={setAttachments}
          />
        ))}
        {isFetching && <Loading />}
      </ScrollContainer>
    );
  };

  const renderSideBar = () => {
    return attachBytes?.length > 0 ? (
      <div className="communication-history__sidebar">
        <div className="communication-history__sidebar-info">
          <h2>Uploaded Files</h2>
          <h4>{`${attachBytes.length} Files Total`}</h4>
        </div>
        <h4>Details</h4>
        <div className="communication-history__sidebar-files">
          {attachBytes.map((file: any) => {
            return <AttachmentResponse isOpenId={isOpenId} file={file} />;
          })}
        </div>
      </div>
    ) : null;
  };

  return (
    <DashboardLayout
      mainContent={
        <section className="faq-contact">
          <PageHeader title="Help Center" subTitle="how can we help?" />
          <BackButton onClick={() => navigate(-1)} />
          <div className="communication-history__searchbar-header">
            <h2>Communication history</h2>
            <div className="communication-history__actions-block">
              <SearchBar
                placeholder="Search by reference number or title"
                onChange={e => setSearch(e.target.value)}
              />
              <Button size="small" onClick={() => setShowModal(true)}>
                Create ticket
              </Button>
            </div>
          </div>
          {renderTickets()}
          {showModal && (
            <Modal minWidth={925} minHeight={955} onClose={onCloseModal}>
              <FaqContact onClose={onCloseModal} />
            </Modal>
          )}
        </section>
      }
      sidebar={renderSideBar()}
    />
  );
};

export default CommunicationHistory;
