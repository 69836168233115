import { Button } from '@components/Button';
import { FieldError, Input } from '@components/Input';
import { Modal } from '@components/Modal';
import { Select } from '@components/Select';
import { SwitchWithLabel } from '@components/Switch';
import { yupResolver } from '@hookform/resolvers/yup';
import useAccounts from '@hooks/useAccounts';
import useBeneficiaries from '@hooks/useBeneficiaries';
import { useCheckIsSwiftBicAllowed } from '@hooks/useCheckAllowedSwiftBic';
import { useCountryList } from '@hooks/useCountryAllowedList';
import useCreateBeneficiary from '@hooks/useCreateBeneficiary';
import { useTransfer } from '@hooks/useTransfer';
import {
  BankAccountWithCurrencies,
  getAccountAddress
} from '@services/bapi/accounts';
import { Beneficiary } from '@services/bapi/beneficiaries';
import { removeAllWhiteSpaces } from '@utils';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createBeneficiaryValidationSchema } from './Schema';
import { useValidateBicCode } from '@hooks/useValidateBicCode';
import { useValidateIban } from '@hooks/useValidateIban';

export type TransferCreateNewContact = {
  name: string;
  bankName: string;
  iban?: string;
  accountNumber: string;
  accountHolderName: string;
  swiftBic: string;
  saveBeneficiary?: boolean;
  email?: string;
  telephone?: string;
  street: string;
  houseNumber: string;
  country?: string;
  countryCode: string;
  zipCode: string;
  city: string;
  transferType?: string;
  bankKey?: string;
  label?: string;
  isGlobal?: boolean;
};

const checkIsBeneficiaryExist = (
  allBeneficiaries: ReadonlyArray<Beneficiary>,
  currentBeneficiary: TransferCreateNewContact
): Beneficiary | undefined => {
  return allBeneficiaries.find(beneficiary => {
    return (
      (beneficiary.iban && beneficiary.iban === currentBeneficiary.iban) ||
      (beneficiary.accountNumber &&
        beneficiary.bankNumber &&
        `${beneficiary.accountNumber}-${beneficiary.bankNumber}` ===
          `${currentBeneficiary.accountNumber}-${currentBeneficiary.bankKey}`)
    );
  });
};

const TransferNewContactForm: React.FC = () => {
  const { transferState, handleContactInfo } = useTransfer();
  const { data: accounts } = useAccounts();
  const accountList = accounts.map(a => a.iban);
  const [disableFields, setDisableFields] = React.useState(false);
  const [isOwnAccount, setIsOwnAccount] = React.useState(false);
  const [isSepaChecked, setIsSepaChecked] = React.useState(
    transferState?.accountToCreditIban ||
      transferState?.accountToCreditAccountNumber
      ? transferState.transferType === 'SEPA'
      : true
  );

  const [showIsOwnAccountModal, setShowIsOwnAccountModal] =
    React.useState(false);
  const [showAlreadyInYourBeneficiaries, setShowAlreadyInYourBeneficiaries] =
    React.useState({ isOpen: false, beneficiaryName: null });
  const newBeneficiary = useCreateBeneficiary();
  const { data: beneficiaries } = useBeneficiaries();
  const [showOptionalFields, setShowOptionalFields] = React.useState(false);
  const [showChangeToSepaModal, setShowChangeToSepaModal] =
    React.useState(false);

  const { countryList } = useCountryList();
  const { checkIsSwiftBicAllowed } = useCheckIsSwiftBicAllowed();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    control
  } = useForm({
    context: { ibanList: accountList },
    mode: 'onTouched',
    resolver: yupResolver(createBeneficiaryValidationSchema),
    defaultValues: {
      bankKey: transferState.accountToCreditBankNumber || '',
      accountNumber: transferState.accountToCreditAccountNumber,
      transferType: transferState.transferType || 'SEPA',
      iban: transferState.accountToCreditIban,
      bankName: transferState.bankName,
      name: transferState.accountToCreditName,
      swiftBic: transferState.swiftBic,
      saveBeneficiary: transferState.saveBeneficiary,
      email: transferState.email,
      telephone: transferState.telephone,
      street: transferState.street,
      houseNumber: transferState.houseNumber,
      zipCode: transferState.zipCode,
      city: transferState.city,
      countryCode: transferState.countryCode,
      country:
        transferState.country ||
        countryList.find(country => country.code === transferState.countryCode)
          ?.label,
      label: transferState.label
    }
  });

  const ibanField = watch('iban');
  const bicField = watch('swiftBic');
  const accountNumberField = watch('accountNumber');
  const bankKeyField = watch('bankKey');
  const transferTypeField = watch('transferType');
  const isGlobal = watch('isGlobal');
  const [isSwitchesVisible, setIsSwitchesVisible] = useState(false);

  const fetchBicCodeValidation = useValidateBicCode();
  const fetchIbanValidation = useValidateIban();
  React.useEffect(() => {
    if (transferState?.label === undefined) {
      setIsSwitchesVisible(true);
      return;
    }

    if (
      transferState?.swiftBic &&
      !checkIsSwiftBicAllowed(transferState?.swiftBic)
    ) {
      setError('swiftBic', {
        message: `BIC is within a blocked country`,
        type: 'validate'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (accountNumberField) {
      clearErrors('iban');
    }

    if (ibanField) {
      clearErrors('accountNumber');
      clearErrors('bankKey');
    }

    if (bankKeyField) {
      clearErrors('iban');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumberField, ibanField, bankKeyField, bankKeyField]);

  React.useEffect(() => {
    if (isGlobal) {
      setValue('saveBeneficiary', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobal]);

  React.useEffect(() => {
    if (!watch('saveBeneficiary')) {
      setValue('isGlobal', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('saveBeneficiary')]);

  React.useEffect(() => {
    if (watch('country')) {
      const selectedCountry = getValues('country');
      const { code } = countryList.find(
        country => country.label === selectedCountry
      );
      setValue('countryCode', code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('country')]);

  const fillAccountDetails = React.useCallback(
    async (account: BankAccountWithCurrencies) => {
      setDisableFields(true);
      const { data: address } = await getAccountAddress();
      setIsSepaChecked(true);
      setValue('transferType', 'SEPA');
      setValue('name', account.alias);
      setValue('swiftBic', account.bic);
      setValue('street', address.addressStreet?.split(',')?.[0]);
      setValue('houseNumber', address.addressHouseId);
      setValue(
        'country',
        countryList.find(country => country.code === address.addressCountry)
          .label
      );
      setValue('countryCode', address.addressCountry);
      setValue('zipCode', address.addressPostalCode);
      setValue('city', address.addressCity);
      clearErrors();
    },
    [countryList, setValue, clearErrors]
  );

  React.useEffect(() => {
    if (watch('iban') && !isOwnAccount) {
      const number = getValues('iban');

      const ownAccount = accounts.find(
        account => account.iban === removeAllWhiteSpaces(number)
      );

      if (ownAccount) {
        setShowIsOwnAccountModal(true);
        setIsOwnAccount(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('iban')]);

  React.useEffect(() => {
    if (isOwnAccount) {
      const number = getValues('iban');
      const ownAccount = accounts.find(
        account => account.iban === removeAllWhiteSpaces(number)
      );

      if (ownAccount) {
        fillAccountDetails(ownAccount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwnAccount]);

  async function onSubmit(values: TransferCreateNewContact) {
    if (values?.swiftBic && !checkIsSwiftBicAllowed(values.swiftBic)) {
      setError('swiftBic', {
        message: `BIC is within a blocked country`,
        type: 'validate'
      });
      return;
    }

    try {
      await fetchBicCodeValidation(bicField);
    } catch (error) {
      setError('swiftBic', {
        message: 'BIC is not valid',
        type: 'validate'
      });
      // eslint-disable-next-line no-useless-return
      return;
    }

    try {
      await fetchIbanValidation(
        values.countryCode,
        removeAllWhiteSpaces(ibanField)
      );
    } catch (error) {
      setError('iban', {
        message: error.response.data?.message ?? 'IBAN is mandatory',
        type: 'validate'
      });
      // eslint-disable-next-line no-useless-return
      return;
    }

    // save new beneficiary if user selected "Save as..."
    if (values.saveBeneficiary) {
      handleSaveBeneficiary();
      return;
    }
    handleTransfer();
  }

  const handleTransfer = useCallback(() => {
    const isInternalTransfer = disableFields;
    const values: TransferCreateNewContact = getValues(undefined);
    if (values.iban) {
      values.iban = removeAllWhiteSpaces(values.iban);
    }

    handleContactInfo(values, isInternalTransfer);
  }, [disableFields, getValues, handleContactInfo]);

  const handleSaveBeneficiary = React.useCallback(() => {
    const values = getValues(undefined);

    const existingBeneficiary = checkIsBeneficiaryExist(beneficiaries, values);
    // NOTE: different beneficiaries can
    // have the same IBAN for "different" purposes @UWE
    // But they must have at least different labels, otherwise STOP
    if (existingBeneficiary?.label === values?.label) {
      setShowAlreadyInYourBeneficiaries({
        isOpen: true,
        beneficiaryName: existingBeneficiary.name
      });
      return;
    }

    const beneficiary = {
      name: values.name.trim(),
      bankName: values.bankName.trim(),
      transferType: values.transferType,
      iban: removeAllWhiteSpaces(values.iban),
      accountNumber: removeAllWhiteSpaces(values.accountNumber),
      bankNumber: removeAllWhiteSpaces(values.bankKey),
      bic: removeAllWhiteSpaces(values.swiftBic),
      email: values.email?.trim(),
      telephone: values.telephone?.trim(),
      street: values.street.trim(),
      houseNumber: values.houseNumber.trim(),
      countryCode: values.countryCode.trim(),
      zipCode: values.zipCode.trim(),
      city: values.city.trim(),
      label: values.label ? values.label.trim() : values.name.trim(),
      isGlobal: values.isGlobal
    } as Beneficiary;

    newBeneficiary.mutate(beneficiary, {
      onSuccess() {
        handleTransfer();
      }
    });
  }, [beneficiaries, getValues, handleTransfer, newBeneficiary]);

  const resetForm = e => {
    if (
      e.target.value === 'SEPA' &&
      !showChangeToSepaModal &&
      (accountNumberField || bankKeyField)
    ) {
      setValue('transferType', 'SWIFT');
      setShowChangeToSepaModal(true);
      return;
    }

    setShowChangeToSepaModal(false);

    if (isOwnAccount) {
      // it's because SEPA is selected and filds are prefilled with an own account
      if (e.target.value === 'SWIFT') {
        setIsOwnAccount(false);
        setDisableFields(false);
        setIsSepaChecked(false);
        reset({
          iban: '',
          transferType: 'SWIFT',
          name: '',
          swiftBic: '',
          street: '',
          houseNumber: '',
          country: '',
          countryCode: '',
          zipCode: '',
          city: ''
        });
      }
      return;
    }

    setIsSepaChecked(e.target.value === 'SEPA');

    if (e.target.value === 'SEPA') {
      setValue('accountNumber', '');
      setValue('bankKey', '');
      clearErrors('accountNumber');
      clearErrors('bankKey');
    }
  };

  const toggleOptionFields = () => {
    setShowOptionalFields(value => !value);
  };

  return (
    <>
      {(showIsOwnAccountModal || showAlreadyInYourBeneficiaries.isOpen) && (
        <Modal>
          <div className="transfer-new-contact-form__modal">
            <p className="paragraph--large semiBold">
              {showIsOwnAccountModal
                ? `The IBAN/Account Number you entered belongs to iBAN-X,
                therefore, we have filled in all information for this internal
                transfer.`
                : `The IBAN/Account Number you entered is already on your beneficiaries list with the name ${showAlreadyInYourBeneficiaries.beneficiaryName}.`}
            </p>
            <Button
              onClick={() =>
                showIsOwnAccountModal
                  ? setShowIsOwnAccountModal(false)
                  : handleTransfer()
              }
            >
              OK
            </Button>
          </div>
        </Modal>
      )}
      <div className="transfer-new-contact-form">
        <div className="change-beneficiary-form__container change-beneficiary-form__container--header">
          <div className="change-beneficiary-form__container change-beneficiary-form__container--mandatory-section">
            <h3>Beneficiary contract data</h3>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="change-beneficiary-form__container change-beneficiary-form__container--form">
            <div className="change-beneficiary-form__form-section">
              <Input
                register={register}
                name="label"
                error={errors.label?.message}
                label="Label"
                type="text"
                autoComplete="off"
                disabled={disableFields}
              />
              <div className="change-beneficiary-form__transferType transfer-screen">
                <div className="change-beneficiary-form__transferType-inputs">
                  <label
                    htmlFor="sepa"
                    className={classNames(isSepaChecked && 'checked')}
                  >
                    <p>SEPA</p>
                    <input
                      type="radio"
                      name="transferType"
                      id="sepa"
                      value="SEPA"
                      {...register('transferType')}
                      onClick={resetForm}
                    />
                  </label>
                  <label
                    htmlFor="swift"
                    className={classNames(!isSepaChecked && 'checked')}
                  >
                    <p>SWIFT</p>
                    <input
                      type="radio"
                      name="transferType"
                      id="swift"
                      value="SWIFT"
                      {...register('transferType')}
                      onClick={resetForm}
                    />
                  </label>
                </div>
                {errors.transferType?.message && (
                  <FieldError error={errors.transferType?.message} />
                )}
              </div>
              <div className="change-beneficiary-form__inline-inputs">
                <div className="change-beneficiary-form__variable-inputs">
                  <Input
                    register={register}
                    error={errors.iban?.message}
                    name="iban"
                    label="IBAN number"
                    type="text"
                    autoComplete="off"
                    data-testid="iban"
                    className={classNames(
                      'iban',
                      (accountNumberField || bankKeyField) && 'disabled'
                    )}
                    disabled={!!(accountNumberField || bankKeyField)}
                  />
                  <Input
                    register={register}
                    error={errors.swiftBic?.message}
                    name="swiftBic"
                    label="BIC code"
                    type="text"
                    autoComplete="off"
                    data-testid="swiftBic"
                    className="bicCode"
                  />
                  <div className="change-beneficiary-form__variable-inputs">
                    <Input
                      register={register}
                      error={errors.accountNumber?.message}
                      name="accountNumber"
                      label="Account Number"
                      type="text"
                      autoComplete="off"
                      data-testid="accountNumber"
                      className={classNames(
                        (ibanField || transferTypeField === 'SEPA') &&
                          'disabled',
                        'accountNumber'
                      )}
                      disabled={!!ibanField || transferTypeField === 'SEPA'}
                    />
                    <Input
                      register={register}
                      error={errors.bankKey?.message}
                      name="bankKey"
                      label="Bank Number"
                      type="text"
                      autoComplete="off"
                      defaultValue=""
                      data-testid="bankKey"
                      note="SortCode, BankKey, RoutingNumber, etc..."
                      className={classNames(
                        'bicCode',
                        (ibanField || transferTypeField === 'SEPA') &&
                          'disabled',
                        'bankKey'
                      )}
                      disabled={!!ibanField || transferTypeField === 'SEPA'}
                    />
                  </div>
                </div>
              </div>
              <Input
                register={register}
                error={errors.bankName?.message}
                name="bankName"
                label="Bank name (optional)"
                type="text"
                autoComplete="off"
                disabled={disableFields}
              />
            </div>
            <div className="change-beneficiary-form__form-section">
              <Input
                register={register}
                error={errors.name?.message}
                name="name"
                label="Name"
                type="text"
                autoComplete="off"
                disabled={disableFields}
              />
              <div className="change-beneficiary-form__group">
                <Input
                  register={register}
                  error={errors.street?.message}
                  name="street"
                  label="Street Name"
                  type="text"
                  autoComplete="off"
                  disabled={disableFields}
                  className="street"
                />
                <Input
                  register={register}
                  error={errors.houseNumber?.message}
                  name="houseNumber"
                  label="House number/id"
                  type="text"
                  autoComplete="off"
                  disabled={disableFields}
                  className="house-number"
                />
              </div>

              <div className="change-beneficiary-form__group transfer-new-contact-form__under-country">
                <Input
                  register={register}
                  name="city"
                  error={errors.city?.message}
                  label="City"
                  type="text"
                  autoComplete="off"
                  disabled={disableFields}
                  className="city"
                />
                <Input
                  register={register}
                  error={errors.zipCode?.message}
                  name="zipCode"
                  label="ZIP Code"
                  type="text"
                  autoComplete="off"
                  disabled={disableFields}
                  className="zipcode"
                />
              </div>
              <div className="transfer-new-contact-form__select">
                <p className="input__label">Country</p>
                <Select
                  id="country"
                  name="country"
                  register={register}
                  options={['', ...countryList.map(v => v.label)]}
                  error={errors.country?.message}
                  disabled={disableFields}
                  selectedOption={watch('country')}
                />
              </div>

              <div className="change-beneficiary-form__form-section">
                <div className="change-beneficiary-form__toggle-fields">
                  <button type="button" onClick={toggleOptionFields}>
                    More details
                  </button>
                  {showOptionalFields && (
                    <>
                      <Input
                        register={register}
                        error={errors.email?.message}
                        name="email"
                        label="Email (optional)"
                        type="email"
                        autoComplete="off"
                      />
                      <Input
                        register={register}
                        error={errors.telephone?.message}
                        name="telephone"
                        label="Telephone Number (optional)"
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="change-beneficiary-form__checkboxes">
                {isSwitchesVisible && (
                  <>
                    <SwitchWithLabel
                      control={control}
                      label="Save beneficiary"
                      name="saveBeneficiary"
                      disabled={disableFields}
                      stretch
                    />

                    <SwitchWithLabel
                      control={control}
                      label="Save global beneficiary"
                      name="isGlobal"
                      disabled={disableFields}
                      stretch
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <Button
            className="transfer-new-contact-form__validate-button"
            type="submit"
          >
            Validate
          </Button>
          {showChangeToSepaModal && (
            <Modal>
              <p>If you change to SEPA you will have to use IBAN instead.</p>
              <div className="modal__buttons">
                <Button onClick={resetForm} value="SEPA">
                  OK
                </Button>
                <Button onClick={() => setShowChangeToSepaModal(false)}>
                  CANCEL
                </Button>
              </div>
            </Modal>
          )}
        </form>
      </div>
    </>
  );
};

export default TransferNewContactForm;
