import { getFeaturesList } from '@services/bapi/features';
import { useQuery } from 'react-query';

export function useGetFeaturesList(isEnabled: boolean) {
  return useQuery(
    'features',
    () => getFeaturesList().then(res => res.data.features),
    {
      enabled: isEnabled
    }
  );
}
