import Copy from '@assets/icons/icon_copy.svg?react';
import EditIcon from '@assets/icons/icon_edit.svg?react';
import { addSpacesEvery4Letters } from '@utils';
import { FC } from 'react';

interface SelectedAccount {
  iban: string;
  accountName: string;
}

interface AccountEditableLineProps {
  id: string;
  iban: string;
  bic: string;
  accountName: string;
  isSelected?: boolean;
  setConfirmCopy: (boolean) => void;
  setSelectedAccount: (account: SelectedAccount) => void;
}

export const AccountEditableLine: FC<AccountEditableLineProps> = ({
  id,
  accountName,
  iban,
  bic,
  isSelected,
  setSelectedAccount,
  setConfirmCopy
}) => {
  const onCopyClick = () => {
    navigator.clipboard.writeText(
      `IBAN/Account Number: ${iban} BIC/Swift/Sort Code: ${bic}`
    );
    setConfirmCopy(true);
  };

  return (
    <li className="account-line" key={id}>
      <div className="account-line__line">
        <div className="account-line__detail">
          <h5>Title</h5>
          <h3 className="small account-line__account-name">{accountName}</h3>
        </div>
        <div className="account-line__account-details">
          <div className="account-line__detail">
            <h5>Iban</h5>
            <h3 className="tiny text-uppercase regular">
              {addSpacesEvery4Letters(iban)}
            </h3>
          </div>
          <div className="account-line__detail">
            <h5>Bic</h5>
            <h3 className="tiny text-uppercase regular">{bic}</h3>
          </div>
          <button onClick={onCopyClick} type="button" aria-label="copy button">
            <Copy />
          </button>
          <button
            type="button"
            onClick={() => setSelectedAccount({ iban, accountName })}
            aria-label="edit button"
          >
            <EditIcon />
          </button>
        </div>
      </div>
      {isSelected && (
        <div className="transaction-line__selected-row-background" />
      )}
    </li>
  );
};
