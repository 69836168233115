import { Button } from '@components/Button';
import classNames from 'classnames';
import { FC } from 'react';

interface ErrorScreenProps {
  className?: string;
  error: string;
  backAction: () => void;
}

export const ErrorScreen: FC<ErrorScreenProps> = ({
  className,
  error,
  backAction
}) => {
  return (
    <div className={classNames('error-screen', className)}>
      <h3>{error}</h3>
      <img
        src="/assets/icons/icon_error.svg?react"
        alt="error icon"
        width={58}
        height={58}
      />
      <Button onClick={backAction} variant="underline">
        BACK
      </Button>
    </div>
  );
};
