import CirclesPattern from '@assets/backgrounds/circles_pattern.svg?react';
import React from 'react';

const PatternBanner: React.FC = () => {
  return (
    <div className="dashboard-pattern">
      <CirclesPattern />
    </div>
  );
};

export default PatternBanner;
