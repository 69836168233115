import classNames from 'classnames';
import RcSwitch from 'rc-switch';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

export type SwitchProps = {
  onChange?: () => void;
  checked?: boolean;
  name: string;
  control?: any;
  disabled?: boolean;
};

export const Switch: FC<SwitchProps> = props => {
  const { onChange, checked, control, name, disabled } = props;
  return control ? (
    <Controller
      render={({ field }) => (
        <RcSwitch
          disabled={disabled}
          defaultChecked={false}
          prefixCls="rc-switch"
          className="rc-switch"
          checked={field.value}
          onChange={val => field.onChange(val)}
        />
      )}
      name={name}
      control={control}
    />
  ) : (
    <RcSwitch
      checked={checked}
      onChange={onChange}
      defaultChecked={false}
      prefixCls="rc-switch"
      className="rc-switch"
      disabled={disabled}
    />
  );
};

export const SwitchWithLabel: FC<
  SwitchProps & {
    label: string;
    name: string;
    stretch?: boolean;
    className?: string;
  }
> = props => {
  const { label, name, className, stretch, ...rest } = props;
  return (
    <label
      className={classNames(
        'paragraph',
        'switch__label',
        stretch && 'switch__label-stretch',
        className
      )}
      htmlFor={name}
    >
      {label}
      <Switch name={name} {...rest} />
    </label>
  );
};
