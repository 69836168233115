import { FilterForm } from '@components/DetailsSidebars/Filters';
import dayjs from 'dayjs';

export const formatDateToODATAFormat = (date: Date) =>
  dayjs(date).format('YYYY-MM-DD');

export const getDatesFromPeriod = (period: FilterForm['period']) => {
  const today = new Date();

  const startDate = new Date();
  const endDate = today;

  if (period === 'MONTH') {
    startDate.setDate(today.getDate() - 29);
    startDate.setHours(0, 0, 0, 0);
  }
  if (period === 'WEEK') {
    startDate.setDate(today.getDate() - 6);
    startDate.setHours(0, 0, 0, 0);
  }

  return { startDate, endDate };
};
