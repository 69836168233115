import { Layout } from '@components/Layout';
import { AuthContext, AuthProvider } from '@hooks/useAuth';
import { routes } from '@res/routes';
import NotFound from '@screens/404';
import Auth from '@screens/Auth';
import MobileNumber from '@screens/Auth/MobileNumber';
import { ResetTransferMfa } from '@screens/Auth/ResetTransferMfa';
import Dashboard from '@screens/Dashboard';
import AccountOverview from '@screens/Dashboard/AccountOverview';
import AccountOverviewDetails from '@screens/Dashboard/AccountOverview/AccountOverviewDetails';
import AccountSettings from '@screens/Dashboard/AccountSettings';
import Reset from '@screens/Dashboard/AccountSettings/Reset';
import ResetLoginMfa from '@screens/Dashboard/AccountSettings/Reset/ResetLoginMfa';
import Statements from '@screens/Dashboard/AccountSettings/Statements';
import CustomizedReport from '@screens/Dashboard/AccountSettings/Statements/CustomizedReport';
import PreGeneratedReport from '@screens/Dashboard/AccountSettings/Statements/PreGeneratedReport';
import Beneficiaries from '@screens/Dashboard/Beneficiaries';
import Faq from '@screens/Dashboard/Faq';
import CommunicationHistory from '@screens/Dashboard/Faq/CommunicationHistory';
import FaqContact from '@screens/Dashboard/Faq/FaqContact';
import FaqQuestion from '@screens/Dashboard/Faq/FaqQuestion';
import { ForeignExchange } from '@screens/Dashboard/ForeignExchange';
import Notifications from '@screens/Dashboard/Notifications';
import { Transactions } from '@screens/Dashboard/Transactions';
import TransactionsByAccountID from '@screens/Dashboard/Transactions/TransactionsByAccountID';
import Transfer from '@screens/Dashboard/Transfer';
import Login from '@screens/Login';
import Logout from '@screens/Logout';
import { isFeatureActive } from '@utils';
import { FeaturesEnum } from '@utils/consts';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import {
  AuthProvider as AuthProviderKeycloak,
  AuthProviderProps
} from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import packageJson from '../package.json';
import './styles/global.scss';

const queryClient = new QueryClient();

// example of AUTHORITY string is https://test.auth.iban-x.net/realms/iBAN-X
const AUTHORITY = `${process.env.NEXT_PUBLIC_KEY_CLOAK_BASE_URL}/realms/${process.env.NEXT_PUBLIC_KEY_CLOAK_REALM}`;

const oidcConfig: AuthProviderProps = {
  authority: AUTHORITY,
  client_id: process.env.NEXT_PUBLIC_KEY_CLOAK_CLIENT_ID,
  redirect_uri: process.env.NEXT_PUBLIC_KEY_CLOAK_REDIRECT_URI,
  post_logout_redirect_uri: process.env.NEXT_PUBLIC_KEY_CLOAK_REDIRECT_URI,
  response_mode: 'fragment',
  response_type: 'code',
  onSigninCallback: () => {
    // NOTE: we don't need to implement any logic here since we use useAuth hook
  }
};

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: `IBAN-X@${packageJson.version}`
});

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProviderKeycloak {...oidcConfig}>
        <AuthProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            theme="light"
            className="Toastify__Container"
          />
          <AuthContext.Consumer>
            {({ features }) => (
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route element={<Layout />}>
                  {/* Protected routes */}
                  <Route path={routes.home} element={<Login />} />
                  <Route
                    path={routes.dashboard.root}
                    element={
                      isFeatureActive(
                        features,
                        FeaturesEnum.accountOverview
                      ) ? (
                        <Dashboard />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.accountOverview}
                    element={
                      isFeatureActive(
                        features,
                        FeaturesEnum.accountOverview
                      ) ? (
                        <AccountOverview />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.accountDetails}
                    element={
                      isFeatureActive(
                        features,
                        FeaturesEnum.accountOverview
                      ) ? (
                        <AccountOverviewDetails />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.accountDetailsByCurrency}
                    element={
                      isFeatureActive(
                        features,
                        FeaturesEnum.accountOverview
                      ) ? (
                        <AccountOverviewDetails />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.beneficiaries}
                    element={
                      isFeatureActive(features, FeaturesEnum.beneficiaries) ? (
                        <Beneficiaries />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.faq}
                    element={
                      isFeatureActive(features, FeaturesEnum.helpCenter) ? (
                        <Faq />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.faqContact}
                    element={
                      isFeatureActive(features, FeaturesEnum.helpCenter) ? (
                        <FaqContact />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.faqQuestion}
                    element={
                      isFeatureActive(features, FeaturesEnum.helpCenter) ? (
                        <FaqQuestion />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.communicationHistory}
                    element={
                      isFeatureActive(features, FeaturesEnum.helpCenter) ? (
                        <CommunicationHistory />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.notifications}
                    element={<Notifications />}
                  />
                  <Route
                    path={routes.dashboard.transactions}
                    element={
                      isFeatureActive(features, FeaturesEnum.transactions) ? (
                        <Transactions />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.transactionsByAccountID}
                    element={
                      isFeatureActive(features, FeaturesEnum.transactions) ? (
                        <TransactionsByAccountID />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.transactionsByAccountIDAndCurrency}
                    element={
                      isFeatureActive(features, FeaturesEnum.transactions) ? (
                        <TransactionsByAccountID />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.foreignExchange}
                    element={
                      isFeatureActive(features, FeaturesEnum.fx) ? (
                        <ForeignExchange />
                      ) : (
                        <NotFound />
                      )
                    }
                  />
                  <Route
                    path={routes.dashboard.accountSettings}
                    element={<AccountSettings />}
                  />
                  <Route
                    path={routes.dashboard.accountStatements}
                    element={<Statements />}
                  />
                  <Route
                    path={routes.dashboard.preGeneratedReport}
                    element={<PreGeneratedReport />}
                  />
                  <Route
                    path={routes.dashboard.customizedReport}
                    element={<CustomizedReport />}
                  />
                  {/* Always enabled features and routes */}
                  <Route
                    path={routes.resetTransferMfa}
                    element={<ResetTransferMfa />}
                  />
                  <Route path={routes.mobile} element={<MobileNumber />} />
                  <Route path={routes.auth} element={<Auth />} />
                  <Route path={routes.logout} element={<Logout />} />
                  <Route
                    path={routes.dashboard.transfer}
                    element={<Transfer />}
                  />
                  <Route path={routes.dashboard.resetMfa} element={<Reset />} />
                  <Route
                    path={routes.dashboard.loginMfa}
                    element={<ResetLoginMfa />}
                  />
                </Route>
              </Routes>
            )}
          </AuthContext.Consumer>
        </AuthProvider>
      </AuthProviderKeycloak>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
);
