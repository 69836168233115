import { ResponseProps } from '@components/CommunicationLine';
import { Attachment, attachThumbnail, Ticket } from '@services/bapi/jira';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

type File = {
  attachmentId: number;
  filename: string;
  createdDate: string;
  value: Promise<AxiosResponse<Blob, any>>;
};

export default function useAttachThumbnail(
  ticketId: Ticket['ticketId'],
  commentId: ResponseProps['commentId'],
  attachments: Attachment[],
  issueDate: string
) {
  const getAllAttachments = async () => {
    const responses: File[] = [];
    attachments?.forEach(attach => {
      responses.push({
        attachmentId: attach.attachmentId,
        filename: attach.filename,
        createdDate: attach.createdDate ? attach.createdDate : issueDate,
        value: attachThumbnail(ticketId, attach.attachmentId)
      });
    });

    return Promise.all(responses);
  };

  return useQuery(
    ['attachThumbnail', commentId],
    () => getAllAttachments().then(res => res)
    // { enabled: attachments.length > 0 }
  );
}
