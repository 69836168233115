import { Button } from '@components/Button';
import { OneTimeInput } from '@components/OneTimeInput';
import { Timer } from '@components/Timer';
import { toastMessage } from '@components/Toaster';
import routes from '@res/routes';
import {
  SendMobileNumber,
  sendMobileOtp,
  sendOtp,
  validateUserOtp
} from '@services/bapi/auth';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import PhoneInput from 'react-phone-input-2';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

/**
 * @author Arungopi
 * This component handles mobile numberregistration process
 * It calls corresponding APIs to generate and send OTP to mobile number
 * and validate the user entered OTP
 * and redirect to dashboard page
 */
type MobileNumberRegistrationProps = {
  onConfirmed?: (value?: boolean) => void;
  classes?: string;
  isAuth?: boolean;
  mobileDisabled?: boolean;
};

const MobileNumberRegistration: React.FC<MobileNumberRegistrationProps> = ({
  onConfirmed,
  classes = '',
  isAuth = false,
  mobileDisabled = false
}) => {
  const [mobile, setMobile] = useState('');
  const [isdisabled, setIsdisabled] = useState(false);
  const [otpIsdisabled, setOtpIsdisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [value, setValue] = React.useState('');
  const [remainTime, setRemainTime] = useState(0);
  const [color, setColor] = useState(false);
  const [resendDisabled, setResendDisabled] = React.useState(true);
  const formRef = React.useRef(null);
  const [_cookies, setCookie] = useCookies();

  const navigate = useNavigate();
  /**
   * @mutate mutation handles the @useMutation(function,object) to make api request
   * to generate and send otp
   * it required two parametrs @params 'createOtp'
                               @params  'object'.
   * @createOtp is an function it handles api request to send otp
   */
  const sendOtpApi = useMutation(sendMobileOtp, {
    onSuccess: response => {
      const otpExpiredTime = Number(response.data.expiresBy);
      const currentTime = Date.parse(new Date().toUTCString());
      setRemainTime(Math.floor((otpExpiredTime - currentTime) / 1000));
      setShow(true);
      setOtpIsdisabled(false);
      setIsdisabled(true);
      setColor(true);
      setMessage('OTP sent successfully');
    },
    onError: (error: AxiosError) => {
      if (error.response.status === 400) {
        setMessage('Invalid Mobile Number');
      } else {
        setMessage('Operation failed. Please try again');
      }
      setShow(false);
      setIsdisabled(false);
      setColor(false);
    }
  });
  /**
   * @onSendOtp is the function call @mutate onClick of sendSms button
   */
  const mobileNumber: SendMobileNumber = { mobileNumber: '' };
  mobileNumber.mobileNumber = `+${mobile}`;
  const onSendOtp = () => {
    setMessage('');
    if (mobile === '') {
      setMessage('Enter your mobile number');
      return;
    }
    setIsdisabled(true);
    sendOtpApi.mutate(mobileNumber);
  };

  const resendOTP = () => {
    sendOtpApi.mutate(mobileNumber);
  };

  /**
   * @validateOtpPin function will handles the @useMutation(function,object) to make api request
   * to validate otp.
   * it required two @params 'validateUserOtp'
                     @params  'object'.
   * @validateUserOtp is an function it handles api request to validate otp
   */

  const validateOtpApi = useMutation(validateUserOtp, {
    onSuccess: () => {
      setCookie('mobileVerified', 'true');
      toastMessage('Mobile Registration is Successfull.', true);
      if (isAuth) {
        onConfirmed();
      } else {
        navigate(routes.dashboard.root, { replace: true });
      }
    },
    onError: (error: AxiosError) => {
      if (error.response.status === 400) {
        setMessage('Invalid OTP');
      } else if (error.response.status === 410) {
        setMessage('OTP is timedout');
      } else {
        setMessage('Operation failed. Please try again');
      }
      setColor(false);
      setOtpIsdisabled(false);
      // setMessage('OTP validation failed. Please try again');
    }
  });

  /**
   * @validateOtp is an function calls @validateOtpPin function at the time of onclick event
   */
  const validateOtp = () => {
    const otpBody: sendOtp = { otp: '' };
    otpBody.otp = value;
    setOtpIsdisabled(true);
    validateOtpApi.mutate(otpBody);
  };
  /**
   * @changeNumber function handles to change the phonenumber
   */
  const changeNumber = () => {
    setIsdisabled(false);
    setRemainTime(0);
    setOtpIsdisabled(true);
    setMessage('');
  };
  const enableResend = () => {
    setRemainTime(0);
    setResendDisabled(false);
  };
  const width = isAuth && 'mobileNumberRegistration__authWidth';
  const buttonHeight = !isAuth && 'mobileNumberRegistration__resendButton';
  const runningMessage = 'Your code will expire in ';
  const completedMessage = 'Your code expired';
  return (
    <form
      ref={formRef}
      className={classNames('mobileNumberRegistration', classes)}
    >
      <div
        className={classNames(
          isdisabled && 'mobileNumberRegistration__disable',
          'mobileNumberRegistration__smsBlock'
        )}
      >
        <p className="mobileNumberRegistration__smsHead paragraph--large">
          Please enter your mobile number.
        </p>
        <br />
        <p className="paragraph--large">
          A verification code will be sent to you via SMS.
        </p>
      </div>
      <div
        className={classNames(
          (isdisabled || mobileDisabled) && 'mobileNumberRegistration__disable'
        )}
      >
        <h4 className="mobileNumberRegistration__label">Mobile number</h4>
        <div
          className={classNames('mobileNumberRegistration__inputGroup', width)}
        >
          <PhoneInput
            country="lu"
            enableSearch
            placeholder=""
            value={mobile}
            onChange={phone => setMobile(phone)}
            disabled={isdisabled || mobileDisabled}
            inputClass="mobileNumberRegistration__inputGroup__phoneInput"
          />
          <Button
            size="small"
            className={classNames(
              'mobileNumberRegistration__button',
              (isdisabled || mobileDisabled) &&
                'mobileNumberRegistration__button__inActive'
            )}
            onClick={onSendOtp}
            disabled={isdisabled || mobileDisabled}
          >
            SEND SMS
          </Button>
        </div>
      </div>
      <div
        className={classNames(
          'mobileNumberRegistration__message',
          color
            ? 'mobileNumberRegistration__message__successColor'
            : 'mobileNumberRegistration__message__failureColor'
        )}
      >
        <p>{message}</p>
      </div>
      <div className="mobileNumberRegistration__otpBlock">
        {show && (
          <div>
            <p>A verification code was sent via SMS to</p>
            <br />
            <p>
              <b> +{mobile} </b>
              <Link to="" replace>
                <a
                  href=""
                  className="mobileNumberRegistration__resendActive"
                  onClick={changeNumber}
                  role="button"
                >
                  Change
                </a>
              </Link>
            </p>
          </div>
        )}
        <h4 className="mobileNumberRegistration__label">Code</h4>
        <div
          className={classNames('mobileNumberRegistration__inputGroup', width)}
        >
          <OneTimeInput
            formMounted
            setValue={setValue}
            error=""
            disabled={otpIsdisabled}
            additional={isAuth ? 6 : 0}
            fields={6}
            separateEvery={3}
          />
          <Button
            type="button"
            className={classNames(
              'mobileNumberRegistration__otpButton',
              otpIsdisabled && 'mobileNumberRegistration__button__inActive'
            )}
            onClick={!otpIsdisabled ? validateOtp : undefined}
          >
            Validate
          </Button>
        </div>
        <div>
          {show && (
            <div className="mobileNumberRegistration__timer">
              <div className="mobileNumberRegistration__timerWidth">
                <Timer
                  isActive
                  Time={remainTime}
                  minutes
                  seconds
                  timerRunningMessage={runningMessage}
                  timerCompletedMessage={completedMessage}
                  EndTimerDirectCallFunction={enableResend}
                />
              </div>
              <div className={classNames(buttonHeight)}>
                <Button
                  variant="underline"
                  type="button"
                  className={classNames(
                    resendDisabled
                      ? 'mobileNumberRegistration__resendInActive'
                      : 'mobileNumberRegistration__resendActive'
                  )}
                  uppercase={false}
                  onClick={resendOTP}
                >
                  Resend OTP
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default MobileNumberRegistration;
