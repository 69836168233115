import { Beneficiary } from '@services/bapi/beneficiaries';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@components/Button';
import { FieldError, Input } from '@components/Input';
// import { OneTimeCodeModal } from '@components/OneTimeCodeModal';
import Check from '@assets/icons/icon_check.svg?react';
import Close from '@assets/icons/icon_close.svg?react';
import { LoadingScreen } from '@components/LoadingScreen';
import { Modal } from '@components/Modal';
import { Select } from '@components/Select';
import { SwitchWithLabel } from '@components/Switch';
import { TransferCreateNewContact } from '@components/TransferNewContactForm';
import { createBeneficiaryValidationSchema } from '@components/TransferNewContactForm/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import useAccounts from '@hooks/useAccounts';
import { useCheckIsSwiftBicAllowed } from '@hooks/useCheckAllowedSwiftBic';
import { useCountryList } from '@hooks/useCountryAllowedList';
import useCreateBeneficiary from '@hooks/useCreateBeneficiary';
import useDeleteBeneficiary from '@hooks/useDeleteBeneficiary';
import useUpdateBeneficiary from '@hooks/useUpdateBeneficiary';
import errorCodes from '@services/bapi/errorCodes';
import { removeAllWhiteSpaces } from '@utils';
import classNames from 'classnames';

type CreateEditBeneficiaryFormProps = {
  beneficiary?: Beneficiary;
  onGoBack?: () => void;
};

const CreateEditBeneficiaryForm: React.FC<CreateEditBeneficiaryFormProps> = ({
  beneficiary,
  onGoBack
}) => {
  const { data: bankAccounts } = useAccounts({
    retry: 1
  });
  const accountList = bankAccounts?.map(a => a.iban);
  const isEditing = React.useRef(
    Object?.entries(beneficiary || {}).length || false
  ).current;

  const [errorMessage, setErrorMessage] = React.useState('');
  const [saveStatus, setSaveStatus] = React.useState<
    'success' | 'error' | null
  >(null);
  const [showOtpModal, setShowOtpModal] = React.useState(false);
  const [showOtpModalDeleteBeneficiary, setShowOtpModalDeleteBeneficiary] =
    React.useState(false);
  const [isSepaChecked, setIsSepaChecked] = React.useState(
    beneficiary ? beneficiary.transferType === 'SEPA' : true
  );
  const { data: ownAccountsNumbers, isLoading } = useAccounts({
    select: data => data.map(({ iban }) => iban)
  });
  const [showOptionalFields, setShowOptionalFields] = React.useState(false);
  const [showChangeToSepaModal, setShowChangeToSepaModal] =
    React.useState(false);

  const { countryList } = useCountryList();
  const { checkIsSwiftBicAllowed, isAllowedCountryCodesFetching } =
    useCheckIsSwiftBicAllowed();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    control
  } = useForm({
    context: { ibanList: accountList },
    mode: 'onTouched',
    resolver: yupResolver(createBeneficiaryValidationSchema),
    defaultValues: {
      ...beneficiary,
      iban: beneficiary?.iban,
      bankKey: beneficiary?.bankNumber,
      accountNumber: beneficiary?.accountNumber,
      transferType: beneficiary?.transferType || 'SEPA',
      swiftBic: beneficiary?.bic || '',
      countryCode: beneficiary?.countryCode || '',
      label: beneficiary?.label || '',
      isGlobal: beneficiary?.isGlobal || false
    } as Partial<TransferCreateNewContact>
  });

  const createBeneficiary = useCreateBeneficiary();
  const updateBeneficiary = useUpdateBeneficiary();
  const deleteBeneficiary = useDeleteBeneficiary(beneficiary?.id);
  const otpConfirmed = React.useRef(false);
  const ibanField = watch('iban');
  const swiftBicField = watch('swiftBic');
  const accountNumberField = watch('accountNumber');
  const bankKeyField = watch('bankKey');
  const transferTypeField = watch('transferType');

  useEffect(() => {
    if (accountNumberField) {
      clearErrors('iban');
    }

    if (ibanField) {
      clearErrors('accountNumber');
      clearErrors('bankKey');
    }

    if (bankKeyField) {
      clearErrors('iban');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumberField, ibanField, swiftBicField, bankKeyField]);

  const onSubmit = async (values: TransferCreateNewContact) => {
    if (values.iban && ownAccountsNumbers?.includes(values.iban.trim())) {
      setError('iban', { message: 'This is one of your accounts' });
      return;
    }

    if (!ownAccountsNumbers?.includes(values.iban?.trim()) && errors.iban) {
      clearErrors('iban');
    }

    if (values?.swiftBic && !checkIsSwiftBicAllowed(values.swiftBic)) {
      setError('swiftBic', {
        message: `BIC is within a blocked country`,
        type: 'validate'
      });
      return;
    }

    // changed OTP to infoModal
    // if (
    //   process.env.NEXT_PUBLIC_OTP_ENABLED === 'true' &&
    //   !otpConfirmed.current
    // ) {
    //   setShowOtpModal(true);
    //   return;
    // }
    if (!otpConfirmed.current) {
      setShowOtpModal(true);
      return;
    }
    const trimedBic = removeAllWhiteSpaces(values.swiftBic);
    const countryCode = trimedBic?.slice(4, 6);

    const isSouthAfricaClient = countryCode?.toLowerCase() === 'za';

    const data = {
      name: values.name.trim(),
      bankName: values.bankName.trim(),
      transferType: values.transferType,
      iban: removeAllWhiteSpaces(values.iban),
      accountNumber: removeAllWhiteSpaces(values.accountNumber),
      bankNumber: isSouthAfricaClient
        ? removeAllWhiteSpaces(values.bankKey, true)
        : removeAllWhiteSpaces(values.bankKey, false),
      bic: removeAllWhiteSpaces(values.swiftBic),
      email: values.email?.trim(),
      telephone: values.telephone?.trim(),
      street: values.street.trim(),
      houseNumber: values.houseNumber.trim(),
      countryCode: values.countryCode.trim(),
      zipCode: values.zipCode.trim(),
      city: values.city.trim(),
      label: values.label ? values.label.trim() : values.name.trim(),
      isGlobal: values.isGlobal
    } as Beneficiary;

    try {
      if (isEditing) {
        await updateBeneficiary.mutateAsync({ ...data, id: beneficiary.id });
      } else {
        await createBeneficiary.mutateAsync(data);
      }
      setSaveStatus('success');
    } catch (error) {
      const err = error as any;

      if (
        (err.response.data as any)?.exception ===
        errorCodes.beneficiary.invalidIbanInput
      ) {
        if (
          (err.response.data as any)?.message ===
          'Invalid input: The informed BIC is not valid. Please check and try again.'
        ) {
          setError('swiftBic', {
            message: 'BIC is not valid',
            type: 'validate'
          });
          return;
        }
        setError('iban', {
          message: err.response.data?.message ?? 'IBAN is mandatory',
          type: 'validate'
        });
        return;
      }

      if (
        (err.response.data as any)?.exception ===
        errorCodes.beneficiary.invalidBicInput
      ) {
        setError('swiftBic', {
          message: 'BIC is not valid',
          type: 'validate'
        });
        return;
      }

      setSaveStatus('error');
      if (
        (err.response.data as any).code ===
        errorCodes.beneficiary.existingAccount
      ) {
        setErrorMessage(
          'A BENEFICIARY WITH THIS ACCOUNT NUMBER ALREADY EXISTS'
        );
        return;
      }
      setErrorMessage('Something went wrong creating the user');

      otpConfirmed.current = false;
    }
  };

  if (watch('country')) {
    const selectedCountry = getValues('country');
    const { code } = countryList.find(
      country => country.label === selectedCountry
    );

    setValue('countryCode', code);
  }

  const handleDeleteBeneficiary = React.useCallback(async () => {
    // changed OTP to infoModal
    // if (
    //   process.env.NEXT_PUBLIC_OTP_ENABLED === 'true' &&
    //   !otpConfirmed.current
    // ) {
    //   setShowOtpModalDeleteBeneficiary(true);
    //   return;
    // }

    if (!otpConfirmed.current) {
      setShowOtpModalDeleteBeneficiary(true);
      return;
    }

    try {
      await deleteBeneficiary.mutateAsync();
      onGoBack();
    } catch (error) {
      // Placeholder
    }
  }, [deleteBeneficiary, onGoBack]);

  const initiateDeleteBeneficiary = React.useCallback(async () => {
    setShowOtpModal(false);
    otpConfirmed.current = true;
    handleDeleteBeneficiary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successMessage = React.useMemo(
    () => (isEditing ? 'BENEFICIARY UPDATED' : 'BENEFICIARY ADDED'),
    [isEditing]
  );

  function handleOtpConfirmation() {
    setShowOtpModal(false);
    otpConfirmed.current = true;
    onSubmit(getValues(undefined));
  }

  useEffect(() => {
    // NOTE: we don't need to test swiftBic against
    // the allowed list before we get the allowed list
    if (!isEditing || isAllowedCountryCodesFetching) {
      return;
    }

    const swiftBic = getValues('swiftBic');
    if (swiftBic && !checkIsSwiftBicAllowed(swiftBic)) {
      setError('swiftBic', {
        message: `BIC is within a blocked country`,
        type: 'validate'
      });
    }
  }, [
    isEditing,
    isAllowedCountryCodesFetching,
    checkIsSwiftBicAllowed,
    getValues,
    setError
  ]);

  if (isLoading || isAllowedCountryCodesFetching) {
    return <LoadingScreen />;
  }

  const resetForm = e => {
    if (
      e.target.value === 'SEPA' &&
      !showChangeToSepaModal &&
      (accountNumberField || bankKeyField)
    ) {
      setValue('transferType', 'SWIFT');
      setShowChangeToSepaModal(true);
      return;
    }

    setShowChangeToSepaModal(false);
    setIsSepaChecked(e.target.value === 'SEPA');

    if (e.target.value === 'SEPA') {
      setValue('accountNumber', '');
      setValue('bankKey', '');
      clearErrors('accountNumber');
      clearErrors('bankKey');
    }
  };

  const toggleOptionFields = () => {
    setShowOptionalFields(value => !value);
  };

  return (
    <>
      {saveStatus === null && (
        <div className="change-beneficiary-form">
          <div className="change-beneficiary-form__container change-beneficiary-form__container--header">
            <div className="change-beneficiary-form__container change-beneficiary-form__container--mandatory-section">
              <h3>Beneficiary contract data</h3>
            </div>
          </div>
          <form>
            <div className="change-beneficiary-form__container change-beneficiary-form__container--form">
              <div className="change-beneficiary-form__form-section">
                <Input
                  register={register}
                  name="label"
                  error={errors.label?.message}
                  label="Label"
                  type="text"
                  autoComplete="off"
                  data-testid="label"
                />
                <div className="change-beneficiary-form__transferType">
                  <div className="change-beneficiary-form__transferType-inputs">
                    <label
                      htmlFor="sepa"
                      className={classNames(isSepaChecked && 'checked')}
                    >
                      SEPA
                      <input
                        type="radio"
                        name="transferType"
                        id="sepa"
                        value="SEPA"
                        {...register('transferType')}
                        onClick={resetForm}
                      />
                    </label>
                    <label
                      htmlFor="swift"
                      className={classNames(!isSepaChecked && 'checked')}
                    >
                      SWIFT
                      <input
                        type="radio"
                        name="transferType"
                        id="swift"
                        value="SWIFT"
                        {...register('transferType')}
                        onClick={resetForm}
                      />
                    </label>
                  </div>
                  {errors.transferType?.message && (
                    <FieldError error={errors.transferType?.message} />
                  )}
                </div>
                <div className="change-beneficiary-form__inline-inputs">
                  <div className="change-beneficiary-form__variable-inputs">
                    <Input
                      register={register}
                      error={errors.iban?.message}
                      name="iban"
                      label="IBAN number"
                      type="text"
                      autoComplete="off"
                      data-testid="iban"
                      className={classNames(
                        'iban',
                        (accountNumberField || bankKeyField) && 'disabled'
                      )}
                      disabled={!!(accountNumberField || bankKeyField)}
                    />
                    <Input
                      register={register}
                      error={errors.swiftBic?.message}
                      name="swiftBic"
                      label="BIC code"
                      type="text"
                      autoComplete="off"
                      data-testid="swiftBic"
                      className="bicCode"
                    />
                    <div className="change-beneficiary-form__variable-inputs">
                      <Input
                        register={register}
                        error={errors.accountNumber?.message}
                        name="accountNumber"
                        label="Account Number"
                        type="text"
                        autoComplete="off"
                        data-testid="accountNumber"
                        className={classNames(
                          (ibanField || transferTypeField === 'SEPA') &&
                            'disabled'
                        )}
                        disabled={!!ibanField || transferTypeField === 'SEPA'}
                      />
                      <Input
                        register={register}
                        error={errors.bankKey?.message}
                        name="bankKey"
                        label="Bank Number"
                        type="text"
                        autoComplete="off"
                        data-testid="bankKey"
                        note="SortCode, BankKey, RoutingNumber, etc..."
                        className={classNames(
                          'bicCode',
                          (ibanField || transferTypeField === 'SEPA') &&
                            'disabled'
                        )}
                        disabled={!!ibanField || transferTypeField === 'SEPA'}
                      />
                    </div>
                  </div>
                </div>
                <Input
                  register={register}
                  error={errors.bankName?.message}
                  name="bankName"
                  label="Bank name (optional)"
                  type="text"
                  autoComplete="off"
                  data-testid="bankName"
                />
              </div>
              <div className="change-beneficiary-form__form-section">
                <Input
                  register={register}
                  error={errors.name?.message}
                  name="name"
                  label="Name"
                  type="text"
                  autoComplete="off"
                  data-testid="name"
                />
                <div className="change-beneficiary-form__group">
                  <Input
                    register={register}
                    error={errors.street?.message}
                    name="street"
                    label="Street Name"
                    type="text"
                    autoComplete="off"
                    data-testid="street"
                    className="street"
                  />
                  <Input
                    register={register}
                    error={errors.houseNumber?.message}
                    name="houseNumber"
                    label="House number/id"
                    type="text"
                    autoComplete="off"
                    data-testid="houseNumber"
                    className="house-number"
                  />
                </div>

                <div className="change-beneficiary-form__group">
                  <Input
                    register={register}
                    name="city"
                    error={errors.city?.message}
                    label="City"
                    type="text"
                    autoComplete="off"
                    data-testid="city"
                    className="city"
                  />
                  <Input
                    register={register}
                    error={errors.zipCode?.message}
                    name="zipCode"
                    label="ZIP Code"
                    type="text"
                    autoComplete="off"
                    data-testid="sipCode"
                    max={10}
                    className="zipcode"
                  />
                </div>
                <div className="transfer-new-contact-form__select">
                  <p className="input__label">Country</p>
                  <Select
                    id="country"
                    name="country"
                    data-testid="country"
                    register={register}
                    options={['', ...countryList.map(v => v.label)]}
                    error={errors.country?.message}
                    selectedOption={
                      countryList?.find(
                        country => country.code === beneficiary?.countryCode
                      )?.label
                    }
                  />
                </div>
                <div className="change-beneficiary-form__form-section">
                  <div className="change-beneficiary-form__toggle-fields">
                    <Button variant="default" onClick={toggleOptionFields}>
                      More details
                    </Button>
                    {showOptionalFields && (
                      <>
                        <Input
                          register={register}
                          error={errors.email?.message}
                          name="email"
                          label="Email (optional)"
                          type="email"
                          autoComplete="off"
                          data-testid="email"
                        />
                        <Input
                          register={register}
                          error={errors.telephone?.message}
                          name="telephone"
                          label="Telephone Number (optional)"
                          data-testid="telephone"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="change-beneficiary-form__checkboxes">
                  <SwitchWithLabel
                    control={control}
                    label={
                      isEditing
                        ? 'Move to global beneficiary'
                        : 'Save global beneficiary'
                    }
                    name="isGlobal"
                    disabled={beneficiary?.isGlobal}
                    stretch
                  />
                </div>
              </div>
            </div>
            <div className="change-beneficiary-form__validate-buttons">
              <Button
                data-testid="submit"
                className="change-beneficiary-form__validate-button"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {isEditing ? 'Update' : 'Add'}
              </Button>
              {isEditing && (
                <Button
                  type="button"
                  className="change-beneficiary-form__validate-button"
                  onClick={handleDeleteBeneficiary}
                >
                  Delete
                </Button>
              )}
            </div>
          </form>
        </div>
      )}
      {saveStatus !== null && (
        <div className="beneficiaries__added">
          {saveStatus === 'success' ? (
            <div className="transfer-confirmation__icon">
              <Check />
            </div>
          ) : (
            <div className="transfer-confirmation__icon transfer-confirmation__icon--denied">
              <Close />
            </div>
          )}

          <p className="paragraph--large">
            {saveStatus === 'success' && successMessage}
            {saveStatus === 'error' && errorMessage.toUpperCase()}
          </p>
          <Button
            onClick={() =>
              saveStatus === 'success' ? onGoBack() : setSaveStatus(null)
            }
            type="button"
            variant="outline"
          >
            {saveStatus === 'success' ? 'BACK' : 'OK'}
          </Button>
        </div>
      )}
      {showOtpModal && (
        <Modal>
          <p>
            Are you sure you want to {isEditing ? 'update this' : 'add new'}{' '}
            beneficiary?
          </p>
          <div className="modal__buttons">
            <Button onClick={handleOtpConfirmation} value="SEPA">
              Yes
            </Button>
            <Button onClick={() => setShowOtpModal(false)}>No</Button>
          </div>
        </Modal>
      )}
      {/* {showOtpModal && (
        <OneTimeCodeModal
          title={`Please enter the One-time code and click OK to ${
            isEditing ? 'update beneficiary' : 'add new beneficiary'
          }`}
          onConfirmed={handleOtpConfirmation}
          viewModal={setShowOtpModal}
        />
      )} */}
      {showOtpModalDeleteBeneficiary && (
        <Modal>
          <p>Are you sure you want to delete this beneficiary?</p>
          <div className="modal__buttons">
            <Button onClick={initiateDeleteBeneficiary} value="SEPA">
              Yes
            </Button>
            <Button onClick={() => setShowOtpModalDeleteBeneficiary(false)}>
              No
            </Button>
          </div>
        </Modal>
      )}
      {/* {showOtpModalDeleteBeneficiary && (
        <OneTimeCodeModal
          title="Please enter the One-time code and click OK to delete the beneficiary"
          onConfirmed={initiateDeleteBeneficiary}
          viewModal={setShowOtpModalDeleteBeneficiary}
        />
      )} */}
      {showChangeToSepaModal && (
        <Modal>
          <p>If you change to SEPA you will have to use IBAN instead.</p>
          <div className="modal__buttons">
            <Button onClick={resetForm} value="SEPA">
              OK
            </Button>
            <Button onClick={() => setShowChangeToSepaModal(false)}>
              CANCEL
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreateEditBeneficiaryForm;
