import LoaderIcon from '@assets/icons/loader.svg?react';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outline' | 'underline';
  isLoading?: boolean;
  size?: 'medium' | 'small';
  uppercase?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'default',
      className = '',
      isLoading,
      size = 'medium',
      uppercase = true,
      ...rest
    },
    ref // receiving the ref here
  ) => {
    return (
      <button
        ref={ref} // attaching the ref to the button element
        type="button"
        className={classNames(
          className,
          'button',
          `button__${variant}`,
          `button__${size}`,
          uppercase && 'button__uppercase'
        )}
        {...rest}
      >
        {isLoading && <LoaderIcon className="button button__spinner" />}
        {children}
      </button>
    );
  }
);
