import * as Yup from 'yup';

export const generateStatementsSchema = Yup.object()
  .shape({
    account: Yup.string().required(),
    currency: Yup.array().min(1).required(),
    fileFormat: Yup.string().required().nullable(),
    period: Yup.string().required().nullable(),
    date: Yup.object()
      .shape({
        startDate: Yup.date(),
        endDate: Yup.date()
      })
      .notRequired()
      .when('period', ([period], schema) => {
        return period === 'CUSTOM'
          ? Yup.object()
              .shape({
                startDate: Yup.date()
                  .required()
                  .typeError('From date is required')
                  .max(
                    new Date(),
                    "From date shouldn't  be greater than today"
                  ),
                endDate: Yup.date()
                  .required()
                  .max(new Date(), "To date shouldn't  be greater than today")
                  .typeError('To date is required')
                  .test(
                    'greater',
                    'Must be greater than From date',
                    (value, context) => {
                      if (
                        value?.getTime() >= context.parent?.startDate?.getTime()
                      ) {
                        return true;
                      }

                      return false;
                    }
                  )
              })
              .required()
          : schema.notRequired();
      })
  })
  .required();
