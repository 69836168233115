import {
  BankAccountWithCurrencies,
  getBankAccounts
} from '@services/bapi/accounts';
import { UseQueryOptions, useQuery } from 'react-query';

export default function useAccounts<
  T extends any = BankAccountWithCurrencies[]
>(options?: UseQueryOptions<BankAccountWithCurrencies[], unknown, T>) {
  return useQuery(
    'accounts',
    async () =>
      await getBankAccounts().then(r => {
        r.sort(function (a, b) {
          return Number(a.bankAccountId) - Number(b.bankAccountId);
        });

        r.forEach(account => {
          account.currencies.sort((a, b) => a.code.localeCompare(b.code));
          if (!account.alias) {
            account.alias = 'iBAN-X Current Account';
          }
        });

        r.forEach(account => {
          // @ts-ignore
          account.id = account.accountId;
          // @ts-ignore
          delete account.accountId;
        });
        return r;
      }),
    {
      ...options
    }
  );
}
