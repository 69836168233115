import { SessionEndPopup } from '@components/SessionEndPopup';
import { useAuth } from '@hooks/useAuth';
import { AUTH_CHANNEL, authChannelMessages } from '@res/constants';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';

let authChannel: BroadcastChannel;
let notConfirmedTimer: ReturnType<typeof setTimeout>;

const inactivityTime: number =
  (Number(process.env.NEXT_PUBLIC_SESSION_IDLE_SECONDS) || 3 * 60) * 1000; // 3 minutes
const notConfirmedTime: number =
  (Number(process.env.NEXT_PUBLIC_SESSION_EXPIRATION_SECONDS) || 2 * 60) * 1000; // 2 minutes

export const SessionExpiration: React.FC = () => {
  const { handleLogout } = useAuth();
  const [showCountdownModal, setShowCountdownModal] = React.useState(false);

  const { reset } = useIdleTimer({
    eventsThrottle: 500,
    timeout: inactivityTime,
    onIdle: () => {
      setShowCountdownModal(true);
    },
    crossTab: true
  });

  const handleReset = React.useCallback(() => {
    reset();
    setShowCountdownModal(false);
    authChannel.postMessage(authChannelMessages.sessionExtended);

    if (notConfirmedTimer) {
      clearTimeout(notConfirmedTimer);
    }
  }, [reset]);

  React.useEffect(() => {
    if (!authChannel) {
      authChannel = new BroadcastChannel(AUTH_CHANNEL);
    }

    authChannel.onmessage = message => {
      if (message.data === authChannelMessages.sessionExtended) {
        setShowCountdownModal(false);
      }
    };
  }, []);

  React.useEffect(() => {
    if (showCountdownModal) {
      notConfirmedTimer = setTimeout(handleLogout, notConfirmedTime);
    }

    return () => {
      if (notConfirmedTimer) {
        clearTimeout(notConfirmedTimer);
      }
    };
  }, [handleLogout, showCountdownModal]);

  if (showCountdownModal) {
    return <SessionEndPopup onConfirm={handleReset} />;
  }

  return <></>;
};
