import {
  GetValidateBicCodeResponse,
  getValidateBicCode
} from '@services/bapi/bic';
import { useQueryClient } from 'react-query';

export function useValidateBicCode() {
  const queryClient = useQueryClient();

  const fetchBicCode = async (
    bicCode: string
  ): Promise<GetValidateBicCodeResponse | undefined> => {
    const response = await getValidateBicCode(bicCode);
    return response.data;
  };

  const query = (bicCode: string) => {
    return queryClient.fetchQuery(['bicCode'], () => fetchBicCode(bicCode));
  };

  return query;
}
