import React from 'react';

import { Loading } from '@components/Loading';
import { KEYCLOAK_TOKEN_KEY } from '@res/constants';
import { useCookies } from 'react-cookie';
import { useAuth as useAuthKeycloak } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const auth = useAuthKeycloak();
  const [cookies] = useCookies();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (auth.isAuthenticated && !cookies[KEYCLOAK_TOKEN_KEY]) {
      navigate(0);
    }
  }, [auth.isAuthenticated, cookies, navigate]);

  const loadingMessage = React.useMemo(() => {
    if (!auth.isAuthenticated) return 'redirecting to login page';
    if (auth.isAuthenticated) return 'redirecting to dashboard page';

    return '';
  }, [auth.isAuthenticated]);

  return (
    <div className="login">
      <section className="login__intro">
        <div className="login__intro-container">
          <div className="login__iban-logo">
            <img
              src="/assets/logos/iban-s_horizontal.svg?react"
              alt="iban logo"
              width={268}
              height={67}
            />
          </div>
          <h2 className="h1">Moving money Made simple</h2>
          <p className="paragraph--large">
            Easy access to payments and transaction banking for Fintechs and
            Digital Businesses
          </p>
        </div>
      </section>
      <section className="login__form">
        <div className="login__form-container">
          <div className="login__buttons-container">
            <Loading />
            <h4 className="text-uppercase">{loadingMessage}</h4>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
