import {
  SaveAccountAliasData,
  saveAccountAlias,
  updateAccountAlias
} from '@services/bapi/accounts';
import { useMutation, useQueryClient } from 'react-query';

export default function useCreateUpdateAlias(iscreateAlias: boolean) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: SaveAccountAliasData) =>
      iscreateAlias
        ? saveAccountAlias(data).then(resp => resp.data)
        : updateAccountAlias(data).then(resp => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accounts');
      }
    }
  );
}
