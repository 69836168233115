export enum FeaturesEnum {
  accountOverview = 'Account Overview',
  transfer = 'Transfer',
  fx = 'Foreign Exchange',
  transactions = 'Transactions',
  beneficiaries = 'Beneficiaries',
  accountSettings = 'Account Settings',
  helpCenter = 'Help Center',
  logout = 'Logout'
}
