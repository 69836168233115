import { Button } from '@components/Button';
import { CustomDateSelector } from '@components/CustomDateSelector';
import { FileFormatSelector } from '@components/FileFormatSelector';
import { FieldError } from '@components/Input';
import { Modal } from '@components/Modal';
import {
  AvailableCurrencies,
  DownloadStatementsProps
} from '@components/PreGeneratedStatements';
import { yupResolver } from '@hookform/resolvers/yup';
import useCustomReports from '@hooks/useCustomReports';
import { getDatesFromPeriod } from '@utils/date';
import { downloadFile } from '@utils/downloadFile';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
  Controller,
  FieldError as FormFieldError,
  useForm
} from 'react-hook-form';
import Select from 'react-select';
import { generateStatementsSchema } from './Schema';

export type RequestCustomStatements = {
  account: string;
  currency: AvailableCurrencies[];
  period: string;
  date: {
    endDate: any;
    startDate: any;
  };
  fileFormat: string;
};

export const CustomStatements: FC<DownloadStatementsProps> = ({
  bankAccounts
}) => {
  let filename = '';
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedCurrencies, setSelectedCurrencies] = useState(null);
  const [availableCurrencies, setAvailableCurrencies] = useState<
    AvailableCurrencies[]
  >([]);

  const { mutateAsync, isLoading } = useCustomReports({
    onSuccess(data) {
      downloadFile(null, data.data, filename);
    },
    onError() {
      setShowModal(true);
    }
  });

  const handleCurrencies = accountIdSelected => {
    setSelectedCurrencies(null);

    if (accountIdSelected !== 'ALL') {
      const accountSelected = bankAccounts.find(account =>
        account.id.includes(accountIdSelected)
      );

      setAvailableCurrencies([
        {
          value: 'ALL',
          label: 'All currencies'
        },
        ...accountSelected.currencies.map(currency => {
          return {
            value: currency.code,
            label: currency.code
          };
        })
      ]);
    } else {
      const allCurrencies = [
        ...bankAccounts
          .map(account => account.currencies)
          .flat()
          .map(currency => currency.code)
      ];

      setAvailableCurrencies([
        ...[...new Set(allCurrencies)].map(currency => {
          return {
            value: currency,
            label: currency
          };
        })
      ]);
    }
  };

  const {
    handleSubmit,
    register,
    control,
    resetField,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(generateStatementsSchema),
    defaultValues: {
      account: '',
      currency: [],
      period: '',
      date: undefined,
      fileFormat: 'pdf'
    }
  });

  const onSubmit = (values: RequestCustomStatements) => {
    if (values.period === 'WEEK' || values.period === 'MONTH') {
      const { endDate, startDate } = getDatesFromPeriod(values.period);
      values.date = { startDate, endDate };
    } else if (values.period === 'CUSTOM') {
      values.date.endDate = new Date(
        new Date(values.date.endDate).setHours(23, 59, 59)
      );
    }

    filename = `${values.account}-${values.currency[0].value}-${dayjs(
      values.date.startDate
    ).format('YYYYMMDD')}-${dayjs(values.date.endDate).format('YYYYMMDD')}.${
      values.fileFormat
    }`;
    mutateAsync(values);
  };

  return (
    <div className="customStatements">
      <form>
        <div className="customStatements__currencies">
          <div className="customStatements__currencies-input">
            <h4>Account number</h4>
            <Controller
              render={({ field }) => (
                <Select
                  options={[
                    ...bankAccounts.map(account => {
                      return {
                        value: account.iban,
                        label: `${account.alias} (${account.iban.slice(
                          0,
                          4
                        )}...${account.iban.slice(-4)})`
                      };
                    })
                  ]}
                  onChange={account => {
                    field.onChange(account.value);
                    setSelectedAccount(account.value);
                    handleCurrencies(account.value);
                    setCurrentStep(2);
                    resetField('currency', { keepTouched: false });
                    resetField('period', { keepTouched: false });
                    resetField('date', { keepTouched: false });
                    resetField('fileFormat', { keepTouched: false });
                  }}
                  placeholder="Select an account"
                />
              )}
              name="account"
              control={control}
            />
            {errors.account?.message && (
              <FieldError error={errors.account?.message} />
            )}
          </div>
          <div className="customStatements__currencies-input">
            <h4>Currency</h4>
            <Controller
              render={({ field }) => (
                <Select
                  value={selectedCurrencies}
                  options={[...availableCurrencies]}
                  onChange={(currencies: AvailableCurrencies[]) => {
                    // delete this 2 lines bellow to make multi
                    // @ts-ignore
                    currencies = [currencies];
                    // delete this 2 lines above to make multi
                    if (
                      currencies.findIndex(
                        currency => currency.label === 'All currencies'
                      ) !== -1
                    ) {
                      // delete this setSelectedCurrencies above to make multi
                      setSelectedCurrencies({
                        label: 'All currencies',
                        value: 'ALL'
                      });
                      // delete this field.onChange above to make multi
                      field.onChange([
                        {
                          label: 'All currencies',
                          value: 'ALL'
                        }
                      ]);
                      // uncomment 2 lines bellow to make multi
                      // setSelectedCurrencies(availableCurrencies);
                      // field.onChange(availableCurrencies);
                    } else {
                      setSelectedCurrencies(currencies);
                      field.onChange(currencies);
                    }

                    setCurrentStep(currencies.length > 0 ? 3 : 2);
                    resetField('period', { keepTouched: false });
                    resetField('date', { keepTouched: false });
                    resetField('fileFormat', { keepTouched: false });
                  }}
                  isDisabled={currentStep <= 1}
                  // uncomment line bellow to make multi
                  // isMulti
                  placeholder="Select a currency"
                />
              )}
              name="currency"
              control={control}
            />
            {(errors.currency as unknown as FormFieldError)?.message && (
              <FieldError
                error={(errors.currency as unknown as FormFieldError)?.message}
              />
            )}
          </div>
        </div>
        <div className="customStatements__date">
          <CustomDateSelector
            inline
            register={register}
            errors={errors}
            watch={watch}
            resetField={resetField}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            nextStep={{ index: 4, name: 'fileFormat' }}
            startDate={
              bankAccounts
                .find(account => account.iban === selectedAccount)
                ?.currencies.find(
                  currency => currency.code === watch('currency')[0]?.value
                )?.creationDate ||
              (bankAccounts
                .find(account => account.iban === selectedAccount)
                ?.currencies.reduce((acc, currency) => {
                  const { creationDate } = currency;
                  if (!acc || dayjs(creationDate).isBefore(acc)) {
                    return dayjs(creationDate);
                  }
                  return acc;
                }, dayjs())
                .format('YYYY-MM-DD') as unknown as Date)
            }
          />
        </div>
        <FileFormatSelector
          register={register}
          errors={errors.fileFormat?.message}
          currentStep={currentStep}
        />
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          uppercase
        >
          Download
        </Button>
      </form>
      {showModal && (
        <Modal className="preStatements__no-report">
          <p>
            There was a problem generating your report. Please try again later.
          </p>
          <Button onClick={() => setShowModal(false)}>OK</Button>
        </Modal>
      )}
    </div>
  );
};
