export const accountValidationDetails = [
  {
    countryCode: 'AD',
    bankNumberLength: 8,
    checkingBankNumber: 2,
    sepa: true,
    ibanLength: 24
  },
  {
    countryCode: 'BR',
    bankNumberLength: 8,
    checkingBankNumber: 2,
    sepa: false,
    ibanLength: 29
  },
  {
    countryCode: 'CH',
    bankNumberLength: 5,
    checkingBankNumber: 2,
    sepa: true,
    ibanLength: 21
  },
  {
    countryCode: 'CR',
    bankNumberLength: 4,
    checkingBankNumber: 2,
    sepa: false,
    ibanLength: 22
  },
  {
    countryCode: 'FI',
    bankNumberLength: 6,
    checkingBankNumber: 2,
    sepa: true,
    ibanLength: 18
  },
  {
    countryCode: 'GR',
    bankNumberLength: 7,
    checkingBankNumber: 2,
    sepa: true,
    ibanLength: 27
  },
  {
    countryCode: 'IL',
    bankNumberLength: 6,
    checkingBankNumber: 2,
    sepa: false,
    ibanLength: 23
  },
  {
    countryCode: 'LI',
    bankNumberLength: 5,
    checkingBankNumber: 2,
    sepa: true,
    ibanLength: 21
  },
  {
    countryCode: 'SE',
    bankNumberLength: 5,
    checkingBankNumber: 2,
    sepa: true,
    ibanLength: 24
  },
  {
    countryCode: 'TN',
    bankNumberLength: 5,
    checkingBankNumber: 2,
    sepa: false,
    ibanLength: 24
  },
  {
    countryCode: 'AE',
    bankNumberLength: 3,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 23
  },
  {
    countryCode: 'AL',
    bankNumberLength: 8,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'AT',
    bankNumberLength: 5,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 20
  },
  {
    countryCode: 'BA',
    bankNumberLength: 6,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 20
  },
  {
    countryCode: 'CZ',
    bankNumberLength: 4,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 24
  },
  {
    countryCode: 'DE',
    bankNumberLength: 8,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 22
  },
  {
    countryCode: 'DK',
    bankNumberLength: 4,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 18
  },
  {
    countryCode: 'EE',
    bankNumberLength: 2,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 20
  },
  {
    countryCode: 'ES',
    bankNumberLength: 8,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 24
  },
  {
    countryCode: 'FR',
    bankNumberLength: 10,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 27
  },
  {
    countryCode: 'GB',
    bankNumberLength: 6,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 22
  },
  {
    countryCode: 'HR',
    bankNumberLength: 7,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 21
  },
  {
    countryCode: 'HU',
    bankNumberLength: 8,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 28
  },
  {
    countryCode: 'IE',
    bankNumberLength: 6,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 22
  },
  {
    countryCode: 'IS',
    bankNumberLength: 4,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 26
  },
  {
    countryCode: 'IT',
    bankNumberLength: 10,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 27
  },
  {
    countryCode: 'KZ',
    bankNumberLength: 3,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 20
  },
  {
    countryCode: 'LB',
    bankNumberLength: 4,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'LU',
    bankNumberLength: 3,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 20
  },
  {
    countryCode: 'ME',
    bankNumberLength: 3,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 22
  },
  {
    countryCode: 'MK',
    bankNumberLength: 3,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 19
  },
  {
    countryCode: 'NA',
    bankNumberLength: 6,
    checkingBankNumber: 4
  },
  {
    countryCode: 'NO',
    bankNumberLength: 4,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 15
  },
  {
    countryCode: 'PL',
    bankNumberLength: 8,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 28
  },
  {
    countryCode: 'PT',
    bankNumberLength: 8,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 25
  },
  {
    countryCode: 'RS',
    bankNumberLength: 3,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 22
  },
  {
    countryCode: 'RU',
    bankNumberLength: 9,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 33
  },
  {
    countryCode: 'SA',
    bankNumberLength: 2,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 24
  },
  {
    countryCode: 'SI',
    bankNumberLength: 5,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 19
  },
  {
    countryCode: 'SK',
    bankNumberLength: 4,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 24
  },
  {
    countryCode: 'SM',
    bankNumberLength: 10,
    checkingBankNumber: 4,
    sepa: true,
    ibanLength: 27
  },
  {
    countryCode: 'UA',
    bankNumberLength: 8,
    checkingBankNumber: 4,
    sepa: false,
    ibanLength: 29
  },
  {
    countryCode: 'ZA',
    bankNumberLength: 6,
    checkingBankNumber: 4
  },
  {
    countryCode: 'AF',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AG',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AI',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AR',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AS',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AW',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AX',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'BE',
    bankNumberLength: 3,
    checkingBankNumber: 5,
    sepa: true,
    ibanLength: 16
  },
  {
    countryCode: 'BL',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'BN',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'BQ',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'BT',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'BY',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'CC',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'CD',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'CF',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'CG',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'CI',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'CK',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'CL',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'CM',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'CO',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'CU',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'CV',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 25
  },
  {
    countryCode: 'CW',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'CX',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'DJ',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'DM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'DZ',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 26
  },
  {
    countryCode: 'EC',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'EG',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 29
  },
  {
    countryCode: 'EH',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'ET',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'FJ',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'FK',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'FM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'FO',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 18
  },
  {
    countryCode: 'GA',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'GD',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GF',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GG',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GH',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GI',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: true,
    ibanLength: 23
  },
  {
    countryCode: 'GL',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 18
  },
  {
    countryCode: 'GM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GN',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GP',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GQ',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'GS',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GU',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'GW',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 25
  },
  {
    countryCode: 'GY',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'HN',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'HT',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'ID',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'IM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'IN',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'IQ',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 23
  },
  {
    countryCode: 'IR',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 26
  },
  {
    countryCode: 'JE',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'JM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'JP',
    bankNumberLength: 11,
    checkingBankNumber: 5
  },
  {
    countryCode: 'KE',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'KG',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'KH',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'KI',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'KM',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'KN',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'KP',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'KY',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'LA',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'LC',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 32
  },
  {
    countryCode: 'LK',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'LR',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'LS',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'LY',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 25
  },
  {
    countryCode: 'MA',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'MC',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: true,
    ibanLength: 27
  },
  {
    countryCode: 'MD',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 24
  },
  {
    countryCode: 'MF',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MG',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'ML',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'MN',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 20
  },
  {
    countryCode: 'MO',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MP',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MQ',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MR',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'MS',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MV',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MW',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MY',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'MZ',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 25
  },
  {
    countryCode: 'NC',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'NE',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'NF',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'NG',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'NI',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 32
  },
  {
    countryCode: 'NP',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'NR',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'NU',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'OM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PA',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PE',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PF',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PG',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PH',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PR',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'PY',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'RE',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'RW',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SB',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SC',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 31
  },
  {
    countryCode: 'SD',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 18
  },
  {
    countryCode: 'SG',
    bankNumberLength: 13,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SH',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SL',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SN',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'SO',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 23
  },
  {
    countryCode: 'SR',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SS',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'ST',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 25
  },
  {
    countryCode: 'SV',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'SX',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SY',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'SZ',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TC',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TD',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'TF',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TG',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'TH',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TJ',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TL',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 23
  },
  {
    countryCode: 'TM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TO',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TT',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TV',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TW',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'TZ',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'UG',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'US',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'UY',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'UZ',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'VC',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'VE',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'VG',
    bankNumberLength: 15,
    checkingBankNumber: 5,
    sepa: false,
    ibanLength: 24
  },
  {
    countryCode: 'VI',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'VN',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'VU',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'WF',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'WS',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'YE',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'ZM',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'ZW',
    bankNumberLength: 15,
    checkingBankNumber: 5
  },
  {
    countryCode: 'AO',
    bankNumberLength: 0,
    checkingBankNumber: 0,
    sepa: false,
    ibanLength: 25
  },
  {
    countryCode: 'AU',
    bankNumberLength: 6,
    checkingBankNumber: 3
  },
  {
    countryCode: 'AZ',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'BG',
    bankNumberLength: 8,
    checkingBankNumber: 3,
    sepa: true,
    ibanLength: 22
  },
  {
    countryCode: 'BH',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 22
  },
  {
    countryCode: 'DO',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'GT',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'JO',
    bankNumberLength: 8,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 30
  },
  {
    countryCode: 'KW',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 30
  },
  {
    countryCode: 'LT',
    bankNumberLength: 5,
    checkingBankNumber: 3,
    sepa: true,
    ibanLength: 20
  },
  {
    countryCode: 'LV',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: true,
    ibanLength: 21
  },
  {
    countryCode: 'MU',
    bankNumberLength: 8,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 30
  },
  {
    countryCode: 'MX',
    bankNumberLength: 6,
    checkingBankNumber: 3
  },
  {
    countryCode: 'NL',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: true,
    ibanLength: 18
  },
  {
    countryCode: 'PK',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 24
  },
  {
    countryCode: 'QA',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: false,
    ibanLength: 29
  },
  {
    countryCode: 'RO',
    bankNumberLength: 4,
    checkingBankNumber: 3,
    sepa: true,
    ibanLength: 24
  },
  {
    countryCode: 'BZ',
    bankNumberLength: 8,
    checkingBankNumber: 1
  },
  {
    countryCode: 'CY',
    bankNumberLength: 8,
    checkingBankNumber: 1,
    sepa: true,
    ibanLength: 28
  },
  {
    countryCode: 'GE',
    bankNumberLength: 2,
    checkingBankNumber: 1,
    sepa: false,
    ibanLength: 22
  },
  {
    countryCode: 'MT',
    bankNumberLength: 9,
    checkingBankNumber: 1,
    sepa: true,
    ibanLength: 31
  },
  {
    countryCode: 'TR',
    bankNumberLength: 5,
    checkingBankNumber: 1,
    sepa: false,
    ibanLength: 26
  },
  {
    countryCode: 'CA',
    bankNumberLength: 10,
    checkingBankNumber: 6
  },
  {
    countryCode: 'CN',
    bankNumberLength: 9,
    checkingBankNumber: 6
  },
  {
    countryCode: 'NZ',
    bankNumberLength: 6,
    checkingBankNumber: 6
  },
  {
    countryCode: 'BB',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'BD',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'BF',
    bankNumberLength: 0,
    checkingBankNumber: 0,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'BI',
    bankNumberLength: 0,
    checkingBankNumber: 0,
    sepa: false,
    ibanLength: 27
  },
  {
    countryCode: 'BJ',
    bankNumberLength: 0,
    checkingBankNumber: 0,
    sepa: false,
    ibanLength: 28
  },
  {
    countryCode: 'BM',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'BO',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'BS',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'BV',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'BW',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'ER',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'HK',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'HM',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'IO',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'KR',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'MH',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'MM',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'PN',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'PW',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'SJ',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'TK',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'UM',
    bankNumberLength: 0,
    checkingBankNumber: 0
  },
  {
    countryCode: 'VA',
    bankNumberLength: 0,
    checkingBankNumber: 0,
    sepa: true,
    ibanLength: 22
  },
  {
    countryCode: 'YT',
    bankNumberLength: 0,
    checkingBankNumber: 0
  }
];
