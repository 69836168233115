// import MockAdapter from 'axios-mock-adapter';
import { bapi } from './client';

export type TransferType = 'SEPA' | 'SWIFT';

export type CreateTransferData = {
  transferType: TransferType;
  data: {
    accountId: string;
    typeCode: number;
    postDate: Date;
    amount: string;
    currency: string;
    paymentNote: string;
    recipientIban: string;
    recipientAccountNumber: string;
    recipientBankNumber: string;
    recipientAccountName: string;
    recipientBic: string;
    beneficiaryAddress: {
      streetName: string;
      houseId: string;
      streetPostalCode: string;
      cityName: string;
      countryCode: string;
    };
    transferAttachments?: Attachment[];
  };
};

export type Attachment = {
  name: string;
  content: string | File;
};

export type TransferAttachmentsData = {
  transferAttachments: Attachment[];
};

const sepaURL = '/v1/payments/sepa-credit-transfers';
const swiftURL = '/v1/payments/swift-credit-transfers';

// const mock = new MockAdapter(bapi);

// const mockResponse = {
//   transferId: 'IBNX0120240314000000001045',
//   accountId: 'LU557136614336765826-EUR',
//   amount: '1.00',
//   currency: 'EUR',
//   paymentNote: 'test',
//   postDate: '2024-03-14T00:00:00',
//   valueDate: '2024-03-14T00:00:00',
//   plannedExecutionDate: '2024-03-14T00:00:00',
//   recipientIban: 'LU707139723628004297',
//   recipientAccountName: 'ACME company GmbH',
//   recipientBic: 'IBNXLULM',
//   beneficiaryAddress: {
//     streetName: 'Friedrichstrasse',
//     houseId: '25',
//     streetPostalCode: '11111',
//     cityName: 'Berlin',
//     countryCode: 'DE'
//   },
//   warningMessages: [
//     {
//       name: '20240131_Swift_0014963380_922.00EUR_.pdf',
//       message: 'File could not be uploaded'
//     },
//     {
//       name: '20240207_Swift_3024003502_17.81EUR.pdf',
//       message: 'File could not be uploaded'
//     }
//   ]
// };

// // Mocking the API calls for both SEPA and SWIFT
// mock.onPost('/v1/payments/sepa-credit-transfers').reply(200, mockResponse);
// mock.onPost('/v1/payments/swift-credit-transfers').reply(200, mockResponse);
// mock.onAny().passThrough();
export const createTransfer = (data: CreateTransferData) => {
  return bapi.post(data.transferType === 'SEPA' ? sepaURL : swiftURL, {
    ...data.data
  });
};
