import { FC } from 'react';

interface FlagProps {
  currencyCode: string;
  width?: number;
  hight?: number;
}

export const Flag: FC<FlagProps> = ({
  currencyCode,
  width = 40,
  hight = 40
}) => {
  const path = `/assets/flags/${currencyCode}.svg?react`;
  return currencyCode ? (
    <img
      src={path}
      alt={`${currencyCode} flag icon`}
      width={width}
      height={hight}
    />
  ) : null;
};
