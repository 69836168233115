import React from 'react';
import { Loading } from '../Loading';

export const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <Loading />
    </div>
  );
};
