import { Button } from '@components/Button';
import { ErrorScreen } from '@components/ErrorScreen';
import { Input } from '@components/Input';
import { Loading } from '@components/Loading';
import { Timer } from '@components/Timer';
import { useAuth } from '@hooks/useAuth';
import useMe from '@hooks/useMe';
import routes from '@res/routes';
import {
  cancelOperationRequest,
  resetTransferMFA,
  sendEmailOtp,
  validateEmailOTP
} from '@services/bapi/auth';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

/**
 * @EmailOtp component created by  @aurungopi
 * This component handles the validation of email otp.
 */
type mailprops = {
  step?: number;
  setStep?: any;
};
const EmailOtp: FC<mailprops> = ({ step, setStep }) => {
  const [emailOtp, setEmailOtp] = React.useState('');
  const [color, setColor] = React.useState(false);
  const [remainTime, setRemainTime] = React.useState(0);
  const [message, setMessage] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [resendDisabled, setResendDisabled] = React.useState(true);
  const [activateTimer, setActivateTimer] = React.useState(false);
  const [operationMessage, setOperationMessage] = React.useState('');
  const [operationMessageColor, setOperationMessageColor] =
    React.useState(true);
  const regx = /^[0-9\b]+$/;
  const { handleLogout } = useAuth();
  const [_cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  /**
   * @sendEmail handles the useMutation for sendEmailOtp api request.
   */
  const sendEmail = useMutation(sendEmailOtp, {
    onSuccess: response => {
      const otpExpiredTime = Number(response.data.expiresBy);
      const currentTime = Date.parse(new Date().toUTCString());
      setRemainTime(Math.floor((otpExpiredTime - currentTime) / 1000));
      setResendDisabled(true);
      setActivateTimer(true);
      setMessage('');
    },
    onError: () => {
      setColor(false);
      setMessage('Something went wrong. Please try again');
    }
  });

  /**
   * @useMe hook is used to fetch the user email.
   */
  const meResponse = useMe({
    onSuccess: data => {
      setEmail(data.email);
      setEmailVerified(data.emailVerified);
    }
  });

  useEffect(() => {
    sendEmail.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @handleMailOtp function is used to check the OTP is number or not and set to state variable.
   */
  const handleMailOtp = (e: any) => {
    const { value } = e.target;
    if (regx.test(value) || value === '') {
      setEmailOtp(e.target.value);
    }
  };
  /**
   *@maskWord function handles the email address is convert to a star formate.
   * @param str to change the email to star formate.
   * @param startIndex to check start index of the string.
   * @param endIndex to check the end index of the string.
   * @returns  to return str in star formate.
   */
  const maskWord = function (
    str: string,
    startIndex: number,
    endIndex: number
  ) {
    let startString = '';
    let endString = '';
    if (startIndex !== 0) {
      startString = str[startIndex - 1];
    }
    if (endIndex !== 0) {
      endString = str.slice(0 - endIndex);
    }
    return (
      startString + '*'.repeat(str.length - (endIndex + startIndex)) + endString
    );
  };
  /**
   *@maskEmail It is mask the email.
   * @param mail it is an argument to maskEmail function.
   * @returns to return email in star formate.
   */
  const maskEmail = function (mail: string) {
    if (mail !== '') {
      const arr = mail.split('@');
      const domain = arr[1].split('.');
      return `${maskWord(arr[0], 1, 1)}@${maskWord(domain[0], 0, 0)}
      .${domain[1]}`;
    }
    return null;
  };

  /**
   * @validateOtp handles the useMutation for validateEmailOTP api request.
   */
  const validateOtp = useMutation(validateEmailOTP, {
    onSuccess: () => {
      setMessage('OTP is valid');
      setCookie('resetTransferMFAInit', 'false');
      setRemainTime(0);
      setColor(true);
      setStep(step + 1);
    },
    onError: (error: AxiosError) => {
      if (error.response.status === 400) {
        setMessage('OTP is Invalid');
      } else if (error.response.status === 410) {
        setMessage('OTP is timedout');
      } else {
        setMessage('Operation failed. Please try again');
      }
      setColor(false);
    }
  });

  const resetFlags = useMutation(resetTransferMFA, {
    onSuccess: () => {
      sendEmail.mutate();
    }
  });
  /**
   * @resendOTP handles resend otp to email.
   */
  const resendOTP = () => {
    resetFlags.mutate();
  };
  /**
   * @checkOTP handles onclick event and make call to the useMutation for validating the OTP.
   */
  const checkOTP = () => {
    const OTPData = { otp: emailOtp };
    validateOtp.mutate(OTPData);
  };

  const enableResend = () => {
    setRemainTime(0);
    setResendDisabled(false);
  };
  /**
   * This method makes an api request to cancel the user operation.
   */
  const cancelOperation = useMutation(cancelOperationRequest, {
    onSuccess: () => {
      setCookie('resetTransferMFAInit', 'false');
      setCookie('otp', 'true');
      navigate(routes.dashboard.accountOverview, { replace: true });
      setOperationMessage('');
    },
    onError: () => {
      setOperationMessageColor(false);
      setOperationMessage('Something went wrong. Please try again');
    }
  });

  /**
   * This @cancelOperation handle the onclick event and make call to the useMutation for cancel the reset-transfer-mfa.
   */

  const cancelOperationButton = () => {
    const operation = 'RESET_TRANSFER_MFA';
    cancelOperation.mutate(operation);
  };

  const runningMessage = 'Your code will expire in ';
  const completedMessage = 'Your code is expired';

  if (meResponse.isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="emailOtp">
      {!emailVerified ? (
        <ErrorScreen
          error="No verified email id found. Please contact support."
          backAction={() => handleLogout()}
        />
      ) : (
        <div>
          <div className="emailOtp__disable">
            <p>A verification code was sent via email to {maskEmail(email)}</p>
            <p> Please check your inbox or your spam folder.</p>
            <div className="emailOtp__email">
              <div className="emailOtp__email__input">
                <Input
                  name="emailOtp"
                  label="Code"
                  maxLength={6}
                  value={emailOtp}
                  onChange={e => handleMailOtp(e)}
                />
              </div>
              <Button
                className="emailOtp__email__button"
                type="button"
                onClick={checkOTP}
              >
                Verify
              </Button>
            </div>
            <div className="emailOtp__timer">
              <div className="emailOtp__timerWidth">
                <Timer
                  isActive={activateTimer}
                  Time={remainTime}
                  minutes
                  seconds
                  timerRunningMessage={runningMessage}
                  timerCompletedMessage={completedMessage}
                  EndTimerDirectCallFunction={enableResend}
                />
              </div>
              <div className="emailOtp__resendButtonContainer">
                <Button
                  variant="underline"
                  type="button"
                  className={classNames(
                    resendDisabled
                      ? 'emailOtp__resendInActive'
                      : 'emailOtp__resendActive'
                  )}
                  uppercase={false}
                  onClick={resendOTP}
                >
                  Resend OTP
                </Button>
              </div>
            </div>
          </div>
          <p
            className={classNames(
              color ? 'emailOtp__successMessage' : 'emailOtp__errorMessage'
            )}
          >
            {message}
          </p>
          <div className="emailOtp__cancelButtonContainer">
            <Button
              variant="underline"
              type="button"
              className="emailOtp__resendActive"
              uppercase={false}
              onClick={cancelOperationButton}
            >
              Back To Dashboard
            </Button>
          </div>
          <p
            className={classNames(
              !operationMessageColor && 'emailOtp__errorMessage'
            )}
          >
            {operationMessage}
          </p>
        </div>
      )}
    </div>
  );
};
export default EmailOtp;
