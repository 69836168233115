import { CustomStatements } from '@components/CustomStatements';
import DashboardLayout from '@components/DashboardLayout';
import { LoadingScreen } from '@components/LoadingScreen';
import { PageHeader } from '@components/PageHeader';
import { PageSecondaryHeader } from '@components/PageSecondaryHeader';
import useAccounts from '@hooks/useAccounts';
import routes from '@res/routes';
import React from 'react';

const CustomizedReport: React.FC = () => {
  const { data: bankAccounts, isLoading } = useAccounts({
    refetchOnWindowFocus: false
  });

  if (isLoading) {
    return (
      <DashboardLayout
        mainContent={
          <section className="account-statements">
            <PageHeader title="Account Statements" />
            <LoadingScreen />
          </section>
        }
      />
    );
  }

  return (
    <DashboardLayout
      mainContent={
        <section className="account-statements">
          <PageHeader title="Account Statements" />
          <PageSecondaryHeader
            title="Download account statements"
            secondaryTitle="Customized report"
            titleRedirect={routes.dashboard.accountStatements}
          />
          <CustomStatements bankAccounts={bankAccounts} />
        </section>
      }
    />
  );
};

export default CustomizedReport;
