import { AccountOverviewDetailsHeader } from '@components/AccountOverviewDetailsHeader';
import { BankAccountRow } from '@components/BankAccountRow';
import DashboardLayout from '@components/DashboardLayout';
import { TransactionDetails } from '@components/DetailsSidebars/TransactionDetails';
import { LoadingScreen } from '@components/LoadingScreen';
import { PageHeader } from '@components/PageHeader';
import { TransactionLine } from '@components/TransactionLine';
import { TransactionList } from '@components/TransactionList';
import useAccounts from '@hooks/useAccounts';
import useTransactions from '@hooks/useTransactions';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import { Transaction } from '@services/bapi/transactions';
import {
  getAccountName,
  getTransactionName,
  getTransactionType
} from '@utils/transactionHelpers';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

const AccountOverviewDetails: React.FC = () => {
  const { accountId: bankAccountID, currency: selectedCurrency } = useParams();
  const initialCurrencyStack = selectedCurrency ? [selectedCurrency] : ['EUR'];
  const [selectedCurrencyStack, setSelectedCurrencyStack] =
    useState(initialCurrencyStack);

  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);

  const { data: accountDetails, isLoading: isLoadingAccountDetails } =
    useAccounts<BankAccountWithCurrencies>({
      select: data =>
        data.find(account => account.bankAccountId === bankAccountID)
    });

  const { data: dataTransactions, isFetched: isFetchedTransactions } =
    useTransactions(
      {
        account: accountDetails?.iban,
        orderBy: 'desc',
        withPaymentNotes: 'true'
      },
      {
        enabled: !!accountDetails?.iban
      }
    );

  // TODO: needs to be discussed: it might be better to show
  // the loader instead of a list of transactions instead of the whole page
  if (isLoadingAccountDetails || !accountDetails || !isFetchedTransactions) {
    return <DashboardLayout mainContent={<LoadingScreen />} />;
  }

  const { transactions: lastTransactions } = dataTransactions;

  function handleTransactionDetails(transactionID: string) {
    if (selectedTransaction?.transactionId === transactionID) {
      setSelectedTransaction(null);
      return;
    }

    setSelectedTransaction(
      lastTransactions.find(
        transaction => transaction.transactionId === transactionID
      )
    );
  }

  function selectCurrency(code) {
    setSelectedCurrencyStack(prevStack => {
      // Remove the code if it's already in the stack
      const updatedStack = prevStack.filter(item => item !== code);

      if (prevStack.length !== updatedStack.length) {
        return updatedStack;
      }

      updatedStack.push(code);

      return updatedStack;
    });
  }

  const filteredTransactions = lastTransactions.reduce(
    (acc, transaction) => {
      if (selectedCurrencyStack.includes(transaction.currencyAc)) {
        // Initialize count for this currency if not already done
        acc.counts[transaction.currencyAc] =
          acc.counts[transaction.currencyAc] || 0;

        // Include transaction if the count is less than 5
        if (acc.counts[transaction.currencyAc] < 5) {
          acc.transactions.push(transaction);
          acc.counts[transaction.currencyAc]++;
        }
      }

      return acc;
    },
    { transactions: [], counts: {} }
  ).transactions;

  return (
    <>
      <DashboardLayout
        mainContent={
          <div className="account-overview-details">
            <PageHeader title="Recent Transactions">
              <AccountOverviewDetailsHeader
                accountName={accountDetails.alias}
                iban={accountDetails.iban}
              />
            </PageHeader>
            <BankAccountRow
              accountId={bankAccountID}
              currencies={accountDetails.currencies}
              size="medium"
              handleClick={selectCurrency}
              currentCurrency={selectedCurrencyStack}
            />
            {filteredTransactions.length ? (
              <TransactionList title="">
                {filteredTransactions.map(transaction => (
                  <Fragment key={transaction.transactionId}>
                    <TransactionLine
                      type={transaction.debitIndicator ? 'debit' : 'credit'}
                      currency={transaction.currencyAc}
                      amount={transaction.amountAc}
                      paymentNote={transaction.paymentNote}
                      targetName={
                        transaction.counterPartyAccountHolder
                          ? transaction.counterPartyAccountHolder
                          : getTransactionName(transaction)
                      }
                      valueDate={transaction.valueDate}
                      onClick={() =>
                        handleTransactionDetails(transaction.transactionId)
                      }
                      isSelected={
                        transaction?.transactionId ===
                        selectedTransaction?.transactionId
                      }
                    />
                  </Fragment>
                ))}
              </TransactionList>
            ) : (
              <div className="account-overview-details__empty-container">
                <h2>No transactions available</h2>
              </div>
            )}
          </div>
        }
        sidebar={
          selectedTransaction ? (
            <TransactionDetails
              accountIban={selectedTransaction.accountId.split('-')[0]}
              accountName={getAccountName(selectedTransaction)}
              type={getTransactionType(selectedTransaction)}
              rawTransaction={selectedTransaction}
            />
          ) : undefined
        }
      />
    </>
  );
};

export default AccountOverviewDetails;
