export const availableCurrencies = [
  'EUR',
  'USD',
  'GBP',
  'JPY',
  'CHF',
  'DKK',
  'PLN',
  'HRK',
  'NOK',
  'TRY'
];

export const allCurrencies = [
  'AED',
  'AUD',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HRK',
  'HUF',
  'ILS',
  'JPY',
  'MXN',
  'NOK',
  'NZD',
  'PLN',
  'RON',
  'SAR',
  'SEK',
  'SGD',
  'TRY',
  'USD',
  'ZAR'
] as const;

export type Currencies = (typeof allCurrencies)[number];

type MappingCurrenciesFullNames = Record<Currencies, string>;
export const MAPPING_CURRENCIES_FULL_NAMES: MappingCurrenciesFullNames = {
  AED: 'United Arab Emirates Dirham',
  AUD: 'Australian Dollar',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CNY: 'Chinese Yuan Renminbi (offshore)',
  CZK: 'Czech Krona',
  DKK: 'Danish Krone',
  EUR: 'European Euro',
  GBP: 'British Pound',
  HKD: 'Hong Kong Dollar',
  HRK: 'Croatian Kuna',
  HUF: 'Hungarian Forint',
  ILS: 'Israeli Shekel',
  JPY: 'Japanese Yen',
  MXN: 'Mexican Pesos',
  NOK: 'Norwegian Krone',
  NZD: 'New Zealand Dollars',
  PLN: 'Polish Zloty',
  RON: 'Romanian Leu',
  SAR: 'Saudi Riyal',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  TRY: 'Turkish Lira',
  USD: 'United States Dollar',
  ZAR: 'South African Rand'
};
