import { FC } from 'react';

interface InnerHeaderProps {
  currentStep: number;
  title: string;
}

export const InnerHeader: FC<InnerHeaderProps> = ({ currentStep, title }) => {
  return (
    <div className="inner-header">
      <div className="inner-header__step">
        <h2>{currentStep}</h2>
      </div>
      <h2 className="inner-header__title">{title}</h2>
    </div>
  );
};
