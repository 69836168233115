import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

interface ModalProps {
  className?: string;
  onClose?: () => void;
  minWidth?: number;
  minHeight?: number;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  className,
  onClose,
  minWidth,
  minHeight
}) => {
  const width = minWidth || 'auto';
  const height = minHeight || 'auto';
  return (
    <div className={classNames('modal', className)}>
      <div className="modal__content" style={{ width, height }}>
        {onClose && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div className="modal__close" onClick={onClose}>
            &#x2715;
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
