import { useTransfer } from '@hooks/useTransfer';
import React from 'react';
import { TransferAccountToDebitPlugin } from '../../../../plugins';

export const TransferAccountToDebit: React.FC = () => {
  const { handleDebitAccountSelect, transferState } = useTransfer();
  return (
    <TransferAccountToDebitPlugin
      iban={transferState.debitAccount?.iban}
      handleDebitAccountSelect={handleDebitAccountSelect}
    />
  );
};
