/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { intervalToDuration } from 'date-fns';
import {
  BANK_HOLIDAYS_PER_CURRENCY,
  NUMBER_OF_DATES_TO_SEARCH_HOLIDAY
} from './const';

export type HolidayInNextNDays = { currencyCodes: string[]; holidayDate: Date };

function getClosestHolidays(today, bankHolidaysPerCurrency, currencies) {
  // Step 1: Filter the object with the given currency array
  const filteredBankHolidays = Object.keys(bankHolidaysPerCurrency)
    .filter(currency => currencies.includes(currency))
    .reduce((obj, key) => {
      obj[key] = bankHolidaysPerCurrency[key];
      return obj;
    }, {});

  // Step 2: Filter the dates that are less than or equal to 'today + n days'
  const filteredDates = {};
  for (const currency in filteredBankHolidays) {
    filteredBankHolidays[currency].forEach(date => {
      const duration = intervalToDuration({
        start: today,
        end: date
      });

      if (duration.years < 0 || duration.months > 0 || duration.days < 0) {
        return undefined;
      }

      if (duration.days > NUMBER_OF_DATES_TO_SEARCH_HOLIDAY) {
        return undefined;
      }

      if (!filteredDates[date]) {
        filteredDates[date] = [];
      }

      filteredDates[date].push(currency);
      today.setHours(0, 0, 0, 0);

      // Iterate over the keys of the object
      for (const key in filteredDates) {
        const curDate = new Date(key); // This will parse the date string into a date object

        // Compare the parsed date to today's date
        if (curDate < today) {
          delete filteredDates[key]; // Delete the key if it's in the past
        }
      }
    });
  }

  return filteredDates;
}

export function searchHolidaysInNextNDays(
  currencies: string[]
): Array<HolidayInNextNDays> {
  const today = new Date();

  const upcomingHolidays = getClosestHolidays(
    today,
    BANK_HOLIDAYS_PER_CURRENCY,
    currencies
  );

  const upcomingHolidaysInNextNDays: Array<HolidayInNextNDays> = [];

  for (const holiday in upcomingHolidays) {
    upcomingHolidaysInNextNDays.push({
      currencyCodes: upcomingHolidays[holiday],
      holidayDate: new Date(holiday)
    });
  }

  const sortedHolidays = upcomingHolidaysInNextNDays.sort((a, b) => {
    const dateA: any = a.holidayDate;
    const dateB: any = b.holidayDate;

    // Compare the Date objects to determine sorting order
    return dateA - dateB;
  });

  return sortedHolidays;
}
