import Upload from '@assets/icons/icon_upload.svg?react';
import { Button } from '@components/Button';
import { CommentResponseError } from '@components/CommunicationLine';
import { FieldError } from '@components/Input';
import { Modal } from '@components/Modal';
import { Select } from '@components/Select';
import { UploadInfo } from '@components/UploadInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateJiraTicket } from '@hooks/useCreateJiraTicket';
import { TicketState } from '@screens/Dashboard/Faq/FaqContact';
import { CreateTicket, CreateTicketResponse } from '@services/bapi/jira';
import { sanitizeString } from '@utils/sanitizeSring';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

export interface ContactUsFormProps {
  createdTicket: TicketState;
  setCreatedTicket: Dispatch<SetStateAction<TicketState>>;
  requestTypeOptions: any;
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  setCreatedTicket,
  createdTicket,
  requestTypeOptions
}) => {
  const {
    watch,
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      requestTypeId: undefined,
      summary: undefined,
      description: undefined,
      attachments: undefined
    } as CreateTicket
  });
  const queryClient = useQueryClient();
  const [isErrorAfterReplying, setIsErrorAfterReplying] =
    useState<CommentResponseError>({
      isError: false,
      message: null
    });

  const createTicket = useCreateJiraTicket({
    onSuccess: (resp: CreateTicketResponse) => {
      setCreatedTicket({
        ...createdTicket,
        isCreated: true,
        id: resp.ticketId
      });
      queryClient.invalidateQueries('tickets');
    },
    onError: err => {
      setIsErrorAfterReplying({
        isError: true,
        message: err
      });
    }
  });

  async function onSubmit(values: CreateTicket) {
    values.description = sanitizeString(values.description);
    values.summary = sanitizeString(values.summary);
    createTicket.mutateAsync(values);
  }

  const handleFileUpload = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setValue('attachments', event.target.files[0], { shouldValidate: true });
    },
    [setValue]
  );

  const attachment: any = watch('attachments');

  return (
    <form
      className="contact-form contact-form__field contact-form__width60"
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      {requestTypeOptions && (
        <>
          <label className="contact-form__width80" htmlFor="requestTypeId">
            <h3 className="small">What can we help you with today?</h3>
            <Select
              options={[
                '',
                ...Object.keys(requestTypeOptions.requestTypes).map(
                  key => requestTypeOptions.requestTypes[key].name
                )
              ]}
              values={[
                '',
                ...Object.keys(requestTypeOptions.requestTypes).map(
                  key => requestTypeOptions.requestTypes[key].id
                )
              ]}
              name="requestTypeId"
              id="requestTypeId"
              register={register}
            />
          </label>
        </>
      )}
      {errors.requestTypeId?.message && (
        <FieldError error={errors.requestTypeId?.message} />
      )}
      <label
        className="contact-form__field contact-form__width80"
        htmlFor="summary"
      >
        <h3 className="small">What’s your question?</h3>

        <textarea
          style={{ resize: 'none' }}
          rows={6}
          cols={80}
          id="summary"
          name="summary"
          placeholder="Maximum of 255 characters"
          {...register('summary')}
        />
      </label>
      {errors?.summary?.message && (
        <FieldError error={errors.summary?.message} />
      )}
      <label className="contact-form__field" htmlFor="subject">
        <h3 className="small">
          Tell us more about your question so we can help you better.
        </h3>
        <textarea
          style={{ resize: 'none' }}
          rows={6}
          cols={80}
          id="message"
          name="message"
          {...register('description')}
        />
      </label>
      {errors?.description?.message && (
        <FieldError error={errors.description.message} />
      )}
      <div className="contact-form__container--buttons">
        <div>
          <label htmlFor="file__input" className="contact-form__upload-dialog">
            <span
              id="get_file"
              className="contact-form__button--upload button button__outline button__small"
            >
              <div className="contact-form__container--upload-button">
                <Upload />
                <span className="paragraph--button-small">Upload File</span>
              </div>
            </span>
            <input
              id="file__input"
              name="file__input"
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={handleFileUpload}
            />
          </label>
          {attachment ? <p>{attachment.name} </p> : null}
          {errors?.attachments?.message ? (
            <FieldError error={errors.attachments.message} />
          ) : null}
          <UploadInfo />
        </div>
        <Button
          size="small"
          className="contact-form__button--send"
          type="submit"
          isLoading={createTicket.isLoading}
        >
          Send Request
        </Button>
      </div>
      {isErrorAfterReplying.isError && (
        <Modal>
          <div className="transfer-new-contact-form__modal">
            <p className="paragraph--large semiBold">
              {isErrorAfterReplying.message}
            </p>
            <Button
              onClick={() =>
                setIsErrorAfterReplying({ isError: false, message: null })
              }
            >
              OK
            </Button>
          </div>
        </Modal>
      )}
    </form>
  );
};

export default ContactUsForm;

const validationSchema = Yup.object().shape({
  requestTypeId: Yup.string().required('Topic is a required field'),
  summary: Yup.string()
    .required('Question is a required field')
    .max(255, 'Maximum of 255 characters')
    .matches(
      /^[a-zA-ZÀ-ÿ0-9.,'\-#@%&/ ]*$/gm,
      'Only alphanumeric, accented characters and the special characters ".,\'\\-#@%&/" are allowed'
    )
    .label('Summary'),
  description: Yup.string()
    .required('Message is a required field')
    .max(4000, 'Maximum of 4000 characters')
    .matches(
      /^[a-zA-ZÀ-ÿ0-9.,'\-#@%&/ ]*$/gm,
      'Only alphanumeric, accented characters and the special characters ".,\'\\-#@%&/" are allowed'
    )
    .label('Description'),
  attachments: Yup.mixed()
    .test('fileSize', 'Invalid file size', (value: any) => {
      if (!value) {
        return true;
      }

      return value.size && value.size <= 2 * (1000 * 1000);
    })
    .test('fileType', 'Invalid file type', (value: any) => {
      if (!value) {
        return true;
      }

      return (
        value.type &&
        ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(
          value.type
        )
      );
    })
    .nullable()
});
