import { AxiosResponse } from 'axios';
import { bapi } from './client';

export type GetCountryAllowedListResponse = Readonly<{
  allowedCountries: string[];
}>;

export type GetCountryAllowed = boolean;

export const getCountryCodeAllowedList = (): Promise<
  AxiosResponse<GetCountryAllowedListResponse>
> => {
  return bapi.get<GetCountryAllowedListResponse>('/v1/country/allowedlist');
};

export const getCountryCodeAllowed = (
  countryCode: string
): Promise<AxiosResponse<GetCountryAllowed>> => {
  return bapi.get<GetCountryAllowed>(`/v1/country/allowed/${countryCode}`);
};
