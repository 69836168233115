import Fullstar from '@assets/icons/icon_full-star.svg?react';
import Upload from '@assets/icons/icon_upload.svg?react';
import { Button } from '@components/Button';
import { FieldError } from '@components/Input';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { UploadInfo } from '@components/UploadInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateJiraComment } from '@hooks/useCreateJiraComment';
import useMe from '@hooks/useMe';
import { useSubmitFeedback } from '@hooks/useSubmitFeedback';
import { bapi } from '@services/bapi/client';
import { Attachment, Feedback, NewComment, Ticket } from '@services/bapi/jira';
import { sanitizeString } from '@utils/sanitizeSring';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseMutationResult, useQueryClient } from 'react-query';
import * as Yup from 'yup';

export interface CommentResponseError {
  isError: boolean;
  message: string | null;
}

export interface ResponseProps {
  commentId: number;
  author: string;
  createdDate: string;
  renderedBody: string;
  myName?: string;
  myUsername?: string;
}

interface CommunicationLineProps {
  ticketId: number;
  title: string;
  referenceNo: number;
  date: string;
  status: string;
  isOpen: boolean;
  setIsOpen: any;
  content?: ResponseProps[];
  isLoadingTicketDetails: boolean;
  description: string;
  // eslint-disable-next-line react/no-unused-prop-types
  attachments?: Attachment[];
  feedbackDetails: Feedback;
  showForm: boolean;
  isLoaded: any;
  setAttachments: any;
  link?: string;
}

type ResponseRender = ResponseProps & { ticketId: Ticket['ticketId'] };

const Response: FC<ResponseRender> = ({
  commentId,
  ticketId,
  author,
  createdDate,
  renderedBody,
  myName,
  myUsername
}) => {
  const downloadFile = (attachmentId, filename) => {
    bapi
      .get(`/v1/helpdesk/tickets/${ticketId}/attachments/${attachmentId}`, {
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  renderedBody = renderedBody
    .replace(/<span.*?>.*?<\/span>/gi, '')
    .replace(/<em.*?>.*?<\/em>/gi, '');

  return (
    <li
      key={commentId}
      className={classNames(
        'communication-response',
        (author === myUsername || author === myName) &&
          'communication-response__me'
      )}
    >
      <p className="communication-response__details semiBold">
        {author === myName ? myUsername : author} | {createdDate}
      </p>
      <p
        className="communication-response__text"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: renderedBody }}
      />
    </li>
  );
};

const thankYouMessage = (feedback: Feedback) => {
  const starRate = [];
  for (let index = 0; index < feedback.rating; index++) {
    starRate.push(<Fullstar />);
  }

  return (
    <>
      <div>{starRate}</div>
      <p>Thank you for your feedback.</p>
    </>
  );
};

const getResolvedFeedback = (
  handleSubmitFeedbackForm,
  registerFeedbackForm,
  errorsFeedbackForm,
  handleSubmitFeedback: (e) => void,
  submitFeedback: UseMutationResult<Feedback>,
  feedbackDetails: Feedback
) => {
  return !Object.keys(feedbackDetails).length ? (
    <>
      <p className="semiBold communication-line__reply-container--title">
        Please, give us your feedback.
      </p>
      <h3 className="tiny secondary regular">
        Are you satisfied with the help that was provided to you by the iBAN-X
        Customer Care?
      </h3>
      <div className="communication-line__feedback-container">
        <div className="communication-line__feedback-buttons">
          <fieldset className="rating">
            <input
              type="radio"
              name="feedbackRate"
              id="rate5"
              value="5"
              {...registerFeedbackForm('rating')}
            />
            <label htmlFor="rate5" />
            <input
              type="radio"
              name="feedbackRate"
              id="rate4"
              value="4"
              {...registerFeedbackForm('rating')}
            />
            <label htmlFor="rate4" />
            <input
              type="radio"
              name="feedbackRate"
              id="rate3"
              value="3"
              {...registerFeedbackForm('rating')}
            />
            <label htmlFor="rate3" />
            <input
              type="radio"
              name="feedbackRate"
              id="rate2"
              value="2"
              {...registerFeedbackForm('rating')}
            />
            <label htmlFor="rate2" />
            <input
              type="radio"
              name="feedbackRate"
              id="rate1"
              value="1"
              {...registerFeedbackForm('rating')}
            />
            <label htmlFor="rate1" />
          </fieldset>
          {errorsFeedbackForm.rating?.message && (
            <FieldError error={errorsFeedbackForm.rating.message} />
          )}
        </div>
        <div className="communication-line__feedback-textarea">
          <textarea
            maxLength={50}
            placeholder="Maximum of 50 characters"
            {...registerFeedbackForm('comment')}
          />
          {errorsFeedbackForm.comment?.message && (
            <FieldError error={errorsFeedbackForm.comment.message} />
          )}
        </div>
        <div className="communication-line__feedback-submit">
          <Button
            size="small"
            onClick={handleSubmitFeedbackForm(handleSubmitFeedback)}
            isLoading={submitFeedback.isLoading}
          >
            Submit feedback
          </Button>
        </div>
      </div>
    </>
  ) : (
    <div className="communication-line__feedback-confirmation">
      {thankYouMessage(feedbackDetails)}
    </div>
  );
};

export const CommunicationLine: FC<CommunicationLineProps> = ({
  ticketId,
  title,
  referenceNo,
  date,
  status,
  isOpen,
  setIsOpen,
  content,
  isLoadingTicketDetails,
  description,
  feedbackDetails,
  showForm,
  isLoaded,
  setAttachments,
  link
}) => {
  const [replyCharCount, setReplyCharCount] = useState(0);
  const [isErrorAfterReplying, setIsErrorAfterReplying] =
    useState<CommentResponseError>({
      isError: false,
      message: null
    });

  const {
    watch,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      comment: undefined,
      attachments: undefined
    } as NewComment
  });

  const {
    handleSubmit: handleSubmitFeedbackForm,
    register: registerFeedbackForm,
    formState: { errors: errorsFeedbackForm }
  } = useForm({
    resolver: yupResolver(feedbackValidationSchema),
    defaultValues: {
      rating: undefined,
      comment: undefined
    }
  });

  const queryClient = useQueryClient();
  const commentTicket = useCreateJiraComment(ticketId, {
    onSuccess: () => {
      queryClient.invalidateQueries(['ticketDetails', ticketId]);
      reset();
    },
    onError: err => {
      setIsErrorAfterReplying({
        isError: true,
        message: err
      });
    }
  });

  const submitFeedback = useSubmitFeedback(ticketId, {
    onSuccess: () => {
      queryClient.invalidateQueries(['ticketDetails', ticketId]);
    }
  });

  const {
    data: { preferredUsername: myUsername, name: myName }
  } = useMe();

  let communicationLineClasses = 'communication-line__line';
  let communicationLineOpenClasses = 'communication-line__arrow-pointer';
  let communicationLineConversationClasses = 'communication-line__conversation';

  const handleFileUpload = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setValue('attachments', event.target.files[0], { shouldValidate: true });
    },
    [setValue]
  );

  const attachment: any = watch('attachments');

  const renderResponses = () => {
    if (isLoadingTicketDetails && isOpen) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <>
        <Response
          commentId={ticketId}
          ticketId={ticketId}
          author={myUsername}
          createdDate={date}
          renderedBody={description}
          myUsername={myUsername}
        />
        {content?.map(response => (
          <Response
            commentId={response.commentId}
            ticketId={ticketId}
            author={response.author}
            createdDate={response.createdDate}
            renderedBody={response.renderedBody}
            myUsername={myUsername}
            myName={myName}
          />
        ))}
      </>
    );
  };

  const renderFeedbackOrReply = () => {
    // Here later will be reply
    return (
      <div className="communication-line__text-area">
        {/* <p className="semiBold communication-line__reply-container--title">
          Reply
        </p>
        <div>
          <textarea
            placeholder="Maximum of 3000 characters"
            rows={5}
            maxLength={3000}
            {...register('comment')}
            onChange={val => setReplyCharCount(val.target.value.length)}
          />
          {errors.comment?.message && (
            <FieldError error={errors.comment.message} />
          )}
          <h4 className="secondary regular communication-line__text-area-count">
            {replyCharCount}/3000
          </h4>
          <label htmlFor="file__input" className="contact-form__upload-dialog">
            <span
              id="get_file"
              className="contact-form__button--upload button button__outline button__small"
            >
              <div className="contact-form__container--upload-button">
                <Upload />
                <span className="paragraph--button-small">Upload File</span>
              </div>
            </span>
            <input
              id="file__input"
              name="file__input"
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={handleFileUpload}
            />
          </label>
          {attachment && <p>{attachment.name}</p>}
          {errors?.attachments?.message && (
            <FieldError error={errors.attachments.message} />
          )}
        </div>
        */}
        <div className="communication-line__info-container">
          {/* <UploadInfo /> */}
          <Button
            size="small"
            type="button"
            isLoading={commentTicket.isLoading}
            // onClick={handleSubmit(onSubmit)}
            onClick={() => window.open(link, '_blank')}
          >
            View ticket
          </Button>
        </div>
        {isErrorAfterReplying.isError && (
          <Modal>
            <div className="transfer-new-contact-form__modal">
              <p className="paragraph--large semiBold">
                {isErrorAfterReplying.message}
              </p>
              <Button
                onClick={() =>
                  setIsErrorAfterReplying({ isError: false, message: null })
                }
              >
                OK
              </Button>
            </div>
          </Modal>
        )}
      </div>
    );
  };

  const handleSubmitFeedback = (rate: Feedback) => {
    submitFeedback.mutateAsync(rate);
  };

  async function onSubmit(values: NewComment) {
    values.comment = sanitizeString(values.comment);
    commentTicket.mutateAsync(values);
    isLoaded.current = false;
    setAttachments([]);
  }

  if (showForm) {
    communicationLineConversationClasses +=
      ' communication-line__conversation--open';
    if (isOpen) {
      communicationLineClasses += ' communication-line__line--open';
      communicationLineOpenClasses +=
        ' communication-line__arrow-pointer--open';
    }
  }

  return (
    <li className="communication-line" key={ticketId}>
      <button
        type="button"
        className={classNames(communicationLineClasses)}
        onClick={e => setIsOpen(e, ticketId)}
        data-testid="submit"
      >
        <h3 className="small communication-line__account-name">{title}</h3>
        <div className="communication-line__account-details">
          <div className="communication-line__detail">
            <h4 className="regular secondary">Reference No.</h4>
            <h3 className="tiny text-uppercase semiBold">{referenceNo}</h3>
          </div>
          <div className="communication-line__detail">
            <h4 className="regular secondary">Date of Submission</h4>
            <h3 className="tiny text-uppercase semiBold">{date}</h3>
          </div>
          <div className="communication-line__detail">
            <h4 className="regular secondary">Status</h4>
            <h3 className="tiny text-uppercase semiBold">{status}</h3>
          </div>
        </div>
        <div className={classNames(communicationLineOpenClasses)}>▼</div>
      </button>
      <form className={classNames(communicationLineConversationClasses)}>
        {isOpen && (
          <>
            <ul className="communication-line__response-list">
              {renderResponses()}
            </ul>
            <div className="communication-line__reply-container">
              {renderFeedbackOrReply()}
            </div>
          </>
        )}
      </form>
    </li>
  );
};

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .required('A new comment is required')
    .max(3000, 'Maximum of 3000 characters'),
  attachments: Yup.mixed()
    .test('fileSize', 'Invalid file size', (value: any) => {
      if (!value) {
        return true;
      }

      return value.size && value.size <= 2 * (1000 * 1000);
    })
    .test('fileType', 'Invalid file type', (value: any) => {
      if (!value) {
        return true;
      }

      return (
        value.type &&
        ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(
          value.type
        )
      );
    })
    .nullable()
});

const feedbackValidationSchema = Yup.object<Feedback>().shape({
  comment: Yup.string().max(50, 'Maximum of 50 characters'),
  rating: Yup.string().required('Rate us').nullable()
});
