import EmailOtp from '@components/EmailOtp';
import { PageSecondaryHeader } from '@components/PageSecondaryHeader';
import PatternBanner from '@components/PatternBanner';
import StepsInfo from '@components/StepsInfo';
import React, { useState } from 'react';
import Auth from '../index';

/**
 *@author Arungopi
 *it handles the steps of reset transefermfa
 */

export type Step = {
  title: string;
};

export const ResetTransferMfa: React.FC = () => {
  const [step, setStep] = useState(2);
  const stepsList: Step[] = [
    {
      title: 'Login Authentication'
    },
    {
      title: 'Verify Email OTP'
    },
    {
      title: 'Reset Transfer MFA'
    }
  ];

  return (
    <div className="reset-transfer-mfa">
      <PatternBanner />
      <PageSecondaryHeader>
        <StepsInfo stepsList={stepsList} currentStep={step} />
      </PageSecondaryHeader>
      <div className="reset-transfer-mfa__steps">
        <p className="reset-transfer-mfa__steps-step">Step {step}</p>
        <h2 className="reset-transfer-mfa__steps-title">
          {stepsList[step - 1].title}
        </h2>
        <div className="reset-transfer-mfa__dynamic-steps">
          {step === 2 && <EmailOtp step={step} setStep={setStep} />}
          {step === 3 && (
            <div className="reset-transfer-mfa__auth">
              <Auth resetMFA={false} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
