import DashboardLayout from '@components/DashboardLayout';
import { PageHeader } from '@components/PageHeader';
import { SearchBar } from '@components/SearchBar';
import routes from '@res/routes';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../../package.json';
import faqs from './faqs.json';

const slugFromQuestion = (question = '') =>
  routes.dashboard.faqQuestionWithoutParam +
  question
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');

type FilteredFaqsType = {
  categories: {
    title: string;
    questions: {
      title: string;
      answer: string;
    }[];
  }[];
};

const Faq: React.FC = () => {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const filteredFaqs: FilteredFaqsType | null = useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();

    return faqs.categories.length
      ? ({
          categories: faqs.categories.map(category => {
            return {
              title: category.title,
              questions: category.questions.filter(question =>
                question.title.toLowerCase().includes(lowerCaseSearch)
              )
            };
          })
        } as FilteredFaqsType)
      : null;
  }, [search]);

  return (
    <DashboardLayout
      mainContent={
        <div className="faq">
          <PageHeader title="Help Center" subTitle="how can we help?" />
          <div className="faq__search-bar-container">
            <SearchBar
              className="faq__search-bar"
              placeholder="Search for help articles..."
              onChange={e => setSearch(e.currentTarget.value)}
            />
          </div>
          <div className="faq__container">
            <div className="faq__question-container">
              <h2 className="faq__category-title black">FAQ&apos;s</h2>
              {faqs.categories.length ? (
                filteredFaqs.categories.map(section =>
                  section.questions?.length ? (
                    <div className="faq__category-info" key={section.title}>
                      <h3 className="faq__category-title secondary">
                        {section.title}
                      </h3>
                      <ol className="faq__questions-list">
                        {section.questions.map(question => (
                          <li className="faq__question" key={question.title}>
                            <button
                              type="button"
                              onClick={() =>
                                navigate(slugFromQuestion(question.title))
                              }
                            >
                              {question.title}
                            </button>
                          </li>
                        ))}
                      </ol>
                    </div>
                  ) : (
                    <>
                      <h3 className="faq__category-title secondary">
                        {section.title}
                      </h3>
                      <p className="faq__category-title">
                        No faqs matching your search.
                      </p>
                    </>
                  )
                )
              ) : (
                <h3 className="faq__category-title secondary">
                  No faqs available at this moment.
                </h3>
              )}
            </div>
            <div>
              <div className="faq__contact-section">
                <h2 className="faq__category-title black">Support center</h2>
                <p className="semiBold">
                  Contact us or view all your previous communications.
                </p>
                <button
                  type="button"
                  onClick={() =>
                    navigate(routes.dashboard.communicationHistory)
                  }
                >
                  View
                </button>
              </div>
              <div className="faq__version">
                <p className="semiBold">
                  Current version: {packageJson.version}
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Faq;
