import * as Yup from 'yup';

export const generatePreStatementsSchema = Yup.object()
  .shape({
    account: Yup.string().required(),
    currency: Yup.string(),
    year: Yup.string().required().nullable(),
    month: Yup.number().required().nullable(),
    fileFormat: Yup.string().required().nullable()
  })
  .required();
