import { Beneficiary, deleteBeneficiary } from '@services/bapi/beneficiaries';
import { useMutation, useQueryClient } from 'react-query';

export default function useDeleteBeneficiary(beneficiaryId: string) {
  const queryClient = useQueryClient();

  return useMutation(() => deleteBeneficiary(beneficiaryId), {
    onSuccess: () => {
      queryClient.setQueryData(
        'beneficiaries',
        queryClient.setQueryData<Beneficiary[]>('beneficiaries', oldData =>
          oldData.filter(beneficiary => beneficiary.id !== beneficiaryId)
        )
      );

      queryClient.invalidateQueries('beneficiaries');
    }
  });
}
