import Close from '@assets/icons/icon_close.svg?react';
import { Button } from '@components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { addSpacesEvery4Letters } from '@utils';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface EditableCardProps {
  otherAccountsAlias?: Array<{ accountIban: string; alias: string }>;
  accountName?: string;
  iban: string;
  onSaveAccountAlias: (alias: string) => void;
  onCloseClick: () => void;
}

export const EditableCard: FC<EditableCardProps> = ({
  accountName,
  iban,
  onSaveAccountAlias,
  onCloseClick
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<{ accountAlias?: string }>({
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => {
    reset({ accountAlias: '' });
    onCloseClick();
  };

  const onSaveChanges = async (values: { accountAlias: string }) => {
    onSaveAccountAlias(values.accountAlias);
  };

  React.useEffect(() => {
    reset({
      accountAlias: accountName?.replace(/[^a-zA-Z0-9 ]/g, ' ') || ''
    });
  }, [accountName, iban, reset]);

  return (
    <>
      <div className="account-settings__edit">
        <div className="account-settings__edit-header">
          <h3>Account Name</h3>
          <button
            type="button"
            className="account-settings__confirm-close"
            onClick={handleClose}
            aria-label="close button"
          >
            <Close />
          </button>
        </div>
        <h4 className="regular secondary">{addSpacesEvery4Letters(iban)}</h4>
        <input name="accountAlias" type="text" {...register('accountAlias')} />
        {errors?.accountAlias && (
          <h5 className="editable-card__error">
            {errors?.accountAlias?.message}
          </h5>
        )}

        <div className="editable-card__edit-instructions">
          <ul>
            <li>
              <h5>max 25 characters</h5>
            </li>
            <li>
              <h5>no special characters</h5>
            </li>
          </ul>
          <Button
            onClick={handleSubmit(onSaveChanges)}
            className="account-settings__save-button"
            size="small"
          >
            save changes
          </Button>
        </div>
      </div>
    </>
  );
};

const validationSchema = Yup.object().shape({
  accountAlias: Yup.string()
    .required()
    .max(25)
    .matches(/^[0-9a-zA-Z/\-?:().,'+ ]*$/, 'No special characters')
    .label('Account name')
});
