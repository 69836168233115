import { SingleDatePickerProps } from '@components/YearPicker';
import classNames from 'classnames';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { FC, useState } from 'react';

export const MonthPicker: FC<
  SingleDatePickerProps & { yearSelected: String }
> = ({ register, resetField, oldestAccount, yearSelected }) => {
  dayjs.extend(localeData);
  const globalLocaleData = dayjs.localeData();
  const allMonths = globalLocaleData.months();
  const [prevChecked, setPrevChecked] = useState(null);
  const today = dayjs();

  return (
    <div className="months-view months-container">
      {allMonths.map((month, index) => {
        return (
          <>
            <input
              type="radio"
              name="monthPicker"
              id={month}
              value={index + 1}
              {...register('month')}
              disabled={
                (yearSelected === oldestAccount.year().toString() &&
                  index < oldestAccount.month()) ||
                (yearSelected === today.year().toString() &&
                  index > today.month() - 1)
              }
              onClick={e => {
                if (prevChecked === e.currentTarget.value) {
                  resetField('month');
                  resetField('account');
                  setPrevChecked(null);
                  return;
                }

                setPrevChecked(e.currentTarget.value);
              }}
            />
            <label
              className={classNames(
                'months-view__month',
                !(
                  (yearSelected === oldestAccount.year().toString() &&
                    index < oldestAccount.month()) ||
                  (yearSelected === today.year().toString() &&
                    index > today.month() - 1)
                ) && 'enabled',
                prevChecked && 'hide'
              )}
              htmlFor={month}
            >
              {month}
            </label>
          </>
        );
      })}
    </div>
  );
};
