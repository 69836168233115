import ArrowIn from '@assets/icons/icon_arrowin.svg?react';
import ArrowOut from '@assets/icons/icon_arrowout.svg?react';
import { Transaction } from '@services/bapi/transactions';
import { formatCurrency } from '@utils';
import classNames from 'classnames';
import { FC } from 'react';

interface TransactionLineProps {
  id?: string;
  type?: 'credit' | 'debit';
  status?: string;
  currency?: string;
  amount: number;
  targetName: string;
  valueDate?: Date;
  postDate?: Date;
  onClick: () => void;
  isSelected: boolean;
  account?: string;
  paymentNote?: Transaction['paymentNote'];
}

const fadeClass = 'transaction-line__fade';

export const TransactionLine: FC<TransactionLineProps> = ({
  id,
  type,
  status,
  currency,
  amount,
  targetName,
  valueDate,
  postDate,
  isSelected,
  account = '',
  paymentNote,
  onClick
}) => {
  return (
    <li key={id} className="transaction-line">
      <div className="transaction-line__row-details">
        <div className="transaction-line__source-details">
          <div className="transaction-line__transaction-icon">
            {type === 'credit' ? (
              <ArrowIn className={classNames(fadeClass)} />
            ) : (
              <ArrowOut />
            )}
          </div>
          {(valueDate || postDate) && (
            <div className="transaction-line__target-details">
              {valueDate && (
                <div className="transaction-line__detail transaction-line__detail--large">
                  <h4 className="regular secondary">Value Date</h4>
                  <h3 className="tiny text-uppercase">
                    {valueDate.toLocaleDateString('fr-CA')}
                  </h3>
                </div>
              )}
              {postDate && (
                <div className="transaction-line__detail transaction-line__detail--large">
                  <h4 className="regular secondary">Posting Date</h4>
                  <h3 className="tiny text-uppercase">
                    {postDate.toLocaleDateString('fr-CA')}
                  </h3>
                </div>
              )}
            </div>
          )}
          <div className="transaction-line__target-details">
            <div className="transaction-line__target-name">
              <h4 className="regular secondary">
                {type === 'credit' ? 'Sender' : 'Recipient'}
              </h4>
              <h3 className="small">{targetName}</h3>
            </div>
            {paymentNote && paymentNote.length > 0 && (
              <div className="transaction-line__target-dates">
                <h4 className="regular">{paymentNote}</h4>
              </div>
            )}
          </div>
        </div>
        <div className="transaction-line__transaction-details">
          {account ? (
            <div className="transaction-line__detail transaction-line__detail--large">
              <h4 className="regular secondary">Account</h4>
              <h3 className="tiny text-uppercase">{account}</h3>
            </div>
          ) : (
            <></>
          )}
          {status ? (
            <div className="transaction-line__detail transaction-line__detail--status">
              <h4 className="regular secondary">Status</h4>
              <h3 className="tiny text-uppercase">{status}</h3>
            </div>
          ) : (
            <></>
          )}
          <div className="transaction-line__detail transaction-line__detail--amount">
            <h4 className="regular secondary">Amount</h4>
            <h3
              className={classNames(
                'tiny',
                'text-uppercase',
                type === 'credit' && fadeClass
              )}
            >
              {formatCurrency(amount, currency)}
            </h3>
          </div>
        </div>
      </div>
      <button
        onClick={onClick}
        type="button"
        className="transaction-line__see-more-button"
      >
        <h4>•••</h4>
      </button>
      {isSelected && (
        <div className="transaction-line__selected-row-background" />
      )}
    </li>
  );
};
