import React, { FC } from 'react';

interface SearchBarProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const SearchBar: FC<SearchBarProps> = ({ placeholder, ...rest }) => {
  return (
    <div className="search-bar">
      <div className="search-bar__container">
        <input
          className="search-bar__input"
          id="search"
          placeholder={placeholder || 'Search'}
          {...rest}
        />
      </div>
    </div>
  );
};
