import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useCallback, useMemo } from 'react';

type UseCountryAllowedList = {
  countryList: Array<CountryItem>;
  resolveCountryItemByCode: (code: string) => CountryItem | undefined;
};

type CountryItem = {
  code: string;
  label: string;
};

const checkNonUnicodeCountryNameCharacters = (
  countriesToCheck: CountryItem[]
): CountryItem[] => {
  // HACK: some country names contain non unicode characters,
  // we prefer replace such country names with normalized English names
  const target = countriesToCheck.find(obj => obj.code === 'AX');
  if (target) {
    const source = { code: 'AX', label: 'Aland Islands' };
    Object.assign(target, source);
  }

  return countriesToCheck;
};

export const useCountryList = (): UseCountryAllowedList => {
  countries.registerLocale(enLocale);
  const countryObj = countries.getNames('en', { select: 'official' });

  const countryList = useMemo(() => {
    const filteredCountries = Object.entries(countryObj).map(
      ([code, label]) => {
        return {
          code,
          label
        };
      }
    );
    return checkNonUnicodeCountryNameCharacters(filteredCountries).sort(
      (a, b) => (a.label > b.label ? 1 : -1)
    );
  }, [countryObj]);

  const resolveCountryItemByCode = useCallback(
    (code: string): CountryItem | undefined => {
      const label = countryObj[code];
      if (label === undefined) {
        return undefined;
      }
      return {
        label,
        code
      };
    },
    [countryObj]
  );

  return {
    countryList,
    resolveCountryItemByCode
  };
};
