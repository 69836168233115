import {
  getValidCurrencies,
  ValidCurrenciesGETResponse
} from '@services/bapi/currencies';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ForeignExchangeException } from './types';

export default function useValidCurrencies(
  accountId: string,
  options?: UseQueryOptions<ValidCurrenciesGETResponse, unknown, any>
): UseQueryResult<
  ValidCurrenciesGETResponse,
  AxiosError<ForeignExchangeException, any>
> {
  return useQuery<
    ValidCurrenciesGETResponse,
    AxiosError<ForeignExchangeException, any>
  >(['accountId', accountId], async () => getValidCurrencies(accountId), {
    ...options
  });
}
