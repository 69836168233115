import Message from '@assets/icons/icon_message.svg?react';
import Money from '@assets/icons/icon_money.svg?react';
import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import { CurrencyInput } from '@components/CurrencyInput';
import { OneTimeCodeModal } from '@components/OneTimeCodeModal';
import { toastMessage } from '@components/Toaster';
import { yupResolver } from '@hookform/resolvers/yup';
import useAccounts from '@hooks/useAccounts';
import { useForeignExchange, useQuote } from '@hooks/useQuote';
import useValidCurrencies from '@hooks/useValidCurrencies';
import { Currencies } from '@res/availableCurrencies';
import { formatAmountWithCurrency } from '@utils/index';
import { useForm } from 'react-hook-form';
import { useForeignExchangeContext } from './ForeignExchange';
import { ExchangeFormDetails } from './types';

const MAX_REFERENCE_FIELD_LENGTH = 30;

const minDate = new Date();
// NOTE - This will be the date from yesterday.
minDate.setDate(minDate.getDate() - 1);

function getValidationSchemeForForeignExchangeDetails(maxAmount: number) {
  return Yup.object().shape({
    amount: Yup.number()
      .transform(value => (Number.isNaN(value) ? 0 : value))
      .min(0)
      .lessThan(maxAmount, 'Not enough funds')
      .required('Please enter the amount you want to exchange'),
    amountFx: Yup.number()
      .transform(value => (Number.isNaN(value) ? 0 : value))
      .min(0)
      .required('Please enter the amount you want to receive'),
    currencyFx: Yup.string(),
    currency: Yup.string(),
    reference: Yup.string()
      .label('Reference')
      .nullable()
      .max(MAX_REFERENCE_FIELD_LENGTH),
    isInternalTransfer: Yup.boolean(),
    internalAccountCurrencies: Yup.array(Yup.string())
  });
}

export const ForeignExchangeDetails: React.FC = () => {
  const { transferState, goToStep } = useForeignExchangeContext();
  const [accountBalance, setAccountBalance] = React.useState(0);
  const scheme = getValidationSchemeForForeignExchangeDetails(accountBalance);
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    setError,
    resetField,
    control,
    formState: { errors, dirtyFields, isValid },
    clearErrors
  } = useForm<Partial<ExchangeFormDetails>>({
    mode: 'all',
    resolver: yupResolver(scheme),
    defaultValues: { amount: undefined, amountFx: undefined, reference: '' }
  });

  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const [isSourceLastChanged, setIsSourceLastChanged] = React.useState(false);
  const [lastInputTouched, setLastInputTouched] = React.useState('amount');
  const [selectedAccount] = React.useState(transferState.debitAccount);
  const [isValidationError, setIsValidationError] = React.useState(false);
  const [otpModalOpen, setOtpModalOpen] = React.useState(false);

  const currencyWatch = watch('currency') as Currencies;
  const currencyFxWatch = watch('currencyFx') as Currencies;
  const amountWatch = watch('amount');
  const amountFxWatch = watch('amountFx');
  const referenceWatch = watch('reference');
  const amountCurrency = isSourceLastChanged ? currencyWatch : currencyFxWatch;

  const {
    data: dataQuote,
    isError: isErrorGetQuote,
    error: errorGetQuote
  } = useQuote({
    sourceCurrency: currencyWatch,
    targetCurrency: currencyFxWatch,
    amount: 1,
    amountCurrency
  });

  const {
    data: validCurrencies,
    isLoading: isValidCurrenciesLoading,
    error: validCurrenciesError,
    isError: isValidCurrenciesError
  } = useValidCurrencies(selectedAccount.iban);

  const {
    mutate: exchange,
    isLoading,
    error,
    isError,
    isSuccess
  } = useForeignExchange({
    amount: isSourceLastChanged ? amountWatch : amountFxWatch,
    amountCurrency,
    targetCurrency: currencyFxWatch,
    sourceCurrency: currencyWatch,
    clientOrderUUID: dataQuote?.clientOrderUUID,
    customerAccount: transferState?.accountToDebitId,
    referenceNote: referenceWatch,
    midRate: dataQuote?.exchangeRate
  });

  const checkDecimalState = (amount, currency, currencyField, lastTouched) => {
    if (`${amount}`.includes('.')) {
      const splitedAmount = amount.toString().split('.')[1];
      if (currency === 'HUF' || currency === 'JPY') {
        if (splitedAmount?.length > 0) {
          setError(currencyField, {
            message: 'Decimal numbers are not accepted for this currency'
          });
          setError(lastTouched, {
            message: 'Decimal numbers are not accepted for this currency'
          });
          setIsValidationError(true);
          setLastInputTouched(lastTouched);
          return false;
        }
      }

      if (splitedAmount?.length > 2) {
        setError(currencyField, {
          message: 'The amount can’t have more then 2 digits after a point'
        });
        setError(lastTouched, {
          message: 'The amount can’t have more then 2 digits after a point'
        });
        setIsValidationError(true);
        setLastInputTouched(lastTouched);
        return false;
      }
    }
    clearErrors(lastTouched);
    clearErrors(currencyField);
    return true;
  };

  useEffect(() => {
    if (dataQuote?.exchangeRate === undefined) {
      return;
    }

    if (dirtyFields.amount) {
      const calc = amountWatch * dataQuote.exchangeRate;
      const formattedCalc = +parseFloat(`${calc}`).toFixed(2);

      resetField('amount', {
        keepDirty: false,
        defaultValue: amountWatch as any
      });

      if (currencyFxWatch === 'HUF' || currencyFxWatch === 'JPY') {
        resetField('amountFx', {
          keepDirty: false,
          defaultValue: Math.round(formattedCalc)
        });
      } else {
        resetField('amountFx', {
          keepDirty: false,
          defaultValue: formattedCalc
        });
      }

      const isValidDecimalAmount = checkDecimalState(
        amountWatch,
        currencyWatch,
        'currency',
        'amount'
      );

      if (!isValidDecimalAmount) return;

      resetField('currency', { keepDirty: false, defaultValue: currencyWatch });
      setIsSourceLastChanged(true);
      setIsValidationError(false);
      setLastInputTouched('amount');
    }

    if (dirtyFields.currency) {
      if (lastInputTouched === 'amount') {
        const calc = amountWatch * dataQuote.exchangeRate;
        const formattedCalc = +parseFloat(`${calc}`).toFixed(2);

        if (currencyWatch === 'HUF' || currencyWatch === 'JPY') {
          resetField('amountFx', {
            keepDirty: false,
            defaultValue: Math.round(formattedCalc)
          });
        } else {
          resetField('amountFx', {
            keepDirty: false,
            defaultValue: formattedCalc
          });
        }

        const isValidDecimal = checkDecimalState(
          amountWatch,
          currencyWatch,
          'currency',
          'amount'
        );

        if (!isValidDecimal) return;

        resetField('amount', {
          keepDirty: false,
          defaultValue: amountWatch as any
        });
        resetField('currency', {
          keepDirty: false,
          defaultValue: currencyWatch
        });
        setIsSourceLastChanged(true);
        setIsValidationError(false);
        setLastInputTouched('amount');
      } else {
        const calc = amountFxWatch / dataQuote.exchangeRate;
        const formattedCalc = +parseFloat(`${calc}`).toFixed(2);

        if (currencyWatch === 'HUF' || currencyWatch === 'JPY') {
          resetField('amount', {
            keepDirty: false,
            defaultValue: Math.round(formattedCalc)
          });
        } else {
          resetField('amount', {
            keepDirty: false,
            defaultValue: formattedCalc
          });
        }

        setIsSourceLastChanged(false);
        setIsValidationError(false);
        setLastInputTouched('amountFx');
      }
    }

    if (dirtyFields.amountFx) {
      const calc = amountFxWatch / dataQuote.exchangeRate;
      const formattedCalc = +parseFloat(`${calc}`).toFixed(2);

      resetField('amountFx', {
        keepDirty: false,
        defaultValue: amountFxWatch
      });

      if (currencyWatch === 'HUF' || currencyWatch === 'JPY') {
        resetField('amount', {
          keepDirty: false,
          defaultValue: Math.round(formattedCalc)
        });
      } else {
        resetField('amount', { keepDirty: false, defaultValue: formattedCalc });
      }

      const isValidDecimalAmountFx = checkDecimalState(
        amountFxWatch,
        currencyFxWatch,
        'currencyFx',
        'amountFx'
      );

      if (!isValidDecimalAmountFx) return;

      resetField('currencyFx', {
        keepDirty: false,
        defaultValue: currencyFxWatch
      });
      setIsSourceLastChanged(false);
      setIsValidationError(false);
      setLastInputTouched('amountFx');
    }

    if (dirtyFields.currencyFx) {
      if (lastInputTouched === 'amount') {
        const calc = amountWatch * dataQuote.exchangeRate;
        const formattedCalc = +parseFloat(`${calc}`).toFixed(2);

        if (currencyFxWatch === 'HUF' || currencyFxWatch === 'JPY') {
          resetField('amountFx', {
            keepDirty: false,
            defaultValue: Math.round(formattedCalc)
          });
        } else {
          resetField('amountFx', {
            keepDirty: false,
            defaultValue: formattedCalc
          });
        }

        const isValidDecimalAmount = checkDecimalState(
          amountWatch,
          currencyWatch,
          'currency',
          'amount'
        );

        if (!isValidDecimalAmount) return;

        resetField('amount', {
          keepDirty: false,
          defaultValue: amountWatch as any
        });
        resetField('currency', {
          keepDirty: false,
          defaultValue: currencyWatch
        });

        setIsSourceLastChanged(true);
        setIsValidationError(false);
        setLastInputTouched('amount');
      } else {
        const calc = amountFxWatch / dataQuote.exchangeRate;
        const formattedCalc = +parseFloat(`${calc}`).toFixed(2);

        if (currencyWatch === 'HUF' || currencyWatch === 'JPY') {
          resetField('amount', {
            keepDirty: false,
            defaultValue: Math.round(formattedCalc as any)
          });
        } else {
          resetField('amount', {
            keepDirty: false,
            defaultValue: formattedCalc as any
          });
        }

        const isValidDecimalAmountFx = checkDecimalState(
          amountFxWatch,
          currencyFxWatch,
          'currencyFx',
          'amountFx'
        );

        if (!isValidDecimalAmountFx) return;

        resetField('amountFx', {
          keepDirty: false,
          defaultValue: amountFxWatch as any
        });
        resetField('currencyFx', {
          keepDirty: false,
          defaultValue: currencyFxWatch
        });
        setLastInputTouched('amountFx');
        setIsSourceLastChanged(false);
        setIsValidationError(false);
      }
    }

    if (amountWatch > accountBalance) {
      setError('amount', { message: 'Not enough funds' });
    }

    if (
      accountBalance >= amountWatch &&
      checkDecimalState(amountWatch, currencyWatch, 'currency', 'amount')
    ) {
      clearErrors('amount');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dirtyFields.currency,
    dirtyFields.currencyFx,
    dirtyFields.amount,
    dirtyFields.amountFx,
    amountWatch,
    amountFxWatch,
    dataQuote?.exchangeRate,
    resetField,
    accountBalance,
    currencyFxWatch,
    currencyWatch,
    setError,
    isValidationError
  ]);

  React.useEffect(() => {
    if (isError || isErrorGetQuote || isValidCurrenciesError) {
      if (
        (error?.response.status === 403 &&
          error?.response?.data.exception === 'MarketClosedException') ||
        (errorGetQuote?.response.status === 403 &&
          errorGetQuote?.response?.data.exception === 'MarketClosedException')
      ) {
        setError('amountFx', { message: 'Market is closed' });
        return;
      }
      if (
        validCurrenciesError?.response?.status === 400 &&
        validCurrenciesError?.response?.data.exception === 'FXException'
      ) {
        toastMessage(
          'No valid currencies found for the given customer account',
          false
        );
        goToStep('selectAccount');
        return;
      }

      if (
        error?.response?.status === 400 &&
        error?.response?.data.exception === 'TradeLimitAmountException'
      ) {
        setError('amount', { message: error?.response?.data.message });
        return;
      }
      if (
        error?.response?.status === 400 &&
        error?.response?.data.exception === 'TradeLimitException'
      ) {
        setError('amount', { message: error?.response?.data.message });
        return;
      }
      if (
        error?.response?.status === 400 &&
        error?.response?.data.exception === 'MinimumAmountException'
      ) {
        setError('amount', { message: error?.response?.data.message });
        return;
      }

      if (error?.response.data.message) {
        setError('amount', { message: error?.response.data.message });
      } else {
        setError('amount', { message: 'Exception: internal server error' });
      }
      console.error(error?.response?.data.exception);
      console.error(validCurrenciesError?.response?.data.exception);
    }
  }, [
    isError,
    isErrorGetQuote,
    error,
    setError,
    errorGetQuote?.response?.data.exception,
    errorGetQuote?.response.status,
    validCurrenciesError,
    isValidCurrenciesError,
    goToStep
  ]);

  const { data: debitAccount } = useAccounts({
    enabled: transferState?.isInternalTransfer || false,
    select: accounts => {
      return accounts.find(
        account => account.iban === transferState.accountToCreditIban
      );
    }
  });

  const currencyOptions = React.useMemo(() => {
    if (!selectedAccount || !validCurrencies) return [];

    const sortedAccountCurrencies = selectedAccount.currencies
      .map(currency => {
        if (transferState.isInternalTransfer) {
          return debitAccount?.currencies?.find(c => c.code === currency.code)
            ? currency.code
            : null;
        }
        return currency.code;
      })
      .filter(code => code !== null)
      .sort(() => -1);
    // NOTE: We want the first 3 currencies to be this, if they are available
    const firstCurrencies = ['EUR', 'GBP', 'USD'].filter(currency =>
      sortedAccountCurrencies.includes(currency)
    );

    const otherCurrencies = sortedAccountCurrencies.reduce((prev, curr) => {
      if (!firstCurrencies.includes(curr)) {
        prev.push(curr);
      }
      return prev;
    }, []);

    let currencies = [...firstCurrencies];

    if (otherCurrencies.length > 0) {
      currencies = [...currencies, ...otherCurrencies];
    }

    if (validCurrencies.length > 0) {
      currencies = currencies.filter(currency =>
        validCurrencies.includes(currency)
      );
    }

    return currencies;
  }, [
    selectedAccount,
    debitAccount?.currencies,
    transferState.isInternalTransfer,
    validCurrencies
  ]);

  React.useEffect(() => {
    if (currencyOptions.length) {
      setValue('currency', currencyOptions[0]);
    }
  }, [currencyOptions, setValue]);

  React.useEffect(() => {
    if (transferState.isInternalTransfer) {
      setValue('isInternalTransfer', true);
      setValue(
        'internalAccountCurrencies',
        debitAccount?.currencies?.map(currency => currency.code)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isSuccess) {
      goToStep('confirmation');
    }
  }, [isSuccess, goToStep]);

  React.useEffect(() => {
    if (currencyOptions.length) {
      setValue('currencyFx', currencyOptions[1]);
    }
  }, [currencyOptions, setValue]);

  React.useEffect(() => {
    if (currencyFxWatch === currencyWatch) {
      setValue(
        'currencyFx',
        currencyOptions.filter(currency => currency !== currencyWatch)[0]
      );
    }
  }, [currencyFxWatch, currencyOptions, currencyWatch, setValue]);

  React.useEffect(() => {
    const balance = transferState.debitAccount.currencies.find(
      ({ code }) => code === currencyWatch
    )?.availableBalance;

    setAccountBalance(balance);
  }, [currencyWatch, transferState.debitAccount.currencies]);

  const currencyInputHelperText = useMemo((): string | undefined => {
    if (!currencyWatch || accountBalance <= 0) {
      return undefined;
    }

    return `Available Balance: ${formatAmountWithCurrency(
      accountBalance,
      currencyWatch
    )}`;
  }, [currencyWatch, accountBalance]);

  const fxCurrencyInputHelperText = useMemo((): string | undefined => {
    const exchangeRate = dataQuote?.exchangeRate;

    if (!currencyFxWatch || !currencyWatch || exchangeRate === undefined) {
      return `Rate: is loading`;
    }

    return `Rate: 1 ${currencyWatch} = ${dataQuote?.exchangeRate} ${currencyFxWatch}`;
  }, [currencyWatch, currencyFxWatch, dataQuote]);

  function handleOtpConfirmation() {
    setOtpModalOpen(false);
    submitButtonRef.current.click();
  }

  function onValidate() {
    setOtpModalOpen(true);
  }

  return (
    <>
      <form
        className="transfer-details"
        onSubmit={handleSubmit(async () => exchange())}
      >
        <div className="transfer-details__item-container">
          <div className="transfer-details__item-label">
            <Money />
            <div>
              <h2>Amount</h2>
              <h4 className="regular">The amount you want to exchange</h4>
            </div>
          </div>
          <div className="transfer-details__inputs-container">
            <div className="transfer-details__amount-input">
              <CurrencyInput
                register={register}
                availableCurrencyCodes={currencyOptions as any}
                nameAmount="amount"
                nameCurrency="currency"
                control={control}
                error={errors.amount?.message || errors.currency?.message}
                autoComplete="off"
                step={0.01}
                min={0}
                helperText={currencyInputHelperText}
                data-testid="amount"
              />
            </div>
          </div>
        </div>

        <div className="transfer-details__item-container">
          <div className="transfer-details__item-label">
            <Money />
            <div>
              <h2>To</h2>
              <h4 className="regular">The amount you want to receive</h4>
            </div>
          </div>
          <div className="transfer-details__inputs-container">
            <div className="transfer-details__amount-input">
              <CurrencyInput
                availableCurrencyCodes={currencyOptions as any}
                register={register}
                nameAmount="amountFx"
                nameCurrency="currencyFx"
                control={control}
                error={errors.amountFx?.message || errors.currencyFx?.message}
                autoComplete="off"
                step={0.01}
                min={0}
                helperText={fxCurrencyInputHelperText}
                exceptedCurrencyCodes={[currencyWatch as Currencies]}
              />
            </div>
          </div>
        </div>

        <div className="transfer-details__item-container">
          <div className="transfer-details__item-label">
            <Message />
            <div>
              <h2>Reference</h2>
              <h4 className="regular">Add a reference (optional)</h4>
            </div>
          </div>
          <div className="transfer-details__inputs-container">
            <Input
              className="transfer-details__fullwidth"
              register={register}
              name="reference"
              error={errors.reference?.message}
              type="text"
              autoComplete="off"
              maxLength={MAX_REFERENCE_FIELD_LENGTH}
            />
          </div>
        </div>
        <div className="transfer-details__button-container">
          <Button
            disabled={
              isLoading ||
              isValidCurrenciesLoading ||
              !isValid ||
              isValidationError
            }
            type="submit"
            isLoading={isLoading || isValidCurrenciesLoading}
            className="foreign-exchange-details__button"
            ref={submitButtonRef}
            onClick={
              process.env.NEXT_PUBLIC_OTP_ENABLED === 'true'
                ? handleSubmit(onValidate)
                : handleSubmit(async () => exchange())
            }
          >
            Exchange
          </Button>
          {/* NOTE: We use this button to submit the form after valid OTP */}
          <button ref={submitButtonRef} hidden type="submit">
            submit
          </button>
        </div>
      </form>
      {otpModalOpen && (
        <OneTimeCodeModal
          onConfirmed={handleOtpConfirmation}
          viewModal={setOtpModalOpen}
        />
      )}
    </>
  );
};
