import { BankAccountRow } from '@components/BankAccountRow';
import { Button } from '@components/Button';
import DashboardLayout from '@components/DashboardLayout';
import { LoadingScreen } from '@components/LoadingScreen';
import { Modal } from '@components/Modal';
import { PageHeader } from '@components/PageHeader';
import useAccounts from '@hooks/useAccounts';
import { routes } from '@res/routes';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './accountOverview.scss';

interface IAccountOverview {
  errorHandler?: Dispatch<SetStateAction<boolean>>;
}

const AccountOverview: React.FC<IAccountOverview> = ({ errorHandler }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { data: bankAccounts, isLoading } = useAccounts({
    onError() {
      errorHandler(true);
      setShowErrorModal(true);
    },
    retry: 1
  });
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <DashboardLayout
        mainContent={
          <div>
            <LoadingScreen />
          </div>
        }
      />
    );
  }

  if (!isLoading && showErrorModal) {
    return (
      <DashboardLayout
        mainContent={
          <div>
            <PageHeader title="ERROR" />
            <Modal>
              <div className="modal__logged-out">
                <p>
                  We are currently facing some connection issue. Please try
                  after sometime
                </p>
                <Button
                  onClick={() => navigate(routes.logout, { replace: true })}
                >
                  OK
                </Button>
              </div>
            </Modal>
          </div>
        }
      />
    );
  }

  return (
    <DashboardLayout
      mainContent={
        <div>
          <PageHeader title="Select Account" />
          {!isLoading && !bankAccounts?.length
            ? 'You do not have any account available yet'
            : bankAccounts?.map(account => (
                <Link
                  key={account.id}
                  to={routes.dashboard.getTransactionsByAccountID(
                    account.bankAccountId
                  )}
                  className="bank-account-row-link nav-link"
                >
                  <div
                    className="dashboard-layout__parent-container"
                    key={account.id}
                  >
                    <BankAccountRow
                      border
                      title={account.alias}
                      iban={account.iban}
                      bic={account.bic}
                      currencies={account.currencies}
                      accountId={account.bankAccountId}
                    />
                  </div>
                </Link>
              ))}
        </div>
      }
    />
  );
};

export default AccountOverview;
