import { BankAccountRowHeader } from '@components/BankAccountRowHeader';
import { CurrencyCard } from '@components/CurrencyCard';
import { FC } from 'react';

import routes from '@res/routes';
import { AccountCurrency } from '@services/bapi/accounts';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

type BankAccountRowSize = 'large' | 'medium' | 'small';

interface BankAccountRowProps {
  border?: boolean;
  title?: string;
  iban?: string;
  bic?: string;
  currencies: AccountCurrency[];
  accountId?: string;
  size?: BankAccountRowSize;
  withLink?: boolean;
  handleClick?: (any) => void;
  currentCurrency?: string | string[];
}

// Define the priority order for the currencies
const priorityOrder = ['EUR', 'USD', 'GBP', 'CHF', 'JPY'];

function sortCurrencies(a, b) {
  const indexA = priorityOrder.indexOf(a.code);
  const indexB = priorityOrder.indexOf(b.code);

  // If both codes are found in the priority list, sort by priority order
  if (indexA !== -1 && indexB !== -1) return indexA - indexB;
  // If only one code is found, it comes first
  if (indexA !== -1) return -1;
  if (indexB !== -1) return 1;

  // If neither code is found, sort alphabetically
  return a.code.localeCompare(b.code);
}

export const BankAccountRow: FC<BankAccountRowProps> = ({
  border,
  title,
  iban,
  bic,
  currencies,
  accountId,
  size = 'large',
  withLink = true,
  handleClick,
  currentCurrency
}) => {
  const roundBorder = border
    ? 'bank-account-row bank-account-row__border'
    : 'bank-account-row';

  const sortedCurrencies = currencies.sort(sortCurrencies);

  return (
    <section
      className={classNames(
        roundBorder,
        `bank-account-row bank-account-row__border-${size} bank-account-row-${size}`
      )}
    >
      {title ? (
        <BankAccountRowHeader title={title} iban={iban} bic={bic} />
      ) : null}
      <div className="bank-account-row__swiper-container">
        <div className="row-wrap">
          {sortedCurrencies?.map(currency =>
            withLink ? (
              <Link
                key={currency.code}
                to={`${routes.dashboard.getTransactionsByAccountIDAndCurrency(accountId, currency.code)}`}
                className="bank-account-row-link"
              >
                <CurrencyCard
                  currencyCode={currency.code}
                  amount={currency.availableBalance}
                  currentCurrency={currentCurrency}
                  handleClick={handleClick}
                />
              </Link>
            ) : (
              <CurrencyCard
                key={currency.code}
                currencyCode={currency.code}
                amount={currency.availableBalance}
                handleClick={handleClick}
                currentCurrency={currentCurrency}
              />
            )
          )}
        </div>
      </div>
    </section>
  );
};
