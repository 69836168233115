import { Transaction } from '@services/bapi/transactions';

import { Options } from '@components/DetailsSidebars/Filters';
import { TransactionItemProps } from '@components/DetailsSidebars/TransactionDetails';
import dayjs from 'dayjs';

export const statusOptions: Options = [
  { label: 'Posted', value: 'posted' },
  { label: 'Posting Exception', value: 'posting exception' },
  { label: 'Posting Canceled', value: 'posting canceled' },
  { label: 'Posting Rejected', value: 'posting rejected' },
  { label: 'Posting Returned', value: 'posting returned' },
  { label: 'Posting in Approval', value: 'posting in approval' }
];

export const getTransactionMessage = (transaction: Transaction) => {
  if (transaction.paymentNotes.length) {
    const paymentNote = transaction.paymentNotes.find(
      note => note.typeCode === 'REM'
    );

    if (paymentNote && paymentNote.note) {
      return paymentNote.note;
    }
  }

  switch (transaction.transactionTypeCode) {
    case '110012':
      return 'Charge Inbound MT103';

    case '110061': {
      return 'IBAN Account Opening Fee';
    }

    default:
      return 'iBAN-X';
  }
};

export const getTransactionName = (transaction: Transaction) => {
  switch (transaction.transactionTypeCode) {
    case '110012':
    case '110061': {
      return 'COMO DL S.A.';
    }

    default:
      return 'N/A';
  }
};

export const translatePaymentNotesTypeCode = (typeCode: string) => {
  switch (typeCode) {
    case 'REM': {
      return 'Remittance';
    }
    case 'EEI': {
      return 'End to End ID';
    }
    case 'TXI': {
      return 'Transaction ID';
    }

    default:
      return typeCode;
  }
};

export const getAccountName = (transaction: Transaction) => {
  if (transaction.counterPartyAccountHolder) {
    return transaction.counterPartyAccountHolder;
  }

  return getTransactionName(transaction);
};

export const getTransactionType = (transaction: Transaction) => {
  if (transaction.debitIndicator) {
    return 'debit';
  }

  return 'credit';
};

export const transactionToTransactionDetails = (
  transaction: Transaction
): TransactionItemProps => ({
  amount: transaction.amountAc,
  postingDate: transaction.postingDate,
  valueDate: transaction.valueDate,
  status: transaction.statusName,
  currency: transaction.currencyAc,
  transactionNumber: transaction.transactionId,
  type: transaction.debitIndicator ? 'debit' : 'credit',
  referenceMessages: transaction.paymentNotes,
  bic: getTransactionBic(transaction),
  iban: transaction.counterPartyBankAccount
});

const getTransactionBic = (transaction: Transaction): string => {
  let bic = '';

  if (
    transaction.counterPartyBankId === '713' &&
    transaction.counterPartyBankCountry === 'LU'
  ) {
    // 4th of February of 2022 23:59:59
    const ibanxBicChangeDate = dayjs()
      .set('y', 2022)
      .set('M', 1)
      .set('date', 4)
      .set('h', 24)
      .set('m', 59)
      .set('s', 59);

    bic = dayjs(transaction.creationTs).isAfter(ibanxBicChangeDate)
      ? 'IBNXLULM'
      : 'ONPXLULM';
  } else {
    bic = transaction.counterPartyBic;
  }

  return bic;
};
