import {
  AvailableReportsRequest,
  getPreGeneratedReportAvailable
} from '@services/bapi/accounts';
import { useMutation } from 'react-query';

export default function usePreGeneratedReportsAvailable(options: any) {
  return useMutation(
    (account: AvailableReportsRequest) =>
      getPreGeneratedReportAvailable(account).then(res => res.data),
    {
      ...options
    }
  );
}
