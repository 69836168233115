import Check from '@assets/icons/icon_check.svg?react';
import Close from '@assets/icons/icon_close.svg?react';
import FileNotPassed from '@assets/icons/icon_file_not_passed.svg?react';
import FilePassed from '@assets/icons/icon_file_passed.svg?react';
import { Button } from '@components/Button';
import routes from '@res/routes';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface TransferConfirmationProps {
  success?: boolean;
  message?: string;
  files?: any[];
  warningMessages?: any;
}

export const TransferConfirmation: FC<TransferConfirmationProps> = ({
  success,
  message,
  files,
  warningMessages
}) => {
  const navigate = useNavigate();

  const renderFileItem = (file, index) => {
    return (
      <div className="file-list__item" key={`file-item-${index + 1}`}>
        <FilePassed />
        <div className="file-list__item--block">
          <p className="file-list__item--name">{file.name}</p>
          <p className="file-list__item--description">File attached</p>
        </div>
      </div>
    );
  };

  const renderErrorItem = (file, index) => {
    return (
      <div className="file-list__item" key={`file-item-${index + 1}`}>
        <FileNotPassed />
        <div className="file-list__item--block">
          <p className="file-list__item--name">{file.name}</p>
          <p className="file-list__item--description">Something went wrong</p>
        </div>
      </div>
    );
  };

  return (
    <div className="transfer-confirmation">
      {success ? (
        <>
          <div className="transfer-confirmation__icon">
            <Check />
          </div>
          <h2 className="regular">REQUEST RECEIVED</h2>
          <h3 className="small regular">
            {message || 'Your money will be sent'}
          </h3>
          {files ? (
            <div className="file-list">
              <div className="file-list-col">
                {warningMessages && warningMessages.length > 0
                  ? warningMessages.map((file, idx) =>
                      renderErrorItem(file, idx)
                    )
                  : files.map((file, idx) => renderFileItem(file, idx))}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div className="transfer-confirmation__icon transfer-confirmation__icon--denied">
            <Close />
          </div>
          <h2 className="regular">REQUEST DENIED</h2>
          <h3 className="small regular">
            {message || 'Your money will not be sent'}
          </h3>
        </>
      )}

      <Button
        onClick={() => navigate(routes.dashboard.accountOverview)}
        type="button"
        variant="default"
      >
        OK
      </Button>
    </div>
  );
};
