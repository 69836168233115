import { Button } from '@components/Button';
import { ErrorScreen } from '@components/ErrorScreen';
import { Modal } from '@components/Modal';
import { confirmOTP } from '@services/bapi/auth';
import { bapiError } from '@services/bapi/client';
import errorCodes from '@services/bapi/errorCodes';
import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { OneTimeInput } from '../OneTimeInput';

type OneTimeCodeModalProps = {
  onConfirmed: (value?: string) => void;
  title?: string;
  viewModal?: (value: boolean) => void;
};

export const OneTimeCodeModal: React.FC<OneTimeCodeModalProps> = ({
  onConfirmed,
  title,
  viewModal
}) => {
  const [error, setError] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [requestCount, setRequestCount] = React.useState(0);
  const formRef = React.useRef(null);

  const mutation = useMutation((pin: string) => confirmOTP(pin), {
    onMutate: () => {
      setRequestCount(prevState => prevState + 1);
    },
    onSuccess: () => {
      onConfirmed();
    },
    onError: (e: AxiosError<bapiError>) => {
      if (e.response.data.code === errorCodes.otp.wrongPing) {
        setError(
          "The One-time code is invalid. Make sure you have enter it correctly or that it hasn't expired."
        );
        return;
      }

      setError('Something went wrong please try again');
    }
  });

  const validateOtp = React.useCallback(() => {
    if (mutation.isLoading) return;

    mutation.mutate(value);
  }, [mutation, value]);

  const handleClose = () => {
    viewModal(false);
  };

  React.useEffect(() => {
    if (error) {
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Modal>
      {requestCount < 3 ? (
        <form
          ref={formRef}
          className="one-time-modal"
          onSubmit={e => {
            e.preventDefault();
            validateOtp();
          }}
        >
          <h2 className="one-time-modal__title">
            {title ||
              'Please enter the One-time code and click OK to proceed with the transfer.'}
          </h2>
          <p>One-time code</p>
          <OneTimeInput
            setValue={setValue}
            error={error}
            formMounted={formRef !== null}
            fields={6}
            isDivided={false}
            value={value}
          />
          <div className="one-time-modal__button-container">
            <Button type="button" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={validateOtp}
              disabled={value.length < 6}
            >
              ok
            </Button>
          </div>
        </form>
      ) : (
        <ErrorScreen
          error="Your request was cancelled"
          backAction={() => handleClose()}
        />
      )}
    </Modal>
  );
};
