import { captureException } from '@sentry/react';
import { bapi } from './client';

export type CreateTicket = {
  requestTypeId: string;
  summary: string;
  description: string;
  attachments?: File;
};

export type CreateTicketResponse = {
  ticketId: number;
  ticketKey: string;
  requestType: string;
  createdDate: string;
  summary: string;
  description: string;
  currentStatus: string;
};

type RequestType = {
  id: number;
  name: string;
  description: string;
};

export type RequestTypesResponse = {
  requestTypes: RequestType[];
};

export type Ticket = {
  ticketId: number;
  ticketKey: string;
  requestType: string;
  createdDate: string;
  summary: string;
  description: string;
  currentStatus: string;
  link?: string;
};

export type GetTicketsResponse = {
  tickets: Ticket[];
};

export type Feedback = {
  rating?: number;
  comment?: string;
};

type TicketComment = {
  commentId: number;
  author: string;
  createdDate: string;
  body: string;
  renderedBody: string;
  attachments: any;
};

export type Attachment = {
  createdDate?: string;
  attachmentId: number;
  fileSize: string;
  fileType: string;
  filename: string;
};

export type AllTicketDetails = Ticket & {
  renderedDescription: string;
  attachments: Attachment[];
  comments: {
    commentsList: TicketComment[];
  };
  feedback: Feedback;
};

type CreatedCommentDetailsResponse = {
  commentId: number;
  author: string;
  createdDate: string;
  body: string;
};

export type NewComment = {
  comment: string;
  attachments?: File;
};

export async function createTicket(ticket: CreateTicket) {
  const formData = new FormData();

  formData.append('requestTypeId', ticket.requestTypeId);
  formData.append('summary', ticket.summary);
  formData.append('description', ticket.description);
  formData.append('attachments', ticket.attachments);

  return bapi
    .post<CreateTicketResponse>(`/v1/helpdesk/tickets`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    .catch(error => {
      captureException(error);
      if (error.response) {
        throw error.response.data.message;
      }

      throw error;
    });
}

export async function getRequestTypes() {
  return bapi.get<RequestTypesResponse>(`/v1/helpdesk/requesttypes`);
}

export async function getAllTickets() {
  return bapi.get<GetTicketsResponse>(`/v1/helpdesk/tickets?limit=200`);
}

export async function getTicketDetails(ticketId: Ticket['ticketId']) {
  return bapi.get<AllTicketDetails>(
    `v1/helpdesk/tickets/${ticketId}?commentsLimit=50`
  );
}

export async function ticketFeedback(
  ticketId: Ticket['ticketId'],
  feedback: Feedback
) {
  return bapi.post<Feedback>(
    `/v1/helpdesk/tickets/${ticketId}/feedback`,
    feedback
  );
}

export async function attachThumbnail(
  ticketId: Ticket['ticketId'],
  attachmentId: Attachment['attachmentId']
) {
  return bapi.get<Blob>(
    `/v1/helpdesk/tickets/${ticketId}/attachments/${attachmentId}/thumbnails`,
    { responseType: 'blob' }
  );
}

export async function commentTicket(
  ticketId: Ticket['ticketId'],
  comment: NewComment
) {
  const formData = new FormData();

  formData.append('comment', comment.comment);
  formData.append('attachments', comment.attachments);

  return bapi
    .post<CreatedCommentDetailsResponse>(
      `/v1/helpdesk/tickets/${ticketId}/comments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    .catch(error => {
      captureException(error);
      if (error.response) {
        throw error.response.data.message;
      }

      throw error;
    });
}
