import { Flag } from '@components/Flag';
import { formatCurrencyWithoutCurrencyCode } from '@utils/index';
import { FC, useMemo } from 'react';

interface CurrencyCardProps {
  currencyCode: string;
  amount: number;
  handleClick?: (any) => void;
  currentCurrency?: string | string[];
}

export const CurrencyCard: FC<CurrencyCardProps> = ({
  currencyCode,
  amount,
  handleClick,
  currentCurrency
}) => {
  const formattedAmount = useMemo(
    () => formatCurrencyWithoutCurrencyCode(amount, currencyCode),
    [currencyCode, amount]
  );

  function isCurrencySelected() {
    if (typeof currentCurrency === 'string') {
      return currentCurrency.toLowerCase() === currencyCode.toLowerCase();
    }

    // Check if value is an array
    if (Array.isArray(currentCurrency)) {
      return currentCurrency.includes(currencyCode);
    }

    return false;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`currency-card ${
        isCurrencySelected() ? 'currency-card-active' : ''
      }`}
      onClick={() => handleClick(currencyCode)}
    >
      <div className="currency-card__country-container">
        <Flag currencyCode={currencyCode} hight={25} width={25} />
        <div className="currency-card__block">
          <h5 className="secondary">{currencyCode}</h5>
          <h4>{formattedAmount}</h4>
        </div>
      </div>
    </div>
  );
};
