import { getAllTickets, GetTicketsResponse } from '@services/bapi/jira';
import { useQuery, UseQueryOptions } from 'react-query';

export default function useAllTickets<T extends any = GetTicketsResponse>(
  options?: UseQueryOptions<GetTicketsResponse, unknown, T>
) {
  return useQuery('tickets', () => getAllTickets().then(res => res.data), {
    retry: 1,
    refetchOnWindowFocus: true,
    refetchInterval: 300 * 1000, // 5 minutes
    ...options
  });
}
