import Arrow from '@assets/icons/icon_directionarrow.svg?react';
import React from 'react';

const BackButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  onClick,
  ...rest
}) => {
  return (
    <button className="back-button" type="button" onClick={onClick} {...rest}>
      <Arrow />
    </button>
  );
};

export default BackButton;
