import {
  GetBankAccountAddressResponse,
  getAccountAddress
} from '@services/bapi/accounts';
import { UseQueryOptions, useQuery } from 'react-query';

export default function useAccountAddress<
  T extends any = GetBankAccountAddressResponse
>(options?: UseQueryOptions<GetBankAccountAddressResponse, unknown, T>) {
  return useQuery(
    ['account', 'address'],
    () => getAccountAddress().then(res => res.data),
    {
      staleTime: 15 * 60 * 1000,
      refetchOnWindowFocus: false,
      ...options
    }
  );
}
