interface UploadInfoProps {
  numberOfFiles?: number;
}

export const UploadInfo = ({ numberOfFiles = 1 }: UploadInfoProps) => {
  return (
    <div>
      <p className="paragraph--tiny secondary">Please note:</p>
      <ul>
        <li>
          <p className="paragraph--tiny secondary">
            {`a) Only ${numberOfFiles} file${
              numberOfFiles > 1 ? 's' : ''
            } less than 2 MB can be uploaded. If there are multiple
            files please consolidate into a single file.`}
          </p>
        </li>
        <li>
          <p className="paragraph--tiny secondary">
            b) Acceptable formats are (PDF/JPG/PNG).
          </p>
        </li>
        <li>
          <p className="paragraph--tiny secondary">
            c) File name should only consist of alphabet (a-z) and numbers (0-9)
            and should not exceed 30 characters inclusive of file extension.
          </p>
        </li>
      </ul>
    </div>
  );
};
