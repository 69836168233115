import { KEYCLOAK_TOKEN_KEY } from '@res/constants';
import routes from '@res/routes';
import axios, { AxiosError } from 'axios';
import errorCodes from './errorCodes';
import { SESSION_STORAGE_KEY } from '@hooks/useHolidayNotice/const';
// import MockAdapter from 'axios-mock-adapter';

export type bapiError = { message: string; code?: string };

export const bapi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_BASEURL
});

export const setbapiAuthHeader = (token: string) => {
  bapi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearCookies = removeCookies => {
  removeCookies(KEYCLOAK_TOKEN_KEY, { path: '/' });
  removeCookies('otp', { path: '/' });
  removeCookies('resetTransferMFAInit', { path: '/' });
  removeCookies('deniedAccess', { path: '/' });
};

// Here is code example to test different API and emulate errors

bapi.interceptors.request.use(
  function (config) {
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

bapi.interceptors.response.use(
  function (config) {
    return config;
  },
  function (err) {
    const errorCode = (err as AxiosError<any>)?.response?.data?.code;
    const errorStatus = (err as AxiosError<any>)?.response?.status;

    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7); // Set expiry date to 7 days from now

    if (
      errorCode === errorCodes.kcTokenExpired ||
      errorCode === errorCodes.kcTokenInvalid
    ) {
      // NOTE: KC Token is expired
      // NOTE : KC Token is not present in the request
      console.error(errorCode);
      window.location.href = `${window.location.origin}${routes.logout}`;
      document.cookie = `deniedAccess=true; expires=${expiryDate.toUTCString()}; path=/`;
    }

    if (errorStatus === 401) {
      console.error(errorCode);
      document.cookie = `deniedAccess=true; expires=${expiryDate.toUTCString()}; path=/`;
      window.location.href = `${window.location.origin}${routes.logout}`;
    }

    return Promise.reject(err);
  }
);
