import { Loading } from '@components/Loading';
import { SearchBar } from '@components/SearchBar';
import { TransferLine } from '@components/TransferLine';
import useAccounts from '@hooks/useAccounts';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

type TransferAccountToDebitPluginProps = {
  iban: string | undefined;
  handleDebitAccountSelect: (account: BankAccountWithCurrencies) => void;
};

export const TransferAccountToDebitPlugin: React.FC<
  TransferAccountToDebitPluginProps
> = ({ iban, handleDebitAccountSelect }) => {
  const [search, setSearch] = React.useState('');

  const { data: bankAccounts, isLoading } = useAccounts();

  const filteredBankAccounts = React.useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();

    return bankAccounts?.length
      ? bankAccounts.filter(
          account =>
            account.alias?.toLowerCase()?.includes(lowerCaseSearch) ||
            account.iban.toLocaleLowerCase().includes(lowerCaseSearch) ||
            account.bic.toLowerCase().includes(lowerCaseSearch)
        )
      : [];
  }, [bankAccounts, search]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="transfer-to-debit">
      <div className="transfer-to-debit__search-bar-container">
        <SearchBar
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search your account"
        />
      </div>
      {filteredBankAccounts.length > 0 ? (
        <ScrollContainer
          component="ul"
          className="transfer-to-debit__account-list"
        >
          {filteredBankAccounts?.map(account => (
            <TransferLine
              key={account.id}
              id={account.id}
              onAccounClick={() => handleDebitAccountSelect(account)}
              accountName={account.alias}
              bic={account.bic}
              iban={account.iban}
              isSelected={iban === account.iban}
              currencies={account.currencies}
            />
          ))}
        </ScrollContainer>
      ) : (
        <h2 className="transfer-to-debit__no-results">No results</h2>
      )}
    </div>
  );
};
