import { Beneficiary, getBeneficiaries } from '@services/bapi/beneficiaries';
import { useQuery } from 'react-query';

export default function useBeneficiaries() {
  return useQuery<Beneficiary[]>(
    'beneficiaries',
    () =>
      getBeneficiaries().then(resp => {
        resp.data.forEach(beneficiary => {
          if (!beneficiary.transferType) {
            beneficiary.transferType = beneficiary.iban ? 'SEPA' : 'SWIFT';
          }
          beneficiary.transferType = beneficiary.transferType.toUpperCase();
        });

        return resp.data;
      }),
    { initialData: [] }
  );
}
