import { useAuth } from '@hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { useAuth as useAuthKeycloak } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const Logout: React.FC = () => {
  const { handleLogout } = useAuth();
  const location = useLocation();
  const { isAuthenticated } = useAuthKeycloak();
  const [_cookies, setCookie] = useCookies();

  const [executionStatus] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      handleLogout();
    }, 5000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLogoutMessage = () => {
    if (location.pathname.includes('CONFIGURE_TOTP')) {
      return executionStatus === 'success' ? (
        'Redirecting to the Login page'
      ) : (
        <>
          Cancelling the operation
          <br />
          Redirecting to the Login Page
        </>
      );
    }

    return (
      <>
        You have been logged out of your account.
        <br />
        To access your account, please login again.
      </>
    );
  };

  return (
    <div className="homepage">
      <div className="homepage__info-container">
        <div className="homepage__info-img">
          <img
            src="/assets/backgrounds/verticalBanner.svg?react"
            alt="vertical banner"
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div className="homepage__info-content">
          <div>
            <img
              src="/assets/logos/iban-s_horizontal.svg?react"
              alt="iban logo"
              width={268}
              height={67}
            />
          </div>
          <h1>Moving money Made simple</h1>
          <p>
            Easy access to payments and transaction banking for Fintechs and
            Digital Businesses
          </p>
        </div>
      </div>
      <div className="homepage__form">
        <div className="homepage__form-container">
          {isAuthenticated && <h1>{renderLogoutMessage()}</h1>}
        </div>
      </div>
    </div>
  );
};

export default Logout;
