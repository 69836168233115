import AccountOverview from '@assets/icons/icon_accountoverview.svg?react';
import AccountSettings from '@assets/icons/icon_accountsetting.svg?react';
import Beneficiaries from '@assets/icons/icon_beneficiaries.svg?react';
import FAQ from '@assets/icons/icon_faq.svg?react';
import Globe from '@assets/icons/icon_globe.svg?react';
import LogOut from '@assets/icons/icon_logout.svg?react';
import Transactions from '@assets/icons/icon_transactions.svg?react';
import Transfer from '@assets/icons/icon_transfer.svg?react';
import useAccounts from '@hooks/useAccounts';
import { useAuth } from '@hooks/useAuth';
import { useHolidayNotice } from '@hooks/useHolidayNotice';
import { routes } from '@res/routes';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isFeatureActive } from '@utils';
import { FeaturesEnum } from '@utils/consts';

interface RouteObject {
  icon: React.ReactElement;
  label: string;
  key: string;
  path: string;
}

interface SidebarProps {
  className?: string;
}

const menuRoutes: RouteObject[] = [
  {
    key: FeaturesEnum.accountOverview,
    icon: <AccountOverview />,
    label: 'Account overview',
    path: routes.dashboard.accountOverview
  },
  {
    key: FeaturesEnum.transfer,
    icon: <Transfer />,
    label: 'Transfer',
    path: routes.dashboard.transfer
  },
  {
    key: FeaturesEnum.fx,
    icon: <Globe />,
    label: 'Foreign exchange',
    path: routes.dashboard.foreignExchange
  },
  {
    key: FeaturesEnum.transactions,
    icon: <Transactions />,
    label: 'Transactions',
    path: routes.dashboard.transactions
  },
  {
    key: FeaturesEnum.beneficiaries,
    icon: <Beneficiaries />,
    label: 'Beneficiaries',
    path: routes.dashboard.beneficiaries
  },
  {
    key: FeaturesEnum.accountSettings,
    icon: <AccountSettings />,
    label: 'Account Settings',
    path: routes.dashboard.accountSettings
  },
  {
    key: FeaturesEnum.helpCenter,
    icon: <FAQ />,
    label: 'Help Center',
    path: routes.dashboard.faq
  },
  {
    key: FeaturesEnum.logout,
    icon: <LogOut />,
    label: 'Log out',
    path: routes.logout
  }
];

export const DashboardMenu: FC<SidebarProps> = () => {
  const [selectedRoute, setSelectedRoute] = useState(
    routes.dashboard.accountOverview
  );

  const getListOfCurrenciesFromAccounts = accounts => {
    const currencies = [];

    if (!accounts) return [];

    for (let i = 0; i <= accounts?.length; i++) {
      const currencyList = accounts[i]?.currencies;
      for (let j = 0; j <= currencyList?.length; j++) {
        currencies.push(currencyList[j]?.code);
      }
    }

    return currencies;
  };

  const { handleLogout, features } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: accounts } = useAccounts<BankAccountWithCurrencies[]>();

  const currencyList = getListOfCurrenciesFromAccounts(accounts);

  const onRouteClick = (route: RouteObject) => {
    navigate(route.path);
  };
  useHolidayNotice(currencyList);

  React.useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location.pathname]);

  return (
    <div className="sidebar">
      <div className="sidebar__icon-container">
        <img
          src="/assets/logos/sm-logo-light.svg?react"
          alt="iban icon"
          width={60}
          height={60}
        />
      </div>
      <div className="sidebar__route-list-container">
        <ul className="sidebar__routes-list">
          {menuRoutes.map(route => {
            if (
              !isFeatureActive(features, route.key) &&
              route.key !== 'Logout'
            ) {
              return null;
            }

            return (
              <li key={route.key}>
                <button
                  className={classNames(
                    'sidebar__route-button',
                    route.path === selectedRoute &&
                      'sidebar__route-button--selected'
                  )}
                  type="button"
                  onClick={
                    route.key === 'Logout'
                      ? () => handleLogout()
                      : () => onRouteClick(route)
                  }
                >
                  {route.icon}
                  <span>{route.label}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
