import { useEffect, useState } from 'react';

import { FieldError } from '@components/Input';
import { formatFileSize } from '@utils/files';

import DeleteFileIcon from '@assets/icons/icon_transfer_upload_delete.svg?react';
import UploadIcon from '@assets/icons/icon_transfer_upload_grey.svg?react';

const VALID_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];

const MAX_FILES_LIMIT = 5;

export const UploadFiles = ({ onFilesSelected, validationFieldError }) => {
  const [files, setFiles] = useState<any[]>([]);

  const handleFileChange = event => {
    const selectedFiles = event.target.files;

    if (files.length > 5) {
      return;
    }

    if (selectedFiles?.length > 0) {
      const newFiles: any = Array.from(selectedFiles);
      let slicedFilesByLimit;

      if (files.length > 0) {
        slicedFilesByLimit = newFiles.slice(0, MAX_FILES_LIMIT - files.length);
      } else {
        slicedFilesByLimit = newFiles.slice(0, MAX_FILES_LIMIT);
      }

      setFiles(prevFiles => [...prevFiles, ...slicedFilesByLimit]);
    }
  };

  const handleDrop = event => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (files.length > 5) {
      return;
    }

    if (droppedFiles?.length > 0) {
      const newFiles: any[] = Array.from(droppedFiles);
      let slicedFilesByLimit;

      if (files.length > 0) {
        slicedFilesByLimit = newFiles.slice(0, MAX_FILES_LIMIT - files.length);
      } else {
        slicedFilesByLimit = newFiles.slice(0, MAX_FILES_LIMIT);
      }

      setFiles(prevFiles => [...prevFiles, ...slicedFilesByLimit]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const validateFile = file => {
    let isError = false;
    let errorMessage = '';

    const isValidType = VALID_TYPES.includes(file.type);

    if (!isValidType) {
      isError = true;
      errorMessage = 'Incompatible file type';
    }

    if (file.size > 2000000) {
      isError = true;
      errorMessage = 'Exceeds maximum size';
    }

    return { isError, errorMessage };
  };

  const renderFileItem = (file, index) => {
    const { isError, errorMessage } = validateFile(file);
    return (
      <div className="file-list__item" key={`file-item-${index + 1}`}>
        <h4
          className={`file-list__item--name ${
            isError ? 'file-error-text' : ''
          }`}
        >
          {file.name}
        </h4>
        <h4
          className={`file-list__item--size regular ${
            isError ? 'file-error-text' : ''
          }`}
        >
          {isError ? errorMessage : formatFileSize(file.size)}
        </h4>
        <DeleteFileIcon onClick={() => handleRemoveFile(index)} />
      </div>
    );
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  return (
    <section className="upload-files">
      <div
        className="document-uploader upload-box"
        onDrop={handleDrop}
        onDragOver={event => event.preventDefault()}
      >
        <div className="upload-info">
          <UploadIcon />
          <label
            htmlFor="browse"
            className={`browse-btn ${
              files.length >= 5 ? 'browse-btn-disabled' : ''
            }`}
          >
            <div>Add files</div>
            <input
              type="file"
              hidden
              id="browse"
              name="browse"
              onChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.pdf"
              multiple
              disabled={files.length >= 5}
            />
          </label>
          <span>
            <span className="upload-info__text"> or drop files here </span>(up
            to 5 files)
          </span>
        </div>
      </div>
      {validationFieldError && <FieldError error={validationFieldError} />}
      {files.length > 0 && (
        <div className="file-list">
          {files.map((file, idx) => renderFileItem(file, idx))}
        </div>
      )}
    </section>
  );
};
