import routes from '@res/routes';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routes.dashboard.accountOverview, { replace: true });
  }, [navigate]);

  return <></>;
};

export default Dashboard;
