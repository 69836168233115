import DashboardLayout from '@components/DashboardLayout';
import React from 'react';

const Notifications: React.FC = () => {
  return (
    <DashboardLayout
      mainContent={
        <div>
          <h1>Notifications</h1>
        </div>
      }
    />
  );
};

export default Notifications;
