import { accountValidationDetails } from '@res/accountDetails';
import { removeAllWhiteSpaces } from '@utils';
import { isIBAN, isValidBIC, isValidIBAN } from '@utils/IBAN';
import { postcodeValidator } from 'postcode-validator';
import * as Yup from 'yup';
import 'yup-phone';
import { TransferCreateNewContact } from '..';

const REGEX_ALPHANUMERIC_WITH_SPACE_INSIDE = /^$|^[^\W_][a-zA-Z0-9 ]*$/gm;

export const createBeneficiaryValidationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .max(80)
      .required()
      .label('Name')
      .matches(/^[^\W_][a-zA-Z\d /\-?:().,'+ ]*$/, 'Invalid name'),
    bankName: Yup.string()
      .trim()
      .max(50)
      .label('Bank name')
      .test('invalid', 'Invalid bank name', v => {
        return /^[0-9a-zA-Z/\-?:().,'+ ]*$/.test(v);
      })
      .notRequired(),
    transferType: Yup.string()
      .trim()
      .label('Bank name')
      .required('You must select the correct transfer'),
    iban: Yup.string()
      .label('IBAN number')
      .trim()
      .when('transferType', ([transferType]) => {
        return transferType === 'SEPA'
          ? Yup.string().required()
          : Yup.string().when('accountNumber', ([accountNumber]) => {
              return accountNumber === ''
                ? Yup.string().required(
                    'Either Iban or Account Number is required'
                  )
                : Yup.string();
            });
      })
      .test({
        name: 'internal-iban-test',
        exclusive: false,
        message: "Can't save internal IBAN as beneficiary",
        test(value) {
          value = removeAllWhiteSpaces(value);
          if (this.options.context?.ibanList?.includes(value)) return false;
          return true;
        }
      })
      .test({
        name: 'valid-iban-test',
        exclusive: false,
        message: 'Invalid IBAN number',
        test(value) {
          if (!value) return true;
          value = removeAllWhiteSpaces(value);

          if (value.length < 2) return false;

          if (isIBAN(value) && isValidIBAN(value ?? '')) {
            return true;
          }

          return false;
        }
      })
      .test({
        name: 'compare-bic-iban-test',
        exclusive: false,
        message: 'Invalid IBAN or BIC Code',
        test(value) {
          if (!value) return true;
          const swiftBic = this.parent.swiftBic.slice(4, 6).toLowerCase();
          if (swiftBic) return value.slice(0, 2).toLowerCase() === swiftBic;

          return true;
        }
      })
      .test({
        name: 'check-sepa-swift-test',
        exclusive: false,
        message: 'SEPA isnt available for this IBAN',
        test(iban) {
          if (!iban) return true;
          const countryCode = iban.slice(0, 2).toLowerCase();
          const filteredCountry = accountValidationDetails.find(
            value =>
              value.countryCode.toLowerCase() === countryCode.toLowerCase()
          );

          if (
            filteredCountry &&
            !filteredCountry?.sepa &&
            this.parent.transferType === 'SEPA'
          )
            return false;

          return true;
        }
      }),
    accountNumber: Yup.string()
      .label('Account Number')
      .trim()
      .when('transferType', ([transferType]) => {
        return transferType === 'SEPA'
          ? Yup.string()
          : Yup.string().when('iban', ([iban]) => {
              return iban === ''
                ? Yup.string().required(
                    'Either Account number or Iban is required'
                  )
                : Yup.string();
            });
      })
      .test({
        name: 'account-number-test',
        exclusive: true,
        message: 'Invalid Account number',
        test(value) {
          if (!value) return true;
          value = removeAllWhiteSpaces(value);
          const regex = /^[a-zA-Z0-9]{0,18}$/;
          const isValidAccountNumber = regex.test(value);

          if (!isIBAN(value) && isValidAccountNumber) {
            return true;
          }

          return false;
        }
      }),
    bankKey: Yup.string()
      .label('BankNumber')
      .notRequired()
      .max(15)
      .test({
        name: 'validate-numerical-alphanumerical',
        exclusive: true,
        message: 'Invalid Bank number',
        test(bankKey) {
          const formData = this.parent as TransferCreateNewContact;
          const countryCode = formData.swiftBic.slice(4, 6);
          const filteredCountry = accountValidationDetails.find(
            value =>
              value.countryCode.toLowerCase() === countryCode.toLowerCase()
          );

          if (filteredCountry?.countryCode?.toLocaleLowerCase() === 'za') {
            return true;
          }

          if (filteredCountry) {
            if (
              filteredCountry.checkingBankNumber === 2 ||
              filteredCountry.checkingBankNumber === 4 ||
              filteredCountry.checkingBankNumber === 6 ||
              filteredCountry.checkingBankNumber === 8
            ) {
              return bankKey?.match(/^\s*\d*$/) !== null;
            }
            return bankKey?.match(/^\s*\w*$/) !== null;
          }

          return true;
        }
      })
      .test({
        name: 'validate-bankKey',
        exclusive: true,
        message: 'Invalid Bank number',
        test(bankKey) {
          const formData = this.parent as TransferCreateNewContact;
          const countryCode = formData.swiftBic.slice(4, 6);
          const filteredCountry = accountValidationDetails.find(
            value =>
              value.countryCode.toLowerCase() === countryCode.toLowerCase()
          );

          if (this.parent.iban) return true;

          if (filteredCountry?.countryCode?.toLocaleLowerCase() === 'za') {
            return true;
          }

          if (filteredCountry && filteredCountry.bankNumberLength) {
            if (
              filteredCountry.checkingBankNumber === 3 ||
              filteredCountry.checkingBankNumber === 4 ||
              filteredCountry.checkingBankNumber === 7 ||
              filteredCountry.checkingBankNumber === 8
            ) {
              if (bankKey?.length !== filteredCountry.bankNumberLength) {
                return false;
              }
            } else if (bankKey?.length > filteredCountry.bankNumberLength) {
              return false;
            }
          }

          return true;
        }
      }),
    swiftBic: Yup.string()
      .trim()
      .required()
      .label('BIC code')
      .max(11)
      .test({
        name: 'valid-bic-test',
        exclusive: false,
        message: 'Invalid BIC code',
        test(value) {
          if (isValidBIC(value)) {
            return true;
          }

          return false;
        }
      })
      .test({
        name: 'compare-bic-iban-test',
        exclusive: false,
        message: 'Invalid IBAN or BIC Code',
        test(value) {
          const iban = this.parent.iban?.slice(0, 2).toLowerCase();

          if (iban) return value.slice(4, 6).toLowerCase() === iban;

          return true;
        }
      }),
    email: Yup.string().trim().email().label('Email'),
    isGlobal: Yup.boolean(),
    telephone: Yup.string()
      .trim()
      .label('Telephone')
      .test({
        name: 'empty-or-validate-phone-number',
        exclusive: false,
        message: 'Invalid telephone number',
        test(telephone) {
          return !telephone || Yup.string().isValid(telephone);
        }
      }),
    street: Yup.string()
      .trim()
      .required()
      .max(50)
      .matches(
        /^[a-zA-ZÀ-ÿ0-9.,'\-#@%&/ ]*$/gm,
        'Only alphanumeric, accented characters and the special characters ".,\'\\-#@%&/" are allowed'
      )
      .label('Street'),
    houseNumber: Yup.string()
      .trim()
      .max(5)
      .matches(
        REGEX_ALPHANUMERIC_WITH_SPACE_INSIDE,
        'Only alphanumeric characters are allowed'
      )
      .label('House Number/ID'),
    country: Yup.string().trim().required().label('Country'),
    countryCode: Yup.string().trim().required(),
    zipCode: Yup.string()
      .trim()
      .required()
      .max(10)
      .label('Zip Code')
      .test(
        'test-zipcode',
        'Invalid post code for selected country',
        (value, context) => {
          try {
            if (!value || !context.parent.countryCode) return true;

            if (postcodeValidator(value, context.parent.countryCode)) {
              return true;
            }

            return false;
          } catch (_) {
            return true;
          }
        }
      ),
    city: Yup.string()
      .trim()
      .required()
      .max(50)
      .matches(/^[a-zA-Z0-9 ]*$/gm, 'Only alphanumeric characters are allowed')
      .label('City'),
    saveBeneficiary: Yup.boolean(),
    label: Yup.string().label('Label').trim().max(86)
  })
  .required();
