import Check from '@assets/icons/icon_check.svg?react';
import Close from '@assets/icons/icon_close.svg?react';
import Copy from '@assets/icons/icon_copy.svg?react';
import Download from '@assets/icons/icon_download.svg?react';
import FAQ from '@assets/icons/icon_faq.svg?react';
import { TransactionPDF } from '@components/TransactionPDF';
import useAccounts from '@hooks/useAccounts';
import useBeneficiaries from '@hooks/useBeneficiaries';
import routes from '@res/routes';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import { Transaction } from '@services/bapi/transactions';
import {
  addSpacesEvery4Letters,
  formatAmountWithCurrency,
  formatCurrencyWithoutCurrencyCode
} from '@utils';
import {
  getTransactionMessage,
  transactionToTransactionDetails,
  translatePaymentNotesTypeCode
} from '@utils/transactionHelpers';
import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

const IBANX_BANK_NAME = 'COMO DL S.A.';
const IBANX_BIC = 'IBNXLULM';
const DEFAULT_BANK_NAME = '';

export interface TransactionItemProps {
  amount: number;
  currency: string;
  postingDate: Date;
  valueDate: Date;
  status: string;
  type: string;
  transactionNumber: string;
  referenceMessages: Transaction['paymentNotes'];
  iban?: string;
  bic?: string;
}

interface TransactionDetailsProps {
  accountName: string;
  accountIban: string;
  type: 'debit' | 'credit';
  customerSupport?: boolean;
  rawTransaction: Transaction;
}

export const TransactionDetails: FC<TransactionDetailsProps> = ({
  accountName,
  accountIban,
  type,
  customerSupport = true,
  rawTransaction
}) => {
  const [hideConfirm, setHideConfirm] = useState(true);
  const navigation = useNavigate();
  const { data: accounts, isLoading } =
    useAccounts<BankAccountWithCurrencies[]>();

  const accountDetails = accounts.find(account => account.iban === accountIban);

  const isInternalTransfer = accounts.find(
    account => account.iban === rawTransaction.counterPartyBankAccount
  );

  const { data: beneficiaries, isLoading: isLoadingBeneficiaries } =
    useBeneficiaries();

  const transaction = React.useMemo(() => {
    return transactionToTransactionDetails(rawTransaction);
  }, [rawTransaction]);

  useEffect(() => {
    setHideConfirm(true);
  }, [transaction.iban]);

  const onCopyClick = () => {
    navigator.clipboard.writeText(
      `IBAN/Account Number: ${transaction.iban} BIC/Swift/Sort Code: ${transaction.bic}`
    );
    setHideConfirm(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `TransferConfirmation-${rawTransaction.debitIndicator ? rawTransaction.counterPartyAccountHolder : accountDetails?.accountHolderName}-${transaction.valueDate.toLocaleDateString('fr-CA')}`
  });

  const allowDownloadAndRepeat = React.useMemo(() => {
    const { transactionId } = rawTransaction;
    return transactionId.substring(transactionId.length - 3) === '001';
  }, [rawTransaction]);

  if (isLoading || isLoadingBeneficiaries) {
    return null;
  }

  const isDebitTransaction = rawTransaction.debitIndicator;
  let sender = {
    senderName: accountDetails?.accountHolderName,
    senderBankName: IBANX_BANK_NAME,
    senderIban: accountDetails?.iban,
    senderBic: accountDetails?.bic
  };

  let recipient = {
    recipient: rawTransaction.counterPartyAccountHolder,
    recipientBankName:
      beneficiaries.find(
        beneficiary =>
          beneficiary.iban === rawTransaction.counterPartyBankAccount
      )?.bankName || isInternalTransfer
        ? IBANX_BANK_NAME
        : DEFAULT_BANK_NAME,
    recipientIban: rawTransaction.counterPartyBankAccount,
    recipientBic: rawTransaction.counterPartyBic
  };

  if (!isDebitTransaction) {
    // NOTE: in the case of a non-debit transaction (credit),
    // we must swap the information about the sender and recipient

    let senderBankName = recipient.recipientBankName;

    // NOTE: try to resolve the sender bank name by the IBANX BIC number
    if (!senderBankName) {
      senderBankName =
        recipient.recipientBic === IBANX_BIC
          ? IBANX_BANK_NAME
          : DEFAULT_BANK_NAME;
    }

    const switchObject = sender;
    sender = {
      senderName: recipient.recipient,
      senderBankName,
      senderIban: recipient.recipientIban,
      senderBic: recipient.recipientBic
    };

    recipient = {
      recipient: switchObject.senderName,
      recipientBankName: switchObject.senderBankName,
      recipientIban: switchObject.senderIban,
      recipientBic: switchObject.senderBic
    };
  }

  return (
    <div className="transaction-details">
      <div className="transaction-details__PDF">
        <TransactionPDF
          transactionNumber={rawTransaction.transactionId}
          {...sender}
          {...recipient}
          amount={formatAmountWithCurrency(
            Number(rawTransaction.amountAc),
            rawTransaction.currencyAc
          )}
          transferDate={rawTransaction.valueDate}
          executionDate={rawTransaction.postingDate}
          currentStatus={rawTransaction.statusName}
          type={isDebitTransaction ? 'DEBIT' : 'CREDIT'}
          referenceText={getTransactionMessage(rawTransaction)}
          ref={componentRef}
        />
      </div>
      {accountName && (
        <div className="transaction-details__header">
          <h4 className="secondary">
            {type === 'credit' ? 'Sender' : 'Recipient'}
          </h4>
          <h2>{accountName}</h2>
        </div>
      )}

      <div className="transaction-details__action-buttons-container">
        {/* NOTE(diogo-menezes) We are not allowing repeating transfers for now */}
        {/* {allowDownloadAndRepeat && type === 'credit' && (
          <Link
            href={routes.dashboard.transferRepeat({
              transactionId: transaction.transactionNumber,
              accountIban,
            })}
            as={routes.dashboard.transfer}
            passHref
            replace
          >
            <button
              onClick={() => {}}
              type="button"
              className="transaction-details__action-button"
            >
              <div className="transaction-details__action-button-icon">
                <Repeat />
              </div>
              <h4 className="regular">Repeat Transaction</h4>
            </button>
          </Link>
        )} */}

        {allowDownloadAndRepeat && (
          <button
            onClick={() => handlePrint()}
            type="button"
            className="transaction-details__action-button"
          >
            <div className="transaction-details__action-button-icon">
              <Download />
            </div>
            <h4 className="regular">Download Confirmation</h4>
          </button>
        )}

        {customerSupport && (
          <button
            onClick={() => navigation(routes.dashboard.faq)}
            type="button"
            className="transaction-details__action-button"
          >
            <div className="transaction-details__action-button-icon">
              <FAQ />
            </div>
            <h4 className="regular">Customer Support</h4>
          </button>
        )}
      </div>

      <h3 className="regular small">Details</h3>
      <div className="transaction-details__container">
        <h4 className="regular secondary">Amount</h4>
        <h3 className="tiny text-uppercase">
          {`${transaction.currency} ${formatCurrencyWithoutCurrencyCode(
            transaction.amount,
            transaction.currency
          )}`}
        </h3>
        <h4 className="regular secondary">Value Date</h4>
        <h3 className="tiny text-uppercase">
          {transaction.valueDate.toLocaleDateString('fr-CA')}
        </h3>
        <h4 className="regular secondary">Posting Date</h4>
        <h3 className="tiny text-uppercase">
          {transaction.postingDate.toLocaleDateString('fr-CA')}
        </h3>
        <h4 className="regular secondary">Status</h4>
        <h3 className="tiny text-uppercase">{transaction.status}</h3>
        <h4 className="regular secondary">Type</h4>
        <h3 className="tiny text-uppercase">{transaction.type}</h3>
        {transaction.referenceMessages.map((referenceMessage, index) => {
          return (
            <>
              {transaction.referenceMessages[index].typeCode !==
                transaction.referenceMessages[index - 1]?.typeCode && (
                <h4 className="regular secondary">
                  {translatePaymentNotesTypeCode(referenceMessage.typeCode)}
                </h4>
              )}
              <h3 className="tiny italic">{referenceMessage.note}</h3>
            </>
          );
        })}
        <h4 className="regular secondary">Transaction Number</h4>
        <h3 className="tiny text-uppercase">{transaction.transactionNumber}</h3>
      </div>
      {(transaction.iban || transaction.bic) && (
        <>
          <h3 className="regular small">Account Information</h3>
          <div className="transaction-details__copy-button-container">
            <button
              type="button"
              className="transaction-details__copy-button"
              onClick={onCopyClick}
            >
              <div className="transaction-details__button-detail-container">
                <h4 className="regular secondary">IBAN/Account Number</h4>
                <h3 className="tiny text-uppercase">
                  {addSpacesEvery4Letters(transaction.iban)}
                </h3>
              </div>
              <div className="transaction-details__button-detail-container">
                <h4 className="regular secondary">BIC/Swift/Sort Code</h4>
                <h3 className="tiny text-uppercase">{transaction.bic}</h3>
              </div>
            </button>

            <Copy className="transaction-details__copy-icon" />

            <div
              className={classNames(
                'transaction-details__copy-confirm',
                hideConfirm && 'transaction-details__copy-confirm--hidden'
              )}
            >
              <button
                type="button"
                className="transaction-details__copy-confirm-close"
                onClick={() => setHideConfirm(true)}
                aria-label="close button"
              >
                <Close />
              </button>
              <div className="transaction-details__confirm-icon">
                <Check />
              </div>
              <h3 className="small">COPIED TO CLIPBOARD</h3>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
