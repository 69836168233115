export default {
  // Middleware
  missingAuthorization: 'api_authorization_missing',

  // KEYCLOAK
  kcTokenExpired: 'kc_token_expired',
  kcTokenInvalid: 'kc_token_verification_failed',

  // BENEFICIARIES
  beneficiary: {
    updateError: 'update_beneficiary_error',
    createError: 'create_beneficiary_error',
    deleteError: 'delete_beneficiary_error',
    getError: 'get_beneficiary_error',
    existingAccount: 'existing_account',
    invalidIbanInput: 'IbanValidationException',
    invalidBicInput: 'BicCodeValidationException'
  },

  otp: {
    wrongPing: 'wrong_pin'
  }
};

export const errorsMessages = {
  general: {
    notAllowed: 'Method not allowed',
    somethingWentWrong: 'Something went wrong'
  },
  beneficiary: {
    existingIban: 'Beneficiary with this IBAN already exists',
    noIdProvided: 'Invalid beneficiary id or not present'
  }
};
