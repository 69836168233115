import { useTransfer } from '@hooks/useTransfer';
import {
  addSpacesEvery4Letters,
  formatAmountWithCurrency,
  removeAllWhiteSpaces
} from '@utils';
import classNames from 'classnames';
import { FC, useMemo } from 'react';

interface TransferDetailsProps {
  accountToDebitName?: string;
  accountToDebitBankName?: string;
  accountToDebitIban?: string;
  accountToDebitBic?: string;
  accountToCredit?: string;
  amount?: number;
  currency?: string;
  referenceMessage?: string;
  bic?: string;
  beneficiaryBankName?: string;
  beneficiaryName?: string;
  accountToCreditIban?: string;
  accountToCreditAccountNumber?: string;
  accountToCreditBankNumber?: string;
  date?: string;
}
interface TransferStepsProps {
  details: TransferDetailsProps;
}

export const TransferSteps: FC<TransferStepsProps> = ({ details }) => {
  const { jumpToStep } = useTransfer();
  const accountToDebit = useMemo(() => {
    if (
      details.accountToDebitName ||
      details.accountToDebitBankName ||
      details.accountToDebitIban ||
      details.accountToDebitBic
    ) {
      return (
        <>
          {details.accountToDebitName && (
            <div className="transfer-steps__step-info">
              <h4 className="regular">Account to Debit</h4>
              <h3>{details.accountToDebitName}</h3>
            </div>
          )}
          {details.accountToDebitIban && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">IBAN</h4>
              <h4 className="regular">
                {addSpacesEvery4Letters(details.accountToDebitIban)}
              </h4>
            </div>
          )}
          {details.accountToDebitBic && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">BIC</h4>
              <h4 className="regular">{details.accountToDebitBic}</h4>
            </div>
          )}
        </>
      );
    }

    return <h4 className="regular">Account to Debit</h4>;
  }, [
    details.accountToDebitBankName,
    details.accountToDebitBic,
    details.accountToDebitIban,
    details.accountToDebitName
  ]);

  const accountToCredit = useMemo(() => {
    if (
      details.beneficiaryName ||
      details.beneficiaryBankName ||
      details.accountToCreditIban ||
      (details.accountToCreditAccountNumber &&
        details.accountToCreditBankNumber) ||
      details.bic
    ) {
      return (
        <>
          {details.beneficiaryName && (
            <div className="transfer-steps__detail-top">
              <h4 className="regular">Account to Credit</h4>
              <h3>{details.beneficiaryName}</h3>
            </div>
          )}
          {details.beneficiaryBankName && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">Bank</h4>
              <h4 className="regular">{details.beneficiaryBankName}</h4>
            </div>
          )}
          {details.accountToCreditIban && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">Iban</h4>
              <h4 className="regular">
                {addSpacesEvery4Letters(details.accountToCreditIban)}
              </h4>
            </div>
          )}
          {details.accountToCreditAccountNumber && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">Account number</h4>
              <h4 className="regular">
                {`${removeAllWhiteSpaces(
                  details.accountToCreditAccountNumber
                )}-${
                  removeAllWhiteSpaces(details.accountToCreditBankNumber) ?? ''
                }-${removeAllWhiteSpaces(details.bic).slice(4, 6)}`}
              </h4>
            </div>
          )}
          {details.bic && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">
                {details.accountToCreditIban ? 'BIC' : 'SWIFT'}
              </h4>
              <h4 className="regular">{details.bic}</h4>
            </div>
          )}
        </>
      );
    }

    return <h4 className="regular">Account to Credit</h4>;
  }, [
    details.beneficiaryName,
    details.beneficiaryBankName,
    details.accountToCreditIban,
    details.accountToCreditAccountNumber,
    details.accountToCreditBankNumber,
    details.bic
  ]);

  return (
    <div className="transfer-steps">
      <div className="transfer-steps__step-container">
        <button
          type="button"
          className={classNames(
            'transfer-steps__step-button-details',
            details.accountToDebitName &&
              'transfer-steps__step-button--disabled'
          )}
          onClick={() => jumpToStep(0)}
        >
          <div className="transfer-steps__step-number">
            <h4>1</h4>
          </div>
          <div className="transfer-steps__details-data-container">
            {accountToDebit}
          </div>
        </button>
      </div>
      <div className="transfer-steps__step-container">
        <button
          type="button"
          className="transfer-steps__step-button-details"
          disabled={!details.accountToDebitName}
          onClick={() => jumpToStep(1)}
        >
          <div className="transfer-steps__step-number">
            <h4>2</h4>
          </div>
          <div className="transfer-steps__details-data-container">
            {accountToCredit}
          </div>
        </button>
      </div>
      <div className="transfer-steps__step-container">
        <button
          type="button"
          className="transfer-steps__step-button-details"
          disabled={
            !details.beneficiaryName ||
            (!details.accountToCreditIban &&
              !details.accountToCreditAccountNumber) ||
            !details.bic
          }
        >
          <div className="transfer-steps__step-number">
            <h4>3</h4>
          </div>
          <div className="transfer-steps__details-data-container">
            {details.currency &&
            details.amount &&
            details.referenceMessage &&
            details.date ? (
              <>
                <h4 className="regular">Amount</h4>
                <h3 className="transfer-steps__last-detail-value">
                  {formatAmountWithCurrency(details.amount, details.currency)}
                </h3>
                <h4 className="regular transfer-steps__last-detail">
                  Reference
                </h4>
                <h3 className="italic transfer-steps__last-detail-value">
                  {details.referenceMessage}
                </h3>
                <h4 className="regular transfer-steps__last-detail">
                  Value Date
                </h4>
                <h3 className="transfer-steps__last-detail-value">
                  {details.date}
                </h3>
              </>
            ) : (
              <h4 className="regular">Details</h4>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};
