import BackButton from '@components/BackButton';
import { Button } from '@components/Button';
import DashboardLayout from '@components/DashboardLayout';
import { Flag } from '@components/Flag';
import { Input } from '@components/Input';
import { PageHeader } from '@components/PageHeader';
import { Terms } from '@components/Terms';
import { allCurrencies } from '@res/availableCurrencies';
import routes from '@res/routes';
import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import faqs from '../faqs.json';

const slugFromQuestion = (question = '') =>
  question
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');

type Question = {
  title: string;
  answer?: ReactElement;
};

const FaqQuestion: React.FC = () => {
  const navigate = useNavigate();
  const { question: questionParam } = useParams();
  const [feedback, setFeedback] = useState('');

  const question = React.useMemo(() => {
    let currentQuestion: Question;

    faqs.categories.forEach(category => {
      const q = category.questions.find(
        quest => slugFromQuestion(quest.title) === questionParam
      );
      if (q) {
        currentQuestion = q;
      }
    });

    switch (currentQuestion.title) {
      case faqs.categories[0].questions[0].title:
        currentQuestion.answer = (
          <div>
            <p>
              Below are some of the most common reasons why transfer fails.There
              could be one or many of following reasons for your transfer fail.
            </p>
            <br />
            <br />
            <p>
              <b>Recipient details are wrong</b>
            </p>
            <p>
              The bank account number and other details you provided are wrong.
              Or your recipient bank account is closed.
            </p>
            <br />
            <br />
            <p>
              <b>Reach of transfer limit</b>
            </p>
            <p>You have reached the maximum amount transfer limit.</p>
            <br />
            <br />
            <p>
              <b>Insufficient funds in your bank account</b>
            </p>
          </div>
        );
        break;
      case faqs.categories[0].questions[1].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>
              i. Open Account overview and search for the Bank Account where you
              want to receive money.
            </p>
            <br />
            <p>
              {' '}
              ii. You will find the IBAN & BIC code specific to each account.
              Alternatively, you may copy the account details from account
              setting page
            </p>
            <br />
            <p>
              {' '}
              iii. Share these account details to your sender who wants to send
              you money{' '}
            </p>
            <br />
            <p>
              {' '}
              iv. You’ll receive their payment directly into that currency
              balance in your account.
            </p>
            <br />
            <br />
            <p> NOTE:</p>
            <p>
              {' '}
              Please make sure the account number you share has got the currency
              that you wish to receive.
            </p>
            <br />
            <br />
            <p>
              {' '}
              Don’t’ see specific currency in your bank account – Don’t worry we
              support{' '}
              <Link
                to="/dashboard/faq/which-currencies-are-supported-by-ibanx-"
                replace
              >
                <span className="faq-question__container--anchor">
                  25+ currencies
                </span>
              </Link>{' '}
              and we can help you.
            </p>
          </div>
        );
        break;
      case faqs.categories[0].questions[2].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>
              Transfer time will vary depending on a range of factors, including
              type of transfer (direct debit, individual transfer etc.), fraud
              prevention, different currencies, different time zones, and bank
              holidays/weekends.
            </p>
            <br />
            <br />
            <p>
              In general, the iBAN-X transfer time will be around same day to
              maximum 1 day within SEPA zone (given all other factors) for SWIFT
              payment generally one to five working days.
            </p>
          </div>
        );
        break;
      case faqs.categories[0].questions[3].title:
        // How do I download a bank statement?
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>i. Go to &apos;Account Settings&apos; Page</p>
            <br />
            <p>
              ii. In the &apos;Account Settings&apos; page, click &apos;Download
              Account Statements&apos; on the top right
            </p>
            <br />
            <p>
              iii. On the next page, click on &apos;Pre-Generated Reports&apos;
              or &apos;Customized Reports&apos;
            </p>
            <br />
            <br />
            <p>
              <b>Pre-Generated Reports:</b>
            </p>
            <br />
            <br />
            <p>
              i. Select &apos;Account Number&apos; and &apos;Currency&apos; from
              the dropdown
            </p>
            <br />
            <p>
              ii. Select the Month for which you want the statement to be
              downloaded
            </p>
            <br />
            <p>iii. Select PDF and click on Download button</p>
            <br />
            <p>
              iv. Do note that statements are generated on the 5th of every
              month.
            </p>
            <br />
            <br />
            <p>
              <b>Customized Reports:</b>
            </p>
            <br />
            <br />
            <p>i. Select Account Number and Currency from the dropdown</p>
            <br />
            <p>
              ii. Select the Date from the options provided - Last 7 Days, Last
              30 Days, and Specific Period
            </p>
            <br />
            <p>
              iii. If you select the &apos;Specific Period&apos; option, then
              select the &apos;From&apos; and &apos;To&apos; date
            </p>
            <br />
            <p>iv. Select PDF and click on Download button</p>
          </div>
        );
        break;
      case faqs.categories[0].questions[4].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>
              All successfully processed transfers made through iBAN-X, you can
              download a transfer confirmation as a proof of payment receipt. If
              there is any delay in receiving payment at your receipt side, you
              may share this confirmation receipt with them to speed up the
              process.
            </p>
            <br />
            <br />
            <p>
              i. Go to the specific transaction from Transaction view (by
              selecting a desired bank account) for which you download the
              confirmation receipt.
            </p>
            <br />
            <p>
              {' '}
              ii. Click on the three dots icon to see the details of specific
              transfer.{' '}
            </p>
            <br />
            <p>
              {' '}
              iii. On the right-hand panel click on download confirmation.{' '}
            </p>
            <br />
            <p>
              {' '}
              iv. Below information you will find in download confirmation:{' '}
            </p>
            <span className="faq-question__question-answer--secondary">
              <p> Your name and account details </p>
              <p> Your recipient’s name and account details </p>
              <p> Date the transfer was made </p>
              <p> Transfer amount </p>
              <p> Transaction number </p>
              <p> Value Date & Posting date </p>
              <p> Status of transfer </p>
              <p> Type of transfer </p>
              <p> Reference message (if any) </p>
            </span>
          </div>
        );
        break;
      case faqs.categories[0].questions[5].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>
              Posting Date is the date when a transaction is posted in the
              system while Value Date is the actual date of execution of
              transaction.
            </p>
            <br />
            <p>
              {' '}
              E.g., You initiated a payout on 15th Feb 2022 to be executed on a
              future date say 20th Feb 2022 & on same day the transaction
              completes. • The Posting Date is 15th Feb 2022 and Value Date 20th
              Feb 2022{' '}
            </p>
          </div>
        );
        break;
      case faqs.categories[1].questions[0].title:
        // How can I change my account password?
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>There are 2 ways to change your account password.</p>
            <br />
            <br />
            <p>
              <b>
                1: If you forgot your password and are unable to login to your
                dashboard
              </b>
            </p>
            <br />
            <br />
            <p>
              i. On the dashboard Login page, click on the ‘Forgot Password’
              link which is just below the Login button
            </p>
            <br />
            <p>
              ii. On clicking the ‘Forgot Password’ link, you will be prompted
              to enter your email id
            </p>
            <br />
            <p>
              iii. Enter your registered email id in the text box and click on
              the ‘Submit’ button
            </p>
            <br />
            <p>
              iv. You will receive an email to your registered email id with a
              link to reset the dashboard password
            </p>
            <br />
            <p>
              v. Click on the reset password link and follow the instructions to
              rest your password
            </p>
            <br />
            <p>
              <b>
                2: If you have your dashboard password and want to change it
              </b>
            </p>
            <br />
            <br />
            <p>
              i. Login to your dashboard and navigate to the ‘Account Settings’
              Page
            </p>
            <br />
            <p>
              ii. In the Accounts Settings page, click on ‘Reset Dashboard
              Password’ link
            </p>
            <br />
            <p>
              iii. Identify yourself by providing your login credentials on the
              next page
            </p>
            <br />
            <p>
              iv. On successful validation of your credentials, you will be
              navigated to the &apos;Update Password&apos; page
            </p>
            <br />
            <p>v. Reset password on this page</p>
          </div>
        );
        break;
      case faqs.categories[1].questions[1].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>
              iBAN-X request you to set-up multi-factor autentication at the
              time of first login and just after successful login.
            </p>
            <br />
            <p>
              {' '}
              In order to prevent unauthorized access and security concerns, you
              must set-up both multi-factor authentication
            </p>
          </div>
        );
        break;
      case faqs.categories[1].questions[2].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>
              You wish to generate multi-factor authentication because you lost
              your mobile phone or any other reason.
            </p>
            <br />
            <br />
            <p>
              <b>1. Reset Account MFA </b>
            </p>
            <br />
            <p>
              Please go to your account setting page and choose the option to
              &apos;Reset my multi factor authentication&apos; and follow the
              procedure.
            </p>
            <br />
            <br />
            <p>
              <b>2. Reset Login MFA</b>
            </p>
            <br />
            <p>
              Please go to your account setting page and choose the option to
              &apos;Reset my multi factor authentication&apos; and follow the
              procedure.
            </p>
          </div>
        );
        break;
      case faqs.categories[1].questions[3].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>
              If you are unable to generate one time passcode to access your
              iBAN-X platform or perform any action inside platform you have to
              reset your multi-factor autentication.
            </p>
            <br />
            <p>
              Please refer &apos;How can I reset my multi-factor authentication
              ?&apos; in FAQs
            </p>
          </div>
        );
        break;
      case faqs.categories[2].questions[0].title:
        currentQuestion.answer = <Terms />;
        break;
      case faqs.categories[2].questions[1].title:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p className="faq-question__currencies-grid--header">
              Here are the 24 currencies currently supported by iBAN-X:
            </p>
            <div className="faq-question__currencies-grid">
              {allCurrencies.map(flag => (
                <div>
                  <Flag currencyCode={flag} />
                  <h4>{flag}</h4>
                </div>
              ))}
            </div>
          </div>
        );
        break;
      default:
        currentQuestion.answer = (
          <div className="faq-question__question-answer">
            <p>Answer soon!</p>
          </div>
        );
        break;
    }
    return currentQuestion;
  }, [questionParam]);

  return (
    <DashboardLayout
      mainContent={
        <div className="faq-question">
          <PageHeader title="Help Center" subTitle="how can we help?" />
          <BackButton onClick={() => navigate(-1)} />
          <h3 className="faq-question__back-route">FAQ&apos;s</h3>
          <div className="faq-question__container">
            <div>
              <h2 className="faq-question__category-title black">
                {question.title}
              </h2>
              {question.answer}
              <p className="faq-question__contact-us-cta">
                If you didn’t find the right answer{' '}
                <button
                  type="button"
                  onClick={() => navigate(routes.dashboard.faqContact)}
                >
                  contact us
                </button>
              </p>
            </div>
            <div>
              <div
                className={classNames(
                  'faq-question__usefull-section',
                  feedback === 'good' && 'faq-question__usefull-section--good'
                )}
              >
                {(feedback === '' || feedback === 'bad') && (
                  <div className="faq-question__usefull-question">
                    <p className="semiBold">Was this article helpfull?</p>
                    <button
                      disabled={feedback === 'bad'}
                      className="faq-question__usefull-question-request-button"
                      onClick={() => setFeedback('good')}
                      type="button"
                    >
                      Yes/
                    </button>
                    <button
                      disabled={feedback === 'bad'}
                      className="faq-question__usefull-question-request-button"
                      type="button"
                      onClick={() => setFeedback('bad')}
                    >
                      No
                    </button>
                  </div>
                )}
                {feedback === 'good' && (
                  <p className="semiBold faq-question__usefull-section--good-text">
                    Thank you for taking the time to provide your feedback! We
                    are going to use it for the platform’ improvement.
                  </p>
                )}
                {feedback === 'bad' && (
                  <>
                    <p className="semiBold faq-question__request-info-question">
                      How can we improve it?
                    </p>
                    <Input
                      name="feedback-input"
                      variant="outline"
                      id="feedback"
                      type="text"
                    />
                    <p className="semiBold faq-question__request-info-question">
                      How can we improve it?
                    </p>
                    <Input
                      name="feedback-email"
                      variant="outline"
                      id="email"
                      type="email"
                    />
                    <Button
                      className="faq-question__send-feedback"
                      variant="default"
                      size="small"
                      onClick={() => setFeedback('good')}
                    >
                      send
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default FaqQuestion;
