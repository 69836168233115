export const routes = {
  dashboard: {
    accountSettings: '/dashboard/account-settings',
    resetMfa: '/dashboard/account-settings/reset',
    loginMfa: '/dashboard/account-settings/reset/login-mfa',
    accountStatements: '/dashboard/account-settings/statements',
    preGeneratedReport: '/dashboard/account-settings/statements/pre-generated',
    customizedReport: '/dashboard/account-settings/statements/customized',
    accountOverview: '/dashboard/account-overview',
    accountDetails: '/dashboard/account-overview/:accountId',
    accountDetailsByCurrency:
      '/dashboard/account-overview/:accountId/:currency',
    beneficiaries: '/dashboard/beneficiaries',
    faq: '/dashboard/faq',
    faqQuestion: '/dashboard/faq/:question',
    faqQuestionWithoutParam: '/dashboard/faq/',
    faqContact: '/dashboard/faq/contact',
    communicationHistory: '/dashboard/faq/communication-history',
    notifications: '/dashboard/notifications',
    transactions: '/dashboard/transactions',
    foreignExchange: '/dashboard/foreign-exchange',
    transactionsByAccountID: '/dashboard/transactions/:accountID/:pageParam',
    transactionsByAccountIDAndCurrency:
      '/dashboard/transactions/:accountID/:currency/:pageParam',
    getTransactionsByAccountID: (accountID: string) =>
      `/dashboard/transactions/${accountID}/1`,
    getTransactionsByAccountIDAndCurrency: (
      accountID: string,
      currency: string
    ) => `/dashboard/transactions/${accountID}/${currency}/1`,
    transfer: '/dashboard/transfer',
    root: '/dashboard',
    transferRepeat: ({
      transactionId,
      accountId
    }: {
      transactionId: string;
      accountId: string;
    }) =>
      `/dashboard/transfer?transactionId=${transactionId}&accountId=${accountId}` // <---------------
  },
  home: '/',
  auth: '/auth',
  resetTransferMfa: '/auth/reset_transfer_mfa',
  mobile: '/auth/register_mobile_number',
  logout: '/logout'
};

export default routes;
