import { useCallback } from 'react';
import useCountryCodeAllowedList from './useCountryCodeAllowedList';

type UseCheckIsSwiftBicAllowed = {
  checkIsSwiftBicAllowed: (swiftBic: string) => boolean;
  isAllowedCountryCodesFetching: boolean;
};

function getCountryCodeFromSwiftBic(swiftBic: string): string | undefined {
  if (swiftBic.length < 5) {
    return undefined;
  }
  return swiftBic.slice(4, 6);
}

export const useCheckIsSwiftBicAllowed = (): UseCheckIsSwiftBicAllowed => {
  const {
    data: allowedCountryCodes,
    isFetching: isAllowedCountryCodesFetching
  } = useCountryCodeAllowedList();

  const checkIsSwiftBicAllowed = useCallback(
    (swiftBic: string): boolean => {
      const countryCodeFromSwiftBic = getCountryCodeFromSwiftBic(swiftBic);
      if (countryCodeFromSwiftBic === undefined) {
        return false;
      }

      return allowedCountryCodes.includes(countryCodeFromSwiftBic);
    },
    [allowedCountryCodes]
  );

  return {
    checkIsSwiftBicAllowed,
    isAllowedCountryCodesFetching
  };
};
