/* eslint-disable react/no-unescaped-entities */
export const Terms = () => {
  return (
    <div className="terms-and-conditions">
      <p>
        The iBAN-X Services are supervised by the competent regulatory
        authority, i.e., the Commission de Surveillance du Secteur Financier (“
        <b>CSSF</b>”), having a license number Z00000013.
      </p>

      <h3>1. SCOPE</h3>
      <ol id="l1">
        <ol id="l2">
          <li data-list-text="1.1">
            <p>
              These General Terms and Conditions set out the legal framework of
              the business relationship between iBAN-X and the corporate Client
              (“Client”) in relation to the products and services provided by
              iBAN-X pursuant to any agreement entered between IBAN-X and User
              (these General Terms and Conditions together with such agreement
              and their annexes are hereinafter referred to as the “
              <b>Agreement</b>
              ”).
              <br />
              <br />
              <b>COMO DL S.A. may at any given time</b>, change its name to COMO
              Digital Life S.A. on or immediately after the Effective date of
              this Agreement. However, such a change will not change the
              obligations stipulated under this agreement. This Agreement and
              its Schedules shall remain legally binding in its entirety and the
              Client will be informed regarding the name change in a timely
              manner.
            </p>
          </li>
          <li data-list-text="1.2">
            <p>
              Where the terms of these General Terms and Conditions differ from
              the terms of a contract individually agreed between iBAN-X and the
              Client, the terms of the latter shall prevail over the General
              Terms and Conditions, unless expressly stated otherwise or it is
              contrary to any duty under applicable law.
              <br />
              <br />
              These General Terms and Conditions shall be applicable only to
              those functions or activities performed directly by the Client and
              all the Legal Entities, Subsidiaries and any other Delegated
              Entities performing functions on Client’s behalf. To the extent it
              deems practicable, Client shall endeavour to include in contracts
              entered with Entities subsequent to the implementation date terms
              that are substantially equivalent to the terms of this Agreement;
              provided that Client shall not be liable hereunder in the event
              any Entity acts in a manner inconsistent with the terms of this
              Agreement.
            </p>
          </li>
          <li data-list-text="1.3">
            <p>
              The headings in these General Terms and Conditions are for
              reference only and do not limit the scope of each clause.
              Capitalised terms have specific definitions and are provided in
              Clause 20 or otherwise in the text of these General Terms and
              Conditions.
            </p>
          </li>
        </ol>
      </ol>
      <h3>2. IBAN-X SERVICES</h3>
      <ol id="l3">
        <ol>
          <li data-list-text="2.1">
            <p>
              iBAN-X Services offers and manages a range of payment services and
              multi-currency IBAN accounts.
            </p>
          </li>
          <li data-list-text="2.2">
            <p>
              At iBAN-X’s discretion, iBAN-X provide the Client with one or more
              of the following services (“<b>Services</b>”), as contractually
              agreed from time to time between Client and iBAN-X:
            </p>
            <ul id="l4">
              <li>
                Execution of direct debits, including one-off direct debits.
              </li>
              <li>
                Execution of payment transactions through a payment card or a
                similar device.
              </li>
              <li>Execution of credit transfers, including standing orders</li>
              <li>
                While providing these Services, iBAN-X will provide to the
                Client access to multi-currency IBAN accounts as well as to
                facilitate payments through but not limited to the SWIFT and to
                the SEPA network.
              </li>
              <li>
                iBAN-X will provide different account types, including but not
                limited to Settlement accounts, Safeguarded and segregated
                accounts and corporate accounts
              </li>
            </ul>
          </li>

          <li data-list-text="2.3">
            <p>
              iBAN-X Client dashboard will allow the Client to access the
              Services online and to perform transactions electronically. By
              accepting this Agreement, the Client will be able to enter all
              services provided by the iBAN-X service platform.
            </p>
          </li>
          <li data-list-text="2.4">
            <p>
              iBAN-X may also render other services, in which case, they will be
              rendered on separate terms and conditions or via a supplementary
              agreement.
            </p>
          </li>
          <li data-list-text="2.5">
            <p>
              iBAN-X does not provide any investment or investment advisory
              services and does not monitor the financial circumstances of the
              customer.
            </p>
          </li>

          <li data-list-text="2.6">
            <p>
              iBAN-X may, in its reasonable discretion, withdraw or suspend any
              or all of the Services to the Client if iBAN-X becomes aware of or
              reasonably suspects financial crime activity, unauthorised or
              fraudulent use of the Services, for reasons relating to the
              security of such Services and Platform, or where iBAN-X considers
              that rendering the Services would breach any Applicable Laws or
              Compliance Obligations. iBAN-X will not be liable for any loss
              that Client may incur from such withdrawal or suspension of the
              Services, except if iBAN-X acted fraudulently, negligently, or
              recklessly in relation thereof, in which case iBAN-X’s liability
              to Client will be as set out in Clause 13.
            </p>
          </li>

          <li data-list-text="2.7">
            <p>
              Client authorises iBAN-X to block Account(s) or to take such other
              measures as iBAN-X may deem appropriate upon extrajudicial
              opposition notified to iBAN-X by third parties regarding Client’s
              assets, or if iBAN-X is informed of any actual or alleged unlawful
              operations by Client or by the beneficial owner(s) of the
              Account(s), or if any third party claims exist on the assets held
              by Client with iBAN-X.
            </p>
          </li>
          <li data-list-text="2.8">
            <p>
              If Client becomes subject to an Insolvency Event, then iBAN-X may
              refuse to act on any Instructions from Client or anyone else
              unless Client has obtained a validation order from the court. Upon
              receipt by iBAN-X of evidence that a liquidator or administrator
              has been appointed, iBAN-X will act on their Instructions. Client
              shall inform iBAN-X if it knows or suspects that it will become
              subject to an Insolvency Event.
            </p>
          </li>
        </ol>
      </ol>
      <h3>3. AUTHORISED USERS</h3>
      <ol id="l6">
        <ol>
          <li data-list-text="3.1">
            <p>
              By entrusting the powers of representation to the persons
              responsible for using the Platform in its name and on its behalf
              (hereinafter the &quot;<b>Authorised Users</b>&quot;), the Client
              expressly acknowledges - unless otherwise specified by Client in
              writing prior to their designation - that these Authorised Users
              have the right to validly engage the Client and to give
              Instructions and/or request to iBAN-X:
            </p>
            <ul id="l7">
              <li>
                Adding or removing other Authorised Users, including
                administrators; or
              </li>
              <li>
                Activating or deactivating services or features available on the
                Platform.
              </li>
            </ul>
          </li>

          <li data-list-text="3.2">
            <p>
              Only Authorised Users may access and use the Platform. Client may
              designate Authorised Users in a manner as approved by iBAN-X in
              its discretion. A person shall only become an Authorised User upon
              iBAN-X’s approval. iBAN-X’s approval of an Authorised User may be
              subject to the Client providing evidence of his or her identity or
              other documentation. iBAN-X reserves its right to decline at its
              sole discretion any request for designation of any new Authorised
              User and may without notice disable Authorised Users’ access to
              the Platforms, in whole or in part.
            </p>
          </li>
          <li data-list-text="3.3">
            <p>
              Client shall notify iBAN-X without undue delay if the information
              provided relating to an Authorised User is no longer up to date.
              Client shall inform iBAN-X in writing via email or electronic
              processes such as the Platform and dashboards if an Authorised
              User should be removed or added or if Client wishes to request any
              other amendments to roles and privileges assigned to any existing
              Authorised User.
            </p>
          </li>
          <li data-list-text="3.4">
            <p>
              Authorised Users shall be provided with personalised security
              credentials to access the Platform and, to the extent applicable,
              a one-time password/code for strong Client authentication through
              an authentication device or authentication software to authorise
              Transactions through the Platforms. Client acknowledges and shall
              endeavour that such personalised security credentials and one-time
              passwords must be kept safe by each Authorised User and must not
              be shared with or used by any other person.
            </p>
          </li>

          <li data-list-text="3.5">
            <p>
              Authorised Users are obliged to immediately notify iBAN-X upon
              becoming aware of irregularities, hacking, misuse or unauthorised
              use of the Platforms, any authentication device or software or any
              of the Services, including Client Accounts. In such an event
              iBAN-X will block any such systems and Accounts.
            </p>
          </li>

          <li data-list-text="3.6">
            <p>
              Authorised Users are required to take all necessary measures to
              ensure that the technical characteristics of any device used to
              access the Platform, any authentication device or software,
              internet access and telecommunications means are up to date for
              obtaining information and for access to the Transactions and
              Services offered by the Platform. Authorised Users are responsible
              for keeping such devices and/or software up to date and installing
              all manufacturer-provided updates and security fixes when
              available. Furthermore, the Client is required to manage properly
              the security of such devices through installing and updating
              security components (antivirus, firewalls, security patches,
              etc.). Furthermore, iBAN-X reserves the right to decline any and
              all support to software versions that are not up to date with the
              latest available version.
            </p>
          </li>

          <li data-list-text="3.7">
            <p>
              Client shall be responsible for the training of Authorised Users
              so that they take all measures necessary to protect the
              personalised security features of the Accounts and only use the
              Services and any Accounts in accordance with the Agreement.
            </p>
          </li>

          <li data-list-text="3.8">
            <p>
              iBAN-X shall ensure that Instructions from Authorised Users are
              executed as soon as reasonably possible, but in no order. iBAN-X
              shall treat an Instruction as genuine if iBAN-X believes in good
              faith that the Instruction is from an Authorised User (for
              example, because it appears on the face of it to have been given
              by an Authorised User) and there are no circumstances that iBAN-X
              is or should reasonably be aware of that cause iBAN-X to suspect
              the authenticity of the Instruction. iBAN-X may assume, except in
              case of manifest error, that the information provided by Client in
              connection with a Transaction is correct.
            </p>
          </li>

          <li data-list-text="3.9">
            <p>
              If iBAN-X deems it necessary to contact Client or an Authorised
              User for the purposes of the verification of an Instruction but is
              unable to establish contact, or if following iBAN-X’s request
              Client or an Authorised User does not provide appropriate
              Instructions, the Transaction may be delayed or may not be
              executed.
            </p>
          </li>

          <li data-list-text="3.10">
            <p>
              iBAN-X may reject or delay, in its discretion and without
              liability, any Instruction from Client if:
            </p>
            <ul id="l8">
              <li>An instruction is inaccurate, incomplete, or unclear.</li>
              <li>
                The Instruction has not been properly authorized by Client or
                that any other breach of security has occurred in relation to
                Client&#39;s use of the Services.
              </li>
              <li>
                The instruction would result in a negative balance on any of the
                Accounts and/or cause any limit imposed by iBAN-X in relation to
                Accounts to be exceeded.
              </li>
              <li>
                It is an Instruction that, in the opinion of iBAN-X, implies a
                risk of participating in a scheme that could potentially be
                money laundering, terrorist financing, or any other criminal
                activities, including but not limited to, tax fraud.
              </li>
              <li>
                Legal or regulatory requirements prevent iBAN-X from executing
                the Instruction or mean that iBAN-X needs to carry out further
                checks.
              </li>
              <li>
                The transaction seems unusual in light of the ways Client
                ordinarily uses the Account(s);
              </li>
              <li>
                The instruction involves a Transaction that is not within the
                internal acceptable risk scope of iBAN-X.
              </li>
              <li>
                an injunction or order from any competent Authority or court to
                freeze funds or any other specific measure associated with
                preventing or investigating crime has been
              </li>
              <li>
                any third-party claims exist on the funds held and upon
                extra-judicial opposition notified to iBAN-X by third parties
                regarding Client’s assets; or
              </li>
              <li>
                Client is in breach of its obligations under the Agreement, or
                any other fact or matter persists as a result of which iBAN-X is
                entitled to terminate the business relationship with Client or
                block access to Client Accounts.
              </li>
            </ul>
          </li>
          <li data-list-text="3.11">
            <p>
              In the event that iBAN-X rejects any instruction from Client based
              on section 3.10, such rejection shall be notified to the payment
              service user, unless prohibited by other relevant Community or
              national legislation.
            </p>
          </li>

          <li data-list-text="3.12">
            <p>
              iBAN-X reserves the right to reject any order or Instruction due
              to compliance reasons and/or for not being in accordance with
              Applicable Law.
            </p>
          </li>

          <li data-list-text="3.13">
            <p>
              iBAN-X will not be liable for any Losses Client suffers as a
              result of a rejection or suspension of the execution of an
              Instruction.
            </p>
          </li>

          <li data-list-text="3.14">
            <p>
              iBAN-X shall not be liable for delays, errors or
              misinterpretations that may arise from incomplete or unclear
              Instructions.
            </p>
          </li>
        </ol>
      </ol>
      <h3>4. COOPERATION DUTY BY CLIENT</h3>
      <ol id="l9">
        <ol id="l10">
          <li data-list-text="4.1">
            <p>
              Client hereby declares that the assets deposited now and in the
              future in any Account are not of criminal origin, nor are they in
              any way likely to be used in the financing of terrorism or
              violation of sanctions laws and other Applicable Laws.
            </p>
          </li>

          <li data-list-text="4.2">
            <p>
              Client agrees to cooperate with iBAN-X on all compliance and
              operations-related matters and to comply with anti-money
              laundering, counter-terrorist financing, and similar legal and
              regulatory obligations applicable to iBAN-X.
            </p>
          </li>

          <li data-list-text="4.3">
            <p>
              Client agrees to respond to iBAN-X’s urgent requests as soon as
              possible and no later than four (4) Business Days from the receipt
              of the request, for example on information related to specific
              Transactions and beneficiaries, payers or payees and provide all
              relevant “Know Your Client” (“
              <b>KYC</b>”) documentation.
            </p>
          </li>

          <li data-list-text="4.4">
            <p>
              Client agrees to respond to iBAN-X’s less urgent requests of
              information as soon as possible and no later than ten (10)
              Business Days from the receipt of the request.
            </p>
          </li>
        </ol>
      </ol>
      <h3>5. OTHER CLIENT OBLIGATIONS</h3>
      <ol id="l11">
        <li data-list-text="5.1">
          <p>Client shall ensure at all times that:</p>
          <ul id="l12">
            <li>
              the execution of its obligations under the Agreement, the
              Transactions and the Services do not contravene any Applicable Law
              or any order of any Authority or any judgment, order or decree of
              any court having jurisdiction over Client, nor the provisions of
              Client’s formation documents.
            </li>
            <li>
              has and will maintain for the term of the business relationship
              all consents, authority, licenses, registrations, authorisations,
              exemptions, and memberships necessary for the conduct of Client’s
              business.
            </li>
            <li>it is in compliance with the Applicable laws.</li>
            <li>
              it has neither suspended the payment of its financial obligations
              as they fall due, entered into arrangements with its creditors,
              become subject to liquidation, bankruptcy, restructuring or other
              bankruptcy proceedings, been party to any enforcement proceedings
              levied against its assets, nor it has been threatened with any
              insolvency or enforcement proceedings.
            </li>
            <li>
              the information provided by Client to iBAN-X is complete, accurate
              and not misleading in any material respect.
            </li>
          </ul>
        </li>
        <li data-list-text="5.2">
          <p>
            Client shall inform iBAN-X immediately if Client breaches any of the
            obligations set out in this Clause 5.
          </p>
        </li>

        <li data-list-text="5.3">
          <p>
            Client shall take all necessary measures to protect the personalised
            security features of the Accounts and only use the Services and the
            Accounts in accordance with the Agreement. The client has no rights
            to (and shall not attempt to) tamper, hack, modify or otherwise
            corrupt the security or functionality of any Transaction Platforms.
          </p>
        </li>
      </ol>
      <h3>6. CLIENT IDENTIFICATION REQUIREMENTS AND COMPLIANCE REVIEW</h3>
      <ol>
        <ol id="l13">
          <li data-list-text="6.1">
            <p>
              iBAN-X is legally required to request information and documentary
              evidence for the acceptance process and knowledge of its Clients
              and their Transactions throughout the business relationship.
            </p>
          </li>
          <li data-list-text="6.2">
            <p>
              For the beginning of a commercial relationship with iBAN-X and in
              order to comply with iBAN-X’s KYC obligations, the Client is
              required to transfer the data and documents requested by iBAN-X,
              such as for example:
            </p>
            <ul id="l14">
              <li>
                the articles of association or the formation document of the
                entity, structure chart, any applicable proxies and powers of
                attorney, list of the directors, managers, representatives, the
                ultimate beneficiaries, as well as the business and the VAT
                number.
              </li>
              <li>
                In relation to the relevant natural persons: full name and
                surname, date and place of birth, address, domicile, place of
                residence, a copy of an official identity card, as well as
                business number and/or VAT registration number.
              </li>
            </ul>
          </li>
          <li data-list-text="6.3">
            <p>
              iBAN-X can ask the Client to provide additional information at any
              time. iBAN-X also reserves the right to legally collect such
              additional information from third parties and other identification
              services.
            </p>
          </li>
          <li data-list-text="6.4">
            <p>
              Client shall provide iBAN-X with correct, accurate and truthful
              information and documentation.
            </p>
          </li>
          <li data-list-text="6.5">
            <p>
              iBAN-X is liable for the consequences of any alleged fraud or
              gross negligence in recording the relevant data it has requested.
              The Client is liable for any damage caused by the failure to
              transmit the information and/or documents requested by iBAN-X or
              by the communication or production of inaccurate documents or
              information.
            </p>
          </li>
          <li data-list-text="6.6">
            <p>
              Furthermore, Client shall notify iBAN-X immediately in writing of
              any changes to the provided data and documents and any changes in
              circumstances that might cause the information and documentation
              provided to iBAN-X to become incomplete or inaccurate. Changes in
              circumstances can be, but are not limited to changes of, type of
              business, type of products or services, any dissolution,
              liquidation, or Insolvency Event, payment flow, licensing,
              geographic location, company name(s), residence address/registered
              office, company registration number, address(es) of residence for
              tax purposes, tax identification number, VAT registration number,
              nationality/nationalities, legal entity identifier, legal entity
              type and any contact details, such as telephone number(s) and
              e-mail address(es) and/or in respect of any other person(s)
              involved in the business relationship, such as the beneficial
              owner(s), any controlling person(s), authorised signatory(ies)
              and/or person(s) holding a power of attorney. iBAN-X will endeavor
              to consider and process such changes swiftly.
            </p>
          </li>
          <li data-list-text="6.7">
            <p>
              iBAN-X has the right to request, at the Client’s expense, a
              translation of the documents provided in foreign languages as well
              as the completion of the related administrative formalities.
            </p>
          </li>
          <li data-list-text="6.8">
            <p>
              Before formalizing a contractual relation and in view of the risks
              of identity theft, iBAN-X may require the Client to make a first
              transfer of funds from a bank account opened under its name with a
              recognized credit institution (European credit institution if the
              Client is an EU based corporation).
            </p>
          </li>
          <li data-list-text="6.9">
            <p>
              Client acknowledges that it is its sole responsibility to comply
              with the legal and regulatory obligations applicable to it and
              shall ensure that any transaction made with iBAN-X complies with
              these applicable laws and regulations.
            </p>
          </li>
          <li data-list-text="6.10">
            <p>
              iBAN-X, or a person appointed by iBAN-X, may ask the Client to
              allow an on-site examination of the documents confirming
              compliance with the obligations under the Agreement. Client shall
              reasonably cooperate with iBAN-X by (a) making applicable records
              available; (b) providing copies of the relevant records requested;
              and (c) directing all employees, agents, and representatives to
              reasonably cooperate. If the compliance review shows that Client
              is not in compliance with the Agreement, Client shall pay iBAN-X’s
              reasonable expenses for conducting the review along with any other
              claim for breach of the Agreement.
            </p>
          </li>
        </ol>
      </ol>
      <h3>7. COMMUNICATIONS</h3>
      <ol id="l17">
        <ol id="l18">
          <li data-list-text="7.1">
            <p>
              Unless otherwise agreed by iBAN-X and Client, the communication
              and correspondence shall be in English.
            </p>
          </li>

          <li data-list-text="7.2">
            <p>
              iBAN-X shall communicate with Client through any means appropriate
              for the relationship with the Client (for instance via regular
              post, by telephone, by means of a website, a mobile application,
              by e-mail or by any other technical or electronic process) any
              information required by virtue of legal, regulatory, or
              contractual obligations. Furthermore, iBAN-X shall communicate
              with the Client, via the Platform, using the e-mail address
              associated with the Client Accounts or the contact details
              provided by the Client.
            </p>
          </li>

          <li data-list-text="7.3">
            <p>
              The Client confirms to iBAN-X that it has regular access to the
              internet simply because it provides iBAN-X with an e-mail address
              or subscribes to one of the Services.
            </p>
          </li>

          <li data-list-text="7.4">
            <p>
              iBAN-X will assume that all documents and copies (including
              electronic copies) provided by the Client are reliable and in
              conformity with authentic originals.
            </p>
          </li>

          <li data-list-text="7.5">
            <p>
              iBAN-X shall use the last postal and/or e-mail addresses provided
              by the Client (or, failing that, the last known address). The
              Client assumes all consequences and any liability in case of delay
              or omission to inform iBAN-X of a change of postal, mailing or
              e-mail address, as well as in case of delay or omission to read
              and react to the contents of any correspondence or communication.
              For these purposes, the Client will only communicate with iBAN-X
              via the Platform.
            </p>
          </li>

          <li data-list-text="7.6">
            <p>
              The Client declares to be perfectly aware and informed of the
              risks related to the transmission of e-mails in an unsecured
              public network such as the Internet. The Client is personally
              responsible for the selection, installation, use and adaptation of
              appropriate measures to secure its e-mails, such as anti-virus
              software, a firewall or the creation of a strong password. In
              particular, the Client takes all reasonable security measures to
              ensure the confidentiality of its password. By choosing or
              accepting such a means of communication, except in the event of
              fraud or gross negligence of the latter, the Client acknowledges
              that iBAN-X is excluded from any liabilities and consequences in
              the event of interception by third parties of e-mails and/or the
              personal data and documents contained therein.
            </p>
          </li>

          <li data-list-text="7.7">
            <p>
              The Client and Authorised Users shall communicate with iBAN-X via
              regular post, by telephone, by e-mail or by any other technical or
              electronic process as allowed by iBAN-X. Instructions by Client
              shall be made via electronic processes, such as the Platform and
              dashboards. Furthermore, Client agrees to promptly contact iBAN-X
              via email, electronic processes such as the Platform and
              dashboards, or telephone in the case its password has been lost or
              stolen or if someone (other than an Authorized User) has used or
              attempted to use the Client Account without its consent.
            </p>
          </li>

          <li data-list-text="7.8">
            <p>
              Part of the information that iBAN-X makes available to Client is
              available in the Platform and on
            </p>
          </li>

          <li data-list-text="7.8">
            <p>
              iBAN-X shall endeavour to promptly consider a change of the
              language, postal or electronic mailing addresses requested by the
              Client.
            </p>
          </li>

          <li data-list-text="7.10">
            <p>
              The delivery and the content of the communication with the Client
              is established by iBAN-X by the production of a copy thereof, in
              accordance with the provisions of Clause 16 of these General Terms
              and Conditions.
            </p>
          </li>

          <li data-list-text="7.11">
            <p>
              Any notice or communication provided by iBAN-X to Client by e-mail
              shall be deemed to have been received at the time of sending, as
              long as this is within Business Hours, otherwise, the relevant
              notice or communication shall be deemed to have been received when
              Business Hours resume the following Business Day.
            </p>
          </li>

          <li data-list-text="7.12">
            <p>
              iBAN-X reserves the right to record telephone conversations with
              Client in order to be able to establish the content and context of
              the conversation.
            </p>
          </li>

          <li data-list-text="7.13">
            <p>
              Client acknowledges and agrees that iBAN-X may validly provide
              certain information, such as for example information about iBAN-X,
              information on costs, fees, and relevant changes, exclusively via
              the iBAN-X’s website. Client will be notified electronically of
              the website address and of the place on such website where it can
              access to this information. Client undertakes to consult the
              iBAN-X’s website regularly.
            </p>
          </li>
        </ol>
      </ol>
      <h3>8. CHARGES, RATES AND FEES</h3>
      <ol id="l21">
        <ol id="l22">
          <li data-list-text="8.1">
            <p>
              iBAN-X shall be entitled to charge for the Services rendered and
              the use of the Platform and the Client shall pay the charges,
              rates, and fees, stated in the relevant Pricing and Payment Terms
              Schedule or other relevant agreements entered into by iBAN-X and
              Client or as notified by iBAN-X to Client from time to time.
            </p>
          </li>

          <li data-list-text="8.2">
            <p>In addition, the following charges are payable by the Client:</p>
            <ul id="l23">
              <li>
                delivery costs of any assets and documents, mailing fees,
                telephone costs and any other expenses incurred on behalf of or
                in the interest of the Client.
              </li>
              <li>
                the costs of any action taken by iBAN-X for the retention or
                recovery of its rights with regard to the Client.
              </li>
              <li>
                any registration fees, taxes and levies payable in connection
                with a transaction with iBAN-X.
              </li>
            </ul>
          </li>
          <li data-list-text="8.3">
            <p>
              Subject to Applicable Laws, iBAN-X reserves the right to charge an
              administration fee for handling queries and requests from
              authorities, including local law enforcement agencies, pertaining
              to any of the Accounts. This administration fee shall be
              additional to any other applicable fees, charges, expenses and/or
              liabilities etc. arising from such query or request. Additionally,
              in the case i) iBAN-X does not properly and timely receive the
              requested KYC and beneficial owner information, and ii) there are
              excess funds that do not move from the Accounts, iBAN-X may charge
              additional fees for the overhead associated with the reporting and
              escalations that would need to be performed to ensure compliance
              with the applicable requirements and obligations.
            </p>
          </li>
          <li data-list-text="8.4">
            <p>
              All the charges, rates and fees stated above become due and
              payable at the beginning or end of each month, depending on the
              type of fee, and are charged and deducted from one of the
              Client&#39;s Accounts opened with iBAN-X, unless otherwise agreed
              by iBAN-X and the Client, in which case the Client shall ensure
              that its account always holds sufficient funds to cover all
              charges, fees and expenses. If there are insufficient funds in the
              account iBAN-X will notify the Client.
            </p>
          </li>
          <li data-list-text="8.5">
            <p>
              Any amount owed by Client to iBAN-X shall be payable when due
              without set-off or counterclaim.
            </p>
          </li>

          <li data-list-text="8.6">
            <p>
              iBAN-X will notify the Client of any unpaid amount. iBAN-X
              reserves the right to charge interest on any overdue and unpaid
              amounts if Client defaults in paying any such amounts when they
              are due to iBAN-X pursuant to the Agreement. Any interest on an
              outstanding amount shall accrue and become payable until the
              outstanding payment is made to iBAN-X in its entirety including
              interest.
            </p>
          </li>

          <li data-list-text="8.7">
            <p>
              iBAN-X reserves the right to suspend access to and prohibit the
              use of the Platform if the charges and fees cannot be collected in
              accordance with Clause 8 or if Client is otherwise in material
              breach of its obligations under the Agreement.
            </p>
          </li>
          <li data-list-text="8.8">
            <p>
              Unless expressly stated otherwise, iBAN-X’s charges, fees and
              costs are exclusive of VAT and other taxes payable by the Client.
            </p>
          </li>
          <li data-list-text="8.9">
            <p>
              If Client does not perform, or delays performance, its obligations
              under the Agreement and iBAN-X incur additional costs or expenses
              as a result, iBAN-X reserves the right to notify and invoice
              Client for the iBAN-X reasonable additional costs incurred. In
              that case, iBAN-X will provide the Client with information to
              demonstrate such additional costs. Interest will be debited from
              the relevant Account on the last Business Day of the month.
            </p>
          </li>
          <li data-list-text="8.10">
            <p>
              iBAN-X may at any time introduce new charges, fees, margins, and
              costs for Services for which iBAN-X has not previously charged
              subject to one (1) month’s notice given to Client.
            </p>
          </li>
          <li data-list-text="8.11">
            <p>
              iBAN-X may change charges, fees, margins, rates, and interest set
              out in the Agreement subject to one (1) month’s notice where such
              changes are not in favour of Client and without notice where in
              favour. iBAN-X may also without notice introduce and increase fees
              for one-off services.
            </p>
          </li>
          <li data-list-text="8.12">
            <p>
              The Client may terminate, within the period specified in Clause
              12, the relevant Agreement in the event the Client disagrees with
              a change of charges, rates, and fees without additional charge.
            </p>
          </li>
          <li data-list-text="8.13">
            <p>
              iBAN-X may change such charges, fees, margins without notice when
              the change is motivated by external circumstances beyond iBAN-X’s
              control including but not limited to:
            </p>
            <ul id="l24">
              <li>
                Any changes in the relationship with iBAN-X’s counterparties
                which affect iBAN-X’s cost structure.
              </li>
              <li>
                Any changes in commission and charges from clearing houses,
                information providers or third-party providers that are passed
                on to Client by iBAN-X; and/or
              </li>
              <li>Any changes required by an Authority or Applicable Law</li>
            </ul>
          </li>

          <li data-list-text="8.14">
            <p>
              Client will receive an amended pricing annex if the changes affect
              Client individual fee terms.
            </p>
          </li>

          <li data-list-text="8.15">
            <p>
              The agreements entered by iBAN-X and Client may provide additional
              fees and payment terms.
            </p>
          </li>

          <li data-list-text="8.16">
            <p>
              The Client shall pay or, as the case may be, reimburse to iBAN-X
              all taxes, duties and charges, whether now existing or imposed in
              the future by Luxembourg or foreign authorities and which are paid
              by iBAN-X or for which iBAN-X may be held liable and that relate
              to Transactions executed by iBAN-X by virtue of the contractual
              relationship with the Client and for which Client is ultimately
              liable. iBAN-X is authorised to debit any amount so due from one
              of the Client’s Accounts irrespective of the settlement date of
              the original transactions.
            </p>
          </li>
        </ol>
      </ol>
      <h3>9. AMENDMENTS</h3>
      <ol>
        <ol>
          <li data-list-text="9.">
            <p>
              The client shall ensure that, in all its dealings with iBAN-X, it
              complies with any legal, regulatory, or other obligations
              incumbent upon it (such as but not limited to its tax obligations
              in the country(ies) in which the client has to pay taxes in
              relation to the transactions and assets held with iBAN-X). Should
              the client fail to comply with such obligations, it shall be
              exclusively responsible for all consequences thereof (including
              possible financial or criminal sanctions) and iBAN-X shall not
              bear any responsibility in that respect. The client shall consult
              relevant legal or other advisers in case of doubt as to its
              applicable obligations’ amendments.
            </p>
            <ol id="l25">
              <li data-list-text="9.1">
                <p>
                  iBAN-X may in its sole discretion amend the terms of the
                  Agreement at any time where such amendment is required by
                  applicable law or to correct any errors.
                </p>
              </li>

              <li data-list-text="9.2">
                <p>
                  Any other amendments to the Agreement shall be agreed by
                  iBAN-X and the Client subject to one (1) months’ notice where
                  such amendments are not in favour of Client and otherwise
                  without notice. The new terms of the Agreement shall come into
                  effect two (2) months after the communication initiated by
                  iBAN-X, except when legal or regulatory requirements require
                  otherwise.
                </p>
              </li>

              <li data-list-text="9.3">
                <p>
                  Notwithstanding, the provisions of Clause 12, Client may
                  terminate the Agreement within the month following the
                  communication of the new terms of the Agreement initiated by
                  iBAN-X, in the case of disagreement on the amendments of the
                  Agreement, except where such amendments result from a legal or
                  regulatory obligation or is made to the benefit of the Client.
                  If Client does not provide iBAN-X with notice of rejection of
                  any amendment of which iBAN-X has notified Client within the
                  period here stipulated (or such other notice period in respect
                  of amendments which may apply under the Agreement), iBAN-X
                  will consider the Agreement to have been amended as per the
                  expiry of the notice period. If Client does provide iBAN-X
                  with notice of rejection of any amendment of which iBAN-X has
                  notified Client, iBAN-X shall have the right to terminate the
                  business relationship with Client with effect as from the date
                  on which the relevant amendments were to apply.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </ol>
      <h3>10. CONFIDENTIALITY</h3>
      <ol>
        <ol id="l21">
          <li data-list-text="10.1">
            <p>
              iBAN-X cannot disclose to third parties any information relating
              to the Client unless it has received from the Client a written
              authorization or is required to do so by law or regulatory
              requirements, or if a legitimate interest motivates the
              disclosure.
            </p>
          </li>
          <li data-list-text="10.2">
            <p>
              The iBAN-X employees, the companies belonging to the group to
              which iBAN-X belongs and their employees who are legitimately
              involved in the relationship and processes with Client are not
              considered third parties for the purposes of this Clause 10. This
              includes employees, agents, commission agents, sales agents,
              subcontractors, and outsourcing service providers.
            </p>
          </li>
          <li data-list-text="10.3">
            <p>
              The Client acknowledges and accepts that iBAN-X may communicate
              information concerning the Client, its representatives, and
              beneficial owners where such communication is required by
              domestic, foreign or international laws or regulations, or by a
              domestic or foreign authority (including courts and tax
              authorities).
            </p>
          </li>

          <li data-list-text="10.4">
            <p>
              The Client acknowledges that based on Luxembourg legislation or
              legislation with extraterritorial effect (in particular, under
              U.S. regulations imposed under the provisions of the HIRE Act of
              18 March 2010 commonly referred to as Foreign Account Tax
              Compliance Act –“FATCA” as implemented in Luxembourg under the
              intergovernmental agreement concluded between Luxembourg and the
              United States of America by the Luxembourg law of 24 July 2015,
              and the OECD Standard for Automatic Exchange of Financial account
              Information and its Common Reporting Standard (the “CRS”) as
              implemented in Luxembourg under the law of 18 December 2015 on the
              Common Reporting Standard (the “CRS Law”)), iBAN-X may have to
              disclose in regular intervals information on the Client, his
              representatives and beneficial owners and the controlling persons,
              within the meaning of FATCA or the CRS Laws, of an account held in
              its books, including their names, addresses, tax identification
              numbers, dates and places of birth, account numbers, account
              balances, income generated by its assets and sales of proceeds, to
              competent domestic or foreign authorities (including to Luxembourg
              tax authorities), which will then forward such information to the
              relevant tax administration (including the U.S. Internal Revenue
              Service).
            </p>
          </li>

          <li data-list-text="10.5">
            <p>
              Client shall provide iBAN-X the information requested pursuant to
              this clause 10 and acknowledges that failure to respond or provide
              the required information may result in incorrect reporting or
              reporting in multiple jurisdictions for which iBAN-X has
              identified indicia in Client’s files. Client shall have the right,
              free of charge, to access the information transferred to the
              Luxembourg tax authorities and may ask for a rectification thereof
              if such data is inaccurate or incomplete.
            </p>
          </li>

          <li data-list-text="10.6">
            <p>
              Some national or foreign laws, regulations or payment systems may
              require the identification of the person placing the order and its
              beneficiary. The Client acknowledges and accepts that, where funds
              or other assets are to be transferred, iBAN-X may have to disclose
              information concerning the Client, its representatives, and
              beneficial owners to third parties, including public authorities
              and correspondent banks of iBAN-X.
            </p>
          </li>

          <li data-list-text="10.7">
            <p>
              Data, including data of a personal nature, included in
              cross-border transfers shall be subject to processing by iBAN-X
              and other specialised intermediaries and in particular SWIFT
              (Society for Worldwide Interbank Financial Telecommunication).
              Such processing may take place in centres established in other
              European States, in the United States of America and other
              countries pursuant to local regulations. The consequence, inter
              alia, thereof, is that the authorities of such countries and
              foreign authorities may request access to such data, in the
              context of the fight against terrorism or for other purposes.
              Moreover, when the Client gives an instruction to iBAN-X to make a
              transfer, the Client hereby expressly consents to all items of
              information required for the proper execution of the transfer to
              be communicated to the processing centre for such transfer, which
              may be in Luxembourg or abroad and accepts that such items of
              information may be subject to processing outside Luxembourg.
            </p>
          </li>

          <li data-list-text="10.8">
            <p>
              The Client confirms that it has informed all of its
              representatives and beneficial owners of the existence and content
              of the instructions and authorisations to data transfers set out
              herein and confirms having obtained their consent and the mandate
              to consent on their behalf to all data transfers set out herein.
              The Client hereby consents to all data transfer instructions and
              authorisations set out in these General Terms and Conditions on
              behalf of its representatives and beneficial owners. The Client
              agrees to indemnify and hold iBAN-X harmless from and against all
              liabilities arising in relation thereto including with respect to
              claims by representatives and beneficial owners that they have not
              consented to transfers of data.
            </p>
          </li>
        </ol>
      </ol>
      <h3>11. PROCESSING PERSONAL DATA</h3>
      <ol>
        <ol id="l27">
          <li data-list-text="11.1">
            <p>
              <a
                href="https://www.iban-x.com/"
                target="_blank"
                rel="noreferrer"
              >
                iBAN-X processes the personal data of Client in accordance with
                applicable law and its Privacy Policy, which is available on its
                website:{' '}
              </a>
              <a
                href="https://www.iban-x.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.iban-x.com/
              </a>
            </p>
          </li>

          <li data-list-text="11.2">
            <p>
              All legally required information relating to the personal data
              collected and processed by iBAN-X, the purposes for which this
              data is processed and the rights of the natural persons whose data
              are processed, are contained in the iBAN-X Privacy Policy.
            </p>
          </li>
          <li data-list-text="11.3">
            <p>
              When Client provides iBAN-X with personal data relating to natural
              persons (e.g., representatives, employees or beneficial owners),
              Client must inform such persons of the Privacy Policy.
            </p>
          </li>

          <li data-list-text="11.4">
            <p>
              The Privacy Policy is subject to change in accordance with the
              rules set out therein.
            </p>
          </li>

          <li data-list-text="11.5">
            <p>
              Upon the signature of this document the parties accept the
              provisions of the Privacy Policy, in line with GDPR provisions and
              declared having it read out and agree with it.
            </p>
          </li>
        </ol>
      </ol>
      <h3>12. TERM AND TERMINATION</h3>
      <ol id="l28">
        <ol id="l29">
          <li data-list-text="12.1">
            <p>
              These General Terms and Conditions and the Agreement shall apply
              from the Effective Date and continue thereafter for three (3)
              years. Thereafter, these General Terms and Conditions will
              automatically renew for an additional 3 (three) year’s period
              unless either Party terminates in writing no less than one hundred
              eighty (180) days prior to the expiration of the then-current
              term.
            </p>
          </li>

          <li data-list-text="12.2">
            <p>
              Both iBAN-X and Client are entitled to terminate the business
              relationship, including these General Terms and Conditions and
              Agreement, immediately by written notice with acknowledgment of
              receipt if:
            </p>
            <ol type="i">
              <li>
                either party is in material breach (or a number of breaches or
                repeated breaches that taken together constitute a material
                breach) of its obligations under the General Terms and
                Conditions or Agreements.
              </li>

              <li data-list-text="ii.">
                Client is subject to an Insolvency Event.
              </li>

              <li data-list-text="iii.">
                Any changes to previous information or circumstances Client have
                provided to iBAN-X conclude that Client is no longer an
                acceptable client to iBAN-X-specially, in consideration to: a)
                the applicable Legal Frame applicable to AML/CTF Law changes; b)
                the Client follows under a sanctioned business or embargoes
                jurisdiction list by an international organisation such as the
                United Nations.
              </li>

              <li data-list-text="iv.">
                there has been or iBAN-X reasonably suspects there has been
                fraud or suspicious activity involving any of the Accounts or
                any Transactions on any of the accounts; In this case, the
                company reserves the right to refrain informing the Client about
                any details about the termination reasons.
              </li>

              <li data-list-text="v.">
                iBAN-X has reasonable grounds for believing that Client has
                committed or is about to commit a crime in connection with any
                of the Client accounts and/or iBAN-X reasonably suspects that
                Client has taken an action that could constitute a violation of
                any applicable anti-corruption or anti-bribery law or
                regulation.
              </li>

              <li data-list-text="vi.">
                iBAN-X reasonably considers that by continuing the Agreement
                iBAN-X may (a) break any Applicable Law or other duty, or (b) be
                exposed to action or censure from any Authority.
              </li>

              <li data-list-text="vii.">
                if the Client fails to pass the required KYC procedures, provide
                false information and/or fails to provide the required
                onboarding information in a timely manner.
              </li>

              <li data-list-text="viii.">
                if required by a competent regulatory Authority or if a party to
                the Agreement is no longer in the possession of its required
                licenses or permits.
              </li>

              <li data-list-text="ix.">
                Client misuse of iBAN-X services for any other purpose/or any
                undeclared use different to the one declared at the moment of
                onboarding, in this respect, any change shall be communicated to
                iBAN-X Compliance Team within (60) calendar days.
              </li>

              <li data-list-text="x.">
                Client misuse of iBAN-X services for any Client who seek to
                engage in criminal activity including money laundering, or
                financing terrorism.
              </li>

              <li data-list-text="xi.">
                The Client has strong ties to a country that is considered
                forbidden by us, or any sanction list would have been allowed to
                open an account with us.
              </li>

              <li data-list-text="xii.">
                Use of pooled client accounts or safe custody of client money or
                assets or bearer shares, without justification.
              </li>
            </ol>
          </li>

          <li data-list-text="12.3">
            <p>
              iBAN-X may at its discretion grant Client a period of up to thirty
              (30) days to remedy a material breach.
            </p>
          </li>

          <li data-list-text="12.4">
            <p>
              Termination of the business relationship is without prejudice to
              any rights which accrued before termination and it shall not
              affect Client’s responsibilities with regard to outstanding
              Transactions and any rights, obligations, liability claims, etc.
              between Client and iBAN-X, and any warranties or indemnities given
              by Client under the Agreement, which shall survive and which by
              their nature are deemed to survive the termination.
            </p>
          </li>

          <li data-list-text="12.5">
            <p>
              At any time after the termination of the Agreement, or after
              iBAN-X has reasonably determined that Client has not performed any
              of its obligations to iBAN-X, iBAN-X may, upon three (3) Business
              Days’ notice (oral or written) to Client, close out, replace or
              reverse any such Transaction or take, or refrain from taking, such
              other action at such times and in such manner as iBAN-X considers
              necessary or appropriate to avoid, cover, reduce or eliminate any
              Loss or liability under or in respect of any contracts, positions
              or commitments.
            </p>
          </li>

          <li data-list-text="12.6">
            <p>
              In the case only one or more services are terminated, but not the
              Agreement as a whole and to the extent they relate to the same
              service or services, all related amounts payable by Client to
              iBAN-X will become immediately due and payable including (but
              without limitation):
            </p>
            <ol id="l31">
              <li data-list-text="i.">
                All outstanding charges, costs, and fees.
              </li>
              <li data-list-text="ii.">
                any costs and expenses incurred for termination; and
              </li>

              <li data-list-text="iii.">
                any Losses and expenses realised in closing out any transactions
                or settling or concluding outstanding obligations incurred by
                iBAN-X on for Client.
              </li>
            </ol>
          </li>

          <li data-list-text="12.7">
            <p>
              Following termination of the Agreement, Client agrees that iBAN-X
              will be entitled to retain access to the fee Account or any Client
              fixed-term deposit before its maturity, if required, until all
              Client outstanding Transactions have been settled. Client shall
              allow iBAN-X to deduct from the fee Account or any fixed-term
              deposit, as applicable, any amounts needed to settle any such
              Transactions. iBAN-X will return to Client any remaining funds
              held in Client Fee Account after all amounts owed have been
              settled. The fee Account or any fixed-term deposit, as applicable,
              shall then be closed.
            </p>
          </li>

          <li data-list-text="12.8">
            <p>
              iBAN-X will not be liable to Client for any Loss that Client
              incurs as a result of iBAN-X acting on Instructions that Client
              authorised prior to termination of any Service.
            </p>
          </li>

          <li data-list-text="12.9">
            <p>
              Upon termination, Client will cooperate with iBAN-X in arranging
              the transfer of Client’s money and funds to another financial
              services provider. If Client fails to cooperate with iBAN-X by not
              providing instructions within a reasonable period of time from
              iBAN-X’s request for Client to do so iBAN-X will have the right to
              close the Account(s). Pending the transfer of Client’s money and
              funds to another provider (where applicable), iBAN-X shall
              continue to hold the relevant money and funds in accordance with
              the other provisions of the Agreement, and subject to Client
              paying all applicable charges, fees and costs. However, iBAN-X
              shall not have any other responsibility in respect of the relevant
              money and funds and the only permitted Transactions on Client
              Account will be the outgoing transfer of Client’s money and funds
              to another financial services provider.
            </p>
          </li>
        </ol>
      </ol>
      <h3>13. LIABILITIES</h3>
      <ol id="l32">
        <ol id="l33">
          <li data-list-text="13.1">
            <p>
              iBAN-X is liable only for fraud and any gross negligence committed
              by it or iBAN-X staff during its business activities. Moreover,
              iBAN-X can only be held liable for the direct consequences of its
              gross negligence. Thus, iBAN-X is not liable for indirect damages,
              namely, any commercial, professional, financial, or other losses
              of the Client, such as loss of profit, increase of expenses,
              disruption of planning, the loss of reputation, clientele or
              expected savings.
            </p>
          </li>
          <li data-list-text="13.2">
            <p>
              In any event, iBAN-X can never be held liable for damages or
              losses resulting directly or indirectly from a force majeure event
              or measures taken by the Luxembourg or foreign authorities.
            </p>
          </li>
          <li data-list-text="13.3">
            <p>
              As a result, it does not respond to the harmful consequences,
              resulting in particular from:
            </p>
            <ol id="l34">
              <li data-list-text="i.">
                a fire, flood or other natural disaster.
              </li>
              <li data-list-text="ii.">of the strike of its staff.</li>
              <li data-list-text="iii.">
                a decision of a government authority or the central bank of a
                country.
              </li>
              <li data-list-text="iv.">
                an embargo or sanction of a financial, economic, or commercial
                nature.
              </li>
              <li data-list-text="v.">
                operations ordered by persons with de facto power in case of
                war, disturbances, riots or occupation of the territory by
                foreign or illegal forces.
              </li>

              <li data-list-text="vi.">
                the decommissioning, even temporary and for any reason
                whatsoever, of its computers, as well as the destruction or
                erasure of the data they contain.
              </li>

              <li data-list-text="vii.">
                errors or interruption of activities of Belgian or foreign
                postal services, companies providing telephone services or any
                other electronic service, private transport companies.
              </li>
            </ol>
          </li>

          <li data-list-text="13.4">
            <p>
              Without limiting, and in addition to, any other obligation that
              you may owe under this Agreement, Client is always responsible for
              the acts and omissions of its employees, contractors and agents,
              to the extent such persons are acting within the scope of their
              relationship with Client.
            </p>
          </li>

          <li data-list-text="13.5">
            <p>
              Client agrees to defend iBAN-X, our affiliates, and their
              respective employees, agents, and service providers (“iBAN-X
              Entity”) against any claim, suit, demand, loss, liability, damage,
              action, or proceeding (each, a “Claim”) brought by a third party
              against a iBAN-X Entity, and Client agrees to fully reimburse the
              iBAN-X Entities for any Claims that result from: (i) a breach of
              any provision of this Agreement; (ii) any Fees, Fines, Disputes,
              Refunds, Reversals, Returns, or any other liability iBAN-X incurs
              that results from Client’s use of the Payment Processing Services;
              (iii) negligent or wilful misconduct of Client employees,
              contractors, or agents.
            </p>
          </li>
        </ol>
      </ol>
      <h3>14. EMBARGOES, COMPLIANCE POLICIES, AND DUTY OF CARE</h3>
      <ol>
        <ol id="l35">
          <li data-list-text="14.1">
            <p>
              The term &quot;<b>Sanctions</b>&quot; covers all sanctions of a
              financial, economic, or commercial nature or established
              restrictive measures, administered, imposed or put in place by the
              European Union, Luxembourg, the United Nations Security Council,
              the OFAC (Office of Foreign Assets Control) in the United States
              and / or the &quot;US Department of State&quot; or any other
              competent authority.
            </p>
          </li>
          <li data-list-text="14.2">
            <p>The Client declares and warrants to iBAN-X:</p>
            <ol id="l36">
              <li data-list-text="i.">
                <p>
                  that neither it, nor any of its subsidiaries, administrators
                  or directors or, to its knowledge, any of the companies
                  related to it, any agent or employee, is engaged in any
                  activity or behaviour that may violate any laws, rules or
                  regulations applicable in any competent jurisdiction in
                  matters of fight against money laundering and corruption.
                </p>
              </li>
              <li data-list-text="ii.">
                <p>
                  that neither it nor any of its subsidiaries, administrators or
                  directors or, to its knowledge, any of the companies related
                  to it, any agent or employee, is a natural or legal person (a
                  &quot;<b>Person</b>&quot;) who is, or is owned or controlled
                  by Persons who are, (i) subject to Sanction measures (a &quot;
                  <b>Person under Sanction(s)</b>&quot;), or (ii) located,
                  established or residing in a country or territory that is, or
                  whose government is, subject to measures of Sanctions
                  generally prohibiting dealing with that government, country or
                  territory (&quot;<b>Sanctioned Country(ies)</b>&quot;).
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="14.3">
            <p>
              The Client specifically undertakes and warrants not to - directly
              or indirectly - use the Services offered by iBAN-X:
            </p>
            <ol id="l37">
              <li data-list-text="i.">
                <p>
                  to fund activities or affairs of or with any person, or in any
                  country or territory, likely to be a Person under Sanction(s)
                  or Sanctioned Country(ies), or
                </p>
              </li>
              <li data-list-text="ii.">
                <p>
                  any another way that would result in a violation of Sanctions
                  by a person.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="14.4">
            <p>
              iBAN-X considers the Sanctions mentioned above in the analysis and
              processing of the Transactions entrusted to it. The same applies
              if, in iBAN-X’s judgment, the nature, purpose, context, conditions
              and, more generally, the circumstances of a Transaction do not
              comply with iBAN-X&#39;s compliance policies on these sanctions or
              the fight against money laundering or against the financing of
              terrorism.
            </p>
          </li>

          <li data-list-text="14.5">
            <p>
              iBAN-X reserves the right not to execute or postpone the execution
              of a transaction (i) that would be or could be in violation of
              such Sanctions and policies or (ii) that would or could be
              considered suspicious under the laws and regulations against money
              laundering and the financing of terrorism; or (iii) when automatic
              transaction-filtering systems block this transaction. The Client
              undertakes to provide iBAN-X with any document and/or information
              that iBAN-X deems useful in order to determine whether a
              transaction complies with said Sanctions and policies or is
              suspicious under the laws and regulations against money laundering
              and the financing of terrorism. Otherwise, iBAN-X will not be able
              to execute a transaction.
            </p>
          </li>

          <li data-list-text="14.6">
            <p>
              In the event Client has doubts as to the conformity of a planned
              transaction with the said Sanctions and policies, Client will
              contact iBAN-X before instructing iBAN-X regarding this
              transaction.
            </p>
          </li>
        </ol>
      </ol>
      <h3>15. COMPLAINTS</h3>
      <ol>
        <ol id="l38">
          <li data-list-text="15.1">
            <p>
              The Client may send a complaint to iBAN-X by sending a letter to
              its registered office at 7, Am Scheerleck, L-6868 Wecker,
              Luxembourg or by email to the following address:
            </p>
            <a
              href="mailto:complaints@iBAN-X.com"
              target="_blank"
              rel="noreferrer"
            >
              complaints@iBAN-X.com
            </a>{' '}
            <a
              href="https://www.iban-x.com/_files/ugd/fd6e16_6bb278833f424afdb5a125315fabd5c6.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Client Complaint Process
            </a>
          </li>
        </ol>
      </ol>
      <h3>16. EVIDENCE</h3>
      <ol>
        <ol id="l39">
          <li data-list-text="16.1">
            <p>
              Without prejudice to mandatory legal or public policy provisions
              which lay down specific rules as regards proof iBAN-X shall be
              entitled to rely on a Client&#39;s own accounts as evidence.
              iBAN-X may administer proof of a legal act by means of a copy or
              reproduction of the original document, regardless of the nature or
              amount of the document. Proof of the act performed by means of a
              technical process may be administered by means of said technical
              process. This copy or reproduction has the same force and
              probative value as the original of the act. The copy or
              reproduction of the document may have a different form from the
              original when it results from the use of any technical process.
            </p>
          </li>
        </ol>
      </ol>
      <h3>17. FORCE MAJEURE</h3>
      <ol>
        <ol id="l40">
          <li data-list-text="17.1">
            <p>
              Neither iBAN-X nor Client will be liable for any delays in
              processing or other non-performance caused by force majeure events
              (unforeseeable, external, and irresistible events) such as
              telecommunications, utility failures, or equipment failures;
              labour strife, riots, war, or terrorist attacks; non-performance
              of our vendors or suppliers, fires or acts of nature; or any other
              event over which the respective party has no reasonable control.
              However, nothing in this clause will affect or excuse Client’s
              liabilities or Client’s obligation to pay any fees, charges,
              interest, fines, disputes, refunds, reversals under the Agreement.
            </p>
          </li>

          <li data-list-text="17.2">
            <p>
              The affected party shall use its reasonable efforts to minimise
              the effects of the force majeure event. If iBAN-X is the affected
              party, it will implement its business continuity plan.
            </p>
          </li>
        </ol>
      </ol>
      <h3>18. MISCELLANEOUS</h3>
      <ol>
        <ol id="l41">
          <li data-list-text="18.1">
            <p>
              Subject to any applicable notice period set out herein, these
              General Terms and Conditions are applicable from the date hereof
              and shall remain effective until a new version is released. The
              newest and current version of the General Terms and Conditions is
              always available on iBAN-X’s website and Platform.
            </p>
          </li>
          <li data-list-text="18.2">
            <p>
              The Agreement constitutes the entire agreement between iBAN-X and
              Client. Any prior statement or representation by either party,
              whether express or implied is hereby excluded from the Agreement
              insofar as is permissible under Applicable Law.
            </p>
          </li>
          <li data-list-text="18.3">
            <p>
              If any provision of the Agreement is held to be wholly or in part
              invalid or unenforceable, the invalidity or unenforceability will
              not affect the other provisions of the Agreement. The invalid or
              unenforceable provision will be replaced by a valid and
              enforceable provision that approximates as closely as possible the
              intent of the invalid or unenforceable provision. This will also
              apply in cases of contractual gaps.
            </p>
          </li>
          <li data-list-text="18.4">
            <p>
              Client may not assign or transfer any of its rights or obligations
              under the Agreement without a prior written consent from iBAN-X.
            </p>
          </li>
          <li data-list-text="18.5">
            <p>
              Client is not entitled to reverse engineer, decompile, or
              disassemble any of the software used for the Transaction Platforms
              or Services. Likewise, Client is not entitled to copy, modify,
              sell, distribute, or transfer any parts of the software used for
              the Transaction Platforms or Services.
            </p>
          </li>
        </ol>
      </ol>
      <h3>19. GOVERNING LAW AND CHOICE OF JURISDICTION</h3>
      <ol>
        <ol id="l42">
          <li data-list-text="19.1">
            <p>
              The business relationship between iBAN-X and the Client shall be
              subject to Luxembourg law and as such the latter shall govern the
              solution of any disputes that may arise between iBAN-X and the
              Client in connection with this relationship.
            </p>
          </li>
          <li data-list-text="19.2">
            <p>
              Without prejudice to the application of Article 1.12, only the
              Luxembourg courts shall be competent to settle any disputes
              arising between iBAN-X and the Client in the context of the
              business relationship.
            </p>
          </li>
        </ol>
      </ol>
      <h3>20. DEFINITIONS</h3>
      <ol>
        <ol id="l43">
          <li data-list-text="20.1">
            <p>
              In these General Business Terms, the following terms shall, unless
              the context otherwise requires, have the following meanings:
            </p>
          </li>
        </ol>
      </ol>
      <p>
        “<b>Account</b>” means any account(s) with iBAN-X opened in the name of
        Client.
      </p>
      <p>
        “<b>Applicable Law</b>” means any law, statute, regulation or legally
        binding requirement or order as interpreted taking appropriate account
        of regulatory policy, guidance or industry code, at the national and
        international levels relating to either of the parties to these General
        Terms and Conditions, including any instructions or requirement imposed
        by a competent Authority, and any rules and restrictions in relation to
        trade embargos or other sanctions regulation imposed by the United
        States of America, the United Nations, the European Union and any of its
        member states, the United Kingdom, any institution, or agency acting on
        behalf of any of them, in each case to which (i) IBAN-X and/or the
        Services, and (ii) if the context so requires, Client, are subject;
      </p>
      <p>
        “<b>Authorities</b>” includes any judicial, administrative, public,
        regulatory or law enforcement body; any government, tax authority,
        securities exchange, court, central bank; and any of their agents or
        agencies.
      </p>
      <p>
        “<b>Business Day</b>” means any day on which banks are open for business
        in Luxembourg.
      </p>
      <p>
        “<b>Business Hours</b>” means the time between 09:00 and 17:00 (CET /
        Luxembourg time).
      </p>
      <p>
        “<b>Compliance Obligation</b>” means under Applicable Law and/or any
        other obligation of IBAN-X to comply with (a) laws or international
        guidance and internal policies or procedures, (b) any demand from
        Authorities regarding reporting, disclosure, or other obligations under
        Applicable Laws, and (c) Applicable Law requiring iBAN-X to verify the
        identity of our clients.
      </p>
      <p>
        “<b>Client(s)</b>”: Legal or natural person to whom the Company is
        offering certain services based in a contractual relationship versus a
        consideration.
      </p>
      <p>
        “<b>Compliance Team</b>” means the Employee, who supports the duties and
        responsibilities of
      </p>
      <p>Compliance Function according to the AML/CTF Law.</p>
      <p>
        “<b>Effective Date</b>” means the date the second party to the Agreement
        signs the Agreement.
      </p>
      <p>
        “<b>Fee Account</b>” means a single account used for settling fees from
        activities on the IBAN-X Platform.
      </p>
      <p>
        “<b>Insolvency Event</b>” means that a party to these General Terms and
        Conditions:
      </p>
      <ul id="l44">
        <li>
          <p>
            is unable, or admits inability, to pay its debts, or suspends or
            threatens to suspend making a payment on any of its debts.
          </p>
        </li>
        <li>
          <p>
            has an order or petition made against it or a resolution passed for
            its administration, winding-up or dissolution, or any other
            corporate step or legal proceeding is taken with a view to the same
            (otherwise than for the purposes of a solvent amalgamation or
            reconstruction).
          </p>
        </li>
        <li>
          <p>
            has an administrative receiver, manager, liquidator, administrator,
            trustee or similar officer appointed over all or a significant part
            of its assets.
          </p>
        </li>
        <li>
          <p>
            enters into or proposes any compromise arrangement with its
            creditors; or
          </p>
        </li>
        <li>
          <p>
            suffers or carries out anything similar to the above in any
            applicable jurisdiction.
          </p>
        </li>
      </ul>
      <p>
        “<b>Instructions</b>” means any payment or Transaction instruction,
        where Client instructs iBAN-X to perform a Transaction.
      </p>
      <p>
        “<b>Loss</b>” includes any loss (including loss of profits), costs,
        damages, taxes, expenses, or other liability.
      </p>
      <p>
        “Legal Frame”: means Luxembourg Legal framework, including European
        directives, laws, Luxembourg national risk assessment, FATF
        recommendations, and any applicable laws and regulations related to
        AML/CTF, as amended.
      </p>
      <p>
        “<b>Party</b>” means each Client and iBAN-X, individually, and “
        <b>Parties</b>” means Client and iBAN-X, collectively.
      </p>
      <p>
        “<b>Partner</b>” means a legal entity, which aims or has entered
        contractual relationship with the Company for the purpose of introducing
        one or more Clients to the Company.
      </p>
      <p>
        “<b>Reseller</b>”: A bank, a financial institution, or a legal entity
        acting as an introducer for new Partners/Clients. The Reseller is not
        involved into the account opening, onboarding or any other operation of
        the Client or Partner.
      </p>
      <p>
        “<b>Transaction</b>” means the transfer or receipt of funds, or any
        other action pertaining to the
      </p>
      <p>
        processing or disposal of funds carried out by IBAN-X on behalf of
        Client.
      </p>
      <p>
        “<b>Platform</b>” means the iBAN-X Platform and any other system or
        application programming interface(s) (API) made available by iBAN-X.
      </p>
      <p>
        References to any law, statute, regulation, or enactment in these
        General Business Terms shall include references to any amendment,
        modification, or re-enactment thereof or to any regulation or order made
        under such law, statute or enactment.
      </p>
      <p>
        Any references to the terms &quot;include&quot;, &quot;includes&quot;,
        &quot;including&quot;, or any similar terms are not intended to limit
        the, or provide an exhaustive, meaning or generality of the related
        wording.
      </p>
    </div>
  );
};
