import { Button } from '@components/Button';
import { FileFormatWithCurrencySelector } from '@components/FileFormatWithCurrencySelector';
import { FieldError } from '@components/Input';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { MonthPicker } from '@components/MonthPicker';
import { YearPicker } from '@components/YearPicker';
import { yupResolver } from '@hookform/resolvers/yup';
import usePreGeneratedReports from '@hooks/usePreGeneratedReports';
import usePreGeneratedReportsAvailable from '@hooks/usePreGeneratedReportsAvailable';
import {
  BankAccountWithCurrencies,
  MonthsAvailable
} from '@services/bapi/accounts';
import { downloadFile } from '@utils/downloadFile';
import classNames from 'classnames';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import React, { FC, useMemo, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { generatePreStatementsSchema } from './Schema';

export interface DownloadStatementsProps {
  bankAccounts: BankAccountWithCurrencies[];
}

export type RequestPreGeneratedStatements = {
  account: string;
  currency: string;
  year: string;
  month: number;
  fileFormat: string;
};

export type AvailableCurrencies = {
  value: string;
  label: string;
};

export const PreGeneratedStatements: FC<DownloadStatementsProps> = ({
  bankAccounts
}) => {
  dayjs.extend(minMax);

  let filename = '';
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [availableCurrenciesOnAccount, setAvailableCurrenciesOnAccount] =
    useState<AvailableCurrencies[]>([]);
  const [currenciesAvailable, setCurrenciesAvailable] = useState<
    MonthsAvailable[]
  >([]);

  const { mutateAsync, isLoading } = usePreGeneratedReports({
    onSuccess(data) {
      downloadFile(null, data, filename);
    },
    onError(err) {
      if (err === 404) {
        setShowModal(true);
      }
    }
  });

  const { mutateAsync: mutateAsyncMonthsAvailable } =
    usePreGeneratedReportsAvailable({
      onSuccess(data: MonthsAvailable[]) {
        setCurrenciesAvailable(data);
      }
    });

  React.useMemo(() => {
    if (selectedAccount === null) return;
    if (selectedAccount !== 'ALL') {
      const accountSelected = bankAccounts.find(account =>
        account.id.includes(selectedAccount)
      );

      setAvailableCurrenciesOnAccount([
        {
          label: 'All currencies',
          value: 'ALL'
        },
        ...accountSelected.currencies.map(currency => {
          return {
            value: currency.code,
            label: currency.code
          };
        })
      ]);
    } else {
      const allCurrencies = [
        ...bankAccounts
          .map(account => account.currencies)
          .flat()
          .map(currency => currency.code)
      ];

      setAvailableCurrenciesOnAccount([
        ...[...new Set(allCurrencies)].map(currency => {
          return {
            value: currency,
            label: currency
          };
        })
      ]);
    }
  }, [bankAccounts, selectedAccount]);

  const {
    handleSubmit,
    register,
    control,
    resetField,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(generatePreStatementsSchema),
    defaultValues: {
      account: '',
      currency: '',
      year: undefined,
      month: undefined,
      fileFormat: 'pdf'
    } as RequestPreGeneratedStatements
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const yearSelected = useMemo(() => watch('year'), [watch('year')]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const monthSelected = useMemo(() => watch('month'), [watch('month')]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const accountSelected = useMemo(() => watch('account'), [watch('account')]);
  const oldestAccount = useMemo(
    () =>
      dayjs.min(
        ...bankAccounts.map(bankAccount => dayjs(bankAccount.startDate))
      ),
    [bankAccounts]
  );

  const onSubmit = (values: RequestPreGeneratedStatements) => {
    filename = `${values.year}-${values.month}-${values.account}-${
      values.currency
    }-${dayjs().format('YYYYMMDDHHmmss')}.${values.fileFormat}`;
    mutateAsync(values);
  };

  return (
    <div className="preStatements">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={classNames(
            monthSelected && 'preStatements__date-container'
          )}
        >
          <div className="preStatements__date">
            <h4>Year</h4>
            <YearPicker
              register={register}
              resetField={resetField}
              oldestAccount={oldestAccount}
            />
            {errors.year?.message && (
              <FieldError error={errors.year?.message} />
            )}
          </div>
          {yearSelected && (
            <div className="preStatements__date">
              <h4>Month</h4>
              <MonthPicker
                register={register}
                resetField={resetField}
                oldestAccount={oldestAccount}
                yearSelected={yearSelected}
              />
              {errors.month?.message && (
                <FieldError error={errors.month?.message} />
              )}
            </div>
          )}
        </div>
        {monthSelected && (
          <div className="preStatements__currencies">
            <div className="preStatements__currencies-input">
              <h4>Account number</h4>
              <Controller
                render={({ field }) => (
                  <Select
                    options={[
                      ...bankAccounts
                        .map(account => {
                          const selectedMonthYear = dayjs()
                            .year(Number(yearSelected))
                            .month(monthSelected)
                            .endOf('month');

                          return dayjs(account.startDate).isAfter(
                            selectedMonthYear
                          )
                            ? null
                            : {
                                value: account.iban,
                                label: `${account.alias} (${account.iban.slice(
                                  0,
                                  4
                                )}...${account.iban.slice(-4)})`
                              };
                        })
                        .filter(account => account !== null)
                    ]}
                    onChange={account => {
                      field.onChange(account.value);
                      setSelectedAccount(account.value);
                      resetField('currency', { keepTouched: false });
                      mutateAsyncMonthsAvailable({
                        account: account.value,
                        year: yearSelected,
                        month: monthSelected
                      });
                      resetField('fileFormat', { keepTouched: false });
                      setCurrenciesAvailable([]);
                    }}
                    placeholder="Select an account"
                  />
                )}
                name="account"
                control={control}
              />
              {errors.account?.message && (
                <FieldError error={errors.account?.message} />
              )}
            </div>
          </div>
        )}
        {accountSelected && (
          <div className="preStatements__currencies-input">
            {availableCurrenciesOnAccount.map(curr => {
              return (
                <FileFormatWithCurrencySelector
                  currency={curr.value}
                  label={curr.label}
                  fileFormats={['pdf', 'csv']}
                  setValue={setValue}
                  disabled={
                    !currenciesAvailable.find(c => {
                      return curr.value !== 'ALL'
                        ? c.accountId.split('-')[1] === curr.value
                        : !c.accountId.split('-')[1];
                    })
                  }
                />
              );
            })}
          </div>
        )}
      </form>
      {isLoading && <Loading />}
      {showModal && (
        <Modal className="preStatements__no-report">
          <p>Error trying to download the report.</p>
          <Button onClick={() => setShowModal(false)}>OK</Button>
        </Modal>
      )}
    </div>
  );
};
