import { FieldError } from '@components/Input';
import classNames from 'classnames';
import React, { FC, useRef } from 'react';

interface OneTimeInputProps {
  setValue: (value: string) => void;
  error: string;
  formMounted: boolean;
  fields: number;
  separateEvery?: number;
  disabled?: boolean;
  additional?: number;
  isDivided?: boolean;
  value?: string | null;
}

type CustomInputElements = HTMLInputElement & { index: number };

export const OneTimeInput: FC<OneTimeInputProps> = ({
  setValue,
  error,
  formMounted,
  fields,
  separateEvery,
  disabled = false,
  additional = 0,
  isDivided = true,
  value
}) => {
  const [inputElements, setInputElements] =
    React.useState<CustomInputElements[]>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (formMounted) {
      setInputElements(
        Array.from(document.querySelectorAll('.one-time-input__input'))
      );
    }
  }, [formMounted]);

  const focusElement = e => {
    const { index } = e.currentTarget;
    if (e.keyCode === 8 && e.target.value === '') {
      inputElements[Math.max(0, index - 1)].focus();
    }
  };

  const focusNextElement = e => {
    const { index } = e.currentTarget;
    const [first, ...rest] = e.target.value;
    e.target.value = first ?? '';
    const lastInputBox = index === inputElements.length - 1;
    const insertedContent = first !== undefined;
    if (insertedContent && !lastInputBox) {
      // continue to input the rest of the string
      inputElements[index + 1].focus();
      inputElements[index + 1].value = rest.join('');
      inputElements[index + 1].dispatchEvent(new Event('input'));
    }
  };

  React.useEffect(() => {
    inputElements?.forEach((ele, index) => {
      ele.index = index;
      ele.addEventListener('keydown', focusElement);
    });

    return () => {
      inputElements?.forEach(ele => {
        ele.removeEventListener('keydown', focusElement);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputElements]);

  React.useEffect(() => {
    // Set focus on the first input element when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [formMounted]);

  function getCode(position: number) {
    if (position === inputElements.length - 1) {
      let val = inputElements.map(element => element.value).join('');
      if (additional) {
        val = val.slice(additional);
      }
      setValue(val);
    }
  }

  function handleSingleInput(e) {
    const { value: curValue } = e.currentTarget;

    // Only process onChange if the input is 6 characters or less
    if (curValue.length <= 6 && /^\d*$/.test(curValue)) {
      setValue(curValue);
    }
  }

  return (
    <>
      <fieldset
        className={classNames(
          'auth__form-item one-time-input__input-container',
          error && 'one-time-input--error'
        )}
      >
        {isDivided ? (
          [...Array(fields).keys()].map((position, index) => (
            <>
              <input
                key={position}
                autoComplete="off"
                required
                name="char"
                disabled={disabled}
                className={classNames(
                  'one-time-input__input',
                  error && ' one-time-input__input--error'
                )}
                onChange={e => {
                  getCode(position + additional);

                  focusNextElement(e);
                }}
                ref={inputRef}
              />
              {(index + 1) % separateEvery === 0 && index + 1 < fields && (
                <span>-</span>
              )}
            </>
          ))
        ) : (
          <input
            autoComplete="off"
            required
            name="char"
            disabled={disabled}
            className={classNames(
              'one-time-input__input',
              error && ' one-time-input__input--error'
            )}
            onChange={handleSingleInput}
            value={value}
            ref={inputRef}
          />
        )}
      </fieldset>
      {error && <FieldError error={error} />}
    </>
  );
};
