import { AxiosResponse } from 'axios';
import { bapi } from './client';

export type GetValidateIbanResponse = boolean;

export const getValidateIban = (
  countryCode: string,
  iban: string
): Promise<AxiosResponse<GetValidateIbanResponse>> => {
  return bapi.get<GetValidateIbanResponse>(
    `/v1/validate/iban/${countryCode}?iban=${iban}`
  );
};
