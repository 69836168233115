import { addSpacesEvery4Letters, formatAmountWithCurrency } from '@utils';
import classNames from 'classnames';
import { FC, useMemo } from 'react';

interface TransferDetailsProps {
  accountToDebitName?: string;
  accountToDebitBankName?: string;
  accountToDebitIban?: string;
  accountToDebitBic?: string;
  accountToCredit?: string;
  amount?: number;
  currency?: string;
  referenceMessage?: string;
  bic?: string;
  beneficiaryBankName?: string;
  beneficiaryName?: string;
  accountToCreditIban?: string;
  accountToCreditAccountNumber?: string;
  accountToCreditBankNumber?: string;
  date?: string;
}
interface TransferStepsProps {
  details: TransferDetailsProps;
  jumpToFirstStep: () => void;
  jumpToSecondStep: () => void;
}

export const ForeignExchangeSteps: FC<TransferStepsProps> = ({
  details,
  jumpToFirstStep,
  jumpToSecondStep
}) => {
  const accountToDebit = useMemo(() => {
    if (
      details.accountToDebitName ||
      details.accountToDebitBankName ||
      details.accountToDebitIban ||
      details.accountToDebitBic
    ) {
      return (
        <>
          {details.accountToDebitName && (
            <div className="transfer-steps__step-info">
              <h4 className="regular">Account to exchange</h4>
              <h3>{details.accountToDebitName}</h3>
            </div>
          )}
          {details.accountToDebitIban && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">IBAN</h4>
              <h4 className="regular">
                {addSpacesEvery4Letters(details.accountToDebitIban)}
              </h4>
            </div>
          )}
          {details.accountToDebitBic && (
            <div className="transfer-steps__detail">
              <h4 className="secondary">BIC</h4>
              <h4 className="regular">{details.accountToDebitBic}</h4>
            </div>
          )}
        </>
      );
    }

    return <h4 className="regular">Account to exchange</h4>;
  }, [
    details.accountToDebitBankName,
    details.accountToDebitBic,
    details.accountToDebitIban,
    details.accountToDebitName
  ]);

  return (
    <div className="transfer-steps">
      <div className="transfer-steps__step-container">
        <button
          type="button"
          className={classNames(
            'transfer-steps__step-button-details',
            details.accountToDebitName &&
              'transfer-steps__step-button--disabled'
          )}
          onClick={jumpToFirstStep}
        >
          <div className="transfer-steps__step-number">
            <h4>1</h4>
          </div>
          <div className="transfer-steps__details-data-container">
            {accountToDebit}
          </div>
        </button>
      </div>
      <div className="transfer-steps__step-container">
        <button
          type="button"
          className="transfer-steps__step-button-details"
          disabled={!details.accountToDebitName}
          onClick={() => jumpToSecondStep}
        >
          <div className="transfer-steps__step-number">
            <h4>2</h4>
          </div>
          <div className="transfer-steps__details-data-container">
            {details.currency &&
            details.amount &&
            details.referenceMessage &&
            details.date ? (
              <>
                <h4 className="regular">Amount</h4>
                <h3 className="transfer-steps__last-detail-value">
                  {formatAmountWithCurrency(details.amount, details.currency)}
                </h3>
                <h4 className="regular transfer-steps__last-detail">
                  Reference
                </h4>
                <h3 className="italic transfer-steps__last-detail-value">
                  {details.referenceMessage}
                </h3>
                <h4 className="regular transfer-steps__last-detail">
                  Value Date
                </h4>
                <h3 className="transfer-steps__last-detail-value">
                  {details.date}
                </h3>
              </>
            ) : (
              <h4 className="regular">Details</h4>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};
