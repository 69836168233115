import Info from '@assets/icons/icon_faq.svg?react';
import classNames from 'classnames';
import React from 'react';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  variant?: 'background' | 'outline' | 'underline';
  label?: string;
  error?: string;
  register?: any;
  className?: string;
  note?: any;
  maxLength?: number | undefined;
}

export const Input: React.FC<InputProps> = ({
  name,
  label,
  error,
  register,
  className,
  variant = 'outline',
  note,
  maxLength,
  ...rest
}) => {
  const inputError = error ? `${'input__input input__'}${variant}--error` : '';
  const inputLimiterError = error ? 'input__limiter--error' : '';
  const inputLimiter = maxLength ? 'input__input--with-limiter' : '';
  const [currentLength, setCurrentLength] = React.useState(0);
  const limiterText = React.useMemo((): string => {
    if (maxLength === undefined) {
      return '';
    }

    return `${currentLength}/${maxLength}`;
  }, [maxLength, currentLength]);

  return (
    <div className={classNames(className, 'input')}>
      <div className="input__default input">
        {label && (
          <label className="paragraph input__label" htmlFor={name}>
            {label}
            {note && (
              <div className="input__note">
                <Info />
                <p className="paragraph--tiny secondary">{note}</p>
              </div>
            )}
          </label>
        )}
        {register ? (
          <input
            className={classNames(
              'input__input',
              `input__${variant}`,
              inputError,
              inputLimiter
            )}
            id={name}
            onKeyUp={e => {
              setCurrentLength(e.currentTarget.value.toString().length);
            }}
            {...register(name)}
            {...rest}
          />
        ) : (
          <input
            className={classNames(
              'input__input',
              `input__${variant}`,
              inputLimiter
            )}
            id={name}
            {...rest}
          />
        )}
        {maxLength && (
          <span
            className={classNames('h4', 'input__limiter', inputLimiterError)}
          >
            {limiterText}
          </span>
        )}
      </div>

      {error && <FieldError error={error} />}
    </div>
  );
};

export const FieldError: React.FC<{ error: string }> = ({ error }) => (
  <div className="input__error">
    <img
      src="/assets/icons/icon_error.svg?react"
      alt="error icon"
      width={18}
      height={18}
    />
    <span className="h4 input__error">{error}</span>
  </div>
);
