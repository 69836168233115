import { Button } from '@components/Button';
import { Modal } from '@components/Modal';
import { Timer } from '@components/Timer';
import { useAuth } from '@hooks/useAuth';
import React, { PropsWithChildren } from 'react';
/**
 * @author Arungopi
 * @props: flag : show continue/cancel buttons or not
 * @props: continueOperation :operation to be performed on click of continue button
 * @props: cancelOperation :operation to be performed on click of cancel button
 * @props: children :text to appear on popup alert if it is not a flag=true component
 */
type logoutAlertProps = {
  flag?: boolean;
  continueOperation?: () => void;
  cancelOperation?: () => void;
};
export const LogoutAlert: React.FC<PropsWithChildren<logoutAlertProps>> = ({
  flag = false,
  continueOperation,
  cancelOperation,
  children
}) => {
  const { handleLogout } = useAuth();
  const logOut = () => {
    handleLogout();
  };
  const message = 'You are going to be logged out';
  return (
    <Modal>
      <div className="logoutAlert__model">
        <h1>Alert!!</h1>
        <br />
        {flag ? (
          <>
            <h3>
              <Timer
                minutes={false}
                seconds
                isActive
                timerCompletedMessage="Logging out."
                timerRunningMessage={message}
                EndTimerDirectCallFunction={logOut}
                Time={5}
              />
            </h3>
            <div className="logoutAlert__model__continueButton">
              <Button onClick={logOut}>Continue</Button>
            </div>
          </>
        ) : (
          <div>
            <p>{children}</p>
            <div className="logoutAlert__model__button">
              <Button onClick={continueOperation}>Continue</Button>
              <Button onClick={cancelOperation}>Cancel</Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
