import { ForeignExchangeSteps } from '@components/DetailsSidebars/ForeignExchangeSteps/foreignExchangeSteps';
import React from 'react';
import { useForeignExchangeContext } from './ForeignExchange';

export const ForeignExchangeSidebar: React.FC = () => {
  const { transferState, goToStep } = useForeignExchangeContext();

  return (
    <ForeignExchangeSteps
      details={{
        accountToDebitName: transferState.accountToDebitName,
        accountToDebitBankName: transferState.debitAccount?.accountHolderName,
        accountToDebitIban: transferState.debitAccount?.iban,
        accountToDebitBic: transferState.debitAccount?.bic,
        accountToCredit: transferState.accountToCreditName,
        amount: transferState.amount,
        currency: transferState.currency,
        referenceMessage: transferState.referenceMessage,
        bic: transferState.swiftBic,
        beneficiaryBankName: transferState.bankName,
        beneficiaryName: transferState.accountToCreditName,
        accountToCreditIban: transferState.accountToCreditIban,
        accountToCreditAccountNumber:
          transferState.accountToCreditAccountNumber,
        accountToCreditBankNumber: transferState.accountToCreditBankNumber,
        date: transferState.date.toLocaleDateString('en-EN')
      }}
      jumpToFirstStep={() => goToStep('selectAccount')}
      jumpToSecondStep={() => goToStep('details')}
    />
  );
};
