import { Beneficiary } from '@services/bapi/beneficiaries';
import { useMemo, useState } from 'react';

type UseFilteredBeneficiaries = {
  togglePersonalSwitcher: () => void;
  toggleGlobalSwitcher: () => void;
  isPersonalSelected: boolean;
  isGlobalSelected: boolean;
  filteredBeneficiaries: Beneficiary[];
};

export const useFilteredBeneficiaries = (
  beneficiaries: Beneficiary[]
): UseFilteredBeneficiaries => {
  const [isPersonalSelected, setIsPersonalSelected] = useState(true);
  const [isGlobalSelected, setIsGlobalSelected] = useState(true);

  const filteredBeneficiaries = useMemo(() => {
    if (!isPersonalSelected && !isGlobalSelected) {
      return [];
    }
    if (isPersonalSelected && isGlobalSelected) {
      return beneficiaries;
    }
    if (isGlobalSelected) {
      return beneficiaries.filter(beneficiary => beneficiary.isGlobal);
    }
    return beneficiaries.filter(beneficiary => !beneficiary.isGlobal);
  }, [beneficiaries, isGlobalSelected, isPersonalSelected]);

  return {
    togglePersonalSwitcher: () => setIsPersonalSelected(!isPersonalSelected),
    toggleGlobalSwitcher: () => setIsGlobalSelected(!isGlobalSelected),
    isPersonalSelected,
    isGlobalSelected,
    filteredBeneficiaries
  };
};
