import React from 'react';
import { TransferAccountToDebitPlugin } from '../../../plugins';
import { useForeignExchangeContext } from './ForeignExchange';

export const ForeignExchangeSelectAccount: React.FC = () => {
  const { handleDebitAccountSelect, transferState } =
    useForeignExchangeContext();
  return (
    <TransferAccountToDebitPlugin
      iban={transferState.debitAccount?.iban}
      handleDebitAccountSelect={handleDebitAccountSelect}
    />
  );
};
