import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useMemo, useState } from 'react';

export type SingleDatePickerProps = {
  register: any;
  resetField: any;
  oldestAccount: Dayjs;
};

export const YearPicker: FC<SingleDatePickerProps> = ({
  register,
  resetField,
  oldestAccount
}) => {
  const startYear = oldestAccount.year();
  const todayYear = dayjs().year();
  const [prevChecked, setPrevChecked] = useState(null);
  const years = useMemo(() => {
    const yearsToReturn = [];
    for (
      let year = startYear < 2022 ? 2022 : startYear;
      year <= todayYear;
      year++
    ) {
      yearsToReturn.push(year);
    }

    return yearsToReturn;
  }, [startYear, todayYear]);

  return (
    <div className="months-view years-container">
      {years.map(year => {
        return (
          <>
            <input
              type="radio"
              name="monthPicker"
              id={year}
              value={year}
              {...register('year')}
              disabled={false}
              onClick={e => {
                if (prevChecked === e.currentTarget.value) {
                  resetField('year');
                  resetField('month');
                  resetField('account');
                  setPrevChecked(null);
                  return;
                }

                setPrevChecked(e.currentTarget.value);
              }}
            />
            <label
              className={classNames(
                'months-view__month',
                'enabled',
                prevChecked && 'hide'
              )}
              htmlFor={year}
            >
              {year}
            </label>
          </>
        );
      })}
    </div>
  );
};
