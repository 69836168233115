import { RequestCustomStatements } from '@components/CustomStatements';
import { getCustomReport } from '@services/bapi/accounts';
import { useMutation } from 'react-query';

export default function useCustomReports(options: any) {
  return useMutation(
    (reportRequestData: RequestCustomStatements) =>
      getCustomReport(reportRequestData).then(res => res),
    {
      ...options
    }
  );
}
