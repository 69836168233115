import Filter from '@assets/icons/icon_filter.svg?react';
import { AccountOverviewDetailsHeader } from '@components/AccountOverviewDetailsHeader';
import { BankAccountRow } from '@components/BankAccountRow';
import DashboardLayout from '@components/DashboardLayout';
import { FilterForm, Filters } from '@components/DetailsSidebars/Filters';
import { TransactionDetails } from '@components/DetailsSidebars/TransactionDetails';
import { Loading } from '@components/Loading';
import { PageHeader } from '@components/PageHeader';
import { SearchBar } from '@components/SearchBar';
import { TransactionLine } from '@components/TransactionLine';
import { TransactionList } from '@components/TransactionList';
import useAccounts from '@hooks/useAccounts';
import useScrollPosition from '@hooks/useScrollPosition';
import useTransactions from '@hooks/useTransactions';
import routes from '@res/routes';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import {
  GetTransactionsFilter,
  Transaction
} from '@services/bapi/transactions';
import { getDatesFromPeriod } from '@utils/date';
import { getTransactionName } from '@utils/transactionHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export type AvailableCurrencies = string[];

// const today = dayjs().set('h', 0).set('m', 0).set('s', 0);

// const yesterday = dayjs().subtract(1, 'd').set('h', 0).set('m', 0).set('s', 0);

// const sevenDaysAgo = dayjs()
//   .subtract(7, 'd')
//   .set('h', 0)
//   .set('m', 0)
//   .set('s', 0);

let debounce: ReturnType<typeof setTimeout>;

const getInitialFilters = () => {
  return {
    currency: 'EUR',
    date: undefined,
    senderRecipient: '',
    status: '',
    account: undefined,
    type: undefined,
    page: 1,
    limit: 20,
    orderBy: 'desc',
    withPaymentNotes: 'true'
  };
};

let isInitialMount = true;

const TransactionsByAccountID: React.FC = () => {
  useScrollPosition();
  const navigate = useNavigate();
  const { accountID, pageParam, currency } = useParams();
  const currentPage = parseInt(pageParam, 10) || 1;

  const { data: selectedAccount, isFetched: isAccountLoaded } =
    useAccounts<BankAccountWithCurrencies>({
      select: data => data.find(account => account.bankAccountId === accountID)
    });

  const [search, setSearch] = React.useState('');
  const [selectedTransaction, setSelectedTransaction] =
    React.useState<Transaction | null>(null);
  const [transactions, setTransactions] = React.useState<Transaction[]>();
  const [allTransactions, setAllTransactions] = React.useState<Transaction[]>();
  const [total, setTotal] = React.useState(0);
  const [availableCurrencies, setAvailableCurrencies] =
    React.useState<AvailableCurrencies>([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [filters, setFilters] = useState<GetTransactionsFilter>({
    ...getInitialFilters(),
    page: currentPage
  });

  const totalPages = Math.ceil(total / filters.limit);

  const {
    isFetching,
    data,
    isFetched,
    refetch: refetchTransactions
  } = useTransactions(filters, {
    enabled: !!filters?.account,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    setFilters({
      ...getInitialFilters(),
      account: selectedAccount?.iban
    });
  }, [selectedAccount?.iban]);

  useEffect(() => {
    if (isFetched) {
      setTimeout(() => {
        const savedScrollPosition = localStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
        }
      }, 100);
    }
  }, [isFetched]);

  useEffect(() => {
    if (isInitialMount) {
      // If it's the first render, do not update localStorage
      isInitialMount = false;
    } else {
      // On subsequent renders (i.e., when the user changes the page), update localStorage
      localStorage.setItem('lastPage', filters.page.toString());
    }
  }, [filters.page]);

  useEffect(() => {
    if (isFetched) {
      const currencies = [];
      const transactionsTransformed = data.transactions.map(transaction => {
        if (!currencies.includes(transaction.currency))
          currencies.push(transaction.currency);

        return {
          ...transaction,
          counterPartyAccountHolder: transaction.counterPartyAccountHolder
            ? transaction.counterPartyAccountHolder
            : getTransactionName(transaction)
        };
      });

      setTransactions(transactionsTransformed);
      setTotal(data.total);
      setAllTransactions(transactionsTransformed);
      if (!availableCurrencies.length) setAvailableCurrencies(currencies);
    }
  }, [isFetched, availableCurrencies.length, data.total, data.transactions]);

  function handleSelectTransaction(transactionID: string) {
    if (selectedTransaction?.transactionId === transactionID) {
      setSelectedTransaction(null);
      return;
    }

    setSelectedTransaction(
      allTransactions.find(
        transaction => transaction.transactionId === transactionID
      )
    );

    setFilterOpen(false);
  }

  function handleFilterToggle() {
    setFilterOpen(!filterOpen);
    setSelectedTransaction(null);
    setSearch('');
  }

  const hasNextPage = React.useMemo(() => {
    return filters.page < totalPages - 1;
  }, [filters.page, totalPages]);

  const handlePageChange = useCallback(
    (page: number) => {
      if (page === filters.page) return;
      navigate(`/dashboard/transactions/${accountID}/${page}`); // Update this to your actual route
      setFilters(prevState => ({ ...prevState, page }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.page, navigate]
  );

  useEffect(() => {
    if (currentPage !== filters.page) {
      setFilters(prevState => ({ ...prevState, page: currentPage }));
    }
  }, [currentPage, filters.page]);

  const handleNextPage = React.useCallback(() => {
    if (!hasNextPage) return;
    handlePageChange(filters.page + 1);
  }, [filters.page, handlePageChange, hasNextPage]);

  const handlePreviousPage = React.useCallback(() => {
    if (filters.page - 1 <= 0) return;

    handlePageChange(filters.page - 1);
  }, [filters.page, handlePageChange]);

  function handleResultsPerPageChange(limit: number) {
    setFilters(prevState => ({ ...prevState, limit, currency, page: 1 }));
    handlePageChange(1);
  }

  function handleSetFilters(values: FilterForm) {
    const newFilters = {
      ...filters,
      ...values,
      currency,
      account: selectedAccount?.iban
    };

    if (values.period === 'WEEK' || values.period === 'MONTH') {
      const { endDate, startDate } = getDatesFromPeriod(values.period);
      newFilters.date = { startDate, endDate };
    } else if (values.period === 'CUSTOM') {
      newFilters.date.endDate = new Date(
        new Date(values.date.endDate).setHours(23, 59, 59)
      );
    } else {
      delete newFilters.period;
    }

    setFilters({ ...newFilters });
    handlePageChange(1);
    refetchTransactions();
  }

  const handleFilterByCurrency = useCallback(
    code => {
      setFilters(prevState => ({ ...prevState, currency: code }));
      handlePageChange(1);
    },
    [handlePageChange]
  );

  useEffect(() => {
    currency && handleFilterByCurrency(currency);
  }, [currency, handleFilterByCurrency]);

  React.useEffect(() => {
    if (debounce) {
      clearInterval(debounce);
    }

    debounce = setTimeout(() => {
      const lowerCaseSearch = search.toLowerCase().trim();
      const filteredTransactions = !search.length
        ? allTransactions
        : allTransactions?.filter(
            transaction =>
              transaction.counterPartyAccountHolder
                .toLowerCase()
                .includes(lowerCaseSearch) ||
              transaction.paymentTypeName
                .toLowerCase()
                .includes(lowerCaseSearch) ||
              String(transaction.amount).includes(lowerCaseSearch) ||
              transaction.accountId.toLowerCase().includes(lowerCaseSearch) ||
              transaction.statusName.toLowerCase().includes(lowerCaseSearch)
          );
      setTransactions(filteredTransactions);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // const getTitle = React.useCallback(
  //   (transaction: Transaction, index: number) => {
  //     const date = transaction.creationTs;

  //     const isToday = dayjs(date).isAfter(today);
  //     const isLastSevenDays =
  //       dayjs(date).isAfter(sevenDaysAgo) && dayjs(date).isBefore(yesterday);

  //     if (isToday) {
  //       if (index > 0) {
  //         return '';
  //       }

  //       return 'Today';
  //     }

  //     if (isLastSevenDays) {
  //       if (index - 1 >= 0) {
  //         const lastTransactionDate = dayjs(transactions[index - 1].creationTs);

  //         if (
  //           lastTransactionDate.isSame(date) ||
  //           lastTransactionDate.isAfter(date)
  //         ) {
  //           return '';
  //         }
  //       }
  //       return 'Last 7 days';
  //     }

  //     if (index - 1 >= 0) {
  //       const lastTransactionDate = dayjs(transactions[index - 1].creationTs);

  //       if (
  //         lastTransactionDate.isSame(date) ||
  //         lastTransactionDate.isBefore(sevenDaysAgo)
  //       ) {
  //         return '';
  //       }

  //       return 'All';
  //     }

  //     return '';
  //   },

  //   [transactions]
  // );

  return (
    <DashboardLayout
      mainContent={
        <div className="transactions">
          <div className="transactions__sticky-content">
            <div className="transactions__content">
              <PageHeader title="Transactions" smallMarginBottom>
                <AccountOverviewDetailsHeader
                  accountName={selectedAccount?.alias}
                  iban={selectedAccount?.iban || ''}
                />
              </PageHeader>
              <div className="transactions__filters">
                {isAccountLoaded && (
                  <BankAccountRow
                    border
                    currencies={selectedAccount.currencies}
                    accountId={selectedAccount.bankAccountId}
                    size="medium"
                    withLink
                    handleClick={handleFilterByCurrency}
                    currentCurrency={filters.currency}
                  />
                )}
                <div className="transactions__filters-bar-container">
                  {/* HIDDEN FOR NOW */}
                  {/* <button
                    onClick={() => {}}
                    type="button"
                    className="transactions__action-button"
                  >
                    <div className="transactions__action-button-icon">
                      <Download />
                    </div>
                  </button> */}
                  <button
                    onClick={handleFilterToggle}
                    type="button"
                    className="transactions__action-button"
                  >
                    {filterOpen && (
                      <div className="transactions__filters-open-background" />
                    )}
                    <div className="transactions__action-button-icon">
                      <Filter />
                    </div>
                  </button>
                </div>
              </div>
              <br />
            </div>
            <div className="transactions__view">
              <div className="transactions__pagination">
                <h4>Results per page: </h4>
                <select
                  onChange={e =>
                    handleResultsPerPageChange(Number(e.target.value))
                  }
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                {filters.page >= 3 && (
                  <button type="button" onClick={() => handlePageChange(1)}>
                    1{filters.page <= 3 ? '' : '...'}
                  </button>
                )}
                {filters.page > 1 && (
                  <button
                    type="button"
                    onClick={handlePreviousPage}
                    disabled={filters.page === 1}
                  >
                    {filters.page - 1}
                  </button>
                )}
                <h4>{filters.page}</h4>
                {hasNextPage && (
                  <button type="button" onClick={handleNextPage}>
                    {filters.page + 1}
                  </button>
                )}
                {totalPages > 1 && totalPages !== filters.page && (
                  <button
                    type="button"
                    onClick={() => handlePageChange(totalPages)}
                  >
                    {totalPages - filters.page <= 2 ? '' : '...'}
                    {totalPages}
                  </button>
                )}
              </div>
              <SearchBar
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
          {isFetching ? (
            <div className="transactions__loading-container">
              <Loading />
            </div>
          ) : (
            <TransactionList isSmallMargin>
              {!transactions?.length ? (
                <div className="transactions__empty-container">
                  <h2>No transactions available</h2>
                </div>
              ) : (
                transactions?.map((transaction, index) => (
                  <React.Fragment key={transaction.transactionId}>
                    {/* {getTitle(transaction, index) ? (
                      <h2 className="transactions__date-title">
                        {getTitle(transaction, index)}
                      </h2>
                    ) : null} */}
                    <TransactionLine
                      currency={transaction.currencyAc}
                      amount={transaction.amountAc}
                      type={transaction.debitIndicator ? 'debit' : 'credit'}
                      paymentNote={transaction.paymentNote}
                      targetName={transaction.counterPartyAccountHolder}
                      valueDate={transaction.valueDate}
                      onClick={() =>
                        handleSelectTransaction(transaction.transactionId)
                      }
                      isSelected={
                        transaction?.transactionId ===
                        selectedTransaction?.transactionId
                      }
                    />
                  </React.Fragment>
                ))
              )}
            </TransactionList>
          )}
        </div>
      }
      sidebar={
        <>
          {selectedTransaction && (
            <TransactionDetails
              accountIban={selectedTransaction.accountId.split('-')[0]}
              accountName={
                selectedTransaction.counterPartyAccountHolder
                  ? selectedTransaction.counterPartyAccountHolder
                  : getTransactionName(selectedTransaction)
              }
              type={selectedTransaction.debitIndicator ? 'debit' : 'credit'}
              rawTransaction={selectedTransaction}
            />
          )}
          {filterOpen && <Filters onApply={handleSetFilters} />}
        </>
      }
    />
  );
};

export default TransactionsByAccountID;
