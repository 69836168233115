import BackButton from '@components/BackButton';
import DashboardLayout from '@components/DashboardLayout';
import { InnerHeader } from '@components/InnerHeader/innerHeader';
import { PageHeader } from '@components/PageHeader';
import { INITIAL_TRANSFER_STATE, TransferState } from '@hooks/useTransfer';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import React, { useCallback } from 'react';
import { ForeignExchangeConfirmation } from './ForeignExchangeConfirmation';
import { ForeignExchangeDetails } from './ForeignExchangeDetails';
import { ForeignExchangeSelectAccount } from './ForeignExchangeSelectAccount';
import { ForeignExchangeSidebar } from './ForeignExchangeSidebar';
import { ExchangeContext, stepId } from './types';

const STEP_TO_COMPONENT_MAPPING: Record<stepId, React.FC> = {
  selectAccount: ForeignExchangeSelectAccount,
  details: ForeignExchangeDetails,
  confirmation: ForeignExchangeConfirmation
};

const STEP_TO_TITLE_MAPPING: Record<stepId, string> = {
  selectAccount: 'Account to exchange',
  details: 'Exchange details',
  confirmation: 'Confirmation'
};

const ForeignExchangeContext = React.createContext<ExchangeContext | undefined>(
  undefined
);

export const useForeignExchangeContext = (): ExchangeContext => {
  const foreignExchangeContext = React.useContext(ForeignExchangeContext);
  if (foreignExchangeContext === undefined) {
    throw Error(
      'useForeignExchangeContext should be used in ForeignExchangeContainer'
    );
  }
  return foreignExchangeContext;
};

export const useForeignExchangeController = (): ExchangeContext => {
  const [currentStep, setCurrentStep] = React.useState<stepId>('selectAccount');
  const goToStep = useCallback((step: stepId) => setCurrentStep(step), []);
  const [transferState, setTransferState] = React.useState<TransferState>(
    INITIAL_TRANSFER_STATE
  );

  const handleDebitAccountSelect = React.useCallback(
    (account: BankAccountWithCurrencies) => {
      setTransferState({
        ...INITIAL_TRANSFER_STATE,
        debitAccount: account,
        accountToDebitId: account.iban,
        accountToDebitName: account?.alias || account.productDescription,
        accountToDebitAccountHolderName: account.accountHolderName
      });
      goToStep('details');
    },
    [goToStep]
  );

  return React.useMemo(
    () => ({
      transferState,
      currentStep,
      goToStep,
      handleDebitAccountSelect
    }),
    [currentStep, transferState, goToStep, handleDebitAccountSelect]
  );
};

export const ForeignExchange: React.FC = () => {
  const controller = useForeignExchangeController();
  const Component = STEP_TO_COMPONENT_MAPPING[controller.currentStep];
  const isDetailsStep = controller.currentStep === 'details';
  const isConfirmationStep = controller.currentStep === 'confirmation';

  return (
    <ForeignExchangeContext.Provider value={controller}>
      <DashboardLayout
        mainContent={
          isConfirmationStep ? (
            <ForeignExchangeConfirmation />
          ) : (
            <>
              <PageHeader title="Foreign exchange" subTitle="New exchange" />
              {isDetailsStep && (
                <BackButton
                  onClick={() => controller.goToStep('selectAccount')}
                />
              )}
              <InnerHeader
                currentStep={controller.currentStep === 'selectAccount' ? 1 : 2}
                title={STEP_TO_TITLE_MAPPING[controller.currentStep]}
              />
              <Component />
            </>
          )
        }
        sidebar={<ForeignExchangeSidebar />}
      />
    </ForeignExchangeContext.Provider>
  );
};
