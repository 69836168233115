import { useEffect, useRef, useState } from 'react';

function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(() => {
    // Always check localStorage for an existing scroll position on mount
    const savedPosition = localStorage.getItem('scrollPosition');
    return savedPosition ? parseInt(savedPosition, 10) : 0;
  });
  const scrollPositionRef = useRef(scrollPosition);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(currentPosition);
      scrollPositionRef.current = currentPosition;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Save to localStorage when scroll position changes
  useEffect(() => {
    localStorage.setItem('scrollPosition', scrollPosition.toString());
  }, [scrollPosition]);

  return scrollPosition;
}

export default useScrollPosition;
