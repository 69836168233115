import Beneficiaries from '@assets/icons/icon_beneficiaries.svg?react';
import classNames from 'classnames';
import { FC } from 'react';

export type BeneficiariesTab = 'beneficiaries' | 'add-beneficiary';
interface BeneficiariesTabsProps {
  currentTab: BeneficiariesTab;
  handleTabSelect: (string: BeneficiariesTab) => void;
  isEdit: boolean;
}

export const BeneficiariesTabs: FC<BeneficiariesTabsProps> = ({
  currentTab,
  handleTabSelect,
  isEdit
}) => {
  const selectedClass = 'beneficiaries-tabs__tab--selected';
  return (
    <div className="beneficiaries-tabs">
      <button
        onClick={() => handleTabSelect('beneficiaries')}
        type="button"
        className={classNames(
          'beneficiaries-tabs__tab',
          'beneficiaries-tabs__tab--left',
          currentTab === 'beneficiaries' && selectedClass
        )}
      >
        <Beneficiaries />
        <h2>Your beneficiaries</h2>
      </button>
      <button
        onClick={() => handleTabSelect('add-beneficiary')}
        type="button"
        className={classNames(
          'beneficiaries-tabs__tab',
          currentTab === 'add-beneficiary' && selectedClass
        )}
      >
        <Beneficiaries />
        <h2>{isEdit ? 'Update' : 'Add new'} beneficiary</h2>
      </button>
    </div>
  );
};
