import React, { FC } from 'react';

import { FieldError } from '@components/Input';

export interface Props extends React.HTMLAttributes<HTMLSelectElement> {
  options: string[];
  values?: string[];
  name: string;
  id?: string;
  register?: any;
  error?: string;
  disabled?: boolean;
  selectedOption?: string;
}

export const Select: FC<Props> = ({
  options,
  values,
  name,
  id = '',
  register,
  error,
  disabled,
  selectedOption,
  ...rest
}) => {
  return (
    <div className="select">
      <select
        id={id}
        name={name}
        {...register?.(name)}
        disabled={disabled}
        {...rest}
      >
        {options.map((option, index) => (
          <option
            key={option}
            value={values ? values[index] : option}
            disabled={option === '---'}
            defaultValue={selectedOption}
          >
            {option}
          </option>
        ))}
      </select>
      <h4 className="select__arrow-pointer">▼</h4>
      {error && <FieldError error={error} />}
    </div>
  );
};
