import { Button } from '@components/Button';
import { FieldError, Input } from '@components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import useMe from '@hooks/useMe';
import routes from '@res/routes';
import { LoginData, confirmPassword } from '@services/bapi/auth';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

type VerifyPasswordProps = {
  step: number;
  setStep: any;
};

const VerifyPassword: React.FC<VerifyPasswordProps> = ({ step, setStep }) => {
  const [password, setPassword] = useState('');
  const { data } = useMe();

  const mutation = useMutation(
    (requestData: LoginData) => confirmPassword(requestData),
    {
      onSuccess: response => {
        if (!response || response.status !== 200) {
          return setError('password', {
            type: 'custom',
            message: 'Incorrect password'
          });
        }

        return setStep(step + 1);
      }
    }
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<{ password?: string }>({
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string().required('Please enter your password')
      })
    )
  });

  const onSubmit = () => {
    mutation.mutateAsync({
      username: data.preferredUsername,
      password
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-password">
        <div className="input-password__field">
          <Input
            register={register}
            name="password"
            label="Password"
            type="password"
            autoComplete="off"
            onChange={e => setPassword(e.currentTarget.value)}
            onClick={e => e.preventDefault()}
          />
        </div>
        <div className="input-password__btn">
          <Link to={routes.dashboard.resetMfa} replace>
            <Button type="button">Cancel</Button>
          </Link>
          <Button type="submit">Confirm</Button>
        </div>
      </div>
      {errors.password?.message && (
        <FieldError error={errors.password?.message} />
      )}
    </form>
  );
};

export default VerifyPassword;
