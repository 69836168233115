import { captureException } from '@sentry/react';
import { bapi } from './client';

export type MeResponse = {
  sub: string;
  bpId: string;
  emailVerified: boolean;
  dateOfBirth: string;
  citizenship: string;
  phoneNumber: string;
  preferredUsername: string;
  locale: string;
  email: string;
  otpConfirmed: boolean;
  mobileVerified: boolean;
  resetTransferMfaInit: boolean;
  name: string;
};

export type LoginData = {
  username: string;
  password: string;
};
export type SendMobileNumber = {
  mobileNumber: string;
};

export type sendOtp = {
  otp: string;
};
export type SentSMSOTP = {
  expiresBy: String;
};

export type SentSMS = {
  expiresIn: number;
  maskedNumber: string;
};

export const me = () => {
  return bapi.get<MeResponse>('/v1/auth/me');
};

export const generateOTPQrCode = () => {
  return bapi.get<string>('/v1/auth/otp/generate');
};

export const confirmOTP = (pin: string) => {
  return bapi.post<{ ok: true }>(
    `/v1/auth/otp/validate`,
    {
      pin: String(pin)
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const confirmSmsOtp = (pin: string) => {
  return bapi
    .post(
      `/v1/auth/otp/mobile/validate`,
      {
        otp: String(pin)
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .catch(error => {
      captureException(error);
      if (error.response) {
        throw error.response.data.message;
      }

      throw error;
    });
};

export const sendSmsOtp = () => {
  return bapi.get<SentSMS>(`/v1/auth/otp/mobile/generate`);
};

export const confirmPassword = (data: LoginData) => {
  const params = new URLSearchParams();
  params.append('username', data.username);
  params.append('password', data.password);

  return bapi
    .post<{ success: true }>('v1/auth/login', params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(err => {
      captureException(err);
    });
};

/**
 * @createOtp @validateUserOtp these function are created by @Arungopi
 * @createOtp handles api request to generate otp
 * @param data to send mobile number for otp generation
 * @validateUserOtp handles api request to validate otp
 * @param data to send mobile number and otp for validation
 */

export const sendMobileOtp = (data: SendMobileNumber) => {
  return bapi.post<SentSMSOTP>('/v1/mobile/register/generate/otp', data);
};

export const validateUserOtp = (data: sendOtp) => {
  return bapi.put('/v1/mobile/register/validate/otp', data);
};

/**
 * @reserTransferMFA @sendEmailOtp @validateEmailOTP created by Arungopi
 * @reserTransferMFA handles API call for reseting transferMFA false
 * @sendEmailOtp handles API call for sending OTP to a email address
 * @validateEmailOTP handles API call for validation OTP
 * @cancelOperationRequest handles API call for cancel operation
 */
export const resetTransferMFA = () => {
  return bapi.put('/v1/auth/otp/reset');
};

export const sendEmailOtp = () => {
  return bapi.get<SentSMSOTP>('/v1/auth/email/otp/generate');
};

export const validateEmailOTP = (data: any) => {
  return bapi.put('/v1/auth/email/otp/validate', data);
};

export const cancelOperationRequest = (operation: String) => {
  return bapi.post('/v1/operation/cancel', {
    operation: String(operation)
  });
};
