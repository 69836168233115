import DashboardLayout from '@components/DashboardLayout';
import { LoadingScreen } from '@components/LoadingScreen';
import { PageHeader } from '@components/PageHeader';
import { SearchBar } from '@components/SearchBar';
import { TransferLine } from '@components/TransferLine';
import useAccounts from '@hooks/useAccounts';
import routes from '@res/routes';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useNavigate } from 'react-router-dom';

export const Transactions: React.FC = () => {
  const [search, setSearch] = React.useState('');
  const navigate = useNavigate();

  const { data: bankAccounts, isLoading } = useAccounts();

  const filteredBankAccounts = React.useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();

    return bankAccounts?.length
      ? bankAccounts.filter(
          account =>
            account.alias?.toLowerCase()?.includes(lowerCaseSearch) ||
            account.iban.toLocaleLowerCase().includes(lowerCaseSearch) ||
            account.bic.toLowerCase().includes(lowerCaseSearch)
        )
      : [];
  }, [bankAccounts, search]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  function handleAccountSelect(account: BankAccountWithCurrencies) {
    navigate(
      routes.dashboard.getTransactionsByAccountID(account.bankAccountId)
    );
  }

  return (
    <DashboardLayout
      mainContent={
        <>
          <PageHeader title="Transactions" />
          <h2 className="transfer-to-debit__title">Select account</h2>
          <div className="transfer-to-debit">
            <div className="transfer-to-debit__search-bar-container">
              <SearchBar
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Search your account"
              />
            </div>
            {filteredBankAccounts.length > 0 ? (
              <ScrollContainer
                component="ul"
                className="transfer-to-debit__account-list"
              >
                {filteredBankAccounts?.map(account => (
                  <TransferLine
                    key={account.id}
                    id={account.id}
                    onAccounClick={() => handleAccountSelect(account)}
                    accountName={account.alias}
                    bic={account.bic}
                    iban={account.iban}
                  />
                ))}
              </ScrollContainer>
            ) : (
              <h2 className="transfer-to-debit__no-results">No results</h2>
            )}
          </div>
        </>
      }
    />
  );
};
