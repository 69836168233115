import { FC } from 'react';
import {
  TransferConfirmation,
  TransferConfirmationProps
} from '../Transfer/TransferConfirmation/transferConfirmation';

export const ForeignExchangeConfirmation: FC<
  TransferConfirmationProps
> = props => {
  return (
    <TransferConfirmation
      {...props}
      success
      message="Your money will be exchanged shortly"
    />
  );
};
