import { bapi } from './client';

export type Beneficiary = {
  id: string;
  userSub: string | null;
  name: string;
  bankName: string;
  transferType: string;
  iban: string;
  bic: string;
  accountNumber: string;
  bankNumber: string;
  email: string;
  telephone: string;
  street: string;
  houseNumber: string;
  countryCode: string;
  zipCode: string;
  city: string;
  label: string;
  isGlobal?: boolean;
};

export type GetBeneficiariesResponse = Beneficiary[];

export type PutBeneficiaryResponse = Beneficiary;

export type DeleteBeneficiaryResponse = {
  success: boolean;
};

export type PostBeneficiariesResponse = {
  beneficiary: Beneficiary;
};

export const getBeneficiaries = () => {
  return bapi.get<GetBeneficiariesResponse>('/v1/beneficiaries');
};

export const createBeneficiary = (beneficiary: Beneficiary) => {
  return bapi.post<PostBeneficiariesResponse>('/v1/beneficiaries', beneficiary);
};

export const updateBeneficiary = (data: Partial<Beneficiary>) => {
  const { id, ...rest } = data;
  return bapi.put<PutBeneficiaryResponse>(`/v1/beneficiaries/${id}`, {
    ...rest
  });
};

export const deleteBeneficiary = (beneficiaryId: Beneficiary['id']) => {
  return bapi.delete<DeleteBeneficiaryResponse>(
    `/v1/beneficiaries/${beneficiaryId}`
  );
};
