import { toastInformationMessage } from '@components/Toaster';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import React from 'react';
import { SESSION_STORAGE_KEY } from './const';
import { searchHolidaysInNextNDays } from './utils';

export function useHolidayNotice(currencies: string[]) {
  const isUserInformedAboutHoliday =
    sessionStorage.getItem(SESSION_STORAGE_KEY) || '';

  React.useEffect(() => {
    if (isUserInformedAboutHoliday !== '' || currencies.length === 0) {
      return;
    }

    const holidays = searchHolidaysInNextNDays(currencies);

    holidays.forEach(holiday => {
      const { holidayDate, currencyCodes } = holiday;
      toastInformationMessage(
        `For ${currencyCodes.join(', ')}, it is a bank holiday (${format(
          holidayDate,
          'PPP',
          {
            locale: enGB
          }
        )})`
      );
    });

    sessionStorage.setItem(SESSION_STORAGE_KEY, '1');
  }, [currencies, isUserInformedAboutHoliday]);
}
