import { toast } from 'react-toastify';

/**
 * @author Arungopi
 * @param message - message to be displayed as a toast message
 * @param bgColor - true if it is success messages, false if it is failure message
 * this component is used to display toast messages on UI.
 */
export const toastMessage = (message: string, bgColor: boolean) => {
  toast.success(<h4>{message}</h4>, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    className: bgColor ? 'toaster__toastGreenBg' : 'toaster__toastRedBg'
  });
};

export const toastInformationMessage = (message: string) => {
  toast.info(<h4>{message}</h4>, {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    className: 'toaster__toastFont'
  });
};
