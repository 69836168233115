import Check from '@assets/icons/icon_check.svg?react';
import Close from '@assets/icons/icon_close.svg?react';
import DownloadBtn from '@assets/icons/icon_download.svg?react';
import RepeatBtn from '@assets/icons/icon_repeat.svg?react';
import ResetBtn from '@assets/icons/icon_reset.svg?react';
import { AccountEditableLine } from '@components/AccountEditableLine';
import { Button } from '@components/Button';
import DashboardLayout from '@components/DashboardLayout';
import { EditableCard } from '@components/EditableCard';
import { LoadingScreen } from '@components/LoadingScreen';
import { PageHeader } from '@components/PageHeader';
import { SearchBar } from '@components/SearchBar';
import useAccountAlias from '@hooks/useAccountAlias';
import useAccounts from '@hooks/useAccounts';
import useCreateUpdateAlias from '@hooks/useCreateUpdateAlias';
import routes from '@res/routes';
import { captureException } from '@sentry/react';
import classNames from 'classnames';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useAuth as useAuthKeycloak } from 'react-oidc-context';
import { Link } from 'react-router-dom';

interface SelectedAccount {
  iban: string;
  accountName: string;
}

const AccountSettings: React.FC = () => {
  const [selectedAccount, setSelectedAccount] =
    React.useState<SelectedAccount>(null);
  const [confirmCopy, setConfirmCopy] = React.useState(false);
  const [confirmSave, setConfirmSave] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const createAlias = useCreateUpdateAlias(true);
  const updateAlias = useCreateUpdateAlias(false);
  const { signinRedirect } = useAuthKeycloak();

  React.useEffect(() => {
    if (confirmCopy) {
      setSelectedAccount(null);
    }
  }, [confirmCopy]);

  React.useEffect(() => {
    if (selectedAccount !== null) {
      setConfirmCopy(false);
    }
  }, [selectedAccount]);

  const { data: bankAccounts } = useAccounts({
    refetchOnWindowFocus: false
  });

  const { data: userAliasAccounts, isLoading } = useAccountAlias({
    refetchOnWindowFocus: false
  });

  const changeDashboardPassword = () => {
    signinRedirect({
      extraQueryParams: {
        kc_action: 'UPDATE_PASSWORD'
      },
      redirect_uri: `${window.location.origin}${routes.logout}`
    });
  };

  async function handleSaveAccountAlias(accountIban: string, alias: string) {
    const accountsAlias = userAliasAccounts?.accountAlias || [];
    let index: number = -1;

    if (accountsAlias.length) {
      index = accountsAlias.findIndex(
        account => account.accountIban === accountIban
      );
      // check if this is right to show confirmation
      if (index >= 0) {
        accountsAlias[index].alias = alias;
      }
    }

    try {
      if (!accountsAlias.length || index < 0) {
        accountsAlias.push(
          await createAlias.mutateAsync({
            data: { accountAlias: { accountIban, alias } }
          })
        );
      } else {
        await updateAlias.mutateAsync({
          data: { accountAlias: accountsAlias[index] }
        });
        setTimeout(() => {}, 3000);
        await updateAlias.mutateAsync({
          data: { accountAlias: accountsAlias[index] }
        });
      }
      setConfirmSave(true);
    } catch (error) {
      captureException(error);
    }
  }

  const filteredBankAccounts = React.useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();

    return bankAccounts?.length
      ? bankAccounts.filter(
          account =>
            account.alias?.toLowerCase()?.includes(lowerCaseSearch) ||
            account.iban.toLocaleLowerCase().includes(lowerCaseSearch) ||
            account.bic.toLowerCase().includes(lowerCaseSearch)
        )
      : [];
  }, [bankAccounts, search]);

  if (isLoading) {
    return (
      <DashboardLayout
        mainContent={
          <section className="account-settings">
            <PageHeader title="Account Settings" />
            <LoadingScreen />
          </section>
        }
      />
    );
  }

  return (
    <DashboardLayout
      mainContent={
        <section className="account-settings">
          <div className="transactions__sticky-content">
            <div className="account-settings__content">
              <PageHeader title="Account Settings" />
              <div className="account-settings__settings-container">
                <Link to={routes.dashboard.resetMfa}>
                  <div className="account-settings__setting-btn">
                    <RepeatBtn />
                    <Button size="small" variant="underline">
                      Reset Multi-factor Authentation
                    </Button>
                  </div>
                </Link>
                <Button
                  size="small"
                  variant="underline"
                  onClick={changeDashboardPassword}
                >
                  <div className="account-settings__setting-btn">
                    <ResetBtn />
                    Reset Dashboard Password
                  </div>
                </Button>
                <Link to={routes.dashboard.accountStatements}>
                  <div className="account-settings__setting-btn">
                    <DownloadBtn />
                    <Button size="small" variant="underline">
                      Download account statements
                    </Button>
                  </div>
                </Link>
              </div>
              <div className="account-settings__searchbar-header">
                <h2>Your bank accounts</h2>
                <SearchBar
                  placeholder="Search your account/IBAN"
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                />
              </div>
            </div>
            <div className="account-settings__background" />
          </div>
          <ScrollContainer
            component="ul"
            className="account-settings__accounts-grid"
          >
            {filteredBankAccounts?.map(account => (
              <AccountEditableLine
                key={account.id}
                id={account.id}
                accountName={account.alias}
                iban={account.iban}
                bic={account.bic}
                isSelected={account.iban === selectedAccount?.iban}
                setSelectedAccount={setSelectedAccount}
                setConfirmCopy={setConfirmCopy}
              />
            ))}
          </ScrollContainer>
        </section>
      }
      sidebar={
        <div className="account-settings__sidebar">
          {selectedAccount !== null && (
            <EditableCard
              otherAccountsAlias={userAliasAccounts?.accountAlias?.filter(
                account => account.accountIban !== selectedAccount.iban
              )}
              onSaveAccountAlias={alias =>
                handleSaveAccountAlias(selectedAccount.iban, alias)
              }
              onCloseClick={() => setSelectedAccount(null)}
              iban={selectedAccount.iban}
              accountName={selectedAccount.accountName}
            />
          )}
          {(confirmCopy || confirmSave) && (
            <ConfirmCard
              confirmCopy={confirmCopy}
              onClose={() => {
                setConfirmSave(false);
                setSelectedAccount(null);
                setConfirmCopy(false);
              }}
            />
          )}
        </div>
      }
    />
  );
};

export default AccountSettings;

type ConfirmCardProps = {
  onClose: () => void;
  confirmCopy: boolean;
};

export const ConfirmCard: React.FC<ConfirmCardProps> = ({
  onClose,
  confirmCopy
}) => {
  return (
    <div
      className={classNames(
        'account-settings__confirm',
        confirmCopy && 'account-settings__confirm--copy'
      )}
    >
      <div className="editable-card__confirm">
        <button
          type="button"
          className="editable-card__confirm-close"
          onClick={onClose}
          aria-label="close button"
        >
          <Close />
        </button>
        <div className="editable-card__confirm-icon">
          <Check />
        </div>
        <h3 className="small">
          {confirmCopy ? 'copied to clipboard' : 'account renamed'}
        </h3>
      </div>
    </div>
  );
};
