import {
  GetCountryAllowedListResponse,
  getCountryCodeAllowedList
} from '@services/bapi/allowedCountryCodes';
import { useQuery } from 'react-query';

export default function useCountryCodeAllowedList() {
  return useQuery<GetCountryAllowedListResponse['allowedCountries']>(
    'countryCodeAllowedList',
    () =>
      getCountryCodeAllowedList()
        .then(resp => {
          return resp.data?.allowedCountries ?? [];
        })
        .catch(err => err),
    {
      onError: err => {
        console.error('Failed to get allowed country list', err);
      },
      initialData: []
    }
  );
}
