const YEAR_2024 = new Date('2024').getFullYear();

export const NUMBER_OF_DATES_TO_SEARCH_HOLIDAY = 8;
export const SESSION_STORAGE_KEY = 'isUserInformedAboutHoliday';

export const BANK_HOLIDAYS_PER_CURRENCY = {
  AED: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 3, 8),
    new Date(YEAR_2024, 3, 9),
    new Date(YEAR_2024, 3, 10),
    new Date(YEAR_2024, 3, 11),
    new Date(YEAR_2024, 3, 12),
    new Date(YEAR_2024, 3, 17),
    new Date(YEAR_2024, 5, 18),
    new Date(YEAR_2024, 11, 2),
    new Date(YEAR_2024, 11, 3)
  ],
  AUD: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 0, 26),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 3, 25),
    new Date(YEAR_2024, 5, 10),
    new Date(YEAR_2024, 7, 5),
    new Date(YEAR_2024, 9, 7),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  CAD: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 0, 26),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 4, 20),
    new Date(YEAR_2024, 6, 1),
    new Date(YEAR_2024, 7, 5),
    new Date(YEAR_2024, 9, 7),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  CHF: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 1, 19),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 9),
    new Date(YEAR_2024, 4, 20),
    new Date(YEAR_2024, 7, 1),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  CNY: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 1, 9),
    new Date(YEAR_2024, 1, 12),
    new Date(YEAR_2024, 1, 13),
    new Date(YEAR_2024, 1, 14),
    new Date(YEAR_2024, 1, 15),
    new Date(YEAR_2024, 3, 4),
    new Date(YEAR_2024, 3, 5),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 2),
    new Date(YEAR_2024, 4, 3),
    new Date(YEAR_2024, 5, 10),
    new Date(YEAR_2024, 8, 16),
    new Date(YEAR_2024, 8, 17),
    new Date(YEAR_2024, 9, 1),
    new Date(YEAR_2024, 9, 2),
    new Date(YEAR_2024, 9, 3),
    new Date(YEAR_2024, 9, 4),
    new Date(YEAR_2024, 9, 7)
  ],
  CZK: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 8),
    new Date(YEAR_2024, 6, 5),
    new Date(YEAR_2024, 9, 28),
    new Date(YEAR_2024, 11, 24),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  DKK: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 28),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 9),
    new Date(YEAR_2024, 4, 10),
    new Date(YEAR_2024, 4, 20),
    new Date(YEAR_2024, 5, 5),
    new Date(YEAR_2024, 11, 24),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26),
    new Date(YEAR_2024, 11, 31)
  ],
  EUR: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  GBP: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 6),
    new Date(YEAR_2024, 4, 27),
    new Date(YEAR_2024, 7, 26),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  HKD: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 3, 4),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 15),
    new Date(YEAR_2024, 5, 10),
    new Date(YEAR_2024, 6, 1),
    new Date(YEAR_2024, 8, 18),
    new Date(YEAR_2024, 9, 1),
    new Date(YEAR_2024, 9, 11),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  HUF: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 15),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 20),
    new Date(YEAR_2024, 7, 20),
    new Date(YEAR_2024, 9, 23),
    new Date(YEAR_2024, 10, 1),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  ILS: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 3, 23),
    new Date(YEAR_2024, 3, 29),
    new Date(YEAR_2024, 4, 14),
    new Date(YEAR_2024, 4, 27),
    new Date(YEAR_2024, 5, 19),
    new Date(YEAR_2024, 6, 4),
    new Date(YEAR_2024, 7, 13),
    new Date(YEAR_2024, 9, 3),
    new Date(YEAR_2024, 9, 4),
    new Date(YEAR_2024, 9, 11),
    new Date(YEAR_2024, 9, 24)
  ],
  JPY: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 0, 2),
    new Date(YEAR_2024, 0, 3),
    new Date(YEAR_2024, 0, 8),
    new Date(YEAR_2024, 1, 12),
    new Date(YEAR_2024, 1, 23),
    new Date(YEAR_2024, 2, 20),
    new Date(YEAR_2024, 3, 29),
    new Date(YEAR_2024, 4, 3),
    new Date(YEAR_2024, 4, 6),
    new Date(YEAR_2024, 6, 15),
    new Date(YEAR_2024, 7, 12),
    new Date(YEAR_2024, 8, 16),
    new Date(YEAR_2024, 8, 23),
    new Date(YEAR_2024, 9, 14),
    new Date(YEAR_2024, 10, 4),
    new Date(YEAR_2024, 11, 31)
  ],
  MXN: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 1, 5),
    new Date(YEAR_2024, 2, 18),
    new Date(YEAR_2024, 3, 28),
    new Date(YEAR_2024, 3, 29),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 8, 16),
    new Date(YEAR_2024, 9, 1),
    new Date(YEAR_2024, 10, 18),
    new Date(YEAR_2024, 11, 12),
    new Date(YEAR_2024, 11, 25)
  ],
  NOK: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 28),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 9),
    new Date(YEAR_2024, 4, 17),
    new Date(YEAR_2024, 4, 20),
    new Date(YEAR_2024, 11, 24),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  NZD: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 0, 2),
    new Date(YEAR_2024, 0, 22),
    new Date(YEAR_2024, 0, 29),
    new Date(YEAR_2024, 1, 6),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 3, 25),
    new Date(YEAR_2024, 5, 3),
    new Date(YEAR_2024, 5, 28),
    new Date(YEAR_2024, 9, 28),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  PLN: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 3),
    new Date(YEAR_2024, 4, 30),
    new Date(YEAR_2024, 7, 15),
    new Date(YEAR_2024, 10, 1),
    new Date(YEAR_2024, 10, 11),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  RON: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 0, 2),
    new Date(YEAR_2024, 0, 24),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 3),
    new Date(YEAR_2024, 4, 6),
    new Date(YEAR_2024, 5, 24),
    new Date(YEAR_2024, 7, 15),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ],
  SAR: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 1, 22),
    new Date(YEAR_2024, 3, 10),
    new Date(YEAR_2024, 3, 11),
    new Date(YEAR_2024, 3, 12)
  ],
  SEK: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 9),
    new Date(YEAR_2024, 5, 6),
    new Date(YEAR_2024, 5, 21),
    new Date(YEAR_2024, 11, 24),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26),
    new Date(YEAR_2024, 11, 31)
  ],
  SGD: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 1, 12),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 10),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 4, 22),
    new Date(YEAR_2024, 5, 17),
    new Date(YEAR_2024, 7, 9),
    new Date(YEAR_2024, 9, 31),
    new Date(YEAR_2024, 11, 25)
  ],
  TRY: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 3, 10),
    new Date(YEAR_2024, 3, 11),
    new Date(YEAR_2024, 3, 12),
    new Date(YEAR_2024, 3, 23),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 5, 17),
    new Date(YEAR_2024, 5, 18),
    new Date(YEAR_2024, 5, 19),
    new Date(YEAR_2024, 6, 15),
    new Date(YEAR_2024, 7, 30),
    new Date(YEAR_2024, 9, 29)
  ],
  USD: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 0, 15),
    new Date(YEAR_2024, 1, 19),
    new Date(YEAR_2024, 4, 27),
    new Date(YEAR_2024, 5, 19),
    new Date(YEAR_2024, 6, 4),
    new Date(YEAR_2024, 8, 2),
    new Date(YEAR_2024, 9, 14),
    new Date(YEAR_2024, 10, 11),
    new Date(YEAR_2024, 10, 28),
    new Date(YEAR_2024, 11, 25)
  ],
  ZAR: [
    new Date(YEAR_2024, 0, 1),
    new Date(YEAR_2024, 2, 21),
    new Date(YEAR_2024, 2, 29),
    new Date(YEAR_2024, 3, 1),
    new Date(YEAR_2024, 4, 1),
    new Date(YEAR_2024, 5, 17),
    new Date(YEAR_2024, 7, 9),
    new Date(YEAR_2024, 8, 24),
    new Date(YEAR_2024, 11, 16),
    new Date(YEAR_2024, 11, 25),
    new Date(YEAR_2024, 11, 26)
  ]
};
