import { getTicketDetails, Ticket } from '@services/bapi/jira';
import { useQuery } from 'react-query';

export default function useTicketDetails(
  ticketId: Ticket['ticketId'],
  isOpen: boolean
): any {
  return useQuery(
    ['ticketDetails', ticketId],
    () => getTicketDetails(ticketId).then(res => res.data),
    {
      refetchOnWindowFocus: true,
      enabled: isOpen,
      refetchInterval: 850 * 1000
    }
  );
}
