import { LoadingScreen } from '@components/LoadingScreen';
import { SearchBar } from '@components/SearchBar';
import { TransferLine } from '@components/TransferLine';
import TransferNewContactForm, {
  TransferCreateNewContact
} from '@components/TransferNewContactForm';
import { TransferTabs } from '@components/TransferTabs';
import useAccountAddress from '@hooks/useAccountAddress';
import useAccounts from '@hooks/useAccounts';
import useBeneficiaries from '@hooks/useBeneficiaries';
import { useTransfer } from '@hooks/useTransfer';
import { Beneficiary } from '@services/bapi/beneficiaries';
import ScrollContainer from 'react-indiana-drag-scroll';

import { SwitchWithLabel } from '@components/Switch';
import { BankAccountWithCurrencies } from '@services/bapi/accounts';
import React, { useState } from 'react';
import { useFilteredBeneficiaries } from '../../Beneficiaries/useFilteredBeneficiaries';

export const TransferAccountToCredit: React.FC = () => {
  const { selectedTab } = useTransfer();

  return (
    <>
      <TransferTabs />
      {selectedTab === 'make-transfer' && <TransferNewContactForm />}
      {selectedTab === 'beneficiaries' && <BeneficiaryList />}
      {selectedTab === 'my-accounts' && <MyAccounts />}
    </>
  );
};
const BeneficiaryList: React.FC = () => {
  const { setSelectedTab, handleContactInfo } = useTransfer();
  const { data: beneficiaries, isLoading } = useBeneficiaries();

  const {
    filteredBeneficiaries,
    isGlobalSelected,
    isPersonalSelected,
    toggleGlobalSwitcher,
    togglePersonalSwitcher
  } = useFilteredBeneficiaries(beneficiaries);

  const handleSelect = React.useCallback(
    (beneficiary: Beneficiary) => {
      handleContactInfo(
        {
          accountNumber: beneficiary.accountNumber,
          bankKey: beneficiary.bankNumber,
          iban: beneficiary.iban,
          name: beneficiary.name,
          accountHolderName: '',
          bankName: beneficiary.bankName,
          transferType: beneficiary.transferType,
          swiftBic: beneficiary.bic,
          email: beneficiary.email,
          telephone: beneficiary.telephone,
          street: beneficiary.street,
          houseNumber: beneficiary.houseNumber,
          countryCode: beneficiary.countryCode,
          zipCode: beneficiary.zipCode,
          city: beneficiary.city,
          label: beneficiary.label
        },
        undefined,
        false
      );

      setSelectedTab('make-transfer');
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleContactInfo]
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="beneficiaries__list-container">
      <div className="beneficiaries__list-filters">
        <SwitchWithLabel
          onChange={togglePersonalSwitcher}
          checked={isPersonalSelected}
          label="Show personal beneficiaries"
          name="personal"
        />
        <SwitchWithLabel
          onChange={toggleGlobalSwitcher}
          checked={isGlobalSelected}
          label="Show global beneficiaries"
          name="global"
        />
      </div>
      <ul className="beneficiaries__list">
        {filteredBeneficiaries.length > 0 ? (
          filteredBeneficiaries?.map((beneficiary, index) => {
            return (
              <TransferLine
                // HACK: as backend doesn't validate bens, it might contains duplicates
                // eslint-disable-next-line react/no-array-index-key
                key={beneficiary.id + beneficiary.label + index}
                onAccounClick={() => handleSelect(beneficiary)}
                id={beneficiary.id}
                bankName={beneficiary.bankName}
                iban={beneficiary.iban}
                accountNumber={beneficiary.accountNumber}
                bankNumber={beneficiary.bankNumber}
                bic={beneficiary.bic}
                label={beneficiary.label}
                accountName={!beneficiary.label && beneficiary.name}
                className={beneficiary.isGlobal && 'beneficiaries__list-list'}
              />
            );
          })
        ) : (
          <div className="beneficiaries__empty">
            <p className="paragraph--large semiBold">
              No beneficiaries available.
            </p>
          </div>
        )}
      </ul>
    </div>
  );
};

const MyAccounts: React.FC = () => {
  const { transferState, handleContactInfo } = useTransfer();
  const { data: accounts } = useAccounts({
    select: results => {
      const newAccounts = [...results].filter(
        account => account.iban !== transferState.accountToDebitId
      );
      const debitAccountCurrencies = transferState.debitAccount.currencies.map(
        ({ code }) => code
      );

      return newAccounts.filter(account => {
        const containsArray = account.currencies.map(currency =>
          debitAccountCurrencies.includes(currency.code)
        );

        if (containsArray.includes(true)) {
          return true;
        }

        return false;
      });
    }
  });
  const [search, setSearch] = useState('');

  const { data: address } = useAccountAddress();

  const handleAccountSelect = React.useCallback(
    (account: BankAccountWithCurrencies) => {
      handleContactInfo(
        {
          name: account.alias || account.productDescription,
          accountHolderName: account.accountHolderName,
          bankName: account.bankAccountId,
          swiftBic: account.bic,
          street: address?.addressStreet,
          houseNumber: address?.addressHouseId,
          countryCode: address?.addressCountry,
          zipCode: address?.addressPostalCode,
          city: address?.addressCity,
          iban: account.iban,
          transferType: 'SEPA'
        } as TransferCreateNewContact,
        true
      );
    },
    [address, handleContactInfo]
  );

  const filteredBankAccounts = React.useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();
    return accounts?.length
      ? accounts.filter(
          account =>
            account.alias?.toLowerCase()?.includes(lowerCaseSearch) ||
            account.iban.toLocaleLowerCase().includes(lowerCaseSearch) ||
            account.bic.toLowerCase().includes(lowerCaseSearch)
        )
      : [];
  }, [accounts, search]);

  return (
    <div className="transfer-to-debit transfer-to-debit--no-border-top">
      <div className="transfer-to-debit__search-bar-container">
        <SearchBar
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search your account"
        />
      </div>
      {filteredBankAccounts.length > 0 ? (
        <ScrollContainer
          component="ul"
          className="transfer-to-debit__account-list"
        >
          {filteredBankAccounts?.map(account => (
            <TransferLine
              key={account.id}
              id={account.id}
              onAccounClick={() => handleAccountSelect(account)}
              accountName={account.alias}
              bic={account.bic}
              iban={account.iban}
              isSelected={transferState.accountToCreditIban === account.iban}
            />
          ))}
        </ScrollContainer>
      ) : (
        <h2 className="transfer-to-debit__no-results">No results</h2>
      )}
    </div>
  );
};
