import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useAuth as useAuthKeycloak } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';

import { KEYCLOAK_TOKEN_KEY, authChannelMessages } from '@res/constants';
import { routes } from '@res/routes';
import { MeResponse } from '@services/bapi/auth';
import { clearCookies, setbapiAuthHeader } from '@services/bapi/client';
import { getBooleanCookie } from '@utils/getCookie';
import { useCookies } from 'react-cookie';
import useAccountAddress from './useAccountAddress';
import useAccountAlias from './useAccountAlias';
import useMe from './useMe';
import { useGetFeaturesList } from './useFeatures';
import { SESSION_STORAGE_KEY } from './useHolidayNotice/const';

interface AuthContextValue extends AuthState {
  handleLogin: () => Promise<void>;
  handleLogout: () => Promise<void>;
  setAuthState: Dispatch<SetStateAction<AuthState>>;
  features: any[];
}

interface AuthState {
  user?: MeResponse;
  hasOTP: boolean;
}

export const AuthContext = React.createContext({} as AuthContextValue);

let authChannel: BroadcastChannel;

export const AuthProvider: React.FC<PropsWithChildren<any>> = ({
  children
}) => {
  const auth = useAuthKeycloak();
  const { isAuthenticated, isLoading, signinRedirect, signoutRedirect } = auth;
  const [authState, setAuthState] = React.useState<AuthState>(initialState);
  const [httpClientInitialized, setHttpClientInitialized] =
    React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const location = useLocation();

  const { isFetched } = useMe({
    enabled: httpClientInitialized,
    onSuccess: data => {
      const {
        otpConfirmed: hasOTP,
        mobileVerified: hasMobile,
        resetTransferMfaInit
      } = data;
      setAuthState({ user: data, hasOTP });
      setCookie('otp', String(hasOTP));
      setCookie('mobileVerified', String(hasMobile));
      setCookie('resetTransferMFAInit', String(resetTransferMfaInit));
      setCookie(KEYCLOAK_TOKEN_KEY, String(auth.user.access_token));
      postLoginAction();
      authChannel.postMessage(authChannelMessages.login);
    },
    staleTime: 15 * 60 * 1000 // 15 min
  });

  const { data } = useGetFeaturesList(isFetched);
  useAccountAddress({ enabled: isFetched });
  useAccountAlias({ enabled: isFetched });

  const handleLogout = async () => {
    setHttpClientInitialized(false);
    authChannel?.postMessage(authChannelMessages.logout);
    logoutActions();
    signoutRedirect();
  };

  const postLoginAction = React.useCallback(() => {
    const resetTransferMFAInitCookie = getBooleanCookie('resetTransferMFAInit');
    const mobileVerifiedCookie = getBooleanCookie('mobileVerified');
    const otpCookie = getBooleanCookie('otp');

    if (!otpCookie || !mobileVerifiedCookie) {
      navigate(routes.auth, { replace: true });
      return;
    }

    if (resetTransferMFAInitCookie) {
      navigate(routes.resetTransferMfa, { replace: true });
      return;
    }

    if (
      location.hash.includes('#state=') ||
      (!location.pathname.includes('dashboard') &&
        !location.pathname.includes('logout'))
    ) {
      navigate(routes.dashboard.accountOverview, { replace: true });
    }
  }, [location, navigate]);

  React.useEffect(() => {
    if (!authChannel) {
      authChannel = new BroadcastChannel('auth');
    }

    authChannel.onmessage = message => {
      switch (message.data) {
        case authChannelMessages.logout:
          logoutActions();
          break;
        case authChannelMessages.login:
          postLoginAction();
          break;
        default:
          break;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postLoginAction]);

  async function logoutActions() {
    clearCookies(removeCookie);
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    setAuthState({ ...initialState });
    navigate(routes.home, { replace: true });
  }

  const handleLogin = React.useCallback(async () => {
    await signinRedirect();
  }, [signinRedirect]);

  React.useEffect(() => {
    // TODO: check it
    if (!isAuthenticated && !isLoading) {
      handleLogin();
    }

    if (isAuthenticated) {
      setbapiAuthHeader(auth.user.access_token);
      setHttpClientInitialized(!cookies.deniedAccess);
      // NOTE: setCookie moved to useMe()
      // setCookie({}, KEYCLOAK_TOKEN_KEY, auth.user.access_token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLogin, isAuthenticated, isLoading, auth.user]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        handleLogin,
        handleLogout,
        setAuthState,
        features: data
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);

const initialState: AuthState = {
  user: null,
  hasOTP: false
};
