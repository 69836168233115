import { addSpacesEvery4Letters } from '@utils';
import { FC } from 'react';

interface BankAccountRowHeaderProps {
  title?: string;
  iban?: string;
  bic?: string;
}

export const BankAccountRowHeader: FC<BankAccountRowHeaderProps> = ({
  title,
  iban,
  bic
}) => {
  return (
    <div className="bank-account-row-header">
      {title && <h2>{title}</h2>}
      {iban && bic && (
        <div className="bank-account-row-header__details-container">
          <div className="bank-account-row-header__detail">
            <h5 className="secondary">Iban</h5>
            <h4>{addSpacesEvery4Letters(iban)}</h4>
          </div>
          <div className="bank-account-row-header__detail">
            <h5 className="secondary">Bic</h5>
            <h4>{bic}</h4>
          </div>
        </div>
      )}
    </div>
  );
};
