import { FieldError, Input } from '@components/Input';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';

type FileFormatSelectorProps = {
  inline?: boolean;
  register: any;
  errors: any;
  watch: any;
  resetField: any;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  currentStep?: number;
  nextStep?: { index: number; name: string };
  startDate?: Date;
};

export const CustomDateSelector: FC<FileFormatSelectorProps> = ({
  inline,
  register,
  errors,
  watch,
  resetField,
  setCurrentStep,
  currentStep,
  nextStep,
  startDate
}) => {
  const resetFields = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const period = e.currentTarget.value;

    if (period !== 'CUSTOM') {
      if (nextStep) {
        setCurrentStep(nextStep.index);
        resetField(nextStep.name, { keepTouched: false });
      }
      resetField('date', { keepTouched: false });
    } else if (setCurrentStep) setCurrentStep(nextStep.index - 1);
  };

  // const disableNextStep = (date) => {
  //   if (setCurrentStep && watch(date)) {
  //     setCurrentStep(nextStep.index);
  //   }
  // };

  useEffect(() => {
    if (
      setCurrentStep &&
      watch('period') === 'CUSTOM' &&
      watch('date.startDate') &&
      watch('date.endDate')
    ) {
      setCurrentStep(nextStep.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('date.startDate'), watch('date.endDate')]);

  const isDisabled = useMemo(
    () => (!nextStep ? false : !(currentStep >= nextStep.index - 1)),
    [currentStep, nextStep]
  );

  const selectedStartDate = useMemo(
    () => watch('date.startDate'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch('date.startDate')]
  );

  const selectedEndDate = useMemo(
    () => watch('date.endDate'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch('date.endDate')]
  );

  const selectedCurrency = useMemo(
    () => watch('currency')?.[0]?.value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch('currency')]
  );

  const calcStartDate = useMemo(() => {
    if (startDate) {
      if (selectedCurrency === 'All') {
        return {
          min: selectedEndDate
            ? dayjs(selectedEndDate).subtract(1, 'month').format('YYYY-MM-DD')
            : dayjs(startDate).format('YYYY-MM-DD'),
          max: selectedEndDate || dayjs().format('YYYY-MM-DD')
        };
      }

      return {
        min: selectedEndDate
          ? dayjs(selectedEndDate).subtract(180, 'd').format('YYYY-MM-DD')
          : dayjs(startDate).format('YYYY-MM-DD'),
        max: selectedEndDate || dayjs().format('YYYY-MM-DD')
      };
    }

    return { min: null, max: dayjs().format('YYYY-MM-DD') };
  }, [startDate, selectedCurrency, selectedEndDate]);

  const calcEndDate = useMemo(() => {
    if (startDate) {
      if (selectedCurrency === 'All') {
        if (selectedStartDate && dayjs(selectedStartDate).date() !== 1) {
          return {
            min: selectedStartDate,
            max:
              dayjs(selectedStartDate).add(1, 'month').date() ===
              dayjs(selectedStartDate).date()
                ? dayjs(selectedStartDate).add(1, 'month').format('YYYY-MM-DD')
                : dayjs(selectedStartDate)
                    .add(1, 'month')
                    .add(1, 'day')
                    .format('YYYY-MM-DD')
          };
        }
        return {
          min: selectedStartDate || dayjs(startDate).format('YYYY-MM-DD'),
          max:
            selectedStartDate && dayjs(selectedStartDate).date() === 1
              ? dayjs(selectedStartDate).endOf('month').format('YYYY-MM-DD')
              : dayjs().format('YYYY-MM-DD')
        };
      }

      return {
        min: selectedStartDate || dayjs(startDate).format('YYYY-MM-DD'),
        max:
          selectedStartDate &&
          dayjs(selectedStartDate).isBefore(dayjs().subtract(180, 'd'))
            ? dayjs(selectedStartDate).add(180, 'd').format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD')
      };
    }

    return { min: null, max: dayjs().format('YYYY-MM-DD') };
  }, [startDate, selectedCurrency, selectedStartDate]);

  return (
    <div
      className={classNames(
        'filters',
        !currentStep && 'enabled',
        currentStep && currentStep >= nextStep.index - 1 ? 'enabled' : null
      )}
    >
      <div className="filters__radio-buttons-container">
        <h3 className="small">Date</h3>
        <div className={classNames(inline && 'filters__radio-group')}>
          <label htmlFor="week" className="filters__radio">
            <input
              id="week"
              type="radio"
              className="filters__radio-input"
              value="WEEK"
              {...register('period')}
              disabled={isDisabled}
              onClick={e => resetFields(e)}
            />
            <div
              className={classNames(
                'filters__radio-radio',
                !currentStep && 'enabled',
                currentStep && currentStep >= nextStep.index - 1
                  ? 'enabled'
                  : null
              )}
            />
            <h3 className="small">Last 7 days</h3>
          </label>
          <label htmlFor="month" className="filters__radio">
            <input
              id="month"
              type="radio"
              className="filters__radio-input"
              value="MONTH"
              {...register('period')}
              disabled={isDisabled}
              onClick={e => resetFields(e)}
            />
            <div
              className={classNames(
                'filters__radio-radio',
                !currentStep && 'enabled',
                currentStep && currentStep >= nextStep.index - 1
                  ? 'enabled'
                  : null
              )}
            />
            <h3 className="small">Last 30 days</h3>
          </label>
          <label htmlFor="custom" className="filters__radio">
            <input
              id="custom"
              type="radio"
              className="filters__radio-input"
              value="CUSTOM"
              {...register('period')}
              disabled={isDisabled}
              onClick={e => resetFields(e)}
            />
            <div
              className={classNames(
                'filters__radio-radio',
                !currentStep && 'enabled',
                currentStep && currentStep >= nextStep.index - 1
                  ? 'enabled'
                  : null
              )}
            />
            <h3 className="small">Specific Period</h3>
          </label>
        </div>
        {errors?.period?.message && (
          <FieldError error={errors?.period?.message ?? ''} />
        )}
      </div>
      {watch('period') === 'CUSTOM' && (
        <div className="filters__date-inputs-container">
          {errors?.date?.startDate && (
            <FieldError error={errors?.date?.startDate?.message ?? ''} />
          )}
          <Input
            variant="background"
            className="filters__input"
            type="date"
            placeholder="From"
            name="date.startDate"
            register={register}
            min={calcStartDate.min}
            max={calcStartDate.max}
          />

          {errors?.date?.endDate && (
            <FieldError error={errors?.date?.endDate?.message ?? ''} />
          )}
          <Input
            className="filters__input"
            variant="background"
            type="date"
            placeholder="To"
            name="date.endDate"
            register={register}
            min={calcEndDate.min}
            max={calcEndDate.max}
          />
        </div>
      )}
    </div>
  );
};
