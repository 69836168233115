import {
  GetTransactionsFilter,
  Transaction,
  getTransactions,
  transactionDTO
} from '@services/bapi/transactions';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { ValidationError, array } from 'yup';

export type AccountLastTransactionsResponse = {
  transactions: Transaction[];
  nextPage?: number;
  total?: number;
};

export default function useTransactions(
  filter: GetTransactionsFilter,
  options?: UseQueryOptions<any>
): UseQueryResult<AccountLastTransactionsResponse> {
  return useQuery<AccountLastTransactionsResponse>(
    ['getAccountLastTransactions', { ...filter }],
    async () => {
      try {
        const response = await getTransactions(filter);
        const transactions = await array()
          .of(transactionDTO)
          .validate(response.data.transactions);
        return {
          transactions,
          total: response.data.total,
          nextPage: response.data.nextPageSkip
        };
      } catch (error) {
        if (error instanceof ValidationError) {
          throw Error(
            `ValidationError getTransactions ${JSON.stringify(error?.message)}`
          );
        }
        throw error;
      }
    },
    {
      ...options,
      onError: err => {
        console.error(err);
        return {
          transactions: []
        };
      },
      initialData: {
        transactions: []
      }
    }
  );
}
