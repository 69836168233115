import { getRequestTypes, RequestTypesResponse } from '@services/bapi/jira';
import { useQuery, UseQueryOptions } from 'react-query';

export default function useJiraRequestTypes(
  options?: UseQueryOptions<RequestTypesResponse>
) {
  return useQuery(
    'jiraRequestTypes',
    () => getRequestTypes().then(res => res.data),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      ...options
    }
  );
}
