import { FC, PropsWithChildren } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

interface TransactionListProps {
  title?: string;
  isSmallMargin?: boolean;
}

export const TransactionList: FC<PropsWithChildren<TransactionListProps>> = ({
  title,
  isSmallMargin,
  children
}) => {
  return (
    <ScrollContainer
      component="div"
      className={isSmallMargin ? 'transaction-list-short' : 'transaction-list'}
    >
      {title && <h2 className="transaction-list__title">{title}</h2>}
      <ul>{children}</ul>
    </ScrollContainer>
  );
};
