import { bapi } from './client';

export type ValidCurrenciesGETResponse = string[];

export async function getValidCurrencies(accountID: string) {
  return bapi
    .get<ValidCurrenciesGETResponse>(
      `/v1/fx/currencies?customerAccount=${accountID}`
    )
    .then(r => r.data);
}
