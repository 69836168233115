import { AxiosResponse } from 'axios';
import { bapi } from './client';
// import MockAdapter from 'axios-mock-adapter';

export type FeatureStatus = 'Active' | 'Inactive';

export type Feature = {
  feature: string;
  status: FeatureStatus;
};

export type GetFeaturesListResponse = {
  features: Feature[];
};

export type GetFeatureResponse = {
  status: FeatureStatus;
};

// const mock = new MockAdapter(bapi);

// const mockResponse = [
//   {
//     feature: 'Account Overview',
//     status: 'Active'
//   },
//   {
//     feature: 'Transfer',
//     status: 'Active'
//   },
//   {
//     feature: 'Foreign Exchange',
//     status: 'Inactive'
//   },
//   {
//     feature: 'Transactions',
//     status: 'Active'
//   },
//   {
//     feature: 'Beneficiaries',
//     status: 'Active'
//   },
//   {
//     feature: 'Account Settings',
//     status: 'Active'
//   },
//   {
//     feature: 'Help Center',
//     status: 'Active'
//   }
// ];

// mock.onGet('/v1/feature/list').reply(200, mockResponse);
// mock.onAny().passThrough();

export const getFeaturesList = (): Promise<
  AxiosResponse<GetFeaturesListResponse>
> => {
  return bapi.get<GetFeaturesListResponse>(`/v1/feature/list`);
};

export const getFeature = (
  feature: string
): Promise<AxiosResponse<GetFeatureResponse>> => {
  return bapi.get<GetFeatureResponse>(`/v1/feature/${feature}`);
};
