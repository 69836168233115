import Pdf from '@assets/icons/icon_pdf.svg?react';
import PdfMore from '@assets/icons/icon_pdfMore.svg?react';
import { Button } from '@components/Button';
import DashboardLayout from '@components/DashboardLayout';
import { PageHeader } from '@components/PageHeader';
import { PageSecondaryHeader } from '@components/PageSecondaryHeader';
import routes from '@res/routes';
import React from 'react';
import { Link } from 'react-router-dom';

const Statements: React.FC = () => {
  return (
    <DashboardLayout
      mainContent={
        <div className="statements-reports">
          <PageHeader title="Account Statements" />
          <PageSecondaryHeader title="Download account statements" />
          <div className="dashboard-layout__parent-container">
            <div className="statements-reports__options">
              <Link
                style={{ width: '100%' }}
                to={routes.dashboard.preGeneratedReport}
                replace
              >
                <Button
                  variant="outline"
                  className="statements-reports__option"
                >
                  <Pdf />
                  Pre-generated report
                </Button>
              </Link>
              <Link
                style={{ width: '100%' }}
                to={routes.dashboard.customizedReport}
                replace
              >
                <Button
                  variant="outline"
                  className="statements-reports__option"
                >
                  <PdfMore />
                  Customized report
                </Button>
              </Link>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Statements;
