import Copy from '@assets/icons/icon_copy.svg?react';
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { LogoutAlert } from '@components/LogoutAlert';
import MobileNumbercomp from '@components/MobileNumberRegistration';
import { Modal } from '@components/Modal';
import { OneTimeInput } from '@components/OneTimeInput';
import { toastMessage } from '@components/Toaster';
import { captureException } from '@sentry/react';
import { confirmOTP, generateOTPQrCode } from '@services/bapi/auth';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useAuth as useAuthKeycloak } from 'react-oidc-context';
import { useQuery } from 'react-query';

type authProps = {
  resetMFA?: boolean;
};

const Auth: React.FC<authProps> = ({ resetMFA = true }) => {
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const [keyModalOpen, setKeyModalOpen] = React.useState(false);
  const [enableMobileNum, setEnableMobileNum] = React.useState(false);
  const [formStep, setFormStep] = React.useState('1');
  const [value, setValue] = React.useState('');
  const [deviceName, setDeviceName] = React.useState('');
  const [isdisabled, setIsdisabled] = React.useState(false);
  const [mobileDisabled, setMobileDisabled] = React.useState(true);
  const [error, setError] = React.useState(null);
  const formRef = React.useRef(null);
  const [manualMFACode, setManualMFACode] = React.useState('');
  const qrCodeRef = React.useRef<HTMLDivElement>();
  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false);
  const [resetModal, setResetModal] = React.useState(false);
  const { isAuthenticated } = useAuthKeycloak();

  const [cookies, setCookie] = useCookies();
  const padding = resetMFA ? '' : 'auth__padding';
  const image = resetMFA ? '' : 'auth__image';
  const onCopyClick = () => {
    navigator.clipboard.writeText(`${manualMFACode}`);
    setCopiedToClipboard(true);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      cookies.mobileVerified === 'false' ||
      cookies.mobileVerified === false
    ) {
      setEnableMobileNum(true);
    } else {
      setEnableMobileNum(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useQuery('qrcode', () => generateOTPQrCode(), {
    refetchOnWindowFocus: false,
    enabled:
      !!isAuthenticated && (cookies.otp === 'false' || cookies.otp === false),
    onSuccess(response) {
      const deleteText = 'Manually pair with ';
      qrCodeRef.current.innerHTML = response.data;
      let initialManualMFACode = qrCodeRef.current
        .querySelector('a')
        .getAttribute('title');

      if (initialManualMFACode.includes(deleteText)) {
        initialManualMFACode = initialManualMFACode.replace(deleteText, '');
      }

      setManualMFACode(initialManualMFACode);
    },
    onError(err) {
      captureException(err);
    }
  });

  const supportedAppps = [
    'Microsoft Authenticator',
    'Google Authenticator',
    'FreeOTP',
    'Other authenticators'
  ];

  const handleConfirmOtp = async () => {
    try {
      await confirmOTP(value).then(() => {
        setCookie('otp', 'true');
        let message = 'Transfer MFA set Successfully.';
        if (resetMFA) {
          message += ' Now Register your Mobile Number.';
        }
        toastMessage(message, true);

        if (enableMobileNum) {
          setMobileDisabled(false);
          setIsdisabled(true);
          setFormStep('2');
        } else {
          redirectToLogout();
        }
      });
    } catch (_) {
      setError('Wrong pin code');
    }
  };

  const redirectToLogout = () => {
    setResetModal(true);
  };
  const handleCodeConfirmation = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (value.length === 6) {
        handleConfirmOtp();
      } else {
        setError(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  // eslint-disable-next-line no-nested-ternary
  const classDiv = enableMobileNum
    ? formStep === '1'
      ? ''
      : 'mobile__faded'
    : 'auth__container';
  return (
    <section className={classNames('auth', image)}>
      <div className={classNames('auth__container--general', padding)}>
        <h1>Welcome to iBAN-X</h1>
        <p className="paragraph--large">
          Please set-up your Multi-factor Authentication. In order to prevent
          unauthorized access, security concern and enjoy iBAN-X platform, you
          must set-up multi-factor authentication.
        </p>
      </div>
      <div
        className={classNames(enableMobileNum && 'auth__container', padding)}
      >
        <div className={classNames(classDiv, padding)}>
          <div className="auth__container--info">
            <ol>
              <li>
                <p className="paragraph--large">
                  Please use your preferred Authenticator or one of the
                  following below to scan the QR code:
                </p>
                <ul className="auth__list--disk-unordered">
                  {supportedAppps.map(app => {
                    if (app === 'totpAppMicrosoftAuthenticatorName') {
                      return null;
                    }
                    return (
                      <li key={app}>
                        <h3>{app}</h3>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li>
                <p className="paragraph--large">
                  Open your Authenticator and scan the following QR code.
                </p>
                <div className="auth__info-grid">
                  <div className="auth__code-container">
                    <div ref={qrCodeRef} />
                    <Button
                      className="auth__how-button  auth__how-button--qr-code"
                      size="small"
                      variant="underline"
                      onClick={() => setQrModalOpen(true)}
                      disabled={isdisabled}
                    >
                      Unable to scan?
                    </Button>
                  </div>
                  <div className="auth__code-container auth__code-container--key">
                    <div className="secondary auth__code-container--label">
                      <p className="secondary">KEY</p>
                      <Button
                        variant="underline"
                        size="small"
                        onClick={() => onCopyClick()}
                        type="button"
                        disabled={isdisabled}
                      >
                        {copiedToClipboard ? 'copied to clipboard' : <Copy />}
                      </Button>
                    </div>
                    <textarea
                      className="auth__text-area"
                      rows={4}
                      wrap="soft"
                      disabled
                      value={manualMFACode}
                    />
                    <Button
                      className="auth__how-button"
                      size="small"
                      variant="underline"
                      onClick={() => setKeyModalOpen(true)}
                      disabled={isdisabled}
                    >
                      How to use it
                    </Button>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <form ref={formRef} className="auth__container--form">
            <ol className="auth__form-item" start={3}>
              <li>
                <p className="paragraph--large">
                  Enter the One-time code provided by the application and
                  provide a Device Name to help you manage your One-time code
                  devices. Click Submit to finish the setup.
                </p>
              </li>
            </ol>
            <p>One-time code</p>
            <OneTimeInput
              formMounted={formRef !== null}
              setValue={setValue}
              error={error}
              disabled={isdisabled}
              fields={6}
            />
            <div className="auth__form-item auth__form-item--input-container">
              <p>Device Name</p>
              <Input
                name="deviceName"
                value={deviceName}
                onChange={e => setDeviceName(e.target.value)}
                disabled={isdisabled}
              />
            </div>
            <Button
              type="button"
              onClick={handleCodeConfirmation}
              className={classNames(isdisabled && 'auth__inActive')}
              disabled={isdisabled}
            >
              Submit
            </Button>
          </form>
        </div>
        {enableMobileNum && (
          <div
            className={classNames(
              formStep === '2' ? 'mobile' : 'mobile mobile__faded'
            )}
          >
            <h1 className="list-marker">4. </h1>
            <MobileNumbercomp
              classes="auth-mobile"
              onConfirmed={redirectToLogout}
              isAuth
              mobileDisabled={mobileDisabled}
            />
          </div>
        )}
      </div>
      {resetModal && <LogoutAlert flag />}
      {qrModalOpen && (
        <Modal>
          <div className="auth__qr-code-modal">
            <p className="paragraph--large semiBold">
              If you are unable to scan the QR code, please try the following:
            </p>
            <ul className="auth__list--disk-unordered">
              <li>
                <p>
                  Brighter lighting (check if your mobile screen brightness is
                  too low).
                </p>
              </li>
              <li>
                <p>
                  Your camera lens may have a smudge, try cleaning your mobile
                  camera.
                </p>
              </li>
              <li>
                <p>
                  Check if your phone/tablet is tilted or in any other position
                  than upright.
                </p>
              </li>
              <li>
                <p>
                  Check if your phone/tablet camera is too close to or too far
                  from the QR code.
                </p>
              </li>
            </ul>
            <p className="paragraph--large semiBold">
              You have tried all of the above and still isn&apos;t working?
            </p>
            <ul className="auth__list--disk-unordered">
              <li>
                <p>
                  Try to copy the &quot;KEY&quot; and generate the One-time code
                  manually.
                </p>
              </li>
            </ul>
            <Button onClick={() => setQrModalOpen(false)} type="button">
              ok
            </Button>
          </div>
        </Modal>
      )}

      {keyModalOpen && (
        <Modal>
          <div className="auth__key-code-modal">
            <div>
              <p className="paragraph--large semiBold">
                How to set-up the Key and manually generate the One-time code?
                Please follow the steps below.
              </p>
              <ol className="auth__list--letters-ordered">
                <li>
                  <p>
                    Brighter lighting (check if your mobile screen brightness is
                    too low).
                  </p>
                </li>
                <li>
                  <p>
                    Your camera lens may have a smudge, try cleaning your mobile
                    camera.
                  </p>
                </li>
                <li>
                  <p>
                    Check if your phone/tablet is tilted or in any other
                    position than upright.
                  </p>
                </li>
                <li>
                  <p>
                    Check if your phone/tablet camera is too close to or too far
                    from the QR code.
                  </p>
                </li>
              </ol>
              <p className="paragraph--large semiBold">IMPORTANT</p>
              <ul className="auth__list--disk-unordered">
                <li>
                  <p>
                    If you are doing the manual set-up, please label the key as
                    &quot;Login MFA&quot; to avoid any confusion with future
                    labels needed.
                  </p>
                </li>
              </ul>
              <Button onClick={() => setKeyModalOpen(false)} type="button">
                ok
              </Button>
            </div>
            <div className="auth__image-container">
              <img
                src="/assets/images/render.png"
                alt="auth"
                width={410}
                height={743}
              />
              <h4 className="secondary">E.g. Google Authenticator</h4>
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default Auth;
