import { Button } from '@components/Button';
import { ErrorScreen } from '@components/ErrorScreen';
import { Loading } from '@components/Loading';
import { LogoutAlert } from '@components/LogoutAlert';
import { OneTimeInput } from '@components/OneTimeInput';
import routes from '@res/routes';
import { confirmSmsOtp, sendSmsOtp } from '@services/bapi/auth';
import classNames from 'classnames';
import dayjs, { duration } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useAuth as useAuthKeycloak } from 'react-oidc-context';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

const ValidateSmsOtp: React.FC = () => {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const [timer, setTimer] = useState(-1);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const { signinRedirect } = useAuthKeycloak();
  const formRef = useRef(null);
  const navigate = useNavigate();

  const validation = useMutation((pin: string) => confirmSmsOtp(pin), {
    onSuccess: () => {
      setShowSuccessModal(true);
    },
    onError: (e: string) => {
      if (e.includes('Invalid Otp') || e === 'OTP is expired') {
        setError(
          "The One-time code is invalid. Make sure you have enter it correctly or that it hasn't expired."
        );
        return;
      }

      setError('Something went wrong please try again');
    }
  });

  const onSubmit = () => {
    validation.mutateAsync(value);
  };

  const relogin = () => {
    signinRedirect({
      extraQueryParams: {
        kc_action: 'CONFIGURE_TOTP'
      },
      redirect_uri: `${window.location.origin}${routes.logout}?execution=CONFIGURE_TOTP`
    });
  };

  const sendSms = useQuery('otp', () => sendSmsOtp().then(res => res.data), {
    retry: 1,
    refetchOnWindowFocus: false,
    onSuccess(response) {
      setIsResendDisabled(true);
      setTimer(Date.now() + response.expiresIn * 1000); // receives seconds
      setPhoneNumber(response.maskedNumber);
    }
  });

  if (showSuccessModal) {
    return (
      <LogoutAlert
        continueOperation={relogin}
        cancelOperation={() => navigate(routes.dashboard.resetMfa)}
      >
        <>
          We have verified you successfully.
          <br />
          <br />
          You are about to change your Login MFA. This action cannot be undone.
          <br />
          <br />
          Please re-login to continue.
        </>
      </LogoutAlert>
    );
  }

  if (sendSms.isLoading) {
    return (
      <div className="validate-otp__loading">
        <Loading />;
      </div>
    );
  }

  const countDownFormat = ({ formatted }) => {
    return (
      <span>
        {formatted.minutes}:{formatted.seconds}
      </span>
    );
  };

  return (
    <div className="validate-otp">
      {sendSms.isError ? (
        <ErrorScreen
          error="There was an error sending your message, please try again later."
          backAction={() =>
            navigate(routes.dashboard.resetMfa, { replace: true })
          }
        />
      ) : (
        <>
          <h3>
            A verification code was sent via SMS to
            <span>{phoneNumber}</span>
          </h3>
          <div className="validate-otp__form">
            <form noValidate ref={formRef} onSubmit={e => e.preventDefault()}>
              <p>Enter OTP</p>
              <OneTimeInput
                formMounted={formRef !== null}
                setValue={setValue}
                error={error}
                fields={6}
                separateEvery={3}
              />
            </form>
            <div className="input-password__btn">
              <Link to={routes.dashboard.resetMfa} replace>
                <Button type="button">Cancel</Button>
              </Link>
              <Button
                type="submit"
                onClick={onSubmit}
                disabled={value.length < 6}
              >
                Verify
              </Button>
            </div>
          </div>
          <div className="validate-otp__expire">
            <p>
              Your code will expire in{' '}
              <Countdown
                date={timer}
                key={timer}
                renderer={countDownFormat}
                zeroPadTime={2}
                onComplete={() => setIsResendDisabled(false)}
              />
            </p>
            <Button
              variant="underline"
              type="button"
              uppercase={false}
              onClick={() => sendSms.refetch()}
              disabled={isResendDisabled}
              className={classNames(isResendDisabled && 'button--disabled')}
            >
              Resend code
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ValidateSmsOtp;
