import React, { useState } from 'react';

import { Button } from '@components/Button';
import ContactUsForm from '@components/ContactUsForm';
import { Modal } from '@components/Modal';
import { PageHeader } from '@components/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@components/Loading';
import useJiraRequestTypes from '@hooks/useJiraRequestTypes';

export type TicketState = {
  isCreated: boolean;
  isEmailSent: boolean;
  id: number | undefined;
};

interface FaqContactProps {
  onClose?: () => void;
}

const FaqContact: React.FC<FaqContactProps> = ({ onClose }) => {
  const [createdTicket, setCreatedTicket] = useState<TicketState>({
    isCreated: false,
    isEmailSent: false,
    id: undefined
  });

  const { data: requestTypeOptions, isLoading } = useJiraRequestTypes();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="faq-contact">
      <PageHeader title="Contact Support" />
      <ContactUsForm
        setCreatedTicket={setCreatedTicket}
        createdTicket={createdTicket}
        requestTypeOptions={requestTypeOptions}
      />
      {createdTicket.isCreated && (
        <Modal>
          <div className="contact-form__confirmation-modal">
            <h3>Thank you for contacting iBAN-X.</h3>
            <p>We have registered your request.</p>
            <p>
              Reference number: <b>{createdTicket.id}</b>
            </p>
            <Button onClick={onClose}>Go Back</Button>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default FaqContact;
