import { Beneficiary, updateBeneficiary } from '@services/bapi/beneficiaries';
import { useMutation, useQueryClient } from 'react-query';

export default function useUpdateBeneficiary() {
  const queryClient = useQueryClient();

  return useMutation(
    (beneficiary: Beneficiary) => updateBeneficiary(beneficiary),
    {
      onSuccess: resp => {
        const updatedBeneficiary = resp.data;
        queryClient.setQueryData(
          'beneficiaries',
          queryClient.setQueryData<Beneficiary[]>('beneficiaries', oldData =>
            oldData.map(beneficiary =>
              beneficiary.id === updatedBeneficiary.id
                ? { ...updatedBeneficiary }
                : beneficiary
            )
          )
        );

        queryClient.invalidateQueries('beneficiaries');
      }
    }
  );
}
