import { FieldError } from '@components/Input';
import classNames from 'classnames';
import { FC } from 'react';

type FileFormatSelectorProps = {
  register: any;
  errors: string;
  currentStep: number;
};

export const FileFormatSelector: FC<FileFormatSelectorProps> = ({
  register,
  errors,
  currentStep
}) => {
  return (
    <div className="fileFormat">
      <div className="fileFormat__files">
        <label
          htmlFor="pdf"
          className={classNames(currentStep > 3 && 'enabled')}
        >
          <input
            {...register('fileFormat')}
            type="radio"
            id="pdf"
            value="pdf"
            name="fileFormat"
            disabled={!(currentStep > 3)}
          />
          <div
            className={classNames(
              'fileFormat__files-radio',
              classNames(currentStep > 3 && 'enabled')
            )}
          />
          <h4 className={classNames(classNames(currentStep > 3 && 'enabled'))}>
            PDF
          </h4>
        </label>
        <label
          htmlFor="csv"
          className={classNames(currentStep > 3 && 'enabled')}
        >
          <input
            {...register('fileFormat')}
            type="radio"
            id="csv"
            value="csv"
            name="fileFormat"
            disabled={!(currentStep > 3)}
          />
          <div
            className={classNames(
              'fileFormat__files-radio',
              classNames(currentStep > 3 && 'enabled')
            )}
          />
          <h4 className={classNames(classNames(currentStep > 3 && 'enabled'))}>
            CSV
          </h4>
        </label>
      </div>
      {errors && <FieldError error={errors} />}
    </div>
  );
};
