import { AccountCurrency } from '@services/bapi/accounts';
import { addSpacesEvery4Letters, removeAllWhiteSpaces } from '@utils';
import classNames from 'classnames';
import { FC } from 'react';

interface TransferLineProps {
  id: string;
  onAccounClick: () => void;
  iban?: string;
  accountNumber?: string;
  bankNumber?: string;
  bic: string;
  accountName?: string;
  bankName?: string;
  label?: string;
  isSelected?: boolean;
  currencies?: AccountCurrency[];
  className?: string;
}

export const TransferLine: FC<TransferLineProps> = ({
  id,
  onAccounClick,
  accountName,
  iban,
  accountNumber,
  bankNumber,
  bic,
  bankName,
  label,
  isSelected,
  currencies,
  className
}) => {
  return (
    <li className="transfer-line" key={id}>
      <button
        className={classNames(
          'transfer-line__button',
          isSelected && 'transfer-line__button--selected',
          className
        )}
        type="button"
        onClick={onAccounClick}
      >
        {accountName && (
          <div className="transfer-line__detail">
            <h5>Account Name</h5>
            <h3 className="tiny text-uppercase">{accountName}</h3>
          </div>
        )}
        {label && (
          <div className="transfer-line__detail">
            <h5>Account Label</h5>
            <h3 className="tiny text-uppercase">{label}</h3>
          </div>
        )}
        <div
          className={classNames(
            'transfer-line__details',
            currencies && 'transfer-line__details-currencies'
          )}
        >
          {iban && (
            <div className="transfer-line__detail">
              <h5>Iban</h5>
              <h3 className="tiny text-uppercase">
                {addSpacesEvery4Letters(iban)}
              </h3>
            </div>
          )}
          {accountNumber && (
            <div className="transfer-line__detail-account">
              <div className="transfer-line__detail">
                <h5>Account number</h5>
                <h3 className="tiny text-uppercase">
                  {removeAllWhiteSpaces(accountNumber)}
                </h3>
              </div>
              {bankNumber && (
                <div className="transfer-line__detail">
                  <h5>Bank key</h5>
                  <h3 className="tiny text-uppercase">
                    {removeAllWhiteSpaces(bankNumber)}
                  </h3>
                </div>
              )}
            </div>
          )}
          <div className="transfer-line__sub-detail">
            <h5>Bic</h5>
            <h3 className="tiny text-uppercase">
              {bic} {bankName && `( ${bankName} )`}
            </h3>
          </div>
          {currencies && (
            <div className="transfer-line__sub-detail">
              <h5>Currencies</h5>
              <h3 className="tiny text-uppercase">
                {currencies.map(currency => currency.code).join(', ')}
              </h3>
            </div>
          )}
        </div>
      </button>
    </li>
  );
};
