import classNames from 'classnames';
import { FC } from 'react';

type FileFormatWithCurrencySelectorProps = {
  currency: string;
  label: string;
  fileFormats: string[];
  setValue: any;
  disabled: boolean;
};

export const FileFormatWithCurrencySelector: FC<
  FileFormatWithCurrencySelectorProps
> = ({ currency, label, fileFormats, setValue, disabled }) => {
  return (
    <div className="currency-container">
      <p>Statement {label}</p>
      <div className="currency-container__formats">
        {fileFormats.map(format => {
          return (
            <button
              type="submit"
              className={classNames(
                'currency-container__formats-single',
                !disabled && 'enabled'
              )}
              onClick={() => {
                setValue('currency', currency);
                setValue('fileFormat', format);
              }}
              disabled={disabled}
            >
              {format}
            </button>
          );
        })}
      </div>
    </div>
  );
};
