import {
  GetQuoteRequestParams,
  GetQuoteResponse,
  PostQuotePayload,
  getQuote,
  getQuoteResponseDTO,
  postQuote
} from '@services/bapi/transactions';
import { AxiosError, AxiosResponse } from 'axios';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery
} from 'react-query';
import { ValidationError } from 'yup';
import { ForeignExchangeException } from './types';

export type QuoteParams = GetQuoteRequestParams;
export type ForeignExchangeProps = PostQuotePayload;

async function fetchQuote(params: QuoteParams): Promise<GetQuoteResponse> {
  try {
    const response = await getQuote(params);
    return await getQuoteResponseDTO.validate(response.data);
  } catch (error) {
    console.error(`useQuote: ${error}`);
    if (error instanceof ValidationError) {
      throw Error(`ValidationError getQuote ${JSON.stringify(error?.message)}`);
    }
    throw error;
  }
}

export function useQuote(
  params: QuoteParams
): UseQueryResult<GetQuoteResponse, AxiosError<ForeignExchangeException, any>> {
  return useQuery<GetQuoteResponse, AxiosError<ForeignExchangeException, any>>(
    ['useQuote', { ...params }],
    async () => {
      const quote = await fetchQuote(params);
      return quote;
    },
    {
      enabled:
        params.sourceCurrency !== params.targetCurrency &&
        params.sourceCurrency !== undefined,
      refetchOnWindowFocus: false
      // refetchInterval:
    }
  );
}

export function useForeignExchange(
  props: ForeignExchangeProps
): UseMutationResult<
  AxiosResponse<boolean, any>,
  AxiosError<ForeignExchangeException, any>,
  void
> {
  return useMutation(async () => {
    const response = await postQuote(props);
    return response;
  });
}
