import { RequestCustomStatements } from '@components/CustomStatements';
import { RequestPreGeneratedStatements } from '@components/PreGeneratedStatements';
import { captureException } from '@sentry/react';
import { convertBankAccountsData } from '@utils/addCurrenciesToBankAccountsData';
import dayjs from 'dayjs';
import { bapi } from './client';

export type AccountDetailsGETResponse = {
  account?: BankAccountWithCurrencies;
};

export type GetAccountAliasResponse = {
  sub: string;
  accountAlias: {
    id: string;
    accountIban: string;
    alias: string;
  }[];
};

export type PostAccountAliasResponse = {
  id: string;
  accountIban: string;
  alias: string;
};

export type BankAccountData = {
  id: string;
  bankAccountId: string;
  bankRoutingId: string;
  bankCountryCode: string;
  accountHolderId: string;
  accountHolderDescription: string;
  accountHolderName: string;
  accountHolderAddress: string;
  productId: string;
  productDescription: string;
  managerId: string;
  managerName: string;
  currencyCode: string;
  startDate: Date;
  committedLimit: number;
  statementFrequencyCode: string;
  statementFrequencyName: string;
  currentBalance: number;
  currentBalanceTs: Date;
  availableAmount: number;
  availableAmountTs: Date;
  lifeCycleStatusCode: string;
  lifeCycleStatusName: string;
  activityStatusCode: string;
  activityStatusName: string;
  AccountLocked: boolean;
  eventId: string;
  iban: string;
  bic: string;
  alias: string;
};

export type GetAllBankAccountsResponse = {
  accounts: BankAccountData[];
};

export type AccountCurrency = {
  code: string;
  availableBalance: number;
  currentBalance: number;
  creationDate: Date;
};

export type BankAccountWithCurrencies = BankAccountData & {
  currencies?: AccountCurrency[];
};

export type GetBankAccountAddressResponse = {
  address: string;
  /** Address country code */
  addressCountry: string;
  addressCountryTxt: string;
  addressCity: string;
  addressPostalCode: string;
  addressStreet: string;
  addressHouseId: string;
  phoneNumber: string;
  emailAddress: string;
};

export type ReportFile = {
  url: string;
};

export type SaveAccountAliasData = {
  data: { accountAlias: { id?: string; accountIban: string; alias: string } };
};

export type MonthsAvailable = {
  id: string;
  accountId: string;
  period: {
    year: number;
    month: number;
  };
  createdAt: string;
};

export type AvailableReportsRequest = {
  account: string;
  year: string;
  month: number;
};

export async function getBankAccounts() {
  return convertBankAccountsData(
    (await bapi.get<GetAllBankAccountsResponse>('/v1/accounts')).data.accounts
  );
}

export async function getBankAccountDetails(accountID: string) {
  return bapi.get<AccountDetailsGETResponse>(`/v1/accounts/${accountID}`);
}

export async function getPreGeneratedReport(
  reportDetails: RequestPreGeneratedStatements
) {
  const urlParams = `accountId=${reportDetails.account}-${reportDetails.currency}&month=${reportDetails.month}&year=${reportDetails.year}&fileFormat=${reportDetails.fileFormat}`;

  return bapi
    .get<ReportFile>(`/v1/accounts/statements/pre-generated?${urlParams}`, {
      responseType: 'blob'
    })
    .catch(error => {
      captureException(error);
      if (error.response.status) {
        throw error.response.status;
      }

      throw error;
    });
}

export async function getPreGeneratedReportAvailable(
  account: AvailableReportsRequest
) {
  return bapi
    .get<
      MonthsAvailable[]
    >(`/v1/accounts/statements/pre-generated/available?iban=${account.account}&year=${account.year}&month=${account.month}`)
    .catch(error => {
      captureException(error);
      if (error.response.status) {
        throw error.response.status;
      }

      throw error;
    });
}

export async function getCustomReport(reportDetails: RequestCustomStatements) {
  return bapi
    .get<ReportFile>(
      `/v1/accounts/statements/custom-generated?accountId=${
        reportDetails.account
      }-${reportDetails.currency[0].value}&fileFormat=${
        reportDetails.fileFormat
      }&startDate=${dayjs(reportDetails.date.startDate).format(
        'YYYY-MM-DD'
      )}&endDate=${dayjs(reportDetails.date.endDate).format('YYYY-MM-DD')}`,
      { responseType: 'blob' }
    )
    .catch(error => {
      captureException(error);
      if (error.response.status) {
        throw error.response.status;
      }

      throw error;
    });
}

export async function saveAccountAlias({ data }: SaveAccountAliasData) {
  return bapi.post<PostAccountAliasResponse>(
    `/v1/accounts/alias`,
    data.accountAlias
  );
}

export async function updateAccountAlias({ data }: SaveAccountAliasData) {
  return bapi.put<PostAccountAliasResponse>(
    `/v1/accounts/alias/${data.accountAlias.id}`,
    data.accountAlias
  );
}

export async function getAccountsAlias() {
  return bapi.get<GetAccountAliasResponse>('/v1/accounts/alias');
}

export async function getAccountAddress() {
  return bapi.get<GetBankAccountAddressResponse>(`/v1/accounts/address`);
}
