import Beneficiaries from '@assets/icons/icon_beneficiaries.svg?react';
import { useTransfer } from '@hooks/useTransfer';
import classNames from 'classnames';
import React, { FC } from 'react';

export type TransferTab = 'beneficiaries' | 'make-transfer' | 'my-accounts';

export const TransferTabs: FC = () => {
  const {
    selectedTab: currentTab,
    setSelectedTab,
    handleClearContactInfo
  } = useTransfer();
  const selectedClass = ' transfer-tabs__tab--selected';

  const handleTabSelect = React.useCallback(
    (tab: TransferTab) => {
      handleClearContactInfo();
      setSelectedTab(tab);
    },
    [handleClearContactInfo, setSelectedTab]
  );
  return (
    <div className="transfer-tabs">
      <button
        onClick={() => handleTabSelect('beneficiaries')}
        type="button"
        className={classNames(
          'transfer-tabs__tab',
          currentTab === 'beneficiaries' && selectedClass
        )}
      >
        <Beneficiaries />
        <h2>Beneficiaries</h2>
        <h4>Transfer to a previous contact</h4>
      </button>
      <button
        onClick={() => handleTabSelect('make-transfer')}
        type="button"
        className={classNames(
          'transfer-tabs__tab',
          'transfer-tabs__tab--middle',
          currentTab === 'make-transfer' && selectedClass
        )}
      >
        <Beneficiaries />
        <h2>Make transfer</h2>
        <h4>Transfer to a new contact</h4>
      </button>
      <button
        onClick={() => handleTabSelect('my-accounts')}
        type="button"
        className={classNames(
          'transfer-tabs__tab',
          currentTab === 'my-accounts' && selectedClass
        )}
      >
        <Beneficiaries />
        <h2>My accounts</h2>
        <h4>Transfer to your own account</h4>
      </button>
    </div>
  );
};
