import { RightSideBar } from '@components/RightSideBar';
import React, { PropsWithChildren } from 'react';

interface DashboardLayoutProps {
  mainContent: React.ReactNode;
  sidebar?: React.ReactNode;
}

const DashboardLayout: React.FC<PropsWithChildren<DashboardLayoutProps>> = ({
  mainContent,
  sidebar
}) => {
  return (
    <div className="dashboard-layout">
      <div className="dashboard-layout__container">
        <div className="dashboard-layout__width-locker">{mainContent}</div>
      </div>
      <RightSideBar>{sidebar}</RightSideBar>
    </div>
  );
};

export default DashboardLayout;
