import { Button } from '@components/Button';
import { Modal } from '@components/Modal';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from 'react';

dayjs.extend(duration);

interface SessionEndPopupProps {
  onConfirm: () => void;
}
const { NEXT_PUBLIC_SESSION_EXPIRATION_SECONDS } = process.env;
export const SessionEndPopup: React.FC<SessionEndPopupProps> = ({
  onConfirm
}) => {
  const expirationMinutes = React.useMemo(() => {
    const expirationSeconds =
      Number(NEXT_PUBLIC_SESSION_EXPIRATION_SECONDS) || 2 * 60;
    return dayjs.duration(expirationSeconds * 1000).format('m');
  }, []);

  return (
    <Modal>
      <div className="modal__logged-out">
        <h2>Your session will be logged out in {expirationMinutes} minutes.</h2>
        <p>To continue this session please click OK</p>
        <Button type="button" onClick={onConfirm}>
          OK
        </Button>
      </div>
    </Modal>
  );
};
